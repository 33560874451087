import React, { useContext, useState } from 'react'
import { RouteComponentProps } from '@reach/router'

// import { API_URL } from '../../config'
import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'

import useConteggioEmailRicevute from '../../apiHooks/queries/useConteggioEmailRicevute'
import classNames from 'classnames'
import useInviaEmailRicevute from '../../apiHooks/mutations/useInviaEmailRicevute'

const Ricevute: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  //===================
  // LOCAL STATE
  //===================
  const [tipoPagamento, setTipoPagamento] = useState<string>('')

  //===================
  // HOOKS QUERIES
  //===================
  const { data: conteggioEmailRicevute = [] } = useConteggioEmailRicevute({
    annoScolastico,
    servizioId,
  })

  //==================
  // MUTATIONS
  //==================
  const inviaEmailRicevute = useInviaEmailRicevute()

  const inviaEmail = () => {
    inviaEmailRicevute.mutate({ servizioId, annoScolastico, tipoPagamento })
  }

  const getOptionLabel = (tipo: string) => {
    switch (tipo) {
      case 'bon':
        return 'Bonifico'
      case 'cont':
        return 'Contanti'
      case 'sdd':
        return 'SDD'
      case 'pagopa':
        return 'PagoPA'
      default:
        return tipo
    }
  }

  return (
    <div className="bg-white pt-6 pb-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-8">Ricevute</h1>
        <div className="flex items-end space-x-6">
          <div>
            <label htmlFor="tipo" className="block mb-1">
              Tipo
            </label>
            <select
              className="block form-select text-lg w-80"
              name="tipo"
              id="tipo"
              value={tipoPagamento}
              onChange={(e) => {
                setTipoPagamento(e.target.value)
              }}
            >
              <option value="">-- Seleziona --</option>
              {conteggioEmailRicevute.map((o) => {
                return (
                  <option key={o.tipo} value={o.tipo}>
                    {getOptionLabel(o.tipo)} ({o.numRicevute})
                  </option>
                )
              })}
            </select>
          </div>

          <button
            type="button"
            onClick={() => inviaEmail()}
            className={classNames(
              'block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-md leading-5 py-3 px-5 rounded transition-colors duration-150',
              { 'hover:bg-blue-700 hover:shadow-xl': tipoPagamento },
              { 'opacity-50 cursor-not-allowed': !tipoPagamento || servizioId === 3 }
            )}
            disabled={!tipoPagamento || servizioId === 3}
          >
            Invia ricevute
          </button>
        </div>
      </div>
    </div>
  )
}

export default Ricevute

import React from 'react'
import IBAN from 'iban'
import FormField, { InputType } from '../../../../components/FormField'

interface FormSingoloServizioProps {
  register: any
  errors: any
  control?: any
  watch?: any
  intestatari: any[]
  fasceCalcolo: any[]
  pagamentoSospeso?: boolean
  servizioId: number
}

const CddBergamo: React.FC<FormSingoloServizioProps> = ({
  register,
  errors,
  control,
  watch,
  intestatari,
  fasceCalcolo,
  pagamentoSospeso,
  servizioId,
}) => {
  return (
    <div>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Intestatario bolletta</div>
        <FormField
          fieldName="intestatario"
          type={InputType.Select}
          options={intestatari.map((intestatario: any) => ({
            value: intestatario.id,
            label: `${intestatario.nome} ${intestatario.cognome}`,
          }))}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="pagamentoSospeso"
          label="Bollette sospese"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <div className="text-gray-900 mt-6 mb-5 font-semibold leading-none">Pagamento SDD</div>
        <FormField
          fieldName="pagamentoSdd"
          defaultValue={pagamentoSospeso}
          label="Pagamento SDD"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="iban"
          label="IBAN"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
          validation={{
            validate: (value: string) =>
              !!watch('pagamentoSdd')
                ? (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido'
                : !value || (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido',
          }}
        />
        <FormField
          fieldName="codiceSdd"
          label="Codice SDD"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRichiestaSdd"
          label="Data richiesta SDD"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Fascia ISEE e quota variabile
        </div>
        <FormField
          fieldName="isee"
          label="Importo ISEE"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="dataScadenzaIsee"
          label={servizioId === 3 ? 'Data acquisizione ISEE' : 'Data scadenza ISEE'}
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="fasciaCalcolo"
          label="Fascia ISEE"
          type={InputType.Select}
          options={
            fasceCalcolo &&
            fasceCalcolo.map((fascia: any) => ({
              value: fascia.id,
              label: fascia.descrizione,
            }))
          }
          isClearable
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Sconti</div>
        <FormField
          fieldName="flagSconto1"
          label="Part Time"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="flagSconto2"
          label="Mantenimento posto"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Date iscrizione, inizio ed eventuale fine
        </div>
        <FormField
          fieldName="dataIscrizione"
          label="Data iscrizione"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataInizio"
          label="Data inizio"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiro"
          label="Data fine"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rimborsi e Maggiorazioni
        </div>
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaImporto"
          label="Importo una tantum Prossima bolletta"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaDescrizione"
          label="Descrizione causale una tantum"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaImporto"
          label="Importo mensile"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaDescrizione"
          label="Descrizione causale mensile"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riportoRiduzione"
          label="Riporto precedente"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Rimborsi Finanziari</div>
        <FormField
          fieldName="rimborsoFinanziarioImporto"
          label="Importo rimborso finanziario"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="rimborsoFinanziarioDescrizione"
          label="Descrizione rimborso finanziario"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Note</div>
        <FormField
          fieldName="note"
          label="Note interne"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>

      <button
        type="submit"
        className="mt-8 block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
      >
        Salva
      </button>
    </div>
  )
}

export default CddBergamo

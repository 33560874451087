import React from 'react'

interface IServizioContext {
  servizioId: number
  handleChangeServizioId: (servizioId: number) => void
}

const ServizioContext = React.createContext<IServizioContext>({
  servizioId: 0,
  handleChangeServizioId: () => {},
})

export default ServizioContext

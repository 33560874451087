interface TipoFrequenza {
  value: string
  label: string
}

export const TIPI_FREQUENZA: TipoFrequenza[] = [
  { value: 'FULL_TIME', label: 'Full-time' },
  { value: 'PROLUNGATO', label: 'Full-time con Prolungato (+10%)' },
  { value: 'PART_TIME_MATTINO', label: 'Part-time mattutino (-30%)' },
  {
    value: 'PART_TIME_POMERIDIANO_CON_PASTO',
    label: 'Part-time pomeridiano con pasto (-30%)',
  },
  {
    value: 'PART_TIME_POMERIDIANO_SENZA_PASTO',
    label: 'Part-time pomeridiano senza pasto (-45%)',
  },
]

export const getLabelByValue = (value: string) => {
  const tipoFrequenza = TIPI_FREQUENZA.find(f => f.value === value)

  return tipoFrequenza ? tipoFrequenza.label : ''
}
interface AlunnoDaControllare {
  id: number
  alunnoId: number
  cognome: string
  nome: string
  intestatarioId: number | null
  dataInizio: string | null
  dataFineAmbientamento: string | null
  tipoFrequenza: string | null
  isAsiloGratis: boolean
  asiloGratisQuotaGenitore: number | null
  pastiSettimanaliPrevisti: boolean | null
  pagamentoSdd: boolean
  codiceSdd: number | null
  iban: string | null
  dataRichiestaSdd: string | null
  modificatoBidella: boolean
  sezioneIdBidella: number | null
}

export const isAnomalia = (nome: string, value: any, secondValue?: any, thirdValue?: any, fourthValue?: any) => {
  if (nome === 'asiloGratis') {
    return !!value && !secondValue
  }

  if (nome === 'SDD') {
    return !!value && (!secondValue || !thirdValue || !fourthValue)
  }

  if (
    nome === 'dataInizio' ||
    nome === 'dataFineAmbientamento' ||
    nome === 'intestatarioId' ||
    nome === 'tipoFrequenza' ||
    nome === 'pastiSettimanaliPrevisti'
  ) {
    return !value
  }

  if (nome === 'fasciaCalcoloId') {
    return !value
  }

  return !!value
}

export const anomaliaAsiloGratis = (isAsiloGratis: boolean, asiloGratisQuotaGenitore: number | null) => {
  return !!isAsiloGratis && !asiloGratisQuotaGenitore
}

export const anomaliaSDD = (
  pagamentoSdd: boolean,
  codiceSdd: number | null,
  iban: string | null,
  dataRichiestaSdd: string | null,
) => {
  return !!pagamentoSdd && (!codiceSdd || !iban || !dataRichiestaSdd)
}

export const hasAnomalieFruizioni = (alunno: AlunnoDaControllare, servizioId: number) => {
  const commonErrors =
    isAnomalia('intestatarioId', alunno.intestatarioId) ||
    isAnomalia('dataInizio', alunno.dataInizio) ||
    isAnomalia('SDD', alunno.pagamentoSdd, alunno.codiceSdd, alunno.iban, alunno.dataRichiestaSdd) ||
    isAnomalia('modificatoBidella', alunno.modificatoBidella) ||
    isAnomalia('sezioneIdBidella', alunno.sezioneIdBidella)

  const asiloErrors =
    isAnomalia('dataFineAmbientamento', alunno.dataFineAmbientamento) ||
    isAnomalia('tipoFrequenza', alunno.tipoFrequenza) ||
    isAnomalia('asiloGratis', alunno.isAsiloGratis, alunno.asiloGratisQuotaGenitore)

  const mensaErrors = isAnomalia('pastiSettimanaliPrevisti', alunno.pastiSettimanaliPrevisti)

  if (servizioId === 3) {
    return commonErrors || mensaErrors
  } else if (servizioId === 2 || servizioId === 4) {
    return commonErrors || asiloErrors
  }

  return false
}

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface Sezione {
  classe?: string
  sezione?: string
  adulti?: boolean
}

interface UpdateClasseArgs {
  sezioneId: number
  sezione: Sezione
}

const updateSezioneById = async ({ sezioneId, sezione }: UpdateClasseArgs) => {
  const { data } = await axios.patch(`/sezioni/${sezioneId}/`, sezione)
  return data
}

const useUpdateSezioneById = (sezioneId: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    (sezione: Sezione) => {
      return updateSezioneById({ sezioneId, sezione })
    },
    {
      onSuccess: (data: any) => {
        console.log(data)
        queryClient.invalidateQueries('sezioniByScuolaId')
        cogoToast.success('Classe modificata')
      },
      onError: () => {},
    }
  )
}

export default useUpdateSezioneById

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface UpdatePagamento {
  avvisoPagamentoId: number
  tipo: string
  data: string
  importo: string
  nota?: string
}

const updatePagamento = async (pagamentoId: string | null, pagamento: UpdatePagamento) => {
  const { data } = await axios.patch(`/pagamenti/${pagamentoId}/`, pagamento)
  return data
}

const useUpdatePagamento = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ pagamentoId, pagamento }: { pagamentoId: string | null; pagamento: UpdatePagamento }) => {
      return updatePagamento(pagamentoId, pagamento)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('avvisiPagamentoByMese')
        cogoToast.success('Pagamento aggiornato')
      },
      onError: () => {},
    }
  )
}

export default useUpdatePagamento

import { useQuery } from 'react-query'
import axios from 'axios'

const getScuole = async () => {
  const { data } = await axios.get(`/scuole/`)

  return data.results
}

const useScuole = () => {
  return useQuery('scuole', getScuole, {
    // 30 minuti
    staleTime: 30 * 60 * 1000,
  })
}

export default useScuole

import axios from 'axios'
import { useQuery } from 'react-query'

export interface Servizio {
  id: number
  nomiParametriFruizione: string[]
  tipo: string
  codiceCalcolo: string
  numeroTurni: number
  nome: string
  clienteIndirizziNotifica: string
  clienteTelefonoContatto: string
  clienteIndirizzoContatto: string
  clienteProssimoNumeroAvvisoPagamento: string
  ultimoNumeroBolletta: number
  emailAvvisiDebugOnly: boolean
  importazioneEsitiCampoImporto: string
  importazioneEsitiCampoDescrizione: string
  importazioneEsitiCampoData: string
  calcoloSemplificatoAggregaSettembreOttobre: boolean
  calcoloSemplificatoAggregaGiugnoLuglio: boolean
  templateBollettaCustom: string
  calcoloSemplificatoIngressoDimissioni: boolean
  calcoloSemplificatoIngressoDimissioniDescrizione: string
  smtpHost: string
  smtpPort: string
  smtpUser: string
  smtpPassword: string
  smtpUseTls: boolean
  smtpUseSsl: boolean
  smtpFromEmail: string
  clienteId: number
  servizioLun: boolean
  servizioMar: boolean
  servizioMer: boolean
  servizioGio: boolean
  servizioVen: boolean
  servizioSab: boolean
  servizioDom: boolean
  flagOrdinamentoAlfabetico: boolean
  codiceServizioAvvisiPagamento: string
}

const getServizio = async (servizioId: number) => {
  const { data } = await axios.get<Servizio>(`/servizi/${servizioId}`)
  return data
}

const useServizioById = (servizioId: number) => {
  return useQuery(
    ['servizioById', servizioId],
    () => getServizio(servizioId),

    {
      enabled: !!servizioId,
    }
  )
}

export default useServizioById

import { useQuery } from 'react-query'
import axios from 'axios'

interface IConteggioEmailRicevute {
  annoScolastico: string
  servizioId: number
}

interface ConteggioRicevuteRes {
  tipo: string,
  numRicevute: number
}

const conteggioEmailRicevute = async (
  _: string,
  { annoScolastico, servizioId }: IConteggioEmailRicevute
) => {
  const { data } = await axios.post<ConteggioRicevuteRes[]>(`/servizi/${servizioId}/conteggio-email-ricevute/`, {
    annoScolastico,
  })

  return data
}

const useConteggioEmailRicevute = ({ annoScolastico, servizioId }: IConteggioEmailRicevute) => {
  return useQuery(
    ['conteggioEmailRicevute', { annoScolastico, servizioId }],
    () => conteggioEmailRicevute('conteggioEmailRicevute', { annoScolastico, servizioId }),
    { enabled: !!annoScolastico && !!servizioId }
  )
}

export default useConteggioEmailRicevute

import { useQuery } from 'react-query'
import axios from 'axios'

interface IGiorniNonErogazioneByServizioId {
  servizioId: number
  from?: string
  to?: string
  scuolaId?: number | null
}

const getGiorniNonErogazioneByServizioId = async (
  _: string,
  { servizioId, from, to, scuolaId }: IGiorniNonErogazioneByServizioId
) => {
  const strScuolaId = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const { data } = await axios.get(
    `/servizi/${servizioId}/giorni-non-erogazione/?from=${from}&to=${to}${strScuolaId}`
  )

  return data.results
}

const useGiorniNonErogazioneByServizioId = ({
  servizioId,
  from,
  to,
  scuolaId,
}: IGiorniNonErogazioneByServizioId) => {
  return useQuery(
    ['giorniNonErogazioneByServizioId', { servizioId, from, to, scuolaId }],
    () =>
      getGiorniNonErogazioneByServizioId('giorniNonErogazioneByServizioId', {
        servizioId,
        from,
        to,
        scuolaId,
      }),
    {
      enabled: !!servizioId,
      // 30 minuti
      staleTime: 30 * 60 * 1000,
    }
  )
}

export default useGiorniNonErogazioneByServizioId

import React, { useState, useEffect, useContext, useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import DayPicker from 'react-day-picker'
import classNames from 'classnames'
import { FiCheckCircle } from 'react-icons/fi'

import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import ServizioContext from '../../context/ServizioContext'
import {
  isNonErogazione,
  isFestivita,
  isChiusuraNoFestivita,
} from '../../lib/validazionePresenze'

import useGiorniNonErogazioneByServizioId from '../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import useCreateGiornoNonErogazione from '../../apiHooks/mutations/useCreateGiornoNonErogazione'
import useDeleteGiornoNonErogazione from '../../apiHooks/mutations/useDeleteGiornoNonErogazione'

import 'react-day-picker/lib/style.css'
import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import InlineEdit, { InputType } from 'riec'
import useServizioById from '../../apiHooks/queries/useServizioById'

const modifiersStyles = {
  isFestivita: {
    backgroundColor: '#f0f0f0',
    color: '#777',
    width: 40,
    height: 40,
    lineHeight: 1,
  },
  isChiusuraNoFestivita: {
    backgroundColor: '#FED7E2',
    color: '#D53F8C',
    width: 40,
    height: 40,
    lineHeight: 1,
  },
}

const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]
const WEEKDAYS_LONG = [
  'Domenica',
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
]
const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']

const getRangeDate = (date: Date) => {
  const momentDate = moment(date)
  return {
    from: momentDate.startOf('month').format('YYYY-MM-DD'),
    to: momentDate.endOf('month').format('YYYY-MM-DD'),
  }
}

interface GiornoNonErogazione {
  id: number
  data: string
  descrizione: string
  isFestivita: boolean
  servizioId: number
  scuolaId: number | null
}

const sortDate = (a: GiornoNonErogazione, b: GiornoNonErogazione) => {
  return moment(a.data).isBefore(moment(b.data)) ? -1 : 1
}

const GiorniChiusura: React.FC<RouteComponentProps> = () => {
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)
  const { data: servizio } = useServizioById(servizioId)

  const anni: string[] = annoScolastico.split('-')
  const dataMin = moment(`${anni[0]}-09-01`, 'YYYY-MM-DD', true)
  const dataMax = moment(`${anni[1]}-08-31`, 'YYYY-MM-DD', true)
  const todayMoment = moment()
  const initialMonth = todayMoment.isBefore(dataMin)
    ? dataMin.toDate()
    : todayMoment.isAfter(dataMax)
    ? dataMax.toDate()
    : todayMoment.toDate()

  const [currentDate, setCurrentDate] = useState(initialMonth)
  const [from, setFrom] = useState(moment(dataMin).format('YYYY-MM-DD'))
  const [to, setTo] = useState(moment(dataMax).format('YYYY-MM-DD'))
  const [scuolaSelected, setScuolaSelected] = useState(null)

  const newGiornoNonErogazione = {
    data: moment().format('D'),
    descrizione: '',
    isFestivita: true,
    servizioId,
  }
  const [giornoNonErogazione, setGiornoNonErogazione] = useState(
    newGiornoNonErogazione
  )

  const { data: giorniNonErogazione = [] } = useGiorniNonErogazioneByServizioId(
    {
      servizioId,
      from,
      to,
      scuolaId: scuolaSelected,
    }
  )

  const { data: scuole } = useScuoleByServizioId(servizioId)

  const createGiornoNonErogazione = useCreateGiornoNonErogazione()
  const deleteGiornoNonErogazione = useDeleteGiornoNonErogazione()

  const modifiers = {
    isFestivita: (d: Date) =>
      isFestivita(
        giorniNonErogazione.filter((giorno: GiornoNonErogazione) => {
          if (scuolaSelected) {
            return giorno.scuolaId + '' === scuolaSelected + ''
          }
          return giorno
        }),
        moment(d).format('YYYY-MM-DD')
      ),
    isChiusuraNoFestivita: (d: Date) =>
      isChiusuraNoFestivita(
        giorniNonErogazione.filter((giorno: GiornoNonErogazione) => {
          if (scuolaSelected) {
            return giorno.scuolaId + '' === scuolaSelected + ''
          }
          return giorno
        }),
        moment(d).format('YYYY-MM-DD')
      ),
  }

  useEffect(() => {
    const range = getRangeDate(currentDate)
    setFrom(range.from)
    setTo(range.to)
  }, [currentDate])

  const handleChangeMonth = (date: Date) => {
    setCurrentDate(date)
  }

  const renderDay = (day: Date) => {
    const data = moment(day).format('YYYY-MM-DD')
    const nonErogazione = isNonErogazione(giorniNonErogazione, data, servizio)
    let descrizioneNonErogazione = ''
    if (nonErogazione) {
      descrizioneNonErogazione = nonErogazione.descrizione
    }
    return (
      <>
        <span title={descrizioneNonErogazione} className="leading-none">
          {moment(day).format('D')}
        </span>
        <div
          className="point absolute w-1 h-1 rounded-full"
          style={{
            top: 4,
            left: 15,
          }}
        ></div>
      </>
    )
  }

  const handleChangeGiornoNonErogazione = (field: string) => (event: any) => {
    const valueToSave =
      field === 'isFestivita' ? !event.target.checked : event.target.value

    setGiornoNonErogazione({ ...giornoNonErogazione, [field]: valueToSave })
  }

  const handleCreateGiornoNonErogazione = (event: any) => {
    const currentYear = moment(currentDate).format('YYYY')
    const currentMonth = moment(currentDate).format('MM')
    const selectedDate = moment(
      `${currentYear}-${currentMonth}-${giornoNonErogazione.data}`,
      'YYYY-MM-D',
      true
    ).format('YYYY-MM-DD')

    const giornoNonErogazioneToSave = {
      ...giornoNonErogazione,
      data: selectedDate,
      servizioId: servizioId,
      scuolaId: scuolaSelected,
    }

    createGiornoNonErogazione.mutate(giornoNonErogazioneToSave)
  }

  const handleChangeScuola = (value: any) => {
    setScuolaSelected(value)
  }

  const giornoNonErogazioneGiaEsistente = useMemo(() => {
    const currentYear = moment(currentDate).format('YYYY')
    const currentMonth = moment(currentDate).format('MM')
    const selectedDay = moment(giornoNonErogazione.data, 'D', true).format('DD')
    const selectedData = moment(
      `${currentYear}-${currentMonth}-${selectedDay}`,
      'YYYY-MM-DD',
      true
    ).format('YYYY-MM-DD')

    return giorniNonErogazione.find((g: any) => {
      return (
        g.data === selectedData &&
        g.servizioId === giornoNonErogazione.servizioId
      )
    })
  }, [
    currentDate,
    giorniNonErogazione,
    giornoNonErogazione.data,
    giornoNonErogazione.servizioId,
  ])

  const isButtonDisabled =
    !giornoNonErogazione.descrizione || !!giornoNonErogazioneGiaEsistente

  return (
    <div>
      <div className="bg-white py-6">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl leading-tight mb-8">
            Giorni festivi e di non erogazione
          </h1>
          <div className="text-lg leading-tight mt-3 mb-4">
            {scuole && (
              <InlineEdit
                type={InputType.Select}
                value={scuolaSelected || ''}
                onChange={handleChangeScuola}
                options={[
                  { value: '', label: 'Tutte le scuole' },
                  ...scuole.map((scuola: any) => ({
                    value: scuola.id,
                    label: scuola.nome,
                  })),
                ]}
                loadingClass="text-red-600"
                // viewClass="cursor-pointer font-semibold hover:text-blue-600"
                // editClass="form-select text-sm"
                editClass="form-select text-lg"
                viewClass="cursor-pointer text-blue-500"
              />
            )}
          </div>
          <div className="flex items-start space-x-12">
            <DayPicker
              className="bg-white rounded py-2 px-2 mb-8 shadow-lg border-gray-200 "
              locale="it"
              months={MONTHS}
              initialMonth={initialMonth}
              fromMonth={dataMin.toDate()}
              toMonth={dataMax.toDate()}
              weekdaysLong={WEEKDAYS_LONG}
              weekdaysShort={WEEKDAYS_SHORT}
              firstDayOfWeek={1}
              modifiers={modifiers}
              modifiersStyles={modifiersStyles}
              onMonthChange={handleChangeMonth}
              renderDay={renderDay}
            />
            <table>
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {moment(currentDate).format('MMMM YYYY') || 'Giorno'}
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Descrizione
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Non erogazione
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {giorniNonErogazione
                  .filter((giorno: GiornoNonErogazione) => {
                    if (scuolaSelected) {
                      return giorno.scuolaId + '' === scuolaSelected + ''
                    }
                    return giorno
                  })
                  .sort(sortDate)
                  .map((g: GiornoNonErogazione) => {
                    return (
                      <tr
                        key={g.id}
                        className={classNames({
                          'bg-pink-100': !g.isFestivita,
                        })}
                      >
                        <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                          <div className="flex justify-center items-center bg-white p-2 border border-gray-300 font-semibold">
                            {moment(g.data).format('DD')}
                          </div>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                          {g.descrizione}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                          {!g.isFestivita && (
                            <FiCheckCircle className="text-pink-500 text-xl mx-auto" />
                          )}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200 text-center">
                          <button
                            className="border border-transparent hover:border-red-200 hover:bg-red-100 hover:text-red-500 focus:shadow-outline focus:outline-none text-blue-500 leading-5 py-1 px-5 rounded transition-colors duration-150"
                            onClick={() => {
                              deleteGiornoNonErogazione.mutate(g.id)
                            }}
                          >
                            Elimina
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                <tr>
                  <td className="bg-gray-200 px-6 py-2 whitespace-nowrap">
                    <input
                      type="number"
                      min={1}
                      max={31}
                      className="form-input block w-20 text-sm h-8"
                      value={giornoNonErogazione.data}
                      onChange={handleChangeGiornoNonErogazione('data')}
                    />
                  </td>
                  <td className="bg-gray-200 px-6 py-2 whitespace-nowrap">
                    <input
                      type="text"
                      className="form-input block w-full text-sm h-8"
                      value={giornoNonErogazione.descrizione}
                      onChange={handleChangeGiornoNonErogazione('descrizione')}
                    />
                  </td>
                  <td className="bg-gray-200 px-6 py-2 whitespace-nowrap text-center">
                    <input
                      type="checkbox"
                      className="form-checkbox  h-6 w-6 text-pink-500"
                      checked={!giornoNonErogazione.isFestivita}
                      onChange={handleChangeGiornoNonErogazione('isFestivita')}
                    />
                  </td>
                  <td className="bg-gray-200 px-6 py-2 whitespace-nowrap text-center">
                    <button
                      type="button"
                      className={classNames(
                        'focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-5 rounded transition-colors duration-150  hover:shadow-xl',
                        {
                          'bg-blue-400 hover:bg-blue-500 cursor-not-allowed':
                            isButtonDisabled,
                        },
                        { 'bg-blue-500 hover:bg-blue-700': !isButtonDisabled }
                      )}
                      onClick={handleCreateGiornoNonErogazione}
                      disabled={isButtonDisabled}
                    >
                      Aggiungi
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GiorniChiusura

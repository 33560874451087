import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const inviaEmailAvviso = async (idAvviso: string, servizioId: number) => {
  const { data } = await axios.post(`/avvisi-pagamento/${idAvviso}/invia-mail-avviso/`, {
    servizioId,
  })
  return data
}

const useInviaEmailAvviso = () => {
  return useMutation(
    ({ idAvviso, servizioId }: { idAvviso: string; servizioId: number }) => {
      return inviaEmailAvviso(idAvviso, servizioId)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        cogoToast.success('Invio email')
      },
      onError: () => {},
    }
  )
}

export default useInviaEmailAvviso

import { useQuery } from 'react-query'
import axios from 'axios'

const getProfile = async (clienteId?: number | null) => {
  const { data } = await axios.get(`/profile/?clienteId=${clienteId}`)
  const {
    username,
    operatore: { nome, cognome, ruolo, servizi, clienteId: clienteIdOperatore, nomeCliente },
  } = data
  return {
    username,
    nome,
    cognome,
    ruolo,
    servizi,
    clienteId: clienteIdOperatore,
    cliente: nomeCliente,
  }
}

const useProfile = (token: string | null, clienteId?: number | null) => {
  return useQuery('profile ', () => getProfile(clienteId), {
    enabled: !!token,
    // 30 minuti
    staleTime: 30 * 60 * 1000,
  })
}

export default useProfile

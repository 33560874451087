import { useQuery } from 'react-query'
import axios from 'axios'

const getAvvisoPagamentoById = async (_: string, avvisoId: number) => {
  const { data } = await axios.get(`/avvisi-pagamento/${avvisoId}`)
  return data
}

const useAvvisoPagamentoById = (avvisoId: number) => {
  return useQuery(['avvisoPagamentoById', avvisoId], () => getAvvisoPagamentoById('avvisoPagamentoById', avvisoId), {
    enabled: !!avvisoId,
  })
}

export default useAvvisoPagamentoById

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const inibizioneDati = async (alunnoId: number, intestatarioId: number, inibito: boolean) => {
  const { data } = await axios.post(`/alunni/${alunnoId}/inibizione-dati/`, {
    intestatarioId,
    inibito,
  })
  return data
}

const useInibizioneDati = (alunnoId: number) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ intestatarioId, inibito }) => {
      return inibizioneDati(alunnoId, intestatarioId, inibito)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        cogoToast.success(`Accesso dati modificato per questo intestatario`)
        queryClient.invalidateQueries('alunnoByIdByAnno')
      },
      onError: () => {
        // console.log('KO')
      },
    }
  )
}

export default useInibizioneDati

import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import useAlunniGenitore2Anni from '../../apiHooks/queries/useAlunniGenitore2Anni'
import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import useCertificazionePagamentiGenitore from '../../apiHooks/queries/useCertificazionePagamentiGenitore'
import { API_URL } from '../../config'
import Loading from '../../components/Loading'

interface FiglioProps extends RouteComponentProps {
  id?: number
}

interface Alunno {
  cap: string
  cittadinanzaCodiceIstat: number
  codiceFiscale: string
  cognome: string
  comuneCodiceIstat: number
  comuneCodiceIstatNascita: number
  dataNascita: string
  fruizioni: []
  id: number
  indirizzo: string
  intestatari: []
  isResidente: boolean
  nazionalitaCodiceIstat: number
  nome: string
  note: string
  statoCodiceIstat: number
  statoCodiceIstatNascita: number
}

const CertificazionePagamenti: React.FC<FiglioProps> = ({ id }) => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const annoScolasticoPrecedente = annoScolastico
    ?.split('-')
    .map((anno) => parseInt(anno) - 1)
    .join('-')

  //===================
  // LOCAL STATE
  //===================

  const [alunni, setAlunni] = useState<any[]>([])
  const [alunno, setAlunno] = useState<Alunno | null>(null)

  const intestatarioId = localStorage.getItem('intestatarioId')
    ? localStorage.getItem('intestatarioId')
    : null

  //===================
  // HOOKS QUERIES
  //===================
  const { data, isFetching: isFetchingAlunni } = useAlunniGenitore2Anni(
    annoScolastico,
    annoScolasticoPrecedente,
    servizioId
  )
  const { alunniAnnoCorrente, alunniAnnoPrecedente } = data || {}

  const { data: pagamenti, isFetching: isFetchingPagamentiCertificati } =
    useCertificazionePagamentiGenitore(id, servizioId, annoScolastico, intestatarioId)

  //===================
  // EFFECTS
  //===================
  useEffect(() => {
    if (alunniAnnoCorrente) {
      const alunni = [...alunniAnnoCorrente]
      for (const alunno of alunniAnnoPrecedente) {
        if (!alunni.find((a: any) => a.codiceFiscale === alunno.codiceFiscale)) {
          alunni.push(alunno)
        }
      }
      setAlunni(alunni)
    }
  }, [alunniAnnoCorrente, alunniAnnoPrecedente, annoScolastico])

  useEffect(() => {
    const alunnoSel = alunni && alunni.find((a: Alunno) => a.id + '' === id + '')
    setAlunno(alunnoSel)
  }, [id, alunni])

  return (
    <div>
      <div className="bg-white h-full pt-8 pb-32">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {pagamenti &&
          !isFetchingAlunni &&
          !isFetchingPagamentiCertificati &&
          !pagamenti.message ? (
            <>
              {verificaData(annoScolastico) ? (
                <>
                  <h1 className="text-4xl leading-tight mb-8">
                    Pagamenti di {alunno?.nome} {alunno?.cognome}
                  </h1>

                  <div className="mb-8">
                    <div className="mb-4">
                      <span className="font-semibold">
                        Totale pagamenti non tracciabili per l'anno {annoScolastico.split('-')[0]}:
                      </span>
                      <span className="text-green-500 font-semibold">
                        &nbsp;&nbsp;€&nbsp;{pagamenti.importoTotalePagatoNonTracciato}
                      </span>
                    </div>
                    <div>
                      <span className="font-semibold">
                        Totale pagamenti tracciabili per l'anno {annoScolastico.split('-')[0]}:
                      </span>
                      <span className="text-green-500 font-semibold">
                        &nbsp;&nbsp;€&nbsp;{pagamenti.importoTotalePagatoTracciato}
                      </span>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      window.open(
                        `${API_URL}/avvisi-pagamento/certificazione-pagamenti-pdf/?annoScolastico=${annoScolastico}&idServizio=${servizioId}&idIntestatario=${intestatarioId}&idAlunno=${id}&codiceComuneIstat=${
                          alunni[0]?.comuneCodiceIstat
                        }&t=${encodeURIComponent(localStorage.getItem('token') || '')}`
                      )
                    }}
                    className="mt-8 block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  >
                    Scarica documento di certificazione dei pagamenti
                  </button>
                </>
              ) : (
                <p className="mt-8 text-lg">
                  Pagamenti non disponibili fino all 01/02/{annoScolastico.split('-')[1]}
                </p>
              )}
            </>
          ) : (
            <>
              {pagamenti && typeof pagamenti.message === 'string' ? (
                <>
                  <h1 className="text-4xl leading-tight mb-8">
                    Pagamenti di {alunno?.nome} {alunno?.cognome}
                  </h1>
                  <p className="mt-8 text-lg">Certificazione dei pagamenti non disponibile.</p>
                  <p className="text-lg">
                    Si prega di contattarci all'indirizzo email{' '}
                    <a href={`mailto:${pagamenti.emailContatto}`}>{pagamenti.emailContatto}</a> o
                    telefonicamente al {pagamenti.telefonoContatto}
                  </p>
                </>
              ) : (
                <Loading />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CertificazionePagamenti

function verificaData(dataInput: string) {
  const annoFinale = parseInt(dataInput.split('-')[1])
  const dataLimite = new Date(annoFinale, 1, 1)
  const dataCorrente = new Date()

  if (dataCorrente <= dataLimite) {
    return false
  } else {
    return true
  }
}

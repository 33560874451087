import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

interface Scuola {
  id: number
  nome: string
  codice: string
  codiceImport: string
  clienteId: number
  tipo: string
  servizi: number[]
}

interface Sezione {
  id: number
  scuolaId: number
  annoScolastico: string
  classe: string
  sezione: string
  presenze?: number | null
  presenze_A?: number
  presenze_P?: number
  presenze_B?: number
  presenze_M?: number
}

const todayMoment = moment()
let dataInitial = todayMoment.toDate()

export const presenze = createSlice({
  name: 'presenze',
  initialState: {
    scuolaSel: null,
    sezioneSel: null,
    dataSel: moment(dataInitial).format('YYYY-MM-DD'),
    meseSel: moment().format('MM'),
    showPresenzeMensili: false,
  },
  reducers: {
    loadScuole: (state, action) => {
      state.scuolaSel = state.scuolaSel || action.payload
    },
    loadSezioneSel: (state, action) => {
      state.sezioneSel = state.sezioneSel || action.payload
    },
    loadDataSel: (state, action) => {
      state.dataSel = state.dataSel || action.payload
    },
    changeScuolaSel: (state, action) => {
      state.scuolaSel = action.payload
    },
    changeSezioneSel: (state, action) => {
      state.sezioneSel = action.payload
    },
    changeDataSel: (state, action) => {
      state.dataSel = action.payload
    },
    changeMeseSel: (state, action) => {
      state.meseSel = action.payload
    },
    changeShowPresenzeMensili: (state, action) => {
      state.showPresenzeMensili = action.payload
    },
  },
})

export const selectPresenze = (state: {
  presenze: {
    scuolaSel: Scuola | null
    sezioneSel: Sezione | null
    dataSel: string
    meseSel: string
    showPresenzeMensili: boolean
  }
}) => state

export const {
  loadScuole,
  loadSezioneSel,
  loadDataSel,
  changeScuolaSel,
  changeSezioneSel,
  changeDataSel,
  changeMeseSel,
  changeShowPresenzeMensili,
} = presenze.actions

export default presenze.reducer

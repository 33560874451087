import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface InvioEmailCucina {
  data: string
  scuolaId: number
  annoScolastico: string
}

const inviaEmailCucina = async (servizioId: number, emailCucina: InvioEmailCucina) => {
  const { data } = await axios.post(
    `/servizi/${servizioId}/invia-email-cucina/?scuolaId=${emailCucina.scuolaId}&data=${emailCucina.data}&annoScolastico=${emailCucina.annoScolastico}`,
    { emailCucina }
  )
  return data
}

const useInviaEmailCucina = () => {
  return useMutation(
    ({ servizioId, emailCucina }: { servizioId: number; emailCucina: InvioEmailCucina }) => {
      return inviaEmailCucina(servizioId, emailCucina)
    },
    {
      onSuccess: (data: any) => {
        // console.log('OK! - ', data)
        cogoToast.success('Email inviata')
      },
      onError: () => {},
    }
  )
}

export default useInviaEmailCucina

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import { Servizio } from '../queries/useServizioById'

const updateServiziById = async (servizioId: number, servizio: Servizio) => {
  const { data } = await axios.patch(`/servizi/${servizioId}/`, servizio)
  return data
}

const useUpdateServiziById = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ servizioId, servizio }: { servizioId: number; servizio: Servizio }) => {
      return updateServiziById(servizioId, servizio)
    },
    {
      onSuccess: (data: Servizio) => {
        console.log(data)
        queryClient.invalidateQueries('servizioById')
        cogoToast.success('Servizio aggiornato')
      },
      onError: () => {},
    }
  )
}

export default useUpdateServiziById

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import { RouteComponentProps } from '@reach/router'
import InlineEdit, { InputType } from 'riec'
import moment from 'moment'
import { useTable, useSortBy, useFilters, useBlockLayout } from 'react-table'
import ReactModal from 'react-modal'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import useProfile from '../../apiHooks/queries/useProfile'
import {
  TipoPresenza,
  isPresente,
  isAssente,
  isRitirato,
  isInRitiroTemporaneo,
  isFestivita,
  isChiusuraNoFestivita,
  // isWeekend,
  isErroreSegnato,
  isErroreDimenticato,
  isWarning,
  isNonGiornoServizio,
} from '../../lib/validazionePresenze'

import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import ServizioContext from '../../context/ServizioContext'

import usePresenzeMese from '../../apiHooks/queries/usePresenzeMese'
import useGiorniNonErogazioneByServizioId from '../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import useSezioniByScuolaId from '../../apiHooks/queries/useSezioniByScuolaId'
import useUpdatePresenzeMese from '../../apiHooks/mutations/useUpdatePresenzeMese'

import {
  selectPresenze,
  loadScuole,
  loadSezioneSel,
  changeScuolaSel,
  changeSezioneSel,
  changeMeseSel,
} from '../../redux/modules/presenze'

import Loading from '../../components/Loading'
import EditPresenzaInput from '../../components/EditPresenzaInput'
import PresenzeMensiliTable from '../../components/PresenzeMensiliTable'
import useServizioById from '../../apiHooks/queries/useServizioById'

interface Scuola {
  id: number
  nome: string
  codice: string
  codiceImport: string
  clienteId: number
  tipo: string
  servizi: number[]
}

interface Sezione {
  id: number
  scuolaId: number
  annoScolastico: string
  classe: string
  sezione: string
  fittizia: boolean
}

interface Fruizione {
  [giorno: string]: boolean
}

interface Alunno {
  id: number
  codiceFiscale: string
  nome: string
  cognome: string
  sezione: Sezione
  presenze: { [key: string]: { tipo: string; isSalvata: boolean } }
  fruizione: any
}
const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}
const modalCustomStyles2 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
    maxWidth: '800px',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const PresenzeMensili: React.FC<RouteComponentProps> = () => {
  //===================
  // REDUX
  //===================
  const presenze = useSelector(selectPresenze)
  const {
    presenze: { scuolaSel, sezioneSel, meseSel },
  } = presenze
  const dispatch = useDispatch()
  const clienteId = localStorage.getItem('clienteId')
  const { data: user } = useProfile(
    ' ',
    clienteId ? parseInt(clienteId, 10) : undefined
  )

  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { data: servizio } = useServizioById(servizioId)

  //===================
  // LOCAL STATE
  //===================
  const [giornoSel, setGiornoSel] = useState<string | null>(null)
  const [annoSel, setAnnoSel] = useState<string>(moment().format('YYYY'))
  const [giorniDelMese, setGiorniDelMese] = useState<number[]>([])
  const [previsione, setPrevisione] = useState(false)
  const [alunni, setAlunni] = useState<any[]>([])
  const [alunniEdit, setAlunniEdit] = useState<any[]>([])
  const [alunnoSel, setAlunnoSel] = useState<Alunno | null>(null)
  const [presenzeAlunnoSel, setPresenzeAlunnoSel] = useState<any>({})
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true)
  const [editAlunnoModalOpen, setEditAlunnoModalOpen] = useState<boolean>(false)
  const [editAllModalOpen, setEditAllModalOpen] = useState<boolean>(false)
  const [presenzeGiornoModalOpen, setPresenzeGiornoModalOpen] =
    useState<boolean>(false)
  const [tipoPresenzaSel, setTipoPresenzaSel] = useState<string | null>(null)

  //===================
  // HOOKS QUERIES
  //===================

  // Fetch scuole
  const { data: scuole = [], isFetching: isFetchingScuole } =
    useScuoleByServizioId(servizioId)

  const scuoleOptions =
    scuole &&
    scuole.map((scuola: Scuola) => {
      return {
        value: scuola.id,
        label: scuola.nome,
      }
    })

  // Fetch sezioni
  const { data: sezioni = [], isFetching: isFetchingSezioni } =
    useSezioniByScuolaId({
      scuolaId: scuolaSel ? scuolaSel.id + '' : '',
      annoScolastico,
    })

  const mesiOptions = [
    { value: '09', label: 'Settembre' },
    { value: '10', label: 'Ottobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Dicembre' },
    { value: '01', label: 'Gennaio' },
    { value: '02', label: 'Febbraio' },
    { value: '03', label: 'Marzo' },
    { value: '04', label: 'Aprile' },
    { value: '05', label: 'Maggio' },
    { value: '06', label: 'Giugno' },
    { value: '07', label: 'Luglio' },
    { value: '08', label: 'Agosto' },
  ]

  // Fetch presenze mensili
  const { data: presenzeMensili } = usePresenzeMese({
    servizioId,
    scuolaId: scuolaSel ? scuolaSel.id + '' : '',
    mese: parseInt(meseSel),
    anno: parseInt(annoSel),
    annoScolastico,
  })

  // Fetch giorni non erogazione
  const { data: giorniNonErogazione } = useGiorniNonErogazioneByServizioId({
    servizioId,
    from: moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true)
      .startOf('month')
      .format('YYYY-MM-DD'),
    to: moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true)
      .endOf('month')
      .format('YYYY-MM-DD'),
    scuolaId: scuolaSel ? scuolaSel.id : null,
  })

  // ========================
  // MUTATIONS
  // ========================
  // UpdatePresenzeMese
  const modificaPresenze = useUpdatePresenzeMese()

  //===================
  // EFFECTS
  //===================
  // Set scuolaSel
  useEffect(() => {
    if (scuole.length > 0) {
      dispatch(loadScuole(scuole[0]))

      sezioneSel?.scuolaId === scuolaSel?.id
        ? dispatch(loadSezioneSel(sezioni[0]))
        : dispatch(changeSezioneSel(sezioni[0]))
    }
  }, [isFetchingScuole, scuole, scuolaSel, sezioni, sezioneSel, dispatch])

  // Set annoSel
  useEffect(() => {
    const anni: string[] = annoScolastico.split('-')
    const anno = parseInt(meseSel, 10) <= 8 ? anni[1] : anni[0]
    setAnnoSel(anno)
  }, [annoScolastico, meseSel])

  // Set giorniDelMese
  useEffect(() => {
    let giorniNelMese: number
    let giorniMese: number[] = []
    giorniNelMese = moment(
      `${annoSel}-${meseSel}-01`,
      'YYYY-MM-DD',
      true
    ).daysInMonth()

    for (let i: number = 1; i <= giorniNelMese; i++) {
      giorniMese.push(i)
    }

    setGiorniDelMese(giorniMese)
  }, [annoSel, meseSel])

  //===================
  // EVENT HANDLERS
  //===================
  // Handle salva presenze per alunno o per tutti gli alunni
  const handleSalvaPresenze = (event: React.MouseEvent) => {
    let presenzeToSave = []

    if (alunnoSel && presenzeAlunnoSel) {
      const alunnoToSave = alunniEdit.find((a: any) => a.id === alunnoSel.id)
      presenzeToSave = Object.keys(alunnoToSave.presenze).map(
        (giorno: string) => {
          // presenzeToSave = Object.keys(presenzeAlunnoSel).map((giorno: string) => {
          return {
            fruizioneId: alunnoToSave.fruizione.id,
            data: moment(
              `${annoSel}-${meseSel}-${giorno}`,
              'YYYY-MM-D',
              true
            ).format('YYYY-MM-DD'),
            // tipo: alunnoToSave.presenze[giorno].tipo || null,
            tipo: presenzeAlunnoSel[giorno].tipo || null,
          }
        }
      )
    } else {
      presenzeToSave = alunniEdit.reduce((acc: any, alunno: Alunno) => {
        const presenze = Object.keys(alunno.presenze).map((giorno: string) => {
          return {
            fruizioneId: alunno.fruizione.id,
            data: moment(
              `${annoSel}-${meseSel}-${giorno}`,
              'YYYY-MM-D',
              true
            ).format('YYYY-MM-DD'),
            tipo: alunno.presenze[giorno].tipo || null,
          }
        })

        return [...acc, ...presenze]
      }, [])
    }

    modificaPresenze.mutate({ servizioId, presenze: presenzeToSave })
    setPrevisione(false)
    setEditAlunnoModalOpen(false)
    setEditAllModalOpen(false)
    setIsReadOnly(true)
    setAlunnoSel(null)
    setPresenzeAlunnoSel({})
  }

  // Handle change presenza alunno
  const handleChangePresenzeAlunno =
    (alunnoId: number, setDefault: boolean) => (event: React.MouseEvent) => {
      // setDefault: se true => default,
      // altrimenti tutte assenze

      const newAlunniEdit = alunniEdit.map((alunno) => {
        if (alunno.id === alunnoId) {
          return {
            ...alunno,
            presenze: Object.keys(alunno.presenze).reduce((acc, giorno) => {
              // CASO 1: NON DEVE ESSERCI PRESENZA => VUOTO
              const alunnoRitiratoTemporaneo = isInRitiroTemporaneo(
                moment(
                  `${annoSel}-${meseSel}-${giorno}`,
                  'YYYY-MM-D',
                  true
                ).format('YYYY-MM-DD'),
                alunno.fruizione.dataInizioRitiroTemporaneo,
                alunno.fruizione.dataFineRitiroTemporaneo
              )

              const alunnoRitirato = isRitirato(
                moment(
                  `${annoSel}-${meseSel}-${giorno}`,
                  'YYYY-MM-D',
                  true
                ).format('YYYY-MM-DD'),
                alunno.fruizione.dataRitiro,
                alunno.fruizione.dataRitiroBidella
              )

              const giornoChiusura =
                isNonGiornoServizio(
                  moment(
                    `${annoSel}-${meseSel}-${giorno}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD'),
                  servizio
                ) ||
                isFestivita(
                  giorniNonErogazione,
                  moment(
                    `${annoSel}-${meseSel}-${giorno}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ) ||
                isChiusuraNoFestivita(
                  giorniNonErogazione,
                  moment(
                    `${annoSel}-${meseSel}-${giorno}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                )

              if (
                alunnoRitirato ||
                alunnoRitiratoTemporaneo ||
                giornoChiusura
              ) {
                return {
                  ...acc,
                  [giorno + '']: { tipo: '', isSalvata: false },
                }
              }

              // CASO 2: TUTTI ASSENTI
              if (!setDefault) {
                return {
                  ...acc,
                  [giorno + '']: { tipo: 'A', isSalvata: false },
                }
              }

              // CASO 3: DEFAULT
              // => SE MANGIA QUEL GIORNO => P
              const giornoSettimana = moment(
                `${annoSel}-${meseSel}-${giorno}`,
                'YYYY-MM-D',
                true
              )
                .format('ddd')
                .toLowerCase()

              if (alunno.fruizione[giornoSettimana]) {
                return {
                  ...acc,
                  [giorno + '']: { tipo: 'P', isSalvata: false },
                }
              }

              // ALTRIMENTI A
              return {
                ...acc,
                [giorno + '']: { tipo: 'A', isSalvata: false },
              }
            }, {}),
          }
        }
        return alunno
      })
      if (alunnoSel) {
        const alunnoEdit = newAlunniEdit.find((a) => a.id === alunnoSel.id)
        setAlunnoSel(alunnoEdit)
        setPresenzeAlunnoSel(alunnoEdit.presenze)
      } else {
        setAlunniEdit(newAlunniEdit)
      }
    }

  // Handle modifica presenze per giorno
  const handleModificaPresenzeGiorno =
    (tipoPresenza: string | null) => (event: React.MouseEvent) => {
      const giornoSelDueCifre = moment(
        `${annoSel}-${meseSel}-${giornoSel}`,
        'YYYY-MM-D',
        true
      ).format('DD')
      // console.log(tipoPresenza, giornoSel, giornoSelDueCifre)

      const tuttePresenze = alunni.reduce((acc: any, alunno: Alunno) => {
        const alunnoRitirato = isRitirato(
          moment(
            `${annoSel}-${meseSel}-${giornoSelDueCifre}`,
            'YYYY-MM-DD',
            true
          ).format('YYYY-MM-DD'),
          alunno.fruizione.dataRitiro,
          alunno.fruizione.dataRitiroBidella
        )

        if (!alunnoRitirato) {
          const presenze = Object.keys(alunno.presenze).map(
            (giorno: string) => {
              const giornoMeseDueCifre = moment(
                `${annoSel}-${meseSel}-${giorno}`,
                'YYYY-MM-D',
                true
              ).format('DD')

              return {
                fruizioneId: alunno.fruizione.id,
                data: moment(
                  `${annoSel}-${meseSel}-${giorno}`,
                  'YYYY-MM-D',
                  true
                ).format('YYYY-MM-DD'),
                tipo:
                  giornoMeseDueCifre === giornoSelDueCifre
                    ? tipoPresenza
                    : alunno.presenze[giorno].tipo || null,
              }
            }
          )

          return [...acc, ...presenze]
        }

        return [...acc]
      }, [])

      const presenzeGiornoToSave = tuttePresenze.filter((p: any) => {
        return (
          moment(p.data, 'YYYY-MM-DD', true).format('DD') === giornoSelDueCifre
        )
      })

      modificaPresenze.mutate({ servizioId, presenze: presenzeGiornoToSave })
      setGiornoSel(null)
      setTipoPresenzaSel(null)
      setPresenzeGiornoModalOpen(false)
    }

  //===================
  // TABLE
  //===================
  const data = useMemo(
    () => (isReadOnly ? alunni : alunniEdit),
    [isReadOnly, alunni, alunniEdit]
  )

  // IS PRESENTE OK
  const isPresenteOkClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isPresente(value) &&
      !isWarning(
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        value,
        servizio
      ) &&
      !isErroreSegnato(
        value,
        giorniNonErogazione,
        fruizione,

        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS ASSENTE OK
  const isAssenteOkClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isAssente(value) &&
      // !isPresente(value) &&
      !isErroreSegnato(
        value,
        giorniNonErogazione,
        fruizione,
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS CHIUSURA (NO FESTIVITÀ)
  const isChiusuraNoFestivitaClass = useCallback(
    (day: string) =>
      isChiusuraNoFestivita(
        giorniNonErogazione,
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD')
      ),
    [annoSel, giorniNonErogazione, meseSel]
  )

  // IS WEEKEND O FESTIVITÀ
  const isWeekendOFestivitaClass = useCallback(
    (day: string) =>
      isNonGiornoServizio(
        moment(`${annoSel}-${meseSel}-${day}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        servizio
      ) ||
      isFestivita(
        giorniNonErogazione,
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD')
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS RITIRATO
  const isRitiratoClass = useCallback(
    (day: string, dataRitiro: string, dataRitiroBidella: string) =>
      isRitirato(
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        dataRitiro,
        dataRitiroBidella
      ),
    [annoSel, meseSel]
  )

  // IS RITIRATO TEMPORANEO
  const isRitiratoTemporaneoClass = useCallback(
    (
      day: string,
      dataInizioRitiroTemporaneo: string,
      dataFineRitiroTemporaneo: string
    ) =>
      isInRitiroTemporaneo(
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        dataInizioRitiroTemporaneo,
        dataFineRitiroTemporaneo
      ),
    [annoSel, meseSel]
  )

  // IS ERRORE DIMENTICATO
  const isErroreDimenticatoClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isErroreDimenticato(
        value,
        giorniNonErogazione,
        fruizione,
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS ERRORE SEGNATO
  const isErroreSegnatoClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isErroreSegnato(
        value,
        giorniNonErogazione,
        fruizione,
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS WARNING
  const isWarningClass = useCallback(
    (value: TipoPresenza, day: string) =>
      isWarning(
        moment(
          `${annoSel}-${meseSel}-${parseInt(day, 10)}`,
          'YYYY-MM-D',
          true
        ).format('YYYY-MM-DD'),
        value,
        servizio
      ),
    [annoSel, meseSel, servizio]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Cognome e nome',
        accessor: 'cognome',
        width: 222,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 space-x-3">
              {isReadOnly ? (
                <div
                  className={`cursor-pointer ${
                    !props.row.original.nome
                      ? 'text-red-600 hover:text-red-700'
                      : 'text-blue-500 hover:text-blue-600'
                  }`}
                  onClick={() => {
                    setEditAlunnoModalOpen(true)
                    setAlunnoSel(props.row.original)
                    setPresenzeAlunnoSel(props.row.original.presenze)
                  }}
                >
                  {props.value} {props.row.original.nome}
                </div>
              ) : (
                <div
                  className={`${
                    !props.row.original.nome
                      ? 'text-red-600 hover:text-red-700'
                      : 'text-blue-500 hover:text-blue-600'
                  }`}
                >
                  {props.value} {props.row.original.nome}
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: 'P',
        accessor: 'presenze',
        width: 58,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          const presenzeArray = Object.keys(props.value).map(
            (giorno: string) => {
              return {
                tipo: props.value[giorno].tipo,
              }
            }
          )
          const totalePresenze = useMemo(
            () =>
              presenzeArray.reduce(
                (sum: any, presenza: any) =>
                  sum +
                  (presenza.tipo === 'P' ||
                  presenza.tipo === 'B' ||
                  presenza.tipo === 'M'
                    ? 1
                    : 0),
                0
              ),
            [presenzeArray]
          )
          return (
            <div className="h-full flex justify-center items-center p-3 space-x-3  bg-blue-100 text-blue-800 font-semibold">
              {totalePresenze}
            </div>
          )
        },
      },
      ...giorniDelMese.map((g) => {
        return {
          Header: (props: any) => {
            return (
              <div className="h-full flex items-start justify-center leading-tight">
                {isReadOnly ? (
                  <div
                    className="text-blue-500 hover:text-blue-600 cursor-pointer"
                    onClick={() => {
                      setGiornoSel(
                        moment(
                          `${annoSel}-${meseSel}-${g}`,
                          'YYYY-MM-D',
                          true
                        ).format('DD')
                      )
                      // setGiornoSel(moment(g, 'D', true).format('DD'))
                      setPresenzeGiornoModalOpen(true)
                    }}
                  >
                    {g + ''}
                  </div>
                ) : (
                  <div>{g + ''}</div>
                )}
              </div>
            )
          },
          accessor: 'presenze.' + g,
          width: 26,
          disableSortBy: true,
          disableFilters: true,
          Cell: (props: any) => {
            const numeroGiorno = props.column.id
              .replace(/^presenze\./, '')
              .replace(/\.tipo$/, '')
            return (
              <div
                className={classNames(
                  'h-full flex items-center justify-center',
                  {
                    'text-green-600':
                      props.value &&
                      props.value.tipo === 'P' &&
                      isPresenteOkClass(
                        props.value ? props.value.tipo : '',
                        numeroGiorno,
                        props.row.original.fruizione
                      ),
                  },
                  {
                    'text-yellow-600':
                      props.value &&
                      props.value.tipo === 'B' &&
                      isPresenteOkClass(
                        props.value ? props.value.tipo : '',
                        numeroGiorno,
                        props.row.original.fruizione
                      ),
                  },
                  {
                    'text-purple-600':
                      props.value &&
                      props.value.tipo === 'M' &&
                      isPresenteOkClass(
                        props.value ? props.value.tipo : '',
                        numeroGiorno,
                        props.row.original.fruizione
                      ),
                  },
                  {
                    // text-gray-500
                    'text-red-600': isAssenteOkClass(
                      props.value ? props.value.tipo : '',
                      numeroGiorno,
                      props.row.original.fruizione
                    ),
                  },
                  {
                    'text-red-500 font-bold': isErroreSegnatoClass(
                      props.value ? props.value.tipo : '',
                      numeroGiorno,
                      props.row.original.fruizione
                    ),
                  },
                  {
                    // text-yellow-500
                    'text-red-500 font-bold': isWarningClass(
                      props.value ? props.value.tipo : '',
                      numeroGiorno
                    ),
                  },
                  {
                    'bg-gray-200': isWeekendOFestivitaClass(numeroGiorno),
                  },
                  {
                    //bg-purple-200
                    'bg-red-300': isChiusuraNoFestivitaClass(numeroGiorno),
                  },
                  {
                    'bg-orange-200': isErroreDimenticatoClass(
                      props.value ? props.value.tipo : '',
                      numeroGiorno,
                      props.row.original.fruizione
                    ),
                  },
                  {
                    'bg-gray-400 font-bold': isRitiratoClass(
                      numeroGiorno,
                      props.row.original.fruizione.dataRitiro,
                      props.row.original.fruizione.dataRitiroBidella
                    ),
                  },
                  {
                    'bg-gray-300 font-bold': isRitiratoTemporaneoClass(
                      numeroGiorno,
                      props.row.original.fruizione.dataInizioRitiroTemporaneo,
                      props.row.original.fruizione.dataFineRitiroTemporaneo
                    ),
                  }
                )}
              >
                {isReadOnly ? (
                  <div>{props.value ? props.value.tipo : ''}</div>
                ) : (
                  <EditPresenzaInput
                    value={props.value ? props.value.tipo : ''}
                    onChange={(e: any) => {
                      const newAlunniEdit = alunniEdit.map((alunno) => {
                        if (alunno.id === props.row.original.id) {
                          return {
                            ...alunno,
                            presenze: {
                              ...alunno.presenze,
                              [g + '']: {
                                tipo: e.target.value,
                                isSalvata: false,
                              },
                            },
                          }
                        }
                        return alunno
                      })
                      setAlunniEdit(newAlunniEdit)
                    }}
                    isSalvata={
                      props.value
                        ? props.value.tipo === '' || props.value.isSalvata
                        : false
                    }
                    servizioId={servizioId}
                    presenzeRole={false}
                  />
                )}
              </div>
            )
          },
          Footer: (props: any) => {
            const numeroGiorno = props.column.id
              .replace(/^presenze\./, '')
              .replace(/\.tipo$/, '')
            const totalePresenze = useMemo(
              () =>
                props.rows.reduce(
                  (sum: any, row: any) =>
                    sum +
                    (row.values.presenze[numeroGiorno] &&
                    (row.values.presenze[numeroGiorno].tipo === 'P' ||
                      row.values.presenze[numeroGiorno].tipo === 'B' ||
                      row.values.presenze[numeroGiorno].tipo === 'M')
                      ? 1
                      : 0),
                  0
                ),
              [props.rows, numeroGiorno]
            )

            return (
              <div className="h-full flex justify-center items-center p-3 font-semibold bg-blue-100 text-blue-800 border-t border-gray-500">
                {totalePresenze + ''}
              </div>
            )
          },
        }
      }),
    ],
    [
      annoSel,
      meseSel,
      giorniDelMese,
      isPresenteOkClass,
      isAssenteOkClass,
      isRitiratoClass,
      isRitiratoTemporaneoClass,
      isChiusuraNoFestivitaClass,
      isWeekendOFestivitaClass,
      isErroreDimenticatoClass,
      isErroreSegnatoClass,
      isWarningClass,
      alunniEdit,
      servizioId,
      isReadOnly,
      // servizio,
    ]
  )

  const columnFilter = (props: any) => {
    const {
      filterValue,
      // preFilteredRows,
      setFilter,
    } = props.column
    // const count = preFilteredRows.length

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
        placeholder="Cerca..."
        // placeholder={`Cerca tra ${count} alunni...`}
        className="form-input p-1 rounded text-xs w-24"
      />
    )
  }

  const defaultColumn = React.useMemo(
    () => ({
      Filter: columnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    totalColumnsWidth,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
    },
    useBlockLayout,
    useFilters,
    useSortBy
  )

  //===================
  // EFFECTS 2
  //===================
  // CALCOLO ALUNNI
  useEffect(() => {
    const getAlunniOk = () => {
      const alunniOk =
        presenzeMensili && presenzeMensili.length
          ? presenzeMensili
              .filter((alunno: any) => alunno.sezione?.id === sezioneSel?.id)
              .map((alunno: any) => {
                const presenze = giorniDelMese.reduce((acc, giorno) => {
                  const presenzaSalvata = alunno.presenze.find(
                    (p: any) =>
                      parseInt(
                        moment(p.data, 'YYYY-MM-DD', true).format('D'),
                        10
                      ) === giorno
                  )

                  if (presenzaSalvata) {
                    return {
                      ...acc,
                      [giorno + '']: {
                        tipo: presenzaSalvata.tipo,
                        isSalvata: true,
                      },
                    }
                  } else {
                    return {
                      ...acc,
                      [giorno + '']: { tipo: '', isSalvata: false },
                    }
                  }
                }, {})

                return {
                  ...alunno,
                  presenze,
                }
              })
          : []

      return alunniOk
    }

    if (presenzeMensili) {
      setAlunni(getAlunniOk())
    }
  }, [presenzeMensili, meseSel, annoSel, giorniDelMese, servizioId, sezioneSel])

  // CALCOLO ALUNNI EDIT
  useEffect(() => {
    const getAlunniEdit = () => {
      const alunniEdit =
        presenzeMensili && presenzeMensili.length
          ? presenzeMensili
              .filter((alunno: any) => alunno.sezione?.id === sezioneSel?.id)
              .map((alunno: any) => {
                const presenze = giorniDelMese.reduce((acc, giorno) => {
                  // CASO 1: PRESENZA GIA' SALVATA => PROPONGO QUELLA
                  const presenzaSalvata = alunno.presenze.find(
                    (p: any) =>
                      parseInt(
                        moment(p.data, 'YYYY-MM-DD', true).format('D'),
                        10
                      ) === giorno
                  )

                  if (presenzaSalvata) {
                    return {
                      ...acc,
                      [giorno + '']: {
                        tipo: presenzaSalvata.tipo,
                        isSalvata: true,
                      },
                    }
                  }

                  if (!previsione) {
                    return {
                      ...acc,
                      [giorno + '']: { tipo: '', isSalvata: false },
                    }
                  }

                  // CASO 2: NON DEVE ESSERCI PRESENZA => VUOTO
                  const alunnoRitiratoTemporaneo = isInRitiroTemporaneo(
                    moment(
                      `${annoSel}-${meseSel}-${giorno}`,
                      'YYYY-MM-D',
                      true
                    ).format('YYYY-MM-DD'),
                    alunno.fruizione.dataInizioRitiroTemporaneo,
                    alunno.fruizione.dataFineRitiroTemporaneo
                  )

                  const alunnoRitirato = isRitirato(
                    moment(
                      `${annoSel}-${meseSel}-${giorno}`,
                      'YYYY-MM-D',
                      true
                    ).format('YYYY-MM-DD'),
                    alunno.fruizione.dataRitiro,
                    alunno.fruizione.dataRitiroBidella
                  )

                  const giornoChiusura =
                    isNonGiornoServizio(
                      moment(
                        `${annoSel}-${meseSel}-${giorno}`,
                        'YYYY-MM-D',
                        true
                      ).format('YYYY-MM-DD'),
                      servizio
                    ) ||
                    isFestivita(
                      giorniNonErogazione,
                      moment(
                        `${annoSel}-${meseSel}-${giorno}`,
                        'YYYY-MM-D',
                        true
                      ).format('YYYY-MM-DD')
                    ) ||
                    isChiusuraNoFestivita(
                      giorniNonErogazione,
                      moment(
                        `${annoSel}-${meseSel}-${giorno}`,
                        'YYYY-MM-D',
                        true
                      ).format('YYYY-MM-DD')
                    )

                  if (
                    alunnoRitirato ||
                    alunnoRitiratoTemporaneo ||
                    giornoChiusura
                  ) {
                    return {
                      ...acc,
                      [giorno + '']: { tipo: '', isSalvata: false },
                    }
                  }

                  // CASO 3: DEVE ESSERCI PRESENZA
                  // => SE MANGIA QUEL GIORNO => P
                  const giornoSettimana = moment(
                    `${annoSel}-${meseSel}-${giorno}`,
                    'YYYY-MM-D',
                    true
                  )
                    .format('ddd')
                    .toLowerCase()

                  if (alunno.fruizione[giornoSettimana]) {
                    return {
                      ...acc,
                      [giorno + '']: { tipo: 'P', isSalvata: false },
                    }
                  }

                  // ALTRIMENTI A
                  return {
                    ...acc,
                    [giorno + '']: { tipo: 'A', isSalvata: false },
                  }
                }, {})

                return {
                  ...alunno,
                  presenze,
                }
              })
          : []

      return alunniEdit
    }

    if (presenzeMensili) {
      setAlunniEdit(getAlunniEdit())
    }
  }, [
    presenzeMensili,
    annoSel,
    meseSel,
    giorniDelMese,
    giorniNonErogazione,
    previsione,
    sezioneSel,
    servizio,
  ])

  return (
    <div className="bg-white py-6">
      <style>{`
        .ag-theme-alpine * {
          border-style: none;
        }
        .ag-react-container {
          height: 100%;
        }
      `}</style>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {isFetchingScuole && <Loading size={40} className="py-6" />}
        <h2 className="text-lg leading-tight mt-3 mb-4 text-gray-600 flex items-center">
          {scuolaSel && (
            <InlineEdit
              type={InputType.Select}
              value={scuolaSel.id + ''}
              onChange={(v) =>
                dispatch(
                  changeScuolaSel(scuole.find((s: Scuola) => s.id === +v))
                )
              }
              options={scuoleOptions}
              editClass="form-select text-lg"
              viewClass="cursor-pointer text-blue-500"
            />
          )}
        </h2>
        <h1 className="text-4xl leading-tight mb-8">
          <span className="text-xl">Presenze di</span>{' '}
          <InlineEdit
            type={InputType.Select}
            value={meseSel}
            onChange={(v) => {
              dispatch(changeMeseSel(v))
            }}
            options={mesiOptions}
            viewClass="cursor-pointer text-blue-500"
            editClass="form-select text-lg"
          />
        </h1>

        <div className="flex flex-wrap items-center py-3 px-4 sm:px-6 bg-gray-100 mb-4">
          {isFetchingSezioni && <Loading size={40} className="py-6" />}
          {sezioni
            .filter((sezione: Sezione) => !sezione.fittizia)
            .map((sezione: any) => (
              <div
                key={sezione.id}
                className={classNames(
                  'py-px px-2 rounded mr-2 my-1 text-sm font-semibold cursor-pointer',
                  {
                    'bg-gray-300 hover:bg-gray-400 text-gray-700 hover:text-gray-900':
                      !sezione.presenze,
                  },
                  {
                    'bg-green-300 hover:bg-green-400 text-green-800 hover:text-green-900':
                      sezione.presenze,
                  },
                  {
                    'border-4 border-blue-400 shadow-lg':
                      sezione.id === sezioneSel?.id,
                  }
                )}
                onClick={() => {
                  setAlunnoSel(null)
                  dispatch(changeSezioneSel(sezione))
                }}
              >
                {sezione.classe} {sezione.sezione}
              </div>
            ))}
        </div>

        <div className="flex space-x-2 items-center justify-start mb-3">
          <button
            type="button"
            onClick={() => {
              setEditAllModalOpen(true)
              setIsReadOnly(false)
            }}
            className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
          >
            Modifica tutti
          </button>
        </div>

        {!!alunniEdit && alunniEdit.length > 0 ? (
          isReadOnly ? (
            <PresenzeMensiliTable
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              footerGroups={footerGroups}
              totalColumnsWidth={totalColumnsWidth}
              rows={rows}
              prepareRow={prepareRow}
            />
          ) : (
            <p>Modifiche in corso</p>
          )
        ) : (
          <p>Non ci sono dati da mostrare</p>
        )}
      </div>

      {user?.ruolo === 'presenze' ? (
        <ReactModal
          isOpen={editAlunnoModalOpen}
          onRequestClose={() => {
            setEditAlunnoModalOpen(false)
            setAlunnoSel(null)
            setPresenzeAlunnoSel({})
            document.getElementById('root')!.style.filter = 'blur(0)'
          }}
          shouldCloseOnOverlayClick={false}
          style={modalCustomStyles2}
        >
          <div className="">
            {alunnoSel && presenzeAlunnoSel && (
              <>
                <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
                  {alunnoSel.cognome} {alunnoSel.nome}
                </div>
                <div className="flex flex-col space-y-6 px-8 py-6">
                  <div className="flex space-x-2 items-center justify-start">
                    <button
                      type="button"
                      onClick={handleChangePresenzeAlunno(alunnoSel.id, false)}
                      className="block bg-red-600 hover:bg-red-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-px px-2 rounded transition-colors duration-150"
                    >
                      Tutte assenze
                    </button>
                    <button
                      type="button"
                      onClick={handleChangePresenzeAlunno(alunnoSel.id, true)}
                      className="block bg-green-600 hover:bg-green-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-px px-2 rounded transition-colors duration-150"
                    >
                      Default
                    </button>
                  </div>
                  <div className="flex items-center space-x-2 pb-5 overflow-x-scroll">
                    {giorniDelMese.map((giorno) => {
                      const giornoDellaSettimana = moment(
                        `${annoSel}-${meseSel}-${giorno}`,
                        'YYYY-MM-D',
                        true
                      )
                        .format('dd')
                        .charAt(0)
                        .toUpperCase()
                      return (
                        <div
                          key={giorno}
                          className="flex flex-col justify-start items-center"
                        >
                          <div
                            className={`${
                              giornoDellaSettimana === 'S' ||
                              giornoDellaSettimana === 'D'
                                ? 'text-red-500'
                                : 'text-blue-700'
                            }`}
                          >
                            {giornoDellaSettimana}
                          </div>
                          <div>{giorno}</div>
                          <div>
                            <EditPresenzaInput
                              value={presenzeAlunnoSel[giorno].tipo || ''}
                              onChange={(e: any) => {
                                const newAlunniEdit = alunniEdit.map(
                                  (alunno) => {
                                    if (alunno.id === alunnoSel?.id) {
                                      return {
                                        ...alunno,
                                        presenze: {
                                          ...presenzeAlunnoSel,
                                          [giorno + '']: {
                                            tipo: e.target.value,
                                            isSalvata: false,
                                          },
                                        },
                                      }
                                    }
                                    return alunno
                                  }
                                )
                                const alunnoEdit = newAlunniEdit.find(
                                  (a) => a.id === alunnoSel.id
                                )
                                setPresenzeAlunnoSel(alunnoEdit.presenze)
                              }}
                              isSalvata={
                                alunnoSel?.presenze[giorno].tipo === '' ||
                                alunnoSel?.presenze[giorno].isSalvata
                              }
                              servizioId={servizioId}
                              isDisabled={isWeekendOFestivitaClass(giorno + '')}
                              presenzeRole={true}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      className="w-auto bg-gray-400 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-gray-500 hover:shadow-xl"
                      onClick={() => {
                        setPrevisione(false)
                        setEditAlunnoModalOpen(false)
                        setAlunnoSel(null)
                        setPresenzeAlunnoSel({})
                      }}
                    >
                      Annulla
                    </button>
                    <button
                      type="button"
                      className="w-auto bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                      onClick={handleSalvaPresenze}
                    >
                      Salva
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </ReactModal>
      ) : (
        <ReactModal
          isOpen={editAlunnoModalOpen}
          onRequestClose={() => {
            setEditAlunnoModalOpen(false)
            setAlunnoSel(null)
            setPresenzeAlunnoSel({})
            document.getElementById('root')!.style.filter = 'blur(0)'
          }}
          shouldCloseOnOverlayClick={false}
          style={modalCustomStyles}
        >
          <div className="max-w-6xl">
            {alunnoSel && presenzeAlunnoSel && (
              <>
                <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
                  {alunnoSel.cognome} {alunnoSel.nome}
                </div>
                <div className="flex flex-col space-y-6 px-8 py-6">
                  <div className="flex space-x-2 items-center justify-start">
                    <button
                      type="button"
                      onClick={handleChangePresenzeAlunno(alunnoSel.id, false)}
                      className="block bg-red-600 hover:bg-red-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-px px-2 rounded transition-colors duration-150"
                    >
                      Tutte assenze
                    </button>
                    <button
                      type="button"
                      onClick={handleChangePresenzeAlunno(alunnoSel.id, true)}
                      className="block bg-green-600 hover:bg-green-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-px px-2 rounded transition-colors duration-150"
                    >
                      Default
                    </button>
                  </div>
                  <div className="flex items-center justify-center space-x-2">
                    {giorniDelMese.map((giorno) => {
                      const giornoDellaSettimana = moment(
                        `${annoSel}-${meseSel}-${giorno}`,
                        'YYYY-MM-D',
                        true
                      )
                        .format('dd')
                        .charAt(0)
                        .toUpperCase()
                      return (
                        <div
                          key={giorno}
                          className="flex flex-col justify-center items-center"
                        >
                          <div
                            className={`${
                              giornoDellaSettimana === 'S' ||
                              giornoDellaSettimana === 'D'
                                ? 'text-red-500'
                                : 'text-blue-700'
                            }`}
                          >
                            {giornoDellaSettimana}
                          </div>
                          <div>{giorno}</div>
                          <div>
                            <EditPresenzaInput
                              value={presenzeAlunnoSel[giorno].tipo || ''}
                              onChange={(e: any) => {
                                const newAlunniEdit = alunniEdit.map(
                                  (alunno) => {
                                    if (alunno.id === alunnoSel?.id) {
                                      return {
                                        ...alunno,
                                        presenze: {
                                          ...presenzeAlunnoSel,
                                          [giorno + '']: {
                                            tipo: e.target.value,
                                            isSalvata: false,
                                          },
                                        },
                                      }
                                    }
                                    return alunno
                                  }
                                )
                                const alunnoEdit = newAlunniEdit.find(
                                  (a) => a.id === alunnoSel.id
                                )
                                setPresenzeAlunnoSel(alunnoEdit.presenze)
                              }}
                              isSalvata={
                                alunnoSel?.presenze[giorno].tipo === '' ||
                                alunnoSel?.presenze[giorno].isSalvata
                              }
                              servizioId={servizioId}
                              isDisabled={isWeekendOFestivitaClass(giorno + '')}
                              presenzeRole={false}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      className="w-auto bg-gray-400 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-gray-500 hover:shadow-xl"
                      onClick={() => {
                        setPrevisione(false)
                        setEditAlunnoModalOpen(false)
                        setAlunnoSel(null)
                        setPresenzeAlunnoSel({})
                      }}
                    >
                      Annulla
                    </button>
                    <button
                      type="button"
                      className="w-auto bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                      onClick={handleSalvaPresenze}
                    >
                      Salva
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </ReactModal>
      )}

      <ReactModal
        isOpen={editAllModalOpen}
        onRequestClose={() => {
          setEditAllModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        shouldCloseOnOverlayClick={false}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Modifica tutti
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div className="flex items-center justify-between">
              <div>
                <input
                  className="form-checkbox mr-3"
                  type="checkbox"
                  name="previsione"
                  id="previsione"
                  checked={previsione}
                  onChange={() => {
                    setPrevisione(!previsione)
                  }}
                />
                <label htmlFor="previsione">Imposta default</label>
              </div>
              <div className="flex space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setPrevisione(false)
                    setEditAllModalOpen(false)
                    setIsReadOnly(true)
                    setAlunniEdit(alunni)
                  }}
                  className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  Annulla modifiche
                </button>
                <button
                  type="button"
                  onClick={handleSalvaPresenze}
                  className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  Salva presenze
                </button>
              </div>
            </div>
            <PresenzeMensiliTable
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              footerGroups={footerGroups}
              totalColumnsWidth={totalColumnsWidth}
              rows={rows}
              prepareRow={prepareRow}
            />
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={presenzeGiornoModalOpen}
        onRequestClose={() => {
          setGiornoSel(null)
          setPresenzeGiornoModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        shouldCloseOnOverlayClick={false}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          {giornoSel && (
            <>
              <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
                Modifica tutte le presenze del{' '}
                {moment(
                  `${annoSel}-${meseSel}-${giornoSel}`,
                  'YYYY-MM-DD',
                  true
                ).format('DD MMMM YYYY')}
              </div>
              <div className="flex flex-col space-y-6 px-8 py-6">
                <div className="flex space-x-6 items-end justify-between">
                  <div>Tutti</div>
                  <div className="flex flex-col items-center justify-center">
                    <label htmlFor="tuttiPresenti" className="text-green-600">
                      P
                    </label>
                    <input
                      type="radio"
                      name="tuttiPresenti"
                      className="form-radio text-green-600"
                      checked={tipoPresenzaSel === 'P'}
                      onChange={() => setTipoPresenzaSel('P')}
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <label htmlFor="tuttiAssenti" className="text-red-600">
                      A
                    </label>
                    <input
                      type="radio"
                      name="tuttiAssenti"
                      className="form-radio text-red-600"
                      checked={tipoPresenzaSel === 'A'}
                      onChange={() => setTipoPresenzaSel('A')}
                    />
                  </div>
                  {servizioId === 3 && (
                    <>
                      <div className="flex flex-col items-center justify-center">
                        <label
                          htmlFor="tuttiInBianco"
                          className="text-yellow-600"
                        >
                          B
                        </label>
                        <input
                          type="radio"
                          name="tuttiInBianco"
                          className="form-radio text-yellow-600"
                          checked={tipoPresenzaSel === 'B'}
                          onChange={() => setTipoPresenzaSel('B')}
                        />
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <label
                          htmlFor="tuttiMinipasto"
                          className="text-purple-600"
                        >
                          M
                        </label>
                        <input
                          type="radio"
                          name="tuttiMinipasto"
                          className="form-radio text-purple-600"
                          checked={tipoPresenzaSel === 'M'}
                          onChange={() => setTipoPresenzaSel('M')}
                        />
                      </div>
                    </>
                  )}
                  <div className="flex flex-col items-center justify-center">
                    <label htmlFor="tuttiVuoti" className="text-gray-600">
                      Vuoti
                    </label>
                    <input
                      type="radio"
                      name="tuttiVuoti"
                      className="form-radio text-gray-600"
                      checked={tipoPresenzaSel === null}
                      onChange={() => setTipoPresenzaSel(null)}
                    />
                  </div>
                </div>
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => {
                      setGiornoSel(null)
                      setTipoPresenzaSel(null)
                      setPresenzeGiornoModalOpen(false)
                    }}
                    className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  >
                    Annulla
                  </button>
                  <button
                    type="button"
                    onClick={handleModificaPresenzeGiorno(tipoPresenzaSel)}
                    className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  )
}

export default PresenzeMensili

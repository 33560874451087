import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface CreateSezione {
  annoScolastico: string
  scuolaId: number
  classe: string
  sezione: string
  adulti: boolean
}

const createSezione = async (sezione: CreateSezione) => {
  const { data } = await axios.post(`/sezioni/`, sezione)
  return data
}

const useCreateSezione = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (sezione: CreateSezione) => {
      return createSezione(sezione)
    },
    {
      onSuccess: (data: any, variables) => {
        queryClient.invalidateQueries('sezioniByScuolaId')
        cogoToast.success('Nuova sezione creata')
      },
      onError: () => {},
    }
  )
}

export default useCreateSezione

import { useQuery } from 'react-query'
import axios from 'axios'

interface CertificazionePagamentiGenitoreProps {
  alunnoId: number | undefined
  servizioId: number
  annoScolastico: string
  intestatarioId: string | null
}

const getCertificazionePagamentiGenitore = async (
  _: string,
  { alunnoId, annoScolastico, intestatarioId, servizioId }: CertificazionePagamentiGenitoreProps
) => {
  const {
    data,
  }: {
    data: {
      message: string | null
      emailContatto?: string | null
      telefonoContatto?: string | null
      importoTotalePagatoNonTracciato?: number
      importoTotalePagatoTracciato?: number
    }
  } = await axios.get(
    `/avvisi-pagamento/certificazione-pagamenti/?annoScolastico=${annoScolastico}&idServizio=${servizioId}&idIntestatario=${intestatarioId}&idAlunno=${alunnoId}`
  )
  return data
}

const useCertificazionePagamentiGenitore = (
  alunnoId: number | undefined,
  servizioId: number,
  annoScolastico: string,
  intestatarioId: string | null
) => {
  return useQuery(
    ['certificazionePagamentiGenitore', alunnoId, annoScolastico, servizioId, intestatarioId],
    () =>
      getCertificazionePagamentiGenitore('certificazionePagamentiGenitore', {
        alunnoId,
        annoScolastico,
        servizioId,
        intestatarioId,
      }),
    {
      enabled: !!alunnoId,
      // 30 minuti
      staleTime: 30 * 60 * 1000,
    }
  )
}

export default useCertificazionePagamentiGenitore

import React from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import Switch from 'rc-switch'
import { FaMinusCircle } from 'react-icons/fa'
import useDeleteIntestatarioById from '../../../../apiHooks/mutations/useDeleteIntestatarioById'

import 'rc-switch/assets/index.css'
import classNames from 'classnames'
import useInibizioneDati from '../../../../apiHooks/mutations/useInibizioneDati'

interface IntestatariDatiProps extends RouteComponentProps {
  intestatari?: any
  fruizioni?: any
  servizi?: any
  alunnoId: number
}

const ElencoIntestatari: React.FC<IntestatariDatiProps> = ({
  intestatari,
  fruizioni,
  servizi,
  alunnoId,
}) => {
  const getServiziIntestatario = (intestatarioId: number) =>
    fruizioni
      .filter((f: any) => f.intestatarioId === intestatarioId)
      .map((f: any) => servizi.find((s: any) => s.id === f.servizioId).nome)

  const deleteIntestatario = useDeleteIntestatarioById()
  const inibizioneDati = useInibizioneDati(alunnoId)

  const handleChangeInibizioneDati = (intestatarioId: number) => (value: boolean) => {
    inibizioneDati.mutate({ intestatarioId, inibito: value })
  }

  return (
    <div className="lg:pl-32 flex flex-col mt-6">
      <div className="align-middle inline-block min-w-full overflow-hidden sm:rounded border border-gray-300">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Intestatario
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Servizi intestati
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Non fornire dati
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {intestatari &&
              intestatari.map((intestatario: any) => {
                return (
                  <tr
                    key={intestatario.id}
                    className={intestatario.inibitoAccessoDati ? 'bg-red-200' : 'bg-white'}
                  >
                    <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                      <div className="flex items-center space-x-2">
                        {!!intestatario.inibitoAccessoDati && (
                          <FaMinusCircle className="text-red-600" />
                        )}
                        <Link to={`${intestatario.id}`} className="text-blue-500">
                          {intestatario.cognome} {intestatario.nome}
                        </Link>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                      {getServiziIntestatario(intestatario.id).map((s: any) => {
                        return (
                          <span
                            key={s}
                            className="py-1 px-2 text-sm bg-purple-100 text-purple-700 capitalize rounded mr-2 border border-purple-200"
                          >
                            {s}
                          </span>
                        )
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                      <Switch
                        checked={intestatario.inibitoAccessoDati}
                        onChange={handleChangeInibizioneDati(intestatario.id)}
                        // checkedChildren="Inibito"
                        // unCheckedChildren="Ok"
                        className={classNames(
                          {
                            'bg-red-600 border-red-600': intestatario.inibitoAccessoDati,
                          },
                          { 'bg-green-400 border-green-400': !intestatario.inibitoAccessoDati }
                        )}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                      <button
                        type="button"
                        className="text-red-700 text-sm font-semibold hover:text-red-800"
                        onClick={() => deleteIntestatario.mutate(intestatario.id)}
                      >
                        Elimina intestatario
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <Link
          to="nuovo"
          className="bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
        >
          Nuovo intestatario
        </Link>
      </div>
    </div>
  )
}

export default ElencoIntestatari

import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface InvioEmailAvvisi {
  dataEmissione: string
}

const inviaEmailAvvisi = async (servizioId: number, dataEmissione: InvioEmailAvvisi) => {
  const { data } = await axios.post(`/servizi/${servizioId}/invia-email-avvisi/`, { dataEmissione })
  return data
}

const useInviaEmailAvvisi = () => {
  return useMutation(
    ({ servizioId, dataEmissione }: { servizioId: number; dataEmissione: InvioEmailAvvisi }) => {
      return inviaEmailAvvisi(servizioId, dataEmissione)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        cogoToast.success('Invio email accodato')
      },
      onError: () => {},
    }
  )
}

export default useInviaEmailAvvisi

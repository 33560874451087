import React from 'react'
import IBAN from 'iban'
import classNames from 'classnames'
import FormField, { InputType } from '../../../../components/FormField'

interface FormSingoloServizioProps {
  register: any
  errors: any
  control?: any
  watch?: any
  setValue?: any
  intestatari: any[]
  sezioneBidella: any
  fasceCalcolo: any[]
  numeroTurni?: number
  giorniMensaPrevisti: any
  pagamentoSospeso?: boolean
  nascondiCertificazionePagamentiFlag: boolean
  servizioId: number
}

const MensaAFasce: React.FC<FormSingoloServizioProps> = ({
  register,
  errors,
  control,
  watch,
  setValue,
  intestatari,
  sezioneBidella,
  fasceCalcolo,
  numeroTurni,
  giorniMensaPrevisti,
  pagamentoSospeso,
  nascondiCertificazionePagamentiFlag,
  servizioId,
}) => {
  const turniOptions = Array.from(Array(numeroTurni).keys()).map((e) => {
    return {
      label: `${e + 1}° turno`,
      value: e + 1,
    }
  })

  return (
    <div>
      <section>
        {/* <h1 className="text-2xl mb-2 leading-tight">Mensa</h1>
        <hr className="border-blue-200 border-t-2 my-8"></hr> */}
        <div className="text-gray-900 mb-5 font-semibold leading-none">Intestatario bolletta</div>
        <FormField
          fieldName="intestatario"
          type={InputType.Select}
          options={intestatari.map((intestatario: any) => ({
            value: intestatario.id,
            label: `${intestatario.nome} ${intestatario.cognome}`,
          }))}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="pagamentoSospeso"
          label="Bollette sospese"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        {servizioId !== 3 && (
          <FormField
            fieldName="nascondiCertificazionePagamentiFlag"
            label="Nascondi certificazione pagamenti"
            defaultValue={nascondiCertificazionePagamentiFlag}
            type={InputType.Boolean}
            register={register}
            errors={errors}
            className="mb-3"
          />
        )}
        <div className="text-gray-900 mt-6 mb-5 font-semibold leading-none">Pagamento SDD</div>
        <FormField
          fieldName="pagamentoSdd"
          defaultValue={pagamentoSospeso}
          label="Pagamento SDD"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="iban"
          label="IBAN"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
          validation={{
            validate: (value: string) =>
              !!watch('pagamentoSdd')
                ? (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido'
                : !value || (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido',
          }}
        />
        <FormField
          fieldName="codiceSdd"
          label="Codice SDD"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRichiestaSdd"
          label="Data richiesta SDD"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Giorni di pasto previsti
        </div>
        <div className="flex items-end mb-4">
          {Object.entries<{ fruizione: boolean; servizio: boolean }>(
            giorniMensaPrevisti.ufficiale
          ).map((value: [string, { fruizione: boolean; servizio: boolean }]) => {
            if (value[1].servizio) {
              return (
                <FormField
                  key={`${value[0]}_${value[1].fruizione}`}
                  defaultValue={value[1].fruizione}
                  fieldName={value[0]}
                  label={value[0].substring(0, 1).toUpperCase()}
                  type={InputType.WeekDay}
                  register={register}
                  errors={errors}
                />
              )
            }
            return null
          })}

          <div className="leading-none text-xs text-blue-600 uppercase">Ufficiale</div>
        </div>
        <div className="flex items-end">
          {Object.entries<{ fruizione: boolean; servizio: boolean }>(
            giorniMensaPrevisti.operatore
          ).map((value: [string, { fruizione: boolean; servizio: boolean }]) => {
            if (value[1].servizio) {
              return (
                <FormField
                  key={`${value[0]}_${value[1].fruizione}`}
                  defaultValue={value[1].fruizione}
                  fieldName={`${value[0]}Bidella`}
                  label={value[0].substring(0, 1).toUpperCase()}
                  type={InputType.WeekDay}
                  register={register}
                  errors={errors}
                />
              )
            }
            return null
          })}

          <div className="leading-none text-xs text-pink-600 uppercase">Da operatore</div>
        </div>
        <FormField
          fieldName="turno"
          label="Turno"
          type={InputType.Select}
          options={turniOptions}
          isClearable
          control={control}
          register={register}
          errors={errors}
          className="mt-6 mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Fascia ISEE e quota variabile
        </div>
        <FormField
          fieldName="isee"
          label="Importo ISEE"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="dataScadenzaIsee"
          label={servizioId === 3 ? 'Data acquisizione ISEE' : 'Data scadenza ISEE'}
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="fasciaCalcolo"
          label="Fascia ISEE"
          type={InputType.Select}
          options={
            fasceCalcolo &&
            fasceCalcolo.map((fascia: any) => ({
              value: fascia.id,
              label: fascia.descrizione,
            }))
          }
          isClearable
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="isResidente"
          label="Residente (altrimenti fascia max)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        {/* <div className="text-gray-900 mb-5 font-semibold leading-none">Agevolazioni</div>
        <FormField
          fieldName="flagIseeMinoreDi5000"
          label="ISEE < 5000"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="flagSecondoFiglio"
          label="Secondo fratello"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="flagDisabile"
          label="Portatore di handicap"
          type={InputType.Boolean}
          register={register}
          errors={errors}
        /> */}
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Agevolazioni</div>
        <FormField
          fieldName="flagDisabile"
          label="Portatore di handicap"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="flagSecondoFiglio"
          label="Secondo fratello"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="flagEsonero"
          label="Esonerato"
          type={InputType.Boolean}
          register={register}
          errors={errors}
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Date iscrizione, inizio ed eventuale ritiro
        </div>
        <FormField
          fieldName="dataIscrizione"
          label="Data iscrizione"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataInizio"
          label="Inizio servizio"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiro"
          label="Data ritiro"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiroBidella"
          label="Data ritiro operatore"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName="text-pink-600"
          inputClassName="text-pink-600"
        />
        {/* <FormField
          fieldName="isRitiroConRestituzioneQuota"
          label="Motivo valido (restituzione quota iscrizione)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        /> */}
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rimborsi e Maggiorazioni
        </div>
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaImporto"
          label="Importo una tantum Prossima bolletta"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaDescrizione"
          label="Descrizione causale una tantum"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaImporto"
          label="Importo mensile"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaDescrizione"
          label="Descrizione causale mensile"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riportoRiduzione"
          label="Riporto precedente"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Rimborsi Finanziari</div>
        <FormField
          fieldName="rimborsoFinanziarioImporto"
          label="Importo rimborso finanziario"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="rimborsoFinanziarioDescrizione"
          label="Descrizione rimborso finanziario"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Note</div>
        <FormField
          fieldName="note"
          label="Note interne"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="noteBidella"
          label="Note operatore"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
        />

        <div className="hidden">
          <FormField
            fieldName="sezioneIdBidella"
            type={InputType.Text}
            errors={errors}
            register={register}
          />
        </div>

        {sezioneBidella && watch('sezioneIdBidella') && (
          <div className="mb-3 border-2 border-pink-500 rounded p-4 bg-pink-100">
            <div className="block text-sm leading-5 font-medium text-pink-500 mb-2">
              Sezione indicata da operatore
            </div>
            <div className="flex">
              <div className="mr-4">
                {sezioneBidella.scuolaNome} {sezioneBidella.classe} {sezioneBidella.sezione}
              </div>
              <button
                className="px-2 rounded bg-green-500 text-white hover:bg-green-600"
                onClick={() => {
                  setValue('sezioneIdBidella', '', { shouldDirty: true })
                }}
              >
                Annulla segnalazione
              </button>
            </div>
          </div>
        )}
      </section>

      <hr className="border-blue-200 border-t-2 my-8"></hr>

      <section>
        <FormField
          fieldName="modificatoBidella"
          label="Modificato da operatore (data ritiro, note o giorni di fruizione)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({
            'text-pink-500': watch('modificatoBidella'),
          })}
          inputClassName={classNames({
            'text-pink-500': watch('modificatoBidella'),
          })}
        />
      </section>
      <button
        type="submit"
        className="mt-8 block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
      >
        Salva
      </button>
    </div>
  )
}

export default MensaAFasce

import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenze {
  annoScolastico: string
  servizioId: number
  alunnoId?: number
}

const getPresenzeGenitore = async (_: string, { annoScolastico, servizioId, alunnoId }: IPresenze) => {
  const { data } = await axios.get(
    `/presenze/genitore/?limit=1000&annoScolastico=${annoScolastico}&idServizio=${servizioId}&idAlunno=${alunnoId}`
  )
  return data
}

const usePresenzeGenitore = ({ annoScolastico, servizioId, alunnoId }: IPresenze) => {
  return useQuery(
    ['presenzeGenitore', { annoScolastico, servizioId, alunnoId }],
    () => getPresenzeGenitore('presenzeGenitore', { annoScolastico, servizioId, alunnoId }),
    {
      enabled: !!annoScolastico && !!servizioId && !!alunnoId,
      // 5 minuti
      staleTime: 5 * 60 * 1000,
    }
  )
}

export default usePresenzeGenitore

import { useQuery } from 'react-query'
import axios from 'axios'

interface IAlunniDaControllare {
  servizioId: number
  annoScolastico: string
  scuolaId?: number
}

const getAlunniDaControllare = async (_: string, { servizioId, annoScolastico, scuolaId }: IAlunniDaControllare) => {
  const scuolaIdQuery = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const { data } = await axios.get(
    `/alunni/da-controllare?servizioId=${servizioId}&annoScolastico=${annoScolastico}${scuolaIdQuery}`
  )

  return data.results
}

const useAlunniDaControllare = ({ servizioId, annoScolastico, scuolaId }: IAlunniDaControllare) => {
  return useQuery(
    ['alunniDaControllare', { servizioId, annoScolastico, scuolaId }],
    () => getAlunniDaControllare('alunniDaControllare', { servizioId, annoScolastico, scuolaId }),
    {
      enabled: !!servizioId && !!annoScolastico,
    }
  )
}

export default useAlunniDaControllare

import React, {
  useCallback,
  useContext,
  useMemo,
  // useRef,
  useState,
} from 'react'
import { RouteComponentProps } from '@reach/router'
import Dropzone from 'react-dropzone'
import { useBlockLayout, useTable } from 'react-table'
// import { VariableSizeList } from 'react-window'
import { FiSearch } from 'react-icons/fi'
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from 'react-icons/fa'
import classNames from 'classnames'

import ServizioContext from '../../context/ServizioContext'

import useAnalisiEsiti from '../../apiHooks/mutations/useAnalisiEsiti'
import useImportaEsiti from '../../apiHooks/mutations/useImportaEsiti'

import Loading from '../../components/Loading'
import Price from '../../components/Price'

const EsitiBonifici: React.FC<RouteComponentProps> = () => {
  //==================
  // USE STATE
  //==================
  const [csvFile, setCsvFile] = useState(null)
  const [dropErrorMessage, setDropErrorMessage] = useState('')

  //==================
  // CONTEXT
  //==================
  const { servizioId } = useContext(ServizioContext)

  //==================
  // REF
  //==================
  // const tableRef = useRef<VariableSizeList>(null)

  //==================
  // MUTATIONS
  //==================
  const analizzaEsiti = useAnalisiEsiti()
  const importaEsiti = useImportaEsiti()

  //==================
  // USE MEMO
  //==================
  const esiti = useMemo(() => {
    return analizzaEsiti.data
  }, [analizzaEsiti])

  const iconToShow = (risultato: string, avvisi: any) => {
    if (risultato === 'error') {
      return <FaTimesCircle className="text-red-500" />
    } else if (risultato === 'ok') {
      const pagatoTotalmenteArray = avvisi.map((avviso: any) => {
        return avviso.pagatoTotalmente
      })
      if (pagatoTotalmenteArray.indexOf(false) !== -1) {
        return <FaExclamationCircle className="text-yellow-500" />
      } else {
        return <FaCheckCircle className="text-green-500" />
      }
    }
  }

  //==================
  // CALLBACKS
  //==================
  const handleDropAccepted = useCallback(
    (files: any) => {
      setDropErrorMessage('')
      setCsvFile(files[0])

      let formData = new FormData()
      formData.append('inputFile', files[0])
      analizzaEsiti.mutate({ servizioId, inputFile: formData })
    },
    [servizioId, analizzaEsiti]
  )

  const handleDropRejected = useCallback((files: any) => {
    const fileName = files[0].file.name

    const fileSize =
      files[0].file.size <= 1048576
        ? `${(files[0].file.size / 1024).toFixed(0)} KB`
        : `${(files[0].file.size / 1048576).toFixed(1)} MB`

    setDropErrorMessage(`"${fileName}" supera la dimensione massima consentita: ${fileSize}`)
  }, [])

  const handleSave = useCallback(
    (esiti) => {
      importaEsiti.mutateAsync({ servizioId, esiti }).then(() => {
        setCsvFile(null)
      })
    },
    [importaEsiti, servizioId]
  )

  //==================
  // REACT TABLE
  //==================
  const data = useMemo(() => esiti || [], [esiti])

  const columns = useMemo(
    () => [
      {
        Header: 'Riga',
        accessor: 'riga',
        width: 60,
        disableFilters: true,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'Importo',
        accessor: 'importo',
        width: 80,
        Cell: (props: any) => {
          return (
            <div
              className={classNames('h-full flex justify-end items-center p-3 text-sm font-semibold', {
                'text-gray-500': !props.value || props.value === '0.00',
              })}
            >
              {props.value ? <Price amount={props.value} /> : null}
            </div>
          )
        },
      },
      {
        Header: 'Descrizione',
        accessor: 'descrizione',
        width: 400,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3 text-xs">{props.value}</div>
        },
      },
      {
        Header: 'Risultato',
        accessor: 'risultato',
        width: 80,
        Cell: (props: any) => {
          return (
            <div className={'h-full flex justify-center items-center p-3 text-xl'}>
              {iconToShow(props.value, props.row.original.avvisi)}
            </div>
          )
        },
      },
      {
        Header: 'Dettaglio',
        accessor: 'dettaglio',
        width: 120,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3 text-xs">{props.value}</div>
        },
      },
      {
        Header: 'Avvisi pagati e importo',
        accessor: 'avvisi',
        width: 600,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              <div className="flex w-full flex-col items-center space-y-3">
                {props.value &&
                  props.value.map((avviso: any) => (
                    <div key={avviso.id} className="flex w-full items-center space-x-4">
                      <div className="py-1 px-2 border bg-gray-100 rounded">{avviso.id}</div>
                      <div>
                        <span
                          className={classNames(
                            {
                              'text-red-600': avviso.importoPagato < avviso.quotaCaricoGenitore,
                            },
                            {
                              'text-green-500 font-semibold': avviso.importoPagato === avviso.quotaCaricoGenitore,
                            },
                            {
                              'text-yellow-600 font-semibold': avviso.importoPagato > avviso.quotaCaricoGenitore,
                            }
                          )}
                        >
                          <Price amount={avviso.importoPagato} />
                        </span>
                        / <Price amount={avviso.quotaCaricoGenitore} />
                      </div>
                      <div className="text-red-600 text-xs">{avviso.error}</div>
                    </div>
                  ))}
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // totalColumnsWidth,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout
  )

  // const rowHeights = useMemo(
  //   () =>
  //     rows.map((r: any) => {
  //       return 220
  //     }),
  //   [rows]
  // )

  // const getItemSize = useCallback(
  //   (index: number) => {
  //     return rowHeights[index]
  //   },
  //   [rowHeights]
  // )

  // const RenderRow = useCallback(
  //   ({ index, style }) => {
  //     const row = rows[index]
  //     prepareRow(row)
  //     return (
  //       <>
  //         <div
  //           {...row.getRowProps({
  //             style,
  //           })}
  //           className="tr hover:bg-blue-100 border-b border-gray-400"
  //         >
  //           <div className="flex items-center">
  //             {row.cells.map((cell) => {
  //               return (
  //                 <div
  //                   {...cell.getCellProps()}
  //                   className="td h-full overflow-y-hidden leading-none p-0"
  //                 >
  //                   {cell.render('Cell')}
  //                 </div>
  //               )
  //             })}
  //           </div>
  //         </div>
  //       </>
  //     )
  //   },
  //   [prepareRow, rows]
  // )

  return (
    <div className="bg-white pt-6 pb-12">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-6">Esiti bonifici</h1>

        <Dropzone
          // accept={'text/csv'}
          multiple={false}
          maxSize={1048576}
          onDropAccepted={handleDropAccepted}
          onDropRejected={handleDropRejected}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={classNames(
                'flex items-center justify-center mb-8 py-8 px-6 max-w-lg border-2 break-all hover:border-blue-300 focus:outline-none transition-border duration-150 rounded cursor-pointer',
                { 'bg-gray-100 border-gray-300': !esiti || !csvFile },
                { 'bg-green-100 border-green-300': esiti && csvFile }
              )}
            >
              <input {...getInputProps()} />
              <div className="text-center">
                {analizzaEsiti.isLoading ? (
                  <div className="flex items-center justify-center space-x-3">
                    <Loading type="Watch" size={20} />
                    <span>Caricamento in corso...</span>
                  </div>
                ) : analizzaEsiti.isSuccess && !!csvFile ? (
                  <span>
                    File caricato:
                    {/* @ts-ignore */}
                    <br />"{csvFile.name || null}"
                  </span>
                ) : (
                  <span>Carica qui il file CSV</span>
                )}
                {!!dropErrorMessage && <div className="mt-1 text-red-600">{dropErrorMessage}</div>}
              </div>
            </div>
          )}
        </Dropzone>

        {!!esiti && !!csvFile && (
          <div className="pb-12">
            <h2 className="text-2xl leading-tight font-bold mb-6 flex items-center space-x-3">
              <FiSearch className="text-blue-400" />
              <div>Esito analisi file</div>
            </h2>
            <div className="table-wrapper block max-w-full overflow-x-scroll overflow-y-hidden">
              <div
                {...getTableProps()}
                className="table w-full max-w-6xl text-sm border border-gray-400 rounded rounded-tr-none"
              >
                <div>
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="tr flex items-center w-full">
                      {headerGroup.headers.map((column: any) => (
                        <div
                          {...column.getHeaderProps()}
                          className="th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold"
                        >
                          {column.render('Header')}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row)
                    return (
                      <div {...row.getRowProps()} className="tr hover:bg-blue-100 border-b border-gray-400">
                        <div className="flex items-center">
                          {row.cells.map((cell) => {
                            return (
                              <div {...cell.getCellProps()} className="td h-full overflow-y-hidden leading-none p-0">
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                  {/* <VariableSizeList
                    ref={tableRef}
                    height={600}
                    itemCount={rows.length}
                    itemSize={getItemSize}
                    width={totalColumnsWidth}
                  > */}
                  {/* {RenderRow} */}
                  {/* </VariableSizeList> */}
                </div>
              </div>
            </div>
          </div>
        )}

        <button
          type="button"
          className={classNames(
            'flex space-x-3 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:shadow-xl',
            { 'bg-blue-400 hover:bg-blue-500 cursor-not-allowed': !csvFile },
            { 'bg-blue-500  hover:bg-blue-700 cursor-pointer': csvFile }
          )}
          onClick={() => handleSave(esiti)}
          disabled={!csvFile}
        >
          <span>Conferma</span>
          {importaEsiti.isLoading && <Loading type="Watch" size={20} />}
        </button>
      </div>
    </div>
  )
}

export default EsitiBonifici

import React from 'react'
import { RouteComponentProps, useParams } from '@reach/router'
import useProfile from '../../apiHooks/queries/useProfile'
import useServizioById from '../../apiHooks/queries/useServizioById'
import useRiepilogoDomandaIscrizione from '../../apiHooks/queries/useDomandaIscrizione'
import { RiepilogoIscrizioneMensa } from '../../components/RiepilogoIscrizioneMensa'
import { RiepilogoIscrizioneAsilo } from '../../components/RiepilogoIscrizioneAsilo'

const RiepilogoDomanda: React.FC<RouteComponentProps> = ({ children }) => {
  const clienteId = localStorage.getItem('clienteId')
  const { id } = useParams()
  const { data: user } = useProfile(' ', clienteId ? parseInt(clienteId, 10) : undefined)
  const { data: servizio } = useServizioById(user?.servizi[0])
  const { data: domandaIscrizione } = useRiepilogoDomandaIscrizione(id, servizio?.tipo)

  if (!servizio || !domandaIscrizione) {
    return null
  }

  return (
    <>
      {servizio?.tipo === 'mensa' && (
        <RiepilogoIscrizioneMensa domandaIscrizione={domandaIscrizione} />
      )}
      {servizio?.tipo === 'asilo' && (
        <RiepilogoIscrizioneAsilo domandaIscrizione={domandaIscrizione} />
      )}
    </>
  )
}

export default RiepilogoDomanda

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const updateAlunno = async (alunnoId: number, alunno: any) => {
  const { data } = await axios.patch(`/alunni/${alunnoId}/`, alunno)
  return data
}

const useUpdateAlunno = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ alunnoId, alunno }: { alunnoId: number; alunno: any }) => {
      return updateAlunno(alunnoId, alunno)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('alunnoByIdByAnno')
        queryClient.invalidateQueries('presenzeAlunniData')
        queryClient.invalidateQueries('countAlunniDaControllare')
        cogoToast.success('Alunno aggiornato')
      },
      onError: () => {},
    }
  )
}

export default useUpdateAlunno

import { useMutation } from 'react-query'
import axios from 'axios'
import { FRONTEND_URL } from '../../config'

const impersona = async (servizioId: number, codiceFiscale: string) => {
  const { data } = await axios.post(`/servizi/${servizioId}/impersona/`, {
    codiceFiscale,
  })
  return data
}

const useImpersona = (
  servizioId: number,
  codiceFiscale: string,
  clienteId: number,
  intestatarioId: number
) => {
  return useMutation(
    () => {
      return impersona(servizioId, codiceFiscale)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)

        localStorage.setItem('token', data.accessToken)
        localStorage.setItem('username', codiceFiscale)
        localStorage.setItem('clienteId', clienteId + '')
        localStorage.setItem('intestatarioId', intestatarioId + '')
        localStorage.setItem('servizioId', servizioId + '')
        window.location.href = `${FRONTEND_URL}/app`
      },
      onError: () => {
        // console.log('KO')
      },
    }
  )
}

export default useImpersona

const checkCaratteri = (codiceFiscale: string) => {
  return /^(?:[B-DF-HJ-NP-TV-Z](?:[AEIOU]{2}|[AEIOU]X)|[AEIOU]{2}X|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[\dLMNP-V][1-9MNP-V]|[1-9MNP-V][0L]))[A-Z]$/i.test(
    codiceFiscale
  )
}

const checkParita = (codiceFiscale: string) => {
  var map = [
    1,
    0,
    5,
    7,
    9,
    13,
    15,
    17,
    19,
    21,
    1,
    0,
    5,
    7,
    9,
    13,
    15,
    17,
    19,
    21,
    2,
    4,
    18,
    20,
    11,
    3,
    6,
    8,
    12,
    14,
    16,
    10,
    22,
    25,
    24,
    23,
  ]
  var s = 0
  for (var i = 0; i < 15; i++) {
    var c = codiceFiscale.charCodeAt(i)
    if (c < 65) c = c - 48
    else c = c - 55
    if (i % 2 === 0) s += map[c]
    else s += c < 10 ? c : c - 10
  }
  var atteso = String.fromCharCode(65 + (s % 26))

  if (atteso === codiceFiscale.charAt(15)) {
    return true
  }
  return false
}

const ottieniConsonanti = (str: string) => str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '')
const ottieniVocali = (str: string) => str.replace(/[^AEIOU]/gi, '')

const calcolaCodiceCognome = (cognome: string) => {
  let codiceCognome = ottieniConsonanti(cognome)
  codiceCognome += ottieniVocali(cognome)
  codiceCognome += 'XXX'
  codiceCognome = codiceCognome.substr(0, 3)
  return codiceCognome.toUpperCase()
}

const calcolaCodiceNome = (nome: string) => {
  let codiceNome = ottieniConsonanti(nome)
  if (codiceNome.length >= 4) {
    codiceNome = codiceNome.charAt(0) + codiceNome.charAt(2) + codiceNome.charAt(3)
  } else {
    codiceNome += ottieniVocali(nome)
    codiceNome += 'XXX'
    codiceNome = codiceNome.substr(0, 3)
  }
  return codiceNome.toUpperCase()
}

export const codiceFiscaleValido = (codiceFiscale: string) => {
  if (!checkCaratteri(codiceFiscale)) {
    return false
  }

  if (!checkParita(codiceFiscale)) {
    return false
  }

  return true
}

export const cognomeCoerente = (codiceFiscale: string, cognome: string) => {
  if (
    codiceFiscale &&
    codiceFiscale.length >= 3 &&
    cognome &&
    calcolaCodiceCognome(cognome) !== codiceFiscale.substr(0, 3)
  ) {
    return false
  }
  return true
}

export const nomeCoerente = (codiceFiscale: string, nome: string) => {
  if (
    codiceFiscale &&
    codiceFiscale.length >= 6 &&
    nome &&
    calcolaCodiceNome(nome) !== codiceFiscale.substr(3, 3)
  ) {
    return false
  }
  return true
}

export const getSesso = (codiceFiscale = '') => {
  if (codiceFiscale.length >= 10 && parseInt(codiceFiscale.charAt(9)) >= 4) {
    return 'F'
  }
  return 'M'
}

import React, { useMemo } from 'react'

interface EditPresenzaInputProps {
  value: string
  onChange: any
  servizioId: number
  isSalvata?: boolean
  isDisabled?: boolean
  presenzeRole?: boolean
}

const EditPresenzaInput: React.FC<EditPresenzaInputProps> = ({
  value,
  onChange,
  servizioId,
  isSalvata,
  isDisabled,
  presenzeRole,
}) => {
  const options = useMemo(() => {
    return servizioId === 3
      ? [
          { value: 'A', label: 'A' },
          { value: 'P', label: 'P' },
          { value: 'B', label: 'B' },
          { value: 'M', label: 'M' },
          { value: '', label: '—' },
        ]
      : [
          { value: 'A', label: 'A' },
          { value: 'P', label: 'P' },
          { value: '', label: '—' },
        ]
  }, [servizioId])

  return (
    <select
      className={`form-select border-gray-200 text-center h-5 py-0 px-1 text-xs ${
        isSalvata ? 'bg-white' : 'bg-yellow-200'
      } ${isDisabled ? 'bg-gray-300' : 'bg-white'} ${
        presenzeRole ? 'w-10' : 'w-5'
      }`}
      style={{ backgroundImage: 'none' }}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
    >
      {options.map((option: any) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </select>
  )
}

export default EditPresenzaInput

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { API_URL } from '../../config'

interface NotaDiCredito {
  dataEmissione: string
  importoIvato: number
  aliquotaIva: number
  importoEsenteIva: number
  note: string
}

const creaNotaDiCredito = async (avvisoId: string, avviso: NotaDiCredito) => {
  const { data } = await axios.post(
    `${API_URL}/avvisi-pagamento/${avvisoId}/crea-nota-credito/`,
    avviso
  )
  return data
}

const useCreaNotaDiCredito = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ avvisoId, avviso }: { avvisoId: string; avviso: NotaDiCredito; fruizioneId: string }) => {
      return creaNotaDiCredito(avvisoId, avviso)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        queryClient.invalidateQueries(['avvisiPagamentoByFruizioneId', variables.fruizioneId])
      },
      onError: () => {},
    }
  )
}

export default useCreaNotaDiCredito

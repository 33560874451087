import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

const updateIscrizione = async (iscrizioneId: any, servizio: string | undefined, payload: any) => {
  const { data } = await axios.patch(`/iscrizioni-${servizio}/${iscrizioneId}/`, payload)
  return data
}

const useUpdateIscrizione = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({
      iscrizioneId,
      servizio,
      payload,
    }: {
      iscrizioneId: any
      servizio: string | undefined
      payload: any
    }) => {
      return updateIscrizione(iscrizioneId, servizio, payload)
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('iscrizioni')
        queryClient.invalidateQueries('domandaIscrizione')
      },
      onError: () => {},
    }
  )
}

export default useUpdateIscrizione

import { useQuery } from 'react-query'
import axios from 'axios'

interface IAlunniDaControllare {
  servizioId: number
  annoScolastico: string
  scuolaId?: number
}

const getCountAlunniDaControllare = async (
  _: string,
  { servizioId, annoScolastico, scuolaId }: IAlunniDaControllare
) => {
  const scuolaIdQuery = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const { data } = await axios.get(
    `/alunni/da-controllare?limit=1&servizioId=${servizioId}&annoScolastico=${annoScolastico}${scuolaIdQuery}`
  )

  return data.count
}

const useCountAlunniDaControllare = ({ servizioId, annoScolastico, scuolaId }: IAlunniDaControllare) => {
  return useQuery(
    ['countAlunniDaControllare', { servizioId, annoScolastico, scuolaId }],
    () => getCountAlunniDaControllare('countAlunniDaControllare', { servizioId, annoScolastico, scuolaId }),
    {
      enabled: !!servizioId && !!annoScolastico,
      // 5 minuti
      staleTime: 5 * 60 * 1000,
    }
  )
}

export default useCountAlunniDaControllare

import React, { useContext } from 'react'
import { RouteComponentProps, Link } from '@reach/router'

import ServizioContext from '../../../context/ServizioContext'
import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import useAlunniDaControllare from '../../../apiHooks/queries/useAlunniDaControllare'
import Loading from '../../../components/Loading'

interface AlunnoDaControllare {
  id: number
  cognome: string | null
  nome: string | null
  codiceFiscale: string | null
  dataNascita: string | null
  classe: string
  sezione: string
  scuola: string
}

const DatoMancante = () => (
  <div className="flex justify-center items-center">
    <div className="bg-red-600 text-white text-sm font-bold rounded-full leading-none w-4 h-4 flex justify-center items-center">
      ?
    </div>
  </div>
)

const AlunniDaControllare: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: alunni = [], isFetching } = useAlunniDaControllare({
    servizioId,
    annoScolastico,
  })

  return (
    <div className="pt-6 pb-20 min-h-screen bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {isFetching && <Loading className="py-12" />}

        <table>
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Cognome
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Nome
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Data nascita
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Codice fiscale
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody>
            {alunni.map((alunno: AlunnoDaControllare) => (
              <tr key={alunno.id}>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  <p className="text-sm text-gray-400">
                    {alunno.scuola} {alunno.classe}
                    {alunno.sezione}
                  </p>
                  {alunno.cognome || <DatoMancante />}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  {alunno.nome || <DatoMancante />}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  {alunno.dataNascita || <DatoMancante />}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  {alunno.codiceFiscale || <DatoMancante />}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  <Link
                    to={`/app/anagrafica/alunno/${alunno.id}`}
                    className="border border-white hover:border-blue-200 hover:bg-blue-100 focus:shadow-outline focus:outline-none text-blue-500 leading-5 py-2 px-5 rounded transition-colors duration-150"
                  >
                    Controlla
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AlunniDaControllare

import { Link, RouteComponentProps } from '@reach/router'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { useQueryClient } from 'react-query'

import classNames from 'classnames'
import { FiCalendar, FiDownload } from 'react-icons/fi'
import { useBlockLayout, useFilters, useSortBy, useTable } from 'react-table'
import { VariableSizeList } from 'react-window'
import useExportIscrizioniToFruizioni from '../../apiHooks/mutations/useExportIscrizioniToFruizioni'
import useIscrizioni from '../../apiHooks/queries/useIscrizioni'
import useProfile from '../../apiHooks/queries/useProfile'
import useServizioById from '../../apiHooks/queries/useServizioById'
import { API_URL } from '../../config'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const ListaIscrizioni: React.FC<RouteComponentProps> = ({ children }) => {
  const clienteId = localStorage.getItem('clienteId')

  const [exportToFruizioni, setExportToFuizioni] = useState<boolean>(false)
  const { data: user } = useProfile(' ', clienteId ? parseInt(clienteId, 10) : undefined)
  const { data: servizio } = useServizioById(user?.servizi[0])
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { data: iscrizioni } = useIscrizioni(annoScolastico, servizio?.tipo, servizio?.id)

  const exportIscrizioniToFruizioni = useExportIscrizioniToFruizioni()

  const [rowsIdsSel, setRowsIdsSel] = useState<any>([])
  const [showDates, setShowDates] = useState(false)
  const queryClient = useQueryClient()

  const tableRef = useRef<VariableSizeList>(null)

  // const statoOptions = [
  //   { value: '', label: 'Seleziona uno stato' },
  //   { value: 'IN_COMPILAZIONE', label: 'In compilazione' },
  //   { value: 'INVIATA', label: 'Inviata' },
  //   { value: 'LETTA', label: 'Letta' },
  //   { value: 'APPROVATA', label: 'Approvata' },
  //   { value: 'GIA_ISCRITTO', label: 'Già iscritto' },
  //   { value: 'MODIFICATA', label: 'Modificata' },
  //   { value: 'RIFIUTATA', label: 'Rifiutata' },
  // ]

  const handleRowsChecking = useCallback(
    (rowId) => {
      let rowsIdsToSave = rowsIdsSel
      if (rowsIdsSel.indexOf(rowId) !== -1) {
        rowsIdsToSave = rowsIdsSel.filter((r: any) => r !== rowId)
      } else {
        rowsIdsToSave = [...rowsIdsSel, rowId]
      }

      setRowsIdsSel(rowsIdsToSave)
    },
    [rowsIdsSel]
  )

  const handleExportIscrizioni = useCallback(
    (ids: string[]) => {
      exportIscrizioniToFruizioni
        .mutateAsync({ tipoIscrizione: servizio?.tipo || '', ids, annoScolastico })
        .then(() => {
          setRowsIdsSel([])
          queryClient.invalidateQueries('iscrizioni')
        })

      setExportToFuizioni(false)
    },
    [exportIscrizioniToFruizioni, servizio, annoScolastico, queryClient]
    // [exportIscrizioniToFruizioni, servizio, annoScolastico]
  )

  const data = useMemo(() => iscrizioni || [], [iscrizioni])

  const columns = useMemo(() => {
    if (servizio?.tipo === 'mensa') {
      return [
        {
          Header: ' ',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },

          accessor: ' ',
          width: 45,
          disableFilters: true,
          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 cursor-pointer">
                <input
                  className="cursor-pointer"
                  type="checkbox"
                  checked={rowsIdsSel.indexOf(props.row.original.id) !== -1}
                  onChange={() => handleRowsChecking(props.row.original.id)}
                />
              </div>
            )
          },
        },
        {
          Header: 'Nome',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'iscrittoPersonaNomeCognome',
          width: 240,

          Cell: (props: any) => {
            return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
          },
        },
        {
          Header: showDates ? 'Data creazione' : 'Codice fiscale',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          disableFilters: showDates,
          accessor: showDates ? 'dataCreazione' : 'iscrittoPersonaCodiceFiscale',
          width: 160,

          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                {showDates ? new Date(props.value).toLocaleDateString() : props.value}
              </div>
            )
          },
        },
        {
          Header: showDates ? 'Data di invio' : 'Data di nascita',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: showDates ? 'dataInvio' : 'iscrittoPersonaNascitaData',
          width: 100,
          disableFilters: true,
          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                {props.value && new Date(props.value).toLocaleDateString()}
              </div>
            )
          },
        },
        {
          Header: showDates ? 'Data approvazione' : 'Comune',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          disableFilters: showDates,
          accessor: showDates ? 'dataApprovazione' : 'comuneResidenza',
          width: 120,
          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                {showDates
                  ? props.value && new Date(props.value).toLocaleDateString()
                  : props.value}
              </div>
            )
          },
        },
        {
          Header: 'Scuola',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'nomeScuola',
          width: 160,

          Cell: (props: any) => {
            return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
          },
        },
        {
          Header: 'Stato',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'stato',
          width: 160,

          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                <p className={`text-white ${getStatoColor(props.value)} px-1 text-sm`}>
                  {props.value.includes('_') ? props.value.replace('_', ' ') : props.value}
                </p>
              </div>
            )
          },
        },
        {
          Header: '',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'show',
          width: 170,
          disableFilters: true,
          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-end items-center p-3">
                <Link
                  to={`${props.row.original.id}/riepilogo-domanda`}
                  className="mx-auto block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded"
                >
                  Vedi domanda »
                </Link>
              </div>
            )
          },
        },
      ]
    } else {
      return [
        {
          Header: ' ',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },

          accessor: ' ',
          width: 45,
          disableFilters: true,
          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 cursor-pointer">
                <input
                  className="cursor-pointer"
                  type="checkbox"
                  checked={rowsIdsSel.indexOf(props.row.original.id) !== -1}
                  onChange={() => handleRowsChecking(props.row.original.id)}
                />
              </div>
            )
          },
        },
        {
          Header: 'Nome',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'iscrittoPersonaNomeCognome',
          width: 240,

          Cell: (props: any) => {
            return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
          },
        },
        {
          Header: showDates ? 'Data creazione' : 'Data di nascita',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          disableFilters: true,
          accessor: showDates ? 'dataCreazione' : 'iscrittoPersonaNascitaData',
          width: 140,

          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                {showDates ? new Date(props.value).toLocaleDateString() : props.value}
              </div>
            )
          },
        },
        {
          Header: showDates ? 'Data di invio' : 'Comune',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: showDates ? 'dataInvio' : 'comuneResidenza',
          width: 140,
          disableFilters: showDates,
          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                {showDates
                  ? props.value && new Date(props.value).toLocaleDateString()
                  : props.value}
              </div>
            )
          },
        },
        {
          Header: showDates ? 'Data approvazione' : 'Asilo scelto',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          disableFilters: showDates,
          accessor: showDates ? 'dataApprovazione' : 'nomeScuola',
          width: 160,

          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                {showDates
                  ? props.value && new Date(props.value).toLocaleDateString()
                  : props.value}
              </div>
            )
          },
        },
        {
          Header: 'Punteggio',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'punteggioGraduatoria',
          width: 100,
          // disableFilters: !showDates,
          Cell: (props: any) => {
            return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
          },
        },
        {
          Header: 'Stato',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'stato',
          width: 160,

          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3">
                <p className={`text-white ${getStatoColor(props.value)} px-1 text-sm`}>
                  {props.value.includes('_') ? props.value.replace('_', ' ') : props.value}
                </p>
              </div>
            )
          },
        },
        {
          Header: '',
          Footer: (props: any) => {
            return (
              <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                &nbsp;
              </div>
            )
          },
          accessor: 'show',
          width: 170,
          disableFilters: true,
          Cell: (props: any) => {
            return (
              <div className="h-full flex justify-end items-center p-3">
                <Link
                  to={`${props.row.original.id}/riepilogo-domanda`}
                  className="mx-auto block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded"
                >
                  Vedi domanda »
                </Link>
              </div>
            )
          },
        },
      ]
    }
  }, [rowsIdsSel, handleRowsChecking, showDates, servizio])

  // const columnFilter = (props: any) => {
  //   const {
  //     filterValue,
  //     // preFilteredRows,
  //     setFilter,
  //   } = props.column

  //   if (props.column.Header === 'Stato') {
  //     return (
  //       <select
  //         className={`form-select border-gray-200 text-center h-5 py-0 px-1 text-xs`}
  //         style={{ backgroundImage: 'none' }}
  //         value={filterValue || ''}
  //         onChange={(e) => {
  //           setFilter(e.target.value || undefined)
  //         }}
  //       >
  //         {statoOptions.map((option: { value: string; label: string }) => {
  //           return (
  //             <option key={option.value} value={option.value}>
  //               {option.label}
  //             </option>
  //           )
  //         })}
  //       </select>
  //     )
  //   } else
  //     return (
  //       <input
  //         value={filterValue || ''}
  //         onChange={(e) => {
  //           setFilter(e.target.value || undefined)
  //         }}
  //         placeholder="Cerca..."
  //         // placeholder={`Cerca tra ${preFilteredRows.length} avvisi...`}
  //         className="form-input p-1 rounded text-xs w-24"
  //       />
  //     )
  // }

  const defaultColumn = useMemo(
    () => ({
      Filter: (props: any) => {
        const {
          filterValue,
          // preFilteredRows,
          setFilter,
        } = props.column

        const statoOptions = [
          { value: '', label: 'SELEZIONA >' },
          { value: 'IN_COMPILAZIONE', label: 'In compilazione' },
          { value: 'INVIATA', label: 'Inviata' },
          { value: 'LETTA', label: 'Letta' },
          { value: 'APPROVATA', label: 'Approvata' },
          { value: 'GIA_ISCRITTO', label: 'Già iscritto' },
          { value: 'MODIFICATA', label: 'Modificata' },
          { value: 'RIFIUTATA', label: 'Rifiutata' },
        ]

        if (props.column.Header === 'Stato') {
          return (
            <select
              className={`form-select border-gray-200 text-center h-5 py-0 px-1 text-xs`}
              style={{ backgroundImage: 'none' }}
              value={filterValue || ''}
              onChange={(e) => {
                setFilter(e.target.value || undefined)
              }}
            >
              {statoOptions.map((option: { value: string; label: string }) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </select>
          )
        } else
          return (
            <input
              value={filterValue || ''}
              onChange={(e) => {
                setFilter(e.target.value || undefined)
              }}
              placeholder="Cerca..."
              // placeholder={`Cerca tra ${preFilteredRows.length} avvisi...`}
              className="form-input p-1 rounded text-xs w-24"
            />
          )
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    totalColumnsWidth,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
    },
    useBlockLayout,
    useFilters,
    useSortBy
  )

  const rowHeights = useMemo(
    () =>
      rows.map((r: any) => {
        return 42
      }),
    [rows]
  )

  const getItemSize = useCallback(
    (index: number) => {
      return rowHeights[index]
    },
    [rowHeights]
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <>
          <div
            {...row.getRowProps({
              style,
            })}
            className="tr hover:bg-blue-100 border-b border-gray-400"
          >
            <div className="flex items-center">
              {row.cells.map((cell) => {
                return (
                  <div
                    {...cell.getCellProps()}
                    className="td h-full overflow-y-hidden leading-none p-0"
                  >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    },
    [prepareRow, rows]
  )

  return (
    <div>
      <div className="bg-white pt-6 pb-20">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl leading-tight mb-8">
            {servizio?.tipo === 'mensa'
              ? 'Iscrizione ristorazione scolastica'
              : 'Iscrizione asilo nido'}
          </h1>
        </div>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between">
          <div className="flex justify-start gap-x-4">
            <button
              className="mb-8 flex items-center gap-x-2 bg-gray-100 border border-gray-400 p-3 rounded font-semibold text-sm hover:bg-gray-200"
              onClick={() => setShowDates((val) => !val)}
            >
              <FiCalendar />
              Mostra date
            </button>
            <a
              className="mb-8 flex items-center gap-x-2 bg-gray-100 border border-gray-400 p-3 rounded font-semibold text-sm hover:bg-gray-200"
              href={`${API_URL}/iscrizioni-${
                servizio?.tipo
              }/csv?annoScolastico=${annoScolastico}&servizio=${
                servizio?.id
              }&t=${encodeURIComponent(localStorage.getItem('token') || '')}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiDownload />
              Scarica csv
            </a>
          </div>
          {rowsIdsSel.length > 0 && (
            <button
              className="mb-8 flex items-center gap-x-2 bg-blue-500 text-white p-3 rounded font-semibold text-sm hover:bg-blue-600"
              onClick={() => setExportToFuizioni(true)}
            >
              Invia {rowsIdsSel.length} iscrizioni »
            </button>
          )}
        </div>

        <div className="max-w-8xl table-wrapper mx-auto px-4 sm:px-6 lg:px-8 mb-12 overflow-y-hidden">
          {!!iscrizioni && (
            <div
              {...getTableProps()}
              className="table w-full max-w-5xl text-sm border border-gray-400 rounded rounded-tr-none mx-auto"
            >
              <div>
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map((column: any) => {
                      return (
                        <div
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={classNames(
                            column.width === 0
                              ? ''
                              : 'th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold'
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                          <div className="pt-2 w-full" onClick={(e) => e.stopPropagation()}>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()}>
                <VariableSizeList
                  ref={tableRef}
                  height={500}
                  itemCount={rows.length}
                  itemSize={getItemSize}
                  width={totalColumnsWidth}
                  className="overflow-table-x-hidden"
                >
                  {RenderRow}
                </VariableSizeList>
              </div>
              {footerGroups.map((group) => (
                <div className="tr" {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <div className="td" {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* MODAL TRASFORMAZIONE ISCRIZIONI IN FRUIZIONI */}
      <ReactModal
        isOpen={exportToFruizioni}
        onRequestClose={() => {
          setExportToFuizioni(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Conferma trasformazione iscrizioni in fruizioni
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div className="flex flex-col gap-y-4">
              Invio di {rowsIdsSel.length} iscrizioni.
              <br />
              <b>Attenzione:</b> se già presenti, i dati di alunni, intestatari e iscrizioni verranno sovrascritti{' '}
              <br />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setExportToFuizioni(false)
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  handleExportIscrizioni(rowsIdsSel)
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Procedi
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export default ListaIscrizioni

type Stato =
  | 'IN_COMPILAZIONE'
  | 'INVIATA'
  | 'LETTA'
  | 'APPROVATA'
  | 'GIA_ISCRITTO'
  | 'MODIFICATA'
  | 'RIFIUTATA'

export function getStatoColor(stato: Stato) {
  switch (stato) {
    case 'APPROVATA':
      return 'bg-green-600'
    case 'LETTA':
      return 'bg-blue-500'
    case 'IN_COMPILAZIONE':
      return 'bg-yellow-500'
    case 'INVIATA':
      return 'bg-orange-500'
    case 'RIFIUTATA':
      return 'bg-red-400'
    case 'GIA_ISCRITTO':
      return 'bg-green-400'
  }
}

import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const analisiEsiti = async (servizioId: number, inputFile: FormData) => {
  const { data } = await axios.post(`/servizi/${servizioId}/analisi-esiti/`, inputFile)
  return data
}

const useAnalisiEsiti = () => {
  return useMutation(
    ({ servizioId, inputFile }: { servizioId: number; inputFile: FormData }) => {
      return analisiEsiti(servizioId, inputFile)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        cogoToast.success('Esito analizzato correttamente')
      },
      onError: () => {
        // console.log('KO')
      },
    }
  )
}

export default useAnalisiEsiti

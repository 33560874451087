import moment from 'moment'

// TIPO PRESENZA
export type TipoPresenza = 'A' | 'P' | 'B' | 'M'

// IS PRESENTE
export const isPresente = (tipoPresenza: TipoPresenza) =>
  tipoPresenza === 'P' || tipoPresenza === 'B' || tipoPresenza === 'M'

// IS ASSENTE
export const isAssente = (tipoPresenza: TipoPresenza) => tipoPresenza === 'A'

const isFuturo = (data: string) => {
  return moment(data, 'YYYY-MM-DD', true).isAfter(moment())
}

const isPrimaDiInizioServizio = (data: string, dataInizio: string) => {
  return (
    dataInizio &&
    moment(data, 'YYYY-MM-DD', true).isBefore(
      moment(dataInizio, 'YYYY-MM-DD', true)
    )
  )
  // or prima di anno scolastico corrente
}

const isDopoFineServizio = (data: string, dataFine: string) => {
  return (
    dataFine &&
    moment(data, 'YYYY-MM-DD', true).isSameOrAfter(
      moment(dataFine, 'YYYY-MM-DD', true)
    )
  )
  // or dopo anno scolastico corrente
}

// IS RITIRATO
export const isRitirato = (
  data: string,
  dataRitiro: string,
  dataRitiroBidella: string
) => {
  if (!dataRitiro && !dataRitiroBidella) {
    return false
  }
  if (
    moment(data, 'YYYY-MM-DD', true).isSameOrAfter(
      moment(dataRitiro, 'YYYY-MM-DD', true)
    ) ||
    moment(data, 'YYYY-MM-DD', true).isSameOrAfter(
      moment(dataRitiroBidella, 'YYYY-MM-DD', true)
    )
  ) {
    return true
  }
  return false
}

// IS IN RITIRO TEMPORANEO
export const isInRitiroTemporaneo = (
  data: string,
  dataInizioRitiroTemporaneo: string,
  dataFineRitiroTemporaneo: string
) => {
  if (!dataInizioRitiroTemporaneo) {
    return false
  }
  if (
    moment(dataInizioRitiroTemporaneo, 'YYYY-MM-DD', true).isAfter(
      moment(data, 'YYYY-MM-DD', true)
    )
  ) {
    return false
  }
  if (!dataFineRitiroTemporaneo) {
    return true
  }
  if (
    moment(dataFineRitiroTemporaneo, 'YYYY-MM-DD', true).isBefore(
      moment(data, 'YYYY-MM-DD', true)
    )
  ) {
    return false
  }
  return true
}

// IS CHIUSURA
// (comprende sia festività che chiusura per scioperi, ...)
export const isChiusura = (giorniNonErogazione: any, data: string) => {
  return (giorniNonErogazione || []).find((g: any) => g.data === data)
}

// IS CHIUSURA NO FESTIVITÀ
export const isChiusuraNoFestivita = (
  giorniNonErogazione: any,
  data: string
) => {
  const giorniChiusuraNoFesta = (giorniNonErogazione || []).filter(
    (g: any) => !g.isFestivita
  )
  return giorniChiusuraNoFesta.find((g: any) => g.data === data)
}

// IS FESTIVITÀ
export const isFestivita = (giorniNonErogazione: any, data: string) => {
  const giorniFesta = (giorniNonErogazione || []).filter(
    (g: any) => !!g.isFestivita
  )
  return giorniFesta.find((g: any) => g.data === data)
}

// IS WEEKEND
export const isWeekend = (data: string) => {
  const weekDay: number = moment(data, 'YYYY-MM-DD', true).weekday()
  return weekDay === 5 || weekDay === 6
}

// IS GIORNO SERVIZIO
//sostituire con is weekend
export const isNonGiornoServizio = (data: string, servizio: any) => {
  const weekDay: number = moment(data, 'YYYY-MM-DD', true).weekday()
  if (servizio?.servizioLun && weekDay === 0) {
    return false
  } else if (servizio?.servizioMar && weekDay === 1) {
    return false
  } else if (servizio?.servizioMer && weekDay === 2) {
    return false
  } else if (servizio?.servizioGio && weekDay === 3) {
    return false
  } else if (servizio?.servizioVen && weekDay === 4) {
    return false
  } else if (servizio?.servizioSab && weekDay === 5) {
    return false
  } else if (servizio?.servizioDom && weekDay === 6) {
    return false
  } else return true
}

// IS NON EROGAZIONE
export const isNonErogazione = (
  giorniNonErogazione: any,
  data: string,
  servizio: any
) => {
  return (
    isChiusura(giorniNonErogazione, data) || isNonGiornoServizio(data, servizio)
  )
}

// IS ERRORE SEGNATO
export const isErroreSegnato = (
  tipoPresenza: TipoPresenza,
  giorniNonErogazione: any,
  fruizione: any,
  data: string,
  servizio: any
) => {
  return (
    tipoPresenza &&
    (isNonErogazione(giorniNonErogazione, data, servizio) ||
      isPrimaDiInizioServizio(data, fruizione.dataInizio) ||
      isDopoFineServizio(data, fruizione.dataRitiro) ||
      isDopoFineServizio(data, fruizione.dataRitiroBidella) ||
      isInRitiroTemporaneo(
        data,
        fruizione.dataInizioRitiroTemporaneo,
        fruizione.dataFineRitiroTemporaneo
      ))
  )
}

// IS ERRORE DIMENTICATO
export const isErroreDimenticato = (
  tipoPresenza: TipoPresenza,
  giorniNonErogazione: any,
  fruizione: any,
  data: string,
  servizio: any
) => {
  return (
    !tipoPresenza &&
    !isNonErogazione(giorniNonErogazione, data, servizio) &&
    !isFuturo(data) &&
    !isPrimaDiInizioServizio(data, fruizione.dataInizio) &&
    !isDopoFineServizio(data, fruizione.dataRitiro) &&
    !isDopoFineServizio(data, fruizione.dataRitiroBidella) &&
    !isInRitiroTemporaneo(
      data,
      fruizione.dataInizioRitiroTemporaneo,
      fruizione.dataFineRitiroTemporaneo
    )
  )
}

// IS ERRORE
export const isErrore = (
  data: string,
  tipoPresenza: TipoPresenza,
  giorniNonErogazione: any,
  fruizione: any,
  servizio: any
) => {
  if (!data) {
    return false
  }
  return (
    isErroreSegnato(
      tipoPresenza,
      giorniNonErogazione,
      fruizione,
      data,
      servizio
    ) ||
    isErroreDimenticato(
      tipoPresenza,
      giorniNonErogazione,
      fruizione,
      data,
      servizio
    )
  )
}

// IS WARNING
export const isWarning = (
  data: string,
  tipoPresenza: TipoPresenza,
  servizio?: any
) => {
  if (!data || servizio === undefined) {
    return false
  }

  const giornoSettimana = moment(data, 'YYYY-MM-DD', true).format('ddd')

  const fruizionePrevista =
    servizio[
      `servizio${
        giornoSettimana.charAt(0).toUpperCase() + giornoSettimana.slice(1)
      }`
    ]

  return !fruizionePrevista && isPresente(tipoPresenza)
}

//import './wdyr'

import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/it'
import cogoToast from 'cogo-toast'
import { navigate } from '@reach/router'
import store from './redux/store/createStore'
import { Provider } from 'react-redux'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { API_URL } from './config'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './styles/styles.css'

//======================
// AXIOS GLOBAL CONFIG
axios.defaults.baseURL = API_URL

// REQUEST INTERCEPTOR TO SET AUTH TOKEN
axios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem('token') || ''

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

// RESPONSE INTERCEPTOR TO CATCH 401
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.config.url.startsWith('/auth-token')) {
        // Login con crredenziali errate
        cogoToast.error('Username o password errate')
      } else {
        // Token scaduto
        cogoToast.warn('Sessione scaduta')
        localStorage.removeItem('token')
        navigate('/')
      }
    } else {
      cogoToast.error(`E' avvenuto un errore durante la comunicazione con il server.`, {
        hideAfter: 5,
      })
    }
    Promise.reject(error)
  }
)

//======================
// MOMENT GLOBAL CONFIG
moment.locale('it')

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface IPresenzaGiorno {
  alunnoId: number
  tipo: string
}

interface IUpdatePresenzeGiorno {
  servizioId?: number
  sezioneId: number
  dataPresenze: string
  presenze: IPresenzaGiorno[]
}

const updatePresenzeGiorno = async ({ servizioId, sezioneId, dataPresenze, presenze }: IUpdatePresenzeGiorno) => {
  const { data } = await axios.post(
    `/servizi/${servizioId}/presenze-giorno/?sezioneId=${sezioneId}&data=${dataPresenze}`,
    presenze
  )
  return data
}

const useUpdatePresenzeGiorno = (servizioId: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ sezioneId, dataPresenze, presenze }: IUpdatePresenzeGiorno) => {
      return updatePresenzeGiorno({ servizioId, sezioneId, dataPresenze, presenze })
    },
    {
      onSuccess: (data: any, variables) => {
        queryClient.invalidateQueries('presenzeSezioniData')
        cogoToast.success('Presenze salvate')
      },
      onError: () => {},
    }
  )
}

export default useUpdatePresenzeGiorno

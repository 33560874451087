import React, { useContext, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useTable, useBlockLayout } from 'react-table'
import moment from 'moment'
import classNames from 'classnames'
import useAlunniGenitore from '../../apiHooks/queries/useAlunniGenitore'
import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import usePresenzeGenitore from '../../apiHooks/queries/usePresenzeGenitore'
import Loading from '../../components/Loading'

interface PresenzaGiorno {
  data: string
  tipo: string
}

interface PresenzaMese {
  mese: string
  presenze: PresenzaGiorno[]
}

interface Alunno {
  cap: string
  cittadinanzaCodiceIstat: number
  codiceFiscale: string
  cognome: string
  comuneCodiceIstat: number
  comuneCodiceIstatNascita: number
  dataNascita: string
  fruizioni: []
  id: number
  indirizzo: string
  intestatari: []
  isResidente: boolean
  nazionalitaCodiceIstat: number
  nome: string
  note: string
  statoCodiceIstat: number
  statoCodiceIstatNascita: number
}

interface FiglioProps extends RouteComponentProps {
  id?: number
}

const Presenze: React.FC<FiglioProps> = ({ id }) => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  //===================
  // LOCAL STATE
  //===================
  const [alunno, setAlunno] = useState<Alunno | null>(null)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: alunni, isFetching: isFetchingAlunni } = useAlunniGenitore(
    annoScolastico,
    servizioId
  )
  const { data: presenzeAlunno, isFetching: isFetchingPresenze } = usePresenzeGenitore({
    annoScolastico,
    servizioId,
    alunnoId: id,
  })

  //===================
  // EFFECTS
  //===================
  useEffect(() => {
    const alunno = alunni && alunni.find((a: Alunno) => a.id + '' === id + '')
    setAlunno(alunno)
  }, [id, alunni])

  const PRESENZEOK = useMemo(
    () =>
      presenzeAlunno &&
      presenzeAlunno.map((presenzeMensili: PresenzaMese) => {
        const presenze: {
          [key: string]: 'A' | 'P'
        } = presenzeMensili.presenze.reduce((acc: any, g: PresenzaGiorno) => {
          const numeroGiorno = moment(g.data).date()
          return {
            ...acc,
            [numeroGiorno + '']: g.tipo,
          }
        }, {})

        return {
          ...presenzeMensili,
          ...presenze,
        }
      }),
    [presenzeAlunno]
  )

  const giorniDelMese = useMemo(
    () => [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31,
    ],
    []
  )

  //==================
  // REACT TABLE
  //==================
  const data: PresenzaMese[] = useMemo(() => PRESENZEOK || [], [PRESENZEOK])

  const columns = useMemo(
    () => [
      {
        Header: (props: any) => {
          return <div className="h-full leading-tight py-1 px-0">Mese</div>
        },
        accessor: 'mese',
        width: 125,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      ...giorniDelMese.map((g) => {
        return {
          Header: (props: any) => {
            return (
              <div className="h-full flex items-center justify-center  leading-tight py-1 px-0">
                {g + ''}
              </div>
            )
          },
          accessor: g + '',
          width: 31,
          Cell: (props: any) => {
            return (
              <div
                className={classNames(
                  'h-full flex items-center justify-center p-3',
                  {
                    'text-gray-500': !props.value,
                  },
                  {
                    'text-red-500 font-bold': props.value === 'A',
                  },
                  {
                    'text-green-500 font-bold': props.value === 'P',
                  }
                )}
              >
                {props.value || (props.value === undefined ? '' : '-')}
              </div>
            )
          },
        }
      }),
    ],
    [giorniDelMese]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    useBlockLayout
  )

  return (
    <div>
      <div className="bg-white pt-8 pb-32">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {!isFetchingAlunni && !isFetchingPresenze ? (
            <>
              <>
                <h1 className="text-4xl leading-tight mb-6">Presenze di {alunno?.nome}</h1>
              </>

              <div className="table-wrapper block max-w-full overflow-x-scroll overflow-y-hidden">
                <div
                  {...getTableProps()}
                  className="table w-full max-w-6xl border border-gray-400 rounded rounded-tr-none"
                >
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className="tr flex items-center w-full"
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div
                            {...column.getHeaderProps()}
                            className={classNames(
                              'th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold',
                              {
                                'text-right':
                                  column.Header === 'Importo' ||
                                  column.Header === 'Pagamento' ||
                                  column.Header === 'Scadenza',
                              },
                              {
                                'text-center': column.Header === 'N°' || column.Header === 'PDF',
                              }
                            )}
                          >
                            {column.render('Header')}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row)
                      return (
                        <div
                          {...row.getRowProps()}
                          className="tr hover:bg-blue-100 border-b border-gray-400"
                        >
                          <div className="flex items-center">
                            {row.cells.map((cell) => {
                              return (
                                <div
                                  {...cell.getCellProps()}
                                  className="td h-full overflow-y-hidden leading-none p-0"
                                >
                                  {cell.render('Cell')}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  )
}

export default Presenze

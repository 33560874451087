import { useQuery } from 'react-query'
import axios from 'axios'

export interface Fascia<T = number> {
  id: T

  codice: string
  aliquotaIva: number
  descrizione: string

  costoPastoDescrizione: string
  costoPastoImporto: number
  costoPastoApplicazioneIva: boolean

  quotaFissaImporto: number
  quotaFissaDescrizione: string
  quotaFissaApplicazioneIva: boolean

  commissioniSddImporto: number
  commissioniSddDescrizione: string
  commissioniSddApplicazioneIva: boolean
}

const getFasce = async (servizioId: number) => {
  const { data } = await axios.get<Fascia[]>(`/servizi/${servizioId}/fasce-calcolo`)
  return data
}

const useFasceByServizioId = (servizioId: number) => {
  return useQuery(['fasceByServizioId', servizioId], () => getFasce(servizioId), {
    enabled: !!servizioId,
  })
}

export default useFasceByServizioId

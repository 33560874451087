import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface PresenzaMese {
  fruizioneId: number
  tipo: string
  data: string
}

const updatePresenzeMese = async (servizioId: number, presenze: PresenzaMese[]) => {
  const { data } = await axios.post(`/servizi/${servizioId}/modifica-presenze/`, presenze)
  return data
}

const useUpdatePresenzeMese = (scuolaId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ servizioId, presenze }: { servizioId: number; presenze: PresenzaMese[] }) => {
      return updatePresenzeMese(servizioId, presenze)
    },
    {
      onSuccess: (data: any, variables) => {
        console.log({ data }, { variables })
        queryClient.invalidateQueries('presenzeMese')
        cogoToast.success('Presenze modificate correttamente')
      },
      onError: () => {},
    }
  )
}

export default useUpdatePresenzeMese

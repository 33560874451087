const shortenText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    let trimmedString = text.substr(0, maxLength)
    trimmedString = text.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))
    return trimmedString + '...'
  }
  return text
}

export default shortenText

import React from 'react'
import IBAN from 'iban'
import classNames from 'classnames'
import FormField, { InputType } from '../../../../components/FormField'
import { TIPI_FREQUENZA } from '../../../../lib/tipoFrequenzaOptions'
import { ASILO_GRATIS_OPTIONS } from '../../../../lib/isAsiloGratisOptions'
import { CENTRO_ESTIVO } from '../../../../lib/centroEstivoOptions'

interface FormSingoloServizioProps {
  register: any
  errors: any
  control?: any
  watch?: any
  setValue?: any
  intestatari: any[]
  sezioneBidella: any
}

const ComuneBergamoAsilo: React.FC<FormSingoloServizioProps> = ({
  register,
  errors,
  control,
  watch,
  setValue,
  intestatari,
  sezioneBidella,
}) => {
  // console.log({sezioneBidella, setValue})
  return (
    <div>
      <section>
        {/* <h1 className="text-2xl mb-2 leading-tight">Asilo</h1>
        <hr className="border-blue-200 border-t-2 my-8"></hr> */}
        <div className="text-gray-900 mb-5 font-semibold leading-none">Intestatario bolletta</div>
        <FormField
          fieldName="intestatario"
          type={InputType.Select}
          options={intestatari.map((intestatario: any) => ({
            value: intestatario.id,
            label: `${intestatario.cognome} ${intestatario.nome}`,
          }))}
          isClearable
          control={control}
          register={register}
          validation={{ required: `E' necessario selezionare un intestatario` }}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="restituzioneQuotaIscrizione"
          label="Restituzione quota iscrizione"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <div className="text-gray-900 mt-6 mb-5 font-semibold leading-none">Pagamento</div>
        <FormField
          fieldName="pagamentoSdd"
          label="Pagamento SDD"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="iban"
          label="IBAN"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
          validation={{
            validate: (value: string) =>
              !!watch('pagamentoSdd')
                ? (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido'
                : !value || (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido',
          }}
        />
        <FormField
          fieldName="codiceSdd"
          label="Codice SDD"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRichiestaSdd"
          label="Data richiesta SDD"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="pagamentoSospeso"
          label="Bollette sospese"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Fascia ISEE e quota variabile
        </div>
        <FormField
          fieldName="isee"
          label="Importo ISEE"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />

        <FormField
          fieldName="dataScadenzaIsee"
          label="Data scadenza ISEE"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="isResidente"
          label="Residente (altrimenti fascia max)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="isDipendenteComune"
          label="Dipendente comune"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="isCasoSociale"
          label="Situazione di fragilità (azzeramento quota variabile)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Tipo frequenza</div>
        <FormField
          fieldName="tipoFrequenza"
          label="Tipo Frequenza"
          type={InputType.Select}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
          options={TIPI_FREQUENZA}
        />
        <FormField
          fieldName="centroEstivo"
          label="Centro estivo"
          type={InputType.Select}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
          options={CENTRO_ESTIVO}
        />
        {/* <FormField
          fieldName="centroEstivo"
          label="Centro estivo"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        /> */}
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Sconto 2°/3° fratello</div>
        <FormField
          fieldName="isSecondoFratello"
          label="Secondo fratello (-30%)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Iscrizione e ambientamento
        </div>
        <FormField
          fieldName="dataIscrizione"
          label="Data iscrizione"
          type={InputType.Date}
          register={register}
          // validation={{ required: 'Inserisci la data di iscrizione' }}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataInizio"
          label="Data inizio servizio"
          type={InputType.Date}
          register={register}
          validation={{ required: 'Inserisci la data di inizio' }}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataFineAmbientamento"
          label="Data fine ambientamento"
          type={InputType.Date}
          register={register}
          // validation={{ required: 'Inserisci la data di fine ambientamento' }}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Nidi gratis</div>
        <FormField
          fieldName="isAsiloGratis"
          label="Beneficia di nido gratis"
          type={InputType.Select}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
          options={ASILO_GRATIS_OPTIONS}
        />

        <FormField
          fieldName="asiloGratisQuotaGenitore"
          label="Quota max in carico a genitore"
          type={InputType.Number}
          step="0.01"
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Ritiro temporaneo (min 20, max 50 gg)
        </div>
        <FormField
          fieldName="dataInizioRitiroTemporaneo"
          label="Data inizio ritiro temporaneo"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataFineRitiroTemporaneo"
          label="Data fine ritiro temporaneo"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rinuncia in corso d'anno
        </div>
        <FormField
          fieldName="dataRitiro"
          label="Data ritiro"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiroBidella"
          label="Data ritiro operatore"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({
            'text-pink-500': watch('dataRitiroBidella'),
          })}
          inputClassName={classNames({
            'text-pink-500': watch('dataRitiroBidella'),
          })}
        />
        <FormField
          fieldName="isRitiroConRestituzioneQuota"
          label="Motivo valido (restituzione quota iscrizione)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rimborsi e Maggiorazioni
        </div>
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaImporto"
          label="Importo una tantum Prossima bolletta"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaDescrizione"
          label="Descrizione causale una tantum"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaImporto"
          label="Importo mensile"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaDescrizione"
          label="Descrizione causale mensile"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riportoRiduzione"
          label="Riporto precedente"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
       <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Rimborsi Finanziari</div>
        <FormField
          fieldName="rimborsoFinanziarioImporto"
          label="Importo rimborso finanziario"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="rimborsoFinanziarioDescrizione"
          label="Descrizione rimborso finanziario"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr> 
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Note</div>
        <FormField
          fieldName="note"
          label="Note interne"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="noteBidella"
          label="Note operatore"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
        />

        <div className="hidden">
          <FormField
            fieldName="sezioneIdBidella"
            type={InputType.Text}
            errors={errors}
            register={register}
          />
        </div>

        {sezioneBidella && watch('sezioneIdBidella') && (
          <div className="mb-3 border-2 border-pink-500 rounded p-4 bg-pink-100">
            <div className="block text-sm leading-5 font-medium text-pink-500 mb-2">
              Sezione indicata da operatore
            </div>
            <div className="flex">
              <div className="mr-4">
                {sezioneBidella.scuolaNome} {sezioneBidella.classe} {sezioneBidella.sezione}
              </div>
              <button
                type="button"
                className="px-2 rounded bg-green-500 text-white hover:bg-green-600"
                onClick={() => {
                  setValue('sezioneIdBidella', '', { shouldDirty: true })
                }}
              >
                Annulla segnalazione
              </button>
            </div>
          </div>
        )}
      </section>

      <hr className="border-blue-200 border-t-2 my-8"></hr>

      <section>
        <FormField
          fieldName="modificatoBidella"
          label="Modificato da operatore"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({
            'text-pink-500': watch('modificatoBidella'),
          })}
          inputClassName={classNames({
            'text-pink-500': watch('modificatoBidella'),
          })}
        />
      </section>

      <button
        type="submit"
        className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
      >
        Salva
      </button>
    </div>
  )
}

export default ComuneBergamoAsilo

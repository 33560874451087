import { Link, navigate } from '@reach/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactModal from 'react-modal'
import useUpdateIscrizione from '../apiHooks/mutations/useUpdateIscrizione'
import FormField, { InputType as formFieldInputType } from './FormField'
import { FiCheck, FiX } from 'react-icons/fi'
import moment from 'moment'
import { API_URL } from '../config'
import classNames from 'classnames'
import { getStatoColor } from '../pages/iscrizioni/ListaIscrizioni'
interface RiepilogoIscrizioneAsiloProps {
  domandaIscrizione: any
}

export const RiepilogoIscrizioneAsilo: React.FC<RiepilogoIscrizioneAsiloProps> = ({
  domandaIscrizione,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const updateIscrizione = useUpdateIscrizione()
  const modalCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      padding: 0,
      border: 'none',
    },
    overlay: {
      backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
    },
  }

  const rifiutoIscrizione = (values: any) => {
    updateIscrizione
      .mutateAsync({
        iscrizioneId: domandaIscrizione.id,
        servizio: 'asilo',
        payload: { stato: 'RIFIUTATA', motivazioneRifiuto: values.motivazioneRifiuto },
      })
      .then((response) => {
        navigate('/app/iscrizioni')
      })
      .catch((error) => {
        console.log(error)
      })
    setOpenModal(false)
    document.getElementById('root')!.style.filter = 'blur(0)'
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<any>({
    defaultValues: { motivazioneRifiuto: domandaIscrizione?.motivazioneRifiuto },
  })

  console.log(domandaIscrizione)

  return (
    <div className="pt-6 pb-20 bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-8 text-gray-700">
          Riepilogo iscrizione asilo nido
        </h1>
        {domandaIscrizione.stato === 'RIFIUTATA' && (
          <p className="mb-6 text-red-600">
            La domanda è stata rifiutata con la seguente motivazione: "
            {domandaIscrizione.motivazioneRifiuto}"
          </p>
        )}
        <div className="sm:text-lg flex justify-between">
          <p>
            Riepilogo della domanda di iscrizione al servizio di asilo nido per{' '}
            <span className="font-semibold uppercase">
              {domandaIscrizione.iscrittoPersonaCognome} {domandaIscrizione.iscrittoPersonaNome}
            </span>
          </p>
          <p className={`text-white ${getStatoColor(domandaIscrizione.stato)} p-1 rounded text-sm`}>
            {domandaIscrizione.stato.includes('_')
              ? domandaIscrizione.stato.replace('_', ' ')
              : domandaIscrizione.stato}
          </p>
        </div>

        {domandaIscrizione.numeroProtocollo && domandaIscrizione.dataProtocollo && (
          <div className="mb-6">
            <p>
              Protocollo n° {domandaIscrizione.numeroProtocollo} del{' '}
              {moment(domandaIscrizione.dataProtocollo).format('DD/MM/YYYY HH:mm')}
            </p>
          </div>
        )}

        <div className="mt-4 overflow-x-auto mb-10">
          <table className="bg-gray-100 mb-4 w-full">
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 text-left pl-6">
                  UTENTE CHE HA EFFETTUATO L'ACCESSO
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-semibold text-gray-800">
                  Cognome e nome
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                  colSpan={2}
                >
                  {domandaIscrizione.primoRichiedenteCognome}{' '}
                  {domandaIscrizione.primoRichiedenteNome}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-semibold text-gray-800">
                  E-mail a cui verranno inviate le comunicazioni
                </td>
                <td className="p-2 pl-6 text-left" colSpan={2}>
                  {domandaIscrizione.primoRichiedenteEmail}
                </td>
              </tr>
            </tbody>

            {/* Iscritto */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  BAMBINO/A ISCRITTO/A
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold align-middle text-gray-800">
                  Dati anagrafici
                </td>
                <td className="p-2 pl-6 text-left">
                  <p className="uppercase font-bold">
                    {domandaIscrizione.iscrittoPersonaCognome}{' '}
                    {domandaIscrizione.iscrittoPersonaNome}
                  </p>
                  <p>{domandaIscrizione.iscrittoParentela}</p>
                  <p>{domandaIscrizione.iscrittoPersonaCodiceFiscale}</p>
                  <p>
                    Nato{' '}
                    {Number(domandaIscrizione.iscrittoPersonaNascitaCodiceComune) !== 0
                      ? 'a ' + domandaIscrizione.comuneNascitaIscritto
                      : 'in ' + domandaIscrizione.statoNascitaIscritto}{' '}
                    il {domandaIscrizione.iscrittoPersonaNascitaData}
                  </p>
                  <br />
                  <p>
                    {domandaIscrizione.iscrittoPersonaResidenzaIndirizzo},{' '}
                    {domandaIscrizione.iscrittoPersonaResidenzaNumeroCivico}
                  </p>
                  <p>
                    {domandaIscrizione.iscrittoPersonaResidenzaCap}{' '}
                    {domandaIscrizione.iscrittoNomeComuneResidenza}
                  </p>
                  <br />
                  <p>Cittadinanza: {domandaIscrizione.cittadinanzaIscritto}</p>
                  <p>Nazionalità: {domandaIscrizione.nazionalitaIscritto}</p>
                </td>
              </tr>
            </tbody>

            {/* Primo richiedente */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 uppercase text-left">
                  1° richiedente
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Dati anagrafici
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                  colSpan={2}
                >
                  <p className="uppercase font-bold text-gray-800">
                    {domandaIscrizione.primoRichiedenteCognome}{' '}
                    {domandaIscrizione.primoRichiedenteNome}
                  </p>
                  <p>{domandaIscrizione.parentelaIntestatario}</p>
                  <p>{domandaIscrizione.primoRichiedenteCodiceFiscale}</p>
                  <p>
                    Nato{' '}
                    {Number(domandaIscrizione.primoRichiedenteNascitaCodiceComune) !== 0
                      ? 'a ' + domandaIscrizione.comuneNascitaPrimoRichiedente
                      : 'in ' + domandaIscrizione.statoNascitaPrimoRichiedente}{' '}
                    il {domandaIscrizione.primoRichiedenteNascitaData}
                  </p>
                  <br />
                  <p>
                    {domandaIscrizione.primoRichiedenteResidenzaIndirizzo},{' '}
                    {domandaIscrizione.primoRichiedenteResidenzaNumeroCivico}
                  </p>
                  <p>
                    {domandaIscrizione.primoRichiedenteResidenzaCap}{' '}
                    {domandaIscrizione.primoRichiedenteNomeComuneResidenza}
                  </p>
                  <br />
                  <p>Cittadinanza: {domandaIscrizione.cittadinanzaPrimoRichiedente}</p>
                  <p>Nazionalità: {domandaIscrizione.nazionalitaPrimoRichiedente}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Ruolo
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                  colSpan={2}
                >
                  <p className="uppercase">{domandaIscrizione.ruoloPrimoRichiedente}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Telefono
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                  colSpan={2}
                >
                  <p>{domandaIscrizione.primoRichiedenteTelefono}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Cellulare
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                  colSpan={2}
                >
                  <p>{domandaIscrizione.primoRichiedenteCellulare}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold text-gray-800">E-mail</td>
                <td className="p-2 pl-6 text-left">
                  <p>{domandaIscrizione.primoRichiedenteEmail}</p>
                </td>
              </tr>
            </tbody>

            {/* Secondo richiedente */}
            {!!domandaIscrizione.secondoRichiedenteCodiceFiscale && (
              <tbody>
                <tr className="bg-blue-400 text-white">
                  <th scope="col" className="p-2 pl-6 text-left uppercase">
                    2° richiedente
                  </th>
                  <th scope="col" className="p-2 text-left" colSpan={2}></th>
                </tr>

                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Dati anagrafici
                  </td>
                  <td
                    className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    <p className="uppercase font-bold text-gray-800">
                      {domandaIscrizione.secondoRichiedenteCognome}{' '}
                      {domandaIscrizione.secondoRichiedenteNome}
                    </p>
                    <p>{domandaIscrizione.parentelaSecondoGenitore}</p>
                    <p>{domandaIscrizione.secondoRichiedenteCodiceFiscale}</p>
                    <p>
                      Nato{' '}
                      {Number(domandaIscrizione.secondoRichiedenteNascitaCodiceComune) !== 0
                        ? 'a ' + domandaIscrizione.comuneNascitaSecondoRichiedente
                        : 'in ' + domandaIscrizione.statoNascitaSecondoRichiedente}{' '}
                      il {domandaIscrizione.secondoRichiedenteNascitaData}
                    </p>
                    <br />
                    <p>
                      {domandaIscrizione.secondoRichiedenteResidenzaIndirizzo},{' '}
                      {domandaIscrizione.secondoRichiedenteResidenzaNumeroCivico}
                    </p>
                    <p>
                      {domandaIscrizione.secondoRichiedenteResidenzaCap}{' '}
                      {domandaIscrizione.secondoRichiedenteNomeComuneResidenza}
                    </p>
                    <br />
                    <p>Cittadinanza: {domandaIscrizione.secondoNomeCittadinanza}</p>
                    <p>Nazionalità: {domandaIscrizione.secondoNomeNazionalita}</p>
                  </td>
                </tr>

                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Ruolo
                  </td>
                  <td
                    className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    <p className="uppercase">{domandaIscrizione.ruoloSecondoRichiedente}</p>
                  </td>
                </tr>

                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Telefono
                  </td>
                  <td
                    className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    <p>{domandaIscrizione.secondoRichiedenteTelefono}</p>
                  </td>
                </tr>

                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Cellulare
                  </td>
                  <td
                    className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    <p>{domandaIscrizione.secondoRichiedenteCellulare}</p>
                  </td>
                </tr>

                <tr>
                  <td className="p-2 pl-6 text-left font-bold text-gray-800">E-mail</td>
                  <td className="p-2 pl-6 text-left">
                    <p>{domandaIscrizione.secondoRichiedenteEmail}</p>
                  </td>
                </tr>
              </tbody>
            )}

            {/* Convenzioni */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 uppercase text-left">
                  Convenzioni
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Almeno uno dei due richiedenti è dipendente del Comune di Bergamo?
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left"
                  colSpan={2}
                >
                  <p>{domandaIscrizione.flagDipendenteComune ? 'Sì' : 'No'}</p>
                </td>
              </tr>
            </tbody>

            {/* Intestatario bollettino */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 uppercase text-left">
                  Intestatario del bollettino di pagamento
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Intestatario del bollettino di pagamento
                </td>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left">
                  <p className="uppercase font-bold">
                    {domandaIscrizione.intestatarioBollettinoCognome}{' '}
                    {domandaIscrizione.intestatarioBollettinoNome}
                  </p>
                  <p>{domandaIscrizione.intestatarioBollettinoParentela}</p>
                  <p>{domandaIscrizione.intestatarioBollettinoCf}</p>
                  <p>
                    Nato{' '}
                    {Number(domandaIscrizione.intestatarioBollettinoComuneNascita) !== 0
                      ? 'a ' + domandaIscrizione.intestatarioBollettinoComuneNascita
                      : 'in ' + domandaIscrizione.intestatarioBollettinoStatoEsteroNascita}{' '}
                    il {domandaIscrizione.intestatarioBollettinoNascitaData}
                  </p>
                  <br />
                  <p>
                    {domandaIscrizione.intestatarioBollettinoResidenzaIndirizzo},{' '}
                    {domandaIscrizione.intestatarioBollettinoResidenzaCivico}
                  </p>
                  <p>
                    {domandaIscrizione.intestatarioBollettinoResidenzaCap}{' '}
                    {domandaIscrizione.intestatarioBollettinoComuneResidenza}
                  </p>
                  <br />
                  <p>Cittadinanza: {domandaIscrizione.intestatarioBollettinoCittadinanza}</p>
                  <p>Nazionalità: {domandaIscrizione.intestatarioBollettinoNazionalita}</p>
                </td>
              </tr>
            </tbody>

            {/* Cambio residenza */}
            {domandaIscrizione.iscritto_persona_residenza_codice_comune === 16024 && (
              <tbody>
                <tr className="bg-blue-400 text-white">
                  <th scope="col" className="p-2 pl-6 text-left">
                    Cambio residenza
                  </th>
                  <th scope="col" className="p-2 text-left" colSpan={2}></th>
                </tr>

                <tr>
                  <td className="p-2 pl-6 text-left font-bold text-gray-800">
                    È in corso un cambio di residenza che sarà effettivo entro la data di
                    inserimento al nido?
                  </td>
                  <td className="p-2 pl-6 align-middle text-left">
                    <p>{domandaIscrizione.flagCambioResidenza ? 'Sì' : 'No'}</p>
                  </td>
                </tr>
              </tbody>
            )}

            {/* Dichiarazione ISEE */}
            {/* <tbody className="align-bottom border-inherit border-solid border-0">
                <tr className="border-inherit border-solid border-b-2 bg-[#cce0f5] border-[#b8cadd]">
                  <th
                    scope="col"
                    className="p-2 border-inherit border-b border-solid text-left uppercase"
                  >
                    Dichiarazione ISEE
                  </th>
                  <th
                    scope="col"
                    className="p-2 border-inherit border-b border-solid text-left"
                    colSpan={2}
                  ></th>
                </tr>

                <tr>
                  <td className="p-2 border-inherit border-b border-solid text-left font-bold align-middle">
                    È dichiarato il reddito ISEE dell'intero nucleo familiare?
                  </td>
                  <td
                    className="p-2 border-inherit border-b border-solid text-left"
                    colSpan={2}
                  >
                    <p>
                      {domandaIscrizione.iseeFlag ? (
                        'Sì'
                      ) : (
                        <span>
                          No <small>(Collocazione in fascia massima)</small>
                        </span>
                      )}
                    </p>
                  </td>
                </tr>

                {!!domandaIscrizione.iseeFlag && (
                  <tr>
                    <td className="p-2 border-inherit border-b border-solid text-left font-bold align-middle">
                      Reddito ISEE
                    </td>
                    <td
                      className="p-2 border-inherit border-b border-solid text-left"
                      colSpan={2}
                    >
                      <p>€ {domandaIscrizione.iseeImporto}</p>
                    </td>
                  </tr>
                )}

                {!!domandaIscrizione.iseeFlag && (
                  <tr>
                    <td className="p-2 border-inherit border-b border-solid text-left font-bold align-middle">
                      Scadenza ISEE
                    </td>
                    <td
                      className="p-2 border-inherit border-b border-solid text-left"
                      colSpan={2}
                    >
                      <p>
                        {moment(domandaIscrizione.iseeScadenza).format(
                          'DD/MM/YYYY'
                        )}
                      </p>
                    </td>
                  </tr>
                )}

                <tr>
                  <td className="p-2 border-inherit border-b border-solid text-left font-bold align-middle">
                    Dichiarazione lettura nota ISEE
                  </td>
                  <td
                    className="p-2 border-inherit border-b border-solid text-left"
                    colSpan={2}
                  >
                    <p>{domandaIscrizione.iseeFlagLetto ? 'Sì' : 'No'}</p>
                  </td>
                </tr>
              </tbody> */}

            {/* ISEE da INPS */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  ISEE
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold text-gray-800">Importo</td>
                <td className="p-2 pl-6 border-gray-200 text-left">
                  {domandaIscrizione?.iseeImporto
                    ? convertToItalianDecimal(domandaIscrizione.iseeImporto)
                    : 'Attenzione: ISEE non trovato. Presentare dichiarazione entro il 31 Agosto.'}
                </td>
              </tr>
            </tbody>

            {/* Attività lavorativa primo richiedente */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 uppercase text-left">
                  Attività lavorativa di {domandaIscrizione.primoRichiedenteCognome}{' '}
                  {domandaIscrizione.primoRichiedenteNome} in qualità di 1° richiedente
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold text-gray-800">Attività lavorativa</td>
                <td className="p-2 pl-6 text-left">
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'dipendente' && (
                    <p>Dipendente</p>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'dipendente-comune-bergamo' && (
                    <p>Dipendente dell'dell’Amministrazione Comunale di Bergamo</p>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'libero-professionista' && (
                    <p>Libero professionista</p>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf' && (
                    <p>Badante / Colf</p>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'studente' && (
                    <p>Studente / Borsista / Praticante</p>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'stagionale' && (
                    <p>Lavoro stagionale</p>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'in-cerca-di-occupazione' && (
                    <p>In cerca di occupazione</p>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'altro' && (
                    <p>Casalinga / Pensionato / Altro</p>
                  )}
                </td>
              </tr>

              {(isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro) ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf' ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'studente' ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'stagionale') && (
                <tr>
                  {(isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro) ||
                    domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf') && (
                    <td className="p-2 pl-6 text-left border-t border-solid border-gray-200 font-bold text-gray-800">
                      Datore di lavoro
                    </td>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'studente' && (
                    <td className="p-2 pl-6 text-left border-t border-solid border-gray-200 font-bold text-gray-800">
                      Riferimento Scuola-Ente
                    </td>
                  )}
                  {domandaIscrizione.primoRichiedenteTipoLavoro === 'stagionale' && (
                    <td className="p-2 pl-6 text-left border-t border-solid border-gray-200 font-bold text-gray-800">
                      Ultimo datore di lavoro
                    </td>
                  )}

                  <td
                    className="p-2 pl-6 text-left border-t border-solid border-gray-200"
                    colSpan={2}
                  >
                    {domandaIscrizione.primoRichiedenteLavoroDatore}
                  </td>
                </tr>
              )}

              {(isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro) ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf') && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold text-gray-800">
                    Luogo di lavoro
                  </td>
                  <td
                    className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    {domandaIscrizione.primoRichiedenteLavoroIndirizzo},{' '}
                    {domandaIscrizione.primoRichiedenteLavoroComune}
                  </td>
                </tr>
              )}

              {(isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro) ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf') && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold text-gray-800">
                    Orario di lavoro
                  </td>
                  <td
                    className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    {domandaIscrizione.primoRichiedenteLavoroOrarioInizio} -{' '}
                    {domandaIscrizione.primoRichiedenteLavoroOrarioFine}
                  </td>
                </tr>
              )}

              {!!domandaIscrizione.primoRichiedenteCodiceFiscale &&
                (isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro) ||
                  domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf') && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold text-gray-800">
                      Ore settimanali
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.primoRichiedenteLavoroOreSettimanali}
                    </td>
                  </tr>
                )}

              {(isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro) ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf' ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'studente') && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold text-gray-800">
                    {isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro)
                      ? 'Recapito telefonico ditta'
                      : 'Recapito telefonico'}
                  </td>
                  <td
                    className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    {domandaIscrizione.primoRichiedenteLavoroTelefono}
                  </td>
                </tr>
              )}

              {(isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro) ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'studente') && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold text-gray-800">
                    {isDipendente(domandaIscrizione.primoRichiedenteTipoLavoro)
                      ? 'Professione'
                      : 'Ufficio'}
                  </td>
                  <td
                    className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    {domandaIscrizione.primoRichiedenteLavoroProfessione}
                  </td>
                </tr>
              )}

              {domandaIscrizione.primoRichiedenteTipoLavoro === 'libero-professionista' && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold text-gray-800">
                    P. IVA
                  </td>
                  <td
                    className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    {domandaIscrizione.primoRichiedenteLavoroPiva &&
                    !domandaIscrizione.primoRichiedenteLavoroPivaRichiestaFlag
                      ? domandaIscrizione.primoRichiedenteLavoroPiva
                      : 'P.IVA richiesta ma non ancora ricevuta'}
                  </td>
                </tr>
              )}

              {domandaIscrizione.primoRichiedenteTipoLavoro === 'libero-professionista' &&
                (!domandaIscrizione.primoRichiedenteLavoroPiva ||
                  domandaIscrizione.primoRichiedenteLavoroPivaRichiestaFlag) && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold text-gray-800">
                      Allegato certificato di apertura partita IVA?
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.primoRichiedenteLavoroPivaRichiestaFlag &&
                      domandaIscrizione.primoRichiedenteLavoroAllegato ? (
                        <div>
                          Sì{' - '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${API_URL}/iscrizioni-asilo/${
                              domandaIscrizione.id
                            }/riepilogo-domanda/allegati?tipologiaAllegato=primo_richiedente_lavoro_allegato&t=${encodeURIComponent(
                              localStorage.getItem('token') || ''
                            )}`}
                            className="underline uppercase text-blue-400 hover:text-blue-500 text-lg leading-7"
                          >
                            Scarica allegato inviato
                          </a>
                        </div>
                      ) : (
                        <span>
                          No <small>(È necessario presentare il documento cartaceo)</small>
                        </span>
                      )}
                    </td>
                  </tr>
                )}

              {(domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf' ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'studente' ||
                domandaIscrizione.primoRichiedenteTipoLavoro === 'stagionale') && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                    {domandaIscrizione.primoRichiedenteTipoLavoro === 'badante-colf' &&
                      'Allegati busta paga o contratto di lavoro?'}
                    {domandaIscrizione.primoRichiedenteTipoLavoro === 'studente' &&
                      'Allegati iscrizione scolastica, convenzioni, attestazioni o altro?'}
                    {domandaIscrizione.primoRichiedenteTipoLavoro === 'stagionale' &&
                      'Allegati busta paga o ultimo contratto di lavoro?'}
                  </td>
                  <td
                    className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                    colSpan={2}
                  >
                    {domandaIscrizione.primoRichiedenteLavoroAllegato ? (
                      <div>
                        Sì {' - '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${API_URL}/iscrizioni-asilo/${
                            domandaIscrizione.id
                          }/riepilogo-domanda/allegati?tipologiaAllegato=primo_richiedente_lavoro_allegato&t=${encodeURIComponent(
                            localStorage.getItem('token') || ''
                          )}`}
                          className="underline uppercase text-blue-400 hover:text-blue-500 text-lg leading-7"
                        >
                          Scarica allegato inviato
                        </a>
                      </div>
                    ) : (
                      <span className="warning">
                        No <small>(È necessario presentare il documento cartaceo)</small>
                      </span>
                    )}
                  </td>
                </tr>
              )}

              {domandaIscrizione.primoRichiedenteTipoLavoro === 'in-cerca-di-occupazione' && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                    Numero protocollo iscrizione al Centro per l'impiego Provincia
                  </td>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left">
                    {domandaIscrizione.primoRichiedenteLavoroNumCentroImpiego}
                  </td>
                </tr>
              )}
            </tbody>

            {/* Attività lavorativa secondo richiedente */}
            {!!domandaIscrizione.secondoRichiedenteCodiceFiscale && (
              <tbody>
                <tr className="bg-blue-400 text-white">
                  <th scope="col" className="p-2 pl-6 uppercase text-left">
                    Attività lavorativa di {domandaIscrizione.secondoRichiedenteCognome}{' '}
                    {domandaIscrizione.secondoRichiedenteNome} in qualità di 2° richiedente
                  </th>
                  <th scope="col" className="p-2 pl-6 text-left" colSpan={2}></th>
                </tr>

                <tr>
                  <td className="p-2 pl-6 text-left font-bold align-middle text-gray-800">
                    Attività lavorativa
                  </td>
                  <td className="p-2 pl-6 text-left">
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'dipendente' && (
                      <p>Dipendente</p>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro ===
                      'dipendente-comune-bergamo' && (
                      <p>Dipendente dell'dell’Amministrazione Comunale di Bergamo</p>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'libero-professionista' && (
                      <p>Libero professionista</p>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf' && (
                      <p>Badante / Colf</p>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'studente' && (
                      <p>Studente / Borsista / Praticante</p>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'stagionale' && (
                      <p>Lavoro stagionale</p>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro ===
                      'in-cerca-di-occupazione' && <p>In cerca di occupazione</p>}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'altro' && (
                      <p>Casalinga / Pensionato / Altro</p>
                    )}
                  </td>
                </tr>

                {(isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro) ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf' ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'studente' ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'stagionale') && (
                  <tr>
                    {(isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro) ||
                      domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf') && (
                      <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                        Datore di lavoro
                      </td>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'studente' && (
                      <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                        Riferimento Scuola-Ente
                      </td>
                    )}
                    {domandaIscrizione.secondoRichiedenteTipoLavoro === 'stagionale' && (
                      <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                        Ultimo datore di lavoro
                      </td>
                    )}

                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroDatore}
                    </td>
                  </tr>
                )}

                {(isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro) ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf') && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                      Luogo di lavoro
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroIndirizzo},{' '}
                      {domandaIscrizione.secondoRichiedenteLavoroComune}
                    </td>
                  </tr>
                )}

                {(isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro) ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf') && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                      Orario di lavoro
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroOrarioInizio} -{' '}
                      {domandaIscrizione.secondoRichiedenteLavoroOrarioFine}
                    </td>
                  </tr>
                )}

                {!!domandaIscrizione.secondoRichiedenteCodiceFiscale &&
                  (isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro) ||
                    domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf') && (
                    <tr>
                      <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                        Ore settimanali
                      </td>
                      <td
                        className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                        colSpan={2}
                      >
                        {domandaIscrizione.secondoRichiedenteLavoroOreSettimanali}
                      </td>
                    </tr>
                  )}

                {(isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro) ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf' ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'studente') && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                      {isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro)
                        ? 'Recapito telefonico ditta'
                        : 'Recapito telefonico'}
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroTelefono}
                    </td>
                  </tr>
                )}

                {(isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro) ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'studente') && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                      {isDipendente(domandaIscrizione.secondoRichiedenteTipoLavoro)
                        ? 'Professione'
                        : 'Ufficio'}
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroProfessione}
                    </td>
                  </tr>
                )}

                {domandaIscrizione.secondoRichiedenteTipoLavoro === 'libero-professionista' && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                      P. IVA
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroPiva &&
                      !domandaIscrizione.secondoRichiedenteLavoroPivaRichiestaFlag
                        ? domandaIscrizione.secondoRichiedenteLavoroPiva
                        : 'P.IVA richiesta ma non ancora ricevuta'}
                    </td>
                  </tr>
                )}

                {domandaIscrizione.secondoRichiedenteTipoLavoro === 'libero-professionista' &&
                  (!domandaIscrizione.secondoRichiedenteLavoroPiva ||
                    domandaIscrizione.secondoRichiedenteLavoroPivaRichiestaFlag) && (
                    <tr>
                      <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                        Allegato certificato di apertura partita IVA?
                      </td>
                      <td
                        className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                        colSpan={2}
                      >
                        {domandaIscrizione.secondoRichiedenteLavoroPivaRichiestaFlag &&
                        domandaIscrizione.secondoRichiedenteLavoroAllegato ? (
                          <div>
                            Sì{' - '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${API_URL}/iscrizioni-asilo/${
                                domandaIscrizione.id
                              }/riepilogo-domanda/allegati?tipologiaAllegato=secondo_richiedente_lavoro_allegato&t=${encodeURIComponent(
                                localStorage.getItem('token') || ''
                              )}`}
                              className="underline uppercase text-blue-400 hover:text-blue-500 text-lg leading-7"
                            >
                              Scarica allegato inviato
                            </a>
                          </div>
                        ) : (
                          <span>
                            No <small>(È necessario presentare il documento cartaceo)</small>
                          </span>
                        )}
                      </td>
                    </tr>
                  )}

                {(domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf' ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'studente' ||
                  domandaIscrizione.secondoRichiedenteTipoLavoro === 'stagionale') && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                      {domandaIscrizione.secondoRichiedenteTipoLavoro === 'badante-colf' &&
                        'Allegati busta paga o contratto di lavoro?'}
                      {domandaIscrizione.secondoRichiedenteTipoLavoro === 'studente' &&
                        'Allegati iscrizione scolastica, convenzioni, attestazioni o altro?'}
                      {domandaIscrizione.secondoRichiedenteTipoLavoro === 'stagionale' &&
                        'Allegati busta paga o ultimo contratto di lavoro?'}
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroAllegato ? (
                        <div>
                          Sì {' - '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${API_URL}/iscrizioni-asilo/${
                              domandaIscrizione.id
                            }/riepilogo-domanda/allegati?tipologiaAllegato=secondo_richiedente_lavoro_allegato&t=${encodeURIComponent(
                              localStorage.getItem('token') || ''
                            )}`}
                            className="underline uppercase text-blue-400 hover:text-blue-500 text-lg leading-7"
                          >
                            Scarica allegato inviato
                          </a>
                        </div>
                      ) : (
                        <span className="warning">
                          No <small>(È necessario presentare il documento cartaceo)</small>
                        </span>
                      )}
                    </td>
                  </tr>
                )}

                {domandaIscrizione.secondoRichiedenteTipoLavoro === 'in-cerca-di-occupazione' && (
                  <tr>
                    <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                      Numero protocollo iscrizione al Centro per l'impiego Provincia
                    </td>
                    <td
                      className="p-2 pl-6 border-t border-solid border-gray-200 text-left"
                      colSpan={2}
                    >
                      {domandaIscrizione.secondoRichiedenteLavoroNumCentroImpiego}
                    </td>
                  </tr>
                )}
              </tbody>
            )}

            {/* Scelta relativa al punteggio per l'ammissione */}
            <tbody>
              {/* A */}
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left uppercase">
                  Scelta relativa al punteggio per l'ammissione
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left uppercase">
                  <big>A</big>&nbsp;&nbsp;&nbsp;
                  <small>
                    Nucleo familiare monoparentale o in obiettive difficoltà nei suoi compiti
                    educativi
                  </small>
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Assenza della figura paterna o materna per: decesso, non riconoscimento del
                  bambino/a, stato di detenzione?
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left align-middle"
                  colSpan={2}
                >
                  {domandaIscrizione.a1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Assenza della figura paterna o materna (non convivenza) per: divorzio, separazione
                  legale o di fatto (documentata), emigrazione all'estero?
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid border-gray-200 text-left align-middle"
                  colSpan={2}
                >
                  {domandaIscrizione.a2 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold align-middle text-gray-800">
                  Genitore minorenne?
                </td>
                <td className="p-2 pl-6 text-left" colSpan={2}>
                  {domandaIscrizione.a3 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              {/* B */}
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left uppercase align-middle">
                  <big>B</big> &nbsp; <small>Tempi e luoghi di lavoro</small>
                </th>
                <th scope="col" className="p-2 text-left">
                  {domandaIscrizione.primoRichiedenteCognome}
                  <br />
                  {domandaIscrizione.primoRichiedenteNome}
                </th>
                <th scope="col" className="p-2 text-left">
                  {domandaIscrizione.secondoRichiedenteCognome}
                  <br />
                  {domandaIscrizione.secondoRichiedenteNome}
                </th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Fino a 18 ore settimanali?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b1_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b1_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Da 19 a 30 ore settimanali?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b2_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b2_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Da 31 ore settimanali e oltre?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b3_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b3_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Lavori occasionali e stagionali (almeno 4 mesi l'anno)?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b4_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b4_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Studente, borsista, praticante, tirocinante?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b5_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b5_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Turni di lavoro sulle 24 ore?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b6_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b6_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Pendolarità giornaliera per almeno 5 giorni della settimana oltre 40 km di
                  distanza dalla propria abitazione al luogo di lavoro?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b7_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b7_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Assenze da casa oltre 4 mesi all'anno?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b8_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b8_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Disoccupato iscritto al Centro per l'Impiego del Lavoro?
                </td>
                <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                  {domandaIscrizione.b9_1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
                {domandaIscrizione.secondoRichiedenteNome && (
                  <td className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200">
                    {domandaIscrizione.b9_2 ? (
                      <FiCheck className="text-green-500 text-2xl" />
                    ) : (
                      <FiX className="text-gray-500" />
                    )}
                  </td>
                )}
              </tr>

              {/* C */}
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left uppercase align-middle">
                  <big>C</big> &nbsp; <small>Conviventi bisognosi di assistenza</small>
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold align-middle text-gray-800">
                  Padre, madre, famigliari conviventi, fratello o sorella invalidi oltre il 67%?
                </td>
                <td className="p-2 text-left align-middle pl-6 border-gray-200" colSpan={2}>
                  {domandaIscrizione.c1 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              {!!domandaIscrizione.c1 && (
                <tr>
                  <td className="p-2 pl-6 border-t border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                    Allegato attestazione invalidità?
                  </td>
                  <td
                    className="p-2 pl-6 border-t border-solid text-left align-middle border-gray-200"
                    colSpan={2}
                  >
                    {domandaIscrizione.attestazioneInvaliditaAllegato ? (
                      <div>
                        Sì {' - '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${API_URL}/iscrizioni-asilo/${
                            domandaIscrizione.id
                          }/riepilogo-domanda/allegati?tipologiaAllegato=attestazione_invalidita_allegato&t=${encodeURIComponent(
                            localStorage.getItem('token') || ''
                          )}`}
                          className="underline uppercase text-blue-400 hover:text-blue-500 text-lg leading-7"
                        >
                          Scarica allegato inviato
                        </a>
                      </div>
                    ) : (
                      <span>No</span>
                    )}
                  </td>
                </tr>
              )}

              {/* D */}
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left uppercase align-middle">
                  <big>D</big> &nbsp; <small>Situazione figli</small>
                </th>
                <th scope="col" className="p-2 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  1) Presenza di figli 0-2 nell'anno di riferimento della domanda d'iscrizione?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d1 ? (
                    <div className="flex items-center gap-x-4">
                      <FiCheck className="text-green-500 text-2xl" />
                      &nbsp;(n° figli {domandaIscrizione.d1NumeroFigli})
                    </div>
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  2) Presenza di figli 3-6 nell'anno di riferimento della domanda d'iscrizione,
                  purché i genitori svolgano entrambi attività lavorativa?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d2 ? (
                    <div className="flex items-center gap-x-4">
                      <FiCheck className="text-green-500 text-2xl" />
                      &nbsp;(n° figli {domandaIscrizione.d2NumeroFigli})
                    </div>
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  3) Presenza di figli 7-11 nell'anno di riferimento della domanda d'iscrizione,
                  purché i genitori svolgano entrambi attività lavorativa?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d3 ? (
                    <div className="flex items-center gap-x-4">
                      <FiCheck className="text-green-500 text-2xl" />
                      &nbsp;(n° figli {domandaIscrizione.d3NumeroFigli})
                    </div>
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  4) Presenza di figli 12-14 nell'anno di riferimento della domanda d'iscrizione,
                  purché i genitori svolgano entrambi attività lavorativa?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d4 ? (
                    <div className="flex items-center gap-x-4">
                      <FiCheck className="text-green-500 text-2xl" />
                      &nbsp;(n° figli {domandaIscrizione.d4NumeroFigli})
                    </div>
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Iscrizione bambini gemelli (due)?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d5 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Iscrizione bambini gemelli (tre e oltre)?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d6 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Bambini in adozione, in affido o in fase di affido pre-adottivo?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d7 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Presenza di figli già frequentanti lo stesso asilo nido nell'anno di riferimento
                  della domanda d'iscrizione?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d8 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Iscrizione del minore già collocato in lista d'attesa nell'anno precedente, purché
                  non abbia rinunciato alla frequenza o iscritto in data successiva entro il mese di
                  Dicembre perché nato dopo la chiusura delle iscrizioni ordinarie?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d9 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Iscrizione di bambini portatori di handicap, segnalati dal competente Servizio di
                  Neuropsichiatria Infantile e dai Servizi Socio Sanitari territoriali{' '}
                  <b>(Priorità)</b>?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d10 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Presenza di particolari problemi di origine sanitario, psicologico e sociale nel
                  bambino/a da iscrivere o nella famiglia, segnalati dal Servizio Sociale del Comune
                  di Bergamo <b>(Priorità)</b>?
                </td>
                <td
                  className="p-2 border-b border-solid text-left align-middle pl-6 border-gray-200"
                  colSpan={2}
                >
                  {domandaIscrizione.d11 ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiX className="text-gray-500" />
                  )}
                </td>
              </tr>

              {(!!domandaIscrizione.d10 || !!domandaIscrizione.d11) && (
                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                    Allegato documentazione sanitaria?
                  </td>
                  <td
                    className="p-2 pl-6 border-b border-solid text-left align-middle border-gray-200"
                    colSpan={2}
                  >
                    {domandaIscrizione.documentazioneSanitariaAllegato ? (
                      <div>
                        Sì {' - '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${API_URL}/iscrizioni-asilo/${
                            domandaIscrizione.id
                          }/riepilogo-domanda/allegati?tipologiaAllegato=documentazione_sanitaria_allegato&t=${encodeURIComponent(
                            localStorage.getItem('token') || ''
                          )}`}
                          className="underline uppercase text-blue-400 hover:text-blue-500 text-lg leading-7"
                        >
                          Scarica allegato inviato
                        </a>
                      </div>
                    ) : (
                      <span>
                        No <small>(È necessario presentare il documento cartaceo)</small>
                      </span>
                    )}
                  </td>
                </tr>
              )}
            </tbody>

            {/* Scelta dell'asilo */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left uppercase">
                  Scelta dell'asilo
                </th>
                <th scope="col" className="p-2 pl-6 text-left" colSpan={2}></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Nido d'infanzia
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid text-left border-gray-200"
                  colSpan={2}
                >
                  <p>{domandaIscrizione.nomeScuola}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Orario
                </td>
                <td
                  className="p-2 pl-6 border-b border-solid text-left border-gray-200"
                  colSpan={2}
                >
                  <p>
                    {domandaIscrizione.orarioNome} {domandaIscrizione.orarioInizio} {' - '}{' '}
                    {domandaIscrizione.orarioFine}
                  </p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold align-middle text-gray-800">
                  Iscrizione valida anche per Primavera Borgo Palazzo?
                </td>
                <td className="p-2 pl-6 text-left" colSpan={2}>
                  <p>{!!domandaIscrizione.primaveraBorgoPalazzoFlag ? 'Sì' : 'No'}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="check-approvazione mt-12">
          <div className="flex space-x-2 items-center tesxt-sm">
            <input
              id="accettazioneDichiarazioni"
              type="checkbox"
              className="cursor-pointer"
              checked={domandaIscrizione.accettazioneDichiarazioni}
              disabled
            />
            <label htmlFor="accettazioneDichiarazioni">
              Accettazione dichiarazione per l'ammissione al servizio
              {!domandaIscrizione.accettazioneDichiarazioni && (
                <>
                  <br />{' '}
                  <span className="text-red-600 text-sm">
                    È necessario che il genitore / legale rappresentante visioni e accetti le
                    dichiarazioni per l'ammissione al servizio
                  </span>
                </>
              )}
            </label>
          </div>
        </div>
        <div className="check-approvazione mt-4">
          <div className="flex space-x-2 items-center tesxt-sm">
            <input
              id="accettazionePrivacy"
              type="checkbox"
              className="cursor-pointer"
              checked={domandaIscrizione.accettazionePrivacy}
              disabled
            />
            <label htmlFor="accettazionePrivacy">
              Accettazione informativa sul trattamento dei dati personali
              {!domandaIscrizione.accettazionePrivacy && (
                <>
                  <br />{' '}
                  <span className="text-red-600 text-sm">
                    È necessario che il genitore / legale rappresentante visioni e accetti l'
                    informativa sul trattamento dei dati personali
                  </span>
                </>
              )}
            </label>
          </div>
        </div>
        <div className="flex justify-between mt-20">
          <Link
            to={`/app/iscrizioni`}
            className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded"
          >
            « Torna indietro
          </Link>

          {domandaIscrizione.stato !== 'GIA_ISCRITTO' && (
            <div className="flex gap-4">
              {domandaIscrizione.stato !== 'APPROVATA' && (
                <button
                  className={classNames(
                    'block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded'
                  )}
                  onClick={() => {
                    updateIscrizione
                      .mutateAsync({
                        iscrizioneId: domandaIscrizione.id,
                        servizio: 'asilo',
                        payload: {
                          stato: 'APPROVATA',
                          dataApprovazione: moment().format(),
                        },
                      })
                      .then((response) => {
                        navigate('/app/iscrizioni')
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                >
                  Approva
                </button>
              )}
              {domandaIscrizione.stato !== 'IN_COMPILAZIONE' && (
                <button
                  className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded"
                  onClick={() =>
                    updateIscrizione
                      .mutateAsync({
                        iscrizioneId: domandaIscrizione.id,
                        servizio: 'asilo',
                        payload: { stato: 'IN_COMPILAZIONE' },
                      })
                      .then((response) => {
                        navigate('/app/iscrizioni')
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  }
                >
                  Riporta in compilazione
                </button>
              )}

              {domandaIscrizione.stato !== 'RIFIUTATA' && (
                <button
                  className="block bg-red-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-red-600 rounded"
                  onClick={() => setOpenModal(true)}
                >
                  Rifiuta iscrizione
                </button>
              )}
            </div>
          )}
        </div>

        <ReactModal
          isOpen={openModal}
          onAfterOpen={() => setValue('motivazioneRifiuto', domandaIscrizione?.motivazioneRifiuto)}
          onRequestClose={() => {
            setOpenModal(false)
            document.getElementById('root')!.style.filter = 'blur(0)'
          }}
          style={modalCustomStyles}
        >
          <div className="p-8" style={{ width: '32rem' }}>
            <form onSubmit={handleSubmit(rifiutoIscrizione)} className="" autoComplete="off">
              <div className="flex flex-col w-full">
                <FormField
                  fieldName="motivazioneRifiuto"
                  label="Motivazione rifiuto iscrizione"
                  type={formFieldInputType.Textarea}
                  rows={3}
                  validation={{
                    maxLength: { value: 500, message: 'Max 500 caratteri' },
                  }}
                  register={register}
                  errors={errors}
                  className="mb-3"
                  watch={watch}
                />
                <button
                  type="submit"
                  className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                >
                  Rifiuta iscrizione
                </button>
              </div>
            </form>
          </div>
        </ReactModal>
      </div>
    </div>
  )
}

const isDipendente = (tipoLavoro: string) => {
  if (tipoLavoro === 'dipendente' || tipoLavoro === 'dipendente-comune-bergamo') return true
  return false
}

export function convertToItalianDecimal(number: number) {
  let numberStr = number.toString()
  let parts = numberStr.split('.')
  let integerPart = parts[0]
  let decimalPart = parts.length > 1 ? parts[1] : ''
  let integerPartWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  let italianNumberStr = integerPartWithSeparators + (decimalPart ? ',' + decimalPart : '')
  return italianNumberStr + ' €'
}

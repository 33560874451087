import { useQuery } from 'react-query'
import axios from 'axios'

interface GetDateSollecitiArgs {
  servizioId: number
  annoScolastico: string
}

const getDateSollecitiByIdByAnno = async (_: string, { servizioId, annoScolastico }: GetDateSollecitiArgs) => {
  const { data } = await axios.get(`/servizi/${servizioId}/date-solleciti/?annoScolastico=${annoScolastico}`)
  return data
}

const useDateSollecitiByIdByAnno = ({ servizioId, annoScolastico }: GetDateSollecitiArgs) => {
  return useQuery(
    ['dateSollecitiByIdByAnno', { servizioId, annoScolastico }],
    () => getDateSollecitiByIdByAnno('dateSollecitiByIdByAnno', { servizioId, annoScolastico }),
    { enabled: !!servizioId && !!annoScolastico }
  )
}

export default useDateSollecitiByIdByAnno

import React from 'react'
import { RouteComponentProps, Link } from '@reach/router'

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className: 'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-900 text-sm font-medium rounded',
      }
    : {}
}

const AvvisiPagamento: React.FC<RouteComponentProps> = ({ children }) => {
  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex">
          <Link
            to="./"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Documenti PDF / SDD
          </Link>
          <Link
            to="avvisi"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Tutti gli avvisi
          </Link>
          <Link
            to="ricevute"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Ricevute
          </Link>
          <Link
            to="insoluti"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Insoluti
          </Link>
          <Link
            to="solleciti"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Solleciti PDF
          </Link>
        </div>
      </div>
      {children}
    </div>
  )
}

export default AvvisiPagamento

import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeAlunni {
  sezioneId?: number
  servizioId: number
  dataPresenze: string
  annoScolastico: string
  mostraRitirati?: boolean
}

const getPresenzeAlunniData = async (
  _: string,
  {
    sezioneId,
    servizioId,
    dataPresenze,
    annoScolastico,
    mostraRitirati,
  }: IPresenzeAlunni
) => {
  const { data } = await axios.get(
    `/sezioni/${sezioneId}/presenze-alunni/${servizioId}/?data=${dataPresenze}&mostra_ritirati=${mostraRitirati}&annoScolastico=${annoScolastico}`
  )

  return data.results
}

const usePresenzeAlunniData = ({
  sezioneId,
  servizioId,
  dataPresenze,
  annoScolastico,
  mostraRitirati = true,
}: IPresenzeAlunni) => {
  return useQuery(
    [
      'presenzeAlunniData',
      { sezioneId, servizioId, dataPresenze, mostraRitirati },
    ],
    () =>
      getPresenzeAlunniData('presenzeAlunniData', {
        sezioneId,
        servizioId,
        dataPresenze,
        annoScolastico,
        mostraRitirati,
      }),
    { enabled: !!sezioneId && !!servizioId && !!dataPresenze }
  )
}

export default usePresenzeAlunniData

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface ICreateFruizione {
  alunnoId: number
  servizioId: number
  annoScolastico: string
}

const createFruizione = async ({ alunnoId, servizioId, annoScolastico }: ICreateFruizione) => {
  const { data } = await axios.post(`/alunni/${alunnoId}/fruizioni/`, {
    servizioId,
    annoScolastico,
  })
  return data
}

const useCreateFruizione = ({ alunnoId, servizioId, annoScolastico }: ICreateFruizione) => {
  const queryClient = useQueryClient()
  return useMutation(
    () => {
      return createFruizione({ alunnoId, servizioId, annoScolastico })
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('alunniBySezioneId')
        queryClient.invalidateQueries('alunnoByIdByAnno')
        queryClient.invalidateQueries('countFruizioniDaControllare')
        cogoToast.success('Servizio attivato')
      },
      onError: () => {},
    }
  )
}

export default useCreateFruizione

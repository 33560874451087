import React, { useContext, useCallback, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useForm } from 'react-hook-form'
import ReactModal from 'react-modal'
import { FiEdit } from 'react-icons/fi'

import ServizioContext from '../../context/ServizioContext'

import useDieteByServizioId from '../../apiHooks/queries/useDieteByServizioId'
import useCreateDieta from '../../apiHooks/mutations/useCreateDieta'
import useUpdateDieta from '../../apiHooks/mutations/useUpdateDieta'
import useDeleteDieta from '../../apiHooks/mutations/useDeleteDieta'

import FormField, { InputType } from '../../components/FormField'

interface Dieta {
  id: number
  tipoDieta: string
  nomeDieta: string
}

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
    overflow: 'initial',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

interface Option {
  label: string
  value: string
}

const CATEGORIE: Option[] = [
  {
    label: '-- Seleziona --',
    value: '',
  },
  {
    label: 'Etico-Religiosa',
    value: 'Etico-Religiosa',
  },
  {
    label: 'Sanitaria',
    value: 'Sanitaria',
  },
  {
    label: 'Standard',
    value: 'Standard',
  },
  {
    label: 'Religiosa',
    value: 'Religiosa',
  },
  {
    label: 'Intolleranza',
    value: 'Intolleranza',
  },
  {
    label: 'Ideologica',
    value: 'Ideologica',
  },
  {
    label: 'Personalizzata',
    value: 'Personalizzata',
  },
]

const Diete: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)

  //===================
  // LOCAL STATE
  //===================
  const [dietaSel, setDietaSel] = useState<Dieta | null>(null)
  const [showCreaModificaDietaModal, setShowCreaModificaDietaModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  //===================
  // QUERIES AND MUTATIONS
  //===================
  const { data: diete = [] } = useDieteByServizioId(servizioId)
  const createDieta = useCreateDieta()
  const updateDieta = useUpdateDieta()
  const deleteDieta = useDeleteDieta()

  //===================
  // EVENT HANDLERS
  //===================
  const closeDeleteModal = useCallback(() => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setShowDeleteModal(false)
  }, [])

  const handleDeleteButton = useCallback((dieta: Dieta) => {
    setShowDeleteModal(true)
    setDietaSel(dieta)
  }, [])

  const handleEliminaDieta = useCallback(
    (dietaId: number) => {
      deleteDieta.mutate({ servizioId, dietaId })
      closeDeleteModal()
    },
    [deleteDieta, servizioId, closeDeleteModal]
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      id: dietaSel?.id,
      tipoDieta: CATEGORIE[0],
      nomeDieta: '',
    },
  })

  const handleCreateEditDieta = useCallback(
    (dieta?: Dieta) => {
      setShowCreaModificaDietaModal(true)
      if (dieta) {
        setDietaSel(dieta)
      }
      const tipoDieta = CATEGORIE.find((c) => c.value === dieta?.tipoDieta)
      reset({
        id: dietaSel?.id,
        tipoDieta: tipoDieta || CATEGORIE[0],
        nomeDieta: dieta?.nomeDieta,
      })
    },
    [reset, dietaSel]
  )

  const onSubmit = (data: any) => {
    setShowCreaModificaDietaModal(false)

    const dietaToSave = {
      tipoDieta: data.tipoDieta.value,
      nomeDieta: data.nomeDieta,
      servizioId,
    }

    if (dietaSel) {
      updateDieta.mutate({
        servizioId,
        dieta: {
          id: dietaSel?.id,
          ...dietaToSave,
        },
      })
    } else {
      createDieta.mutate({
        servizioId,
        dieta: dietaToSave,
      })
    }

    setDietaSel(null)
  }

  return (
    <div>
      <div className="bg-white py-6">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl leading-tight mb-8">Diete</h1>
          <table>
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Categoria
                </th>
                <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Nome dieta
                </th>
                <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody>
              {diete.map((dieta: Dieta) => {
                return (
                  <tr key={dieta.id} className="text-sm">
                    <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">{dieta.tipoDieta}</td>
                    <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">{dieta.nomeDieta}</td>
                    <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200 text-center">
                      <div className="flex items-center justify-between space-x-3">
                        <button
                          type="button"
                          className="border border-transparent hover:border-teal-200 hover:bg-teal-100 hover:text-teal-500 focus:shadow-outline focus:outline-none text-blue-500 leading-5 py-1 px-5 rounded transition-colors duration-150"
                          onClick={() => handleCreateEditDieta(dieta)}
                        >
                          <FiEdit />
                        </button>
                        <button
                          type="button"
                          className="border border-transparent hover:border-red-200 hover:bg-red-100 hover:text-red-500 focus:shadow-outline focus:outline-none text-blue-500 leading-5 py-1 px-5 rounded transition-colors duration-150"
                          onClick={() => handleDeleteButton(dieta)}
                        >
                          Elimina
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="mt-6">
            <button
              className="bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              onClick={() => handleCreateEditDieta()}
            >
              Nuova dieta
            </button>
          </div>
        </div>
      </div>

      {/* CREATE/EDIT MODAL */}
      <ReactModal
        isOpen={showCreaModificaDietaModal}
        onRequestClose={() => {
          setShowCreaModificaDietaModal(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            {dietaSel ? 'Modifica' : 'Crea'} dieta
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex flex-col space-y-6 px-8 py-6">
              <FormField
                fieldName="tipoDieta"
                label="Categoria"
                type={InputType.Select}
                options={CATEGORIE}
                validation={{
                  validate: (tipoDieta: Option) =>
                    (!!tipoDieta.value && !!tipoDieta.label) || 'Inserisci il tipo di dieta',
                }}
                register={register}
                errors={errors}
                control={control}
              />
              <FormField
                fieldName="nomeDieta"
                label="Nome dieta"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il nome della dieta',
                }}
                register={register}
                errors={errors}
              />
              <div className="flex space-x-6 items-center justify-between">
                <button
                  type="button"
                  onClick={() => {
                    setShowCreaModificaDietaModal(false)
                    setDietaSel(null)
                  }}
                  className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  Annulla
                </button>
                <button
                  type="submit"
                  className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  {!!dietaSel ? 'Salva' : 'Crea'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      {/* DELETE MODAL */}
      {dietaSel && (
        <ReactModal
          isOpen={!!showDeleteModal}
          onRequestClose={() => {
            document.getElementById('root')!.style.filter = 'blur(0)'
          }}
          style={modalCustomStyles}
        >
          <div className="max-w-lg">
            <div className="bg-blue-500 text-white font-semibold py-2 text-center">Elimina dieta</div>

            <div className="px-10 py-6">
              <p className="mb-4">
                Vuoi davvero eliminare <b>{dietaSel.nomeDieta}</b>?
              </p>

              <div className="flex justify-between space-x-3">
                <button
                  type="button"
                  className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => closeDeleteModal()}
                >
                  Annulla
                </button>

                <button
                  type="button"
                  className="block bg-red-600 hover:bg-red-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => {
                    handleEliminaDieta(dietaSel.id)
                  }}
                >
                  Sì, elimina dieta
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
    </div>
  )
}

export default Diete

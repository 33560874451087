import { useQuery } from 'react-query'
import axios from 'axios'

const getIscrizioni = async (_: string,servizioTipo : string|undefined,servizioId: number | undefined, annoScolastico: string) => {
  if (!servizioId) {
    return null
  }
  const { data } = await axios.get(`/iscrizioni-${servizioTipo}?annoScolastico=${annoScolastico}&servizio=${servizioId}&limit=10000`)

  return data.results
}

const useIscrizioni = (annoScolastico: string, servizioTipo?: string,servizioId?:number) => {
  return useQuery(
    ['iscrizioni', servizioId, annoScolastico],
    () => getIscrizioni('iscrizioni',servizioTipo, servizioId, annoScolastico),
    {
      enabled: !!servizioId && !!annoScolastico,
      staleTime: 1000 * 60 * 5, 
      cacheTime: 1000 * 60 * 10, 
    }
  )
}

export default useIscrizioni

import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { useForm } from 'react-hook-form'

import { codiceFiscaleValido, nomeCoerente, cognomeCoerente } from '../../../../lib/codiceFiscale'
import { pIvaCoerente } from '../../../../lib/pIva'

import Loading from '../../../../components/Loading'
import FormField, { InputType } from '../../../../components/FormField'

import useIntestatarioById from '../../../../apiHooks/queries/useIntestatarioById'
import useComuni from '../../../../apiHooks/queries/useComuni'
import useCittadinanze from '../../../../apiHooks/queries/useCittadinanze'
import useUpdateIntestatarioById from '../../../../apiHooks/mutations/useUpdateIntestatarioById'
import useCreateIntestatario from '../../../../apiHooks/mutations/useCreateIntestatarioAlunno'
import { FaMinusCircle } from 'react-icons/fa'
import useImpersona from '../../../../apiHooks/mutations/useImpersona'
import ServizioContext from '../../../../context/ServizioContext'
import useProfile from '../../../../apiHooks/queries/useProfile'

interface IntestatarioProps extends RouteComponentProps {
  intestatarioId?: number
  alunnoId?: number
}

const Intestatario: React.FC<IntestatarioProps> = ({ intestatarioId, alunnoId }) => {
  // ========================
  // CONTEXT
  // ========================
  const { servizioId } = useContext(ServizioContext)

  // ========================
  // LOCAL STATE
  // ========================
  const [comuniKeyword, setComuniKeyword] = useState('')
  const [statiKeyword, setStatiKeyword] = useState('')

  // ========================
  // QUERIES
  // ========================
  const clienteId = localStorage.getItem('clienteId')

  const { data: user } = useProfile(' ', clienteId ? parseInt(clienteId, 10) : undefined)

  // Intestatario
  const { data: existingIntestatario, isFetching, error } = useIntestatarioById(intestatarioId)

  // Comuni
  const { data: comuni = [], status: statusComuni } = useComuni(comuniKeyword)

  // Stati
  const { data: stati = [], status: statusStati } = useCittadinanze(statiKeyword)

  const newIntestatario = {
    nome: '',
    cognome: '',
    codiceFiscale: '',
    cittadinanzaCodiceIstat: '',
    indirizzo: '',
    cap: '',
    comuneCodiceIstat: '',
  }

  const intestatario = !!intestatarioId ? existingIntestatario : newIntestatario

  // FORM
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: intestatario,
  })
  useEffect(() => {
    !!intestatarioId && reset(intestatario)
  }, [intestatarioId, intestatario, reset])

  const updateIntestatario = useUpdateIntestatarioById(+intestatarioId!, +alunnoId!)
  const createIntestatario = useCreateIntestatario(+alunnoId!)
  const impersona = useImpersona(
    servizioId,
    intestatario?.codiceFiscale,
    user?.clienteId,
    intestatario?.id
  )

  const onSubmit = (data: any) => {
    const intestatarioToSave = {
      ...data,
      comuneCodiceIstat: data.comuneDati?.codiceIstat,
      comuneCodiceIstatNascita: data.comuneNascitaDati ? data.comuneNascitaDati.codiceIstat : null,
      // statoCodiceIstat: data.statoDati.codiceIstat,
      statoCodiceIstatNascita: data.statoNascitaDati?.codiceIstat,
      cittadinanzaCodiceIstat: data.cittadinanzaDati?.codiceIstat || '',
      nazionalitaCodiceIstat: data.nazionalitaDati ? data.nazionalitaDati.codiceIstat : null,
      dataNascita: data.dataNascita || null,
    }

    if (!intestatarioId) {
      createIntestatario.mutate({ intestatario: intestatarioToSave })
    } else {
      updateIntestatario.mutate({ intestatario: intestatarioToSave })
    }

    navigate(`app/anagrafica/alunno/${alunnoId}/intestatari`)
  }

  if (isFetching) {
    return (
      <div className="flex justify-center py-12">
        <Loading />
      </div>
    )
  }

  if (error) {
    return <span>Si è verificato un errore</span>
  }

  const emailValida = (email: string) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  const isCodiceFiscale = (value: string) => {
    return /^[A-Za-z]/.test(value)
  }

  const handleImpersona = () => {
    impersona.mutate({})
  }

  return (
    <div className="lg:pl-32 flex flex-col pt-6 pb-20 max-w-3xl">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-2">
          {!!intestatario.inibitoAccessoDati && <FaMinusCircle className="text-red-600" />}
          <h1 className="text-2xl leading-tight">
            {intestatario.cognome} {intestatario.nome}
          </h1>
        </div>
        <button
          type="button"
          className="bg-gray-600 hover:bg-gray-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-4 rounded transition-colors duration-150"
          onClick={handleImpersona}
        >
          Impersona
        </button>
      </div>

      <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <section>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="cognome"
                label="Cognome"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il cognome',
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') ||
                    !isCodiceFiscale(getValues('codiceFiscale')) ||
                    cognomeCoerente(getValues('codiceFiscale'), value) ||
                    'Cognome e codice fiscale non coerenti',
                }}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="nome"
                label="Nome"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il nome',
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') ||
                    !isCodiceFiscale(getValues('codiceFiscale')) ||
                    nomeCoerente(getValues('codiceFiscale'), value) ||
                    'Nome e codice fiscale non coerenti',
                }}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="ruolo"
                label="Ruolo"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il ruolo',
                }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
          <FormField
            fieldName="noCodiceFiscale"
            label="No codice fiscale"
            type={InputType.Boolean}
            register={register}
            errors={errors}
            className="mb-3"
          />
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="codiceFiscale"
                label="Codice Fiscale / P.IVA"
                type={InputType.Text}
                validation={{
                  // required: 'Inserisci il codice fiscale',
                  // validate: (value: string) =>
                  //   codiceFiscaleValido(value) || 'Il codice fiscale non è valido',
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') ||
                    (!!value && isCodiceFiscale(value) && codiceFiscaleValido(value)) ||
                    (!!value && !isCodiceFiscale(value) && pIvaCoerente(value).isValid) ||
                    'Inserire un codice fiscale o P.IVA validi',
                }}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="dataNascita"
                label="Data di nascita"
                type={InputType.Date}
                // validation={{ required: 'Inserisci la data di nascita' }}
                register={register}
                errors={errors}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="comuneNascitaDati"
                label="Comune di nascita"
                type={InputType.Select}
                onInputChange={setComuniKeyword}
                options={comuni}
                isLoading={statusComuni === 'loading'}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                //validation={{ required: 'Inserisci il comune' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="statoNascitaDati"
                label="Stato di nascita"
                type={InputType.Select}
                onInputChange={setStatiKeyword}
                options={stati}
                isLoading={statusStati === 'loading'}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                // validation={{ required: 'Inserisci lo stato di nascita' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="cittadinanzaDati"
                label="Cittadinanza"
                type={InputType.Select}
                onInputChange={setStatiKeyword}
                options={stati}
                isLoading={statusStati === 'loading'}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                // validation={{ required: 'Inserisci la cittadinanza' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="nazionalitaDati"
                label="Nazionalità"
                type={InputType.Select}
                onInputChange={setStatiKeyword}
                options={stati}
                isLoading={statusStati === 'loading'}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                //validation={{ required: 'Inserisci la nazionalità' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="mb-6">
            <FormField
              fieldName="indirizzo"
              label="Indirizzo"
              type={InputType.Text}
              validation={{ required: "Inserisci l'indirizzo" }}
              register={register}
              errors={errors}
              control={control}
            />
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="cap"
                label="CAP"
                type={InputType.Text}
                validation={{ required: 'Inserisci il CAP' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="comuneDati"
                label="Comune di residenza"
                type={InputType.Select}
                onInputChange={setComuniKeyword}
                options={comuni}
                isLoading={statusComuni === 'loading'}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                validation={{
                  // required: 'Inserisci il comune'
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') || !!value || 'Inserisci il comune',
                }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="luogoLavoro"
                label="Luogo di lavoro"
                type={InputType.Text}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="professione"
                label="Professione"
                type={InputType.Text}
                register={register}
                errors={errors}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="telefono"
                label="Telefono"
                type={InputType.Text}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="telefono2"
                label="Telefono 2"
                type={InputType.Text}
                register={register}
                errors={errors}
              />
            </div>
          </div>

          <div className="mb-3">
            <FormField
              fieldName="email"
              label="Email"
              type={InputType.Text}
              validation={{
                validate: (value: string) =>
                  !value || emailValida(value) || "Inserire un'email valida",
              }}
              register={register}
              errors={errors}
              control={control}
            />
          </div>
          <div className="mb-6">
            <FormField
              fieldName="flagInvioMail"
              label="Invia bollette via email"
              type={InputType.Boolean}
              register={register}
              errors={errors}
              className="mb-3"
            />
          </div>
        </section>

        <button
          type="submit"
          className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
        >
          {!!intestatarioId ? 'Salva' : 'Crea'}
        </button>
      </form>
    </div>
  )
}

export default Intestatario

import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeAlunni {
  sezioneId?: number
  servizioId: number
  dataPresenze: string
  cognome: string
}

const getAlunniAltreSezioni = async (
  _: string,
  { sezioneId, servizioId, dataPresenze, cognome }: IPresenzeAlunni
) => {
  const { data } = await axios.get(
    `/sezioni/${sezioneId}/presenze-alunni/${servizioId}/?data=${dataPresenze}&escludi_sezioni_fittizie=true&mostra_ritirati=true&escludi_sezione=true&cognome=${cognome}`
  )

  return data.results
    .filter((alunno: any) => !alunno.sezione?.fittizia && !!alunno.sezione && !!alunno.fruizione)
    .map((alunno: any) => {
      return {
        value: alunno.fruizione?.id,
        label: `${alunno.cognome} ${alunno.nome} (${alunno.sezione?.scuolaNome} - ${alunno.sezione?.classe}${alunno.sezione?.sezione})`,
      }
    })
}

const useAlunniAltreSezioni = ({
  sezioneId,
  servizioId,
  dataPresenze,
  cognome,
}: IPresenzeAlunni) => {
  return useQuery(
    ['alunniAltreSezioni', { sezioneId, servizioId, dataPresenze, cognome }],
    () =>
      getAlunniAltreSezioni('alunniAltreSezioni', { sezioneId, servizioId, dataPresenze, cognome }),
    {
      enabled: !!sezioneId && !!servizioId && !!dataPresenze && !!cognome,
    }
  )
}

export default useAlunniAltreSezioni

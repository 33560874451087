import React, { useState, useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import useLogin from '../../apiHooks/mutations/useLogin'
import imgLogo from '../../images/logo_v.svg'

const Login: React.FC<RouteComponentProps> = () => {
  const [password, setPassword] = useState('')
  const [time, setTime] = useState(moment().format('HH:mm'))

  const login = useLogin()

  useEffect(() => {
    if (!localStorage.getItem('username')) {
      navigate('/')
    }
    const timer = setInterval(() => {
      setTime(moment().format('HH:mm'))
    }, 10 * 1000)
    return () => clearInterval(timer)
  }, [])

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault()
    const username = localStorage.getItem('username')
    if (username) {
      login.mutateAsync({ username: username, password }).then((res) => {
        if (res && res.token) {
          localStorage.setItem('token', res.token)
          localStorage.setItem('username', res.user.username)
          localStorage.setItem('nome', res.user.operatore.nome)
          localStorage.setItem('cognome', res.user.operatore.cognome)
          navigate('/app')
        }
      })
    }
  }

  return (
    <div>
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1488998427799-e3362cec87c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80); background-size: cover; background-position: 50% 0%; background-blend-mode: multiply }`}</style>
      </Helmet>
      <header className="pt-16 pb-12 flex flex-col justify-center items-center">
        {/* <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/EBay_logo.svg/1280px-EBay_logo.svg.png"
          alt="logo"
          className="w-32 block mb-6"
        /> */}
        <div
          className="font-hairline leading-none text-gray-800"
          style={{
            fontSize: '12rem',
            // textShadow: '0 0 15px hsla(207,70%,30%,0.7)',
          }}
        >
          {time}
        </div>
      </header>

      <div className="flex flex-col items-center py-12" style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}>
        <div className="text-4xl font-thin mb-5 text-gray-800 leading-none">
          {localStorage.getItem('nome')} {localStorage.getItem('cognome')}
        </div>
        <form onSubmit={handleLogin} className="flex justify-center items-center">
          <input
            type="password"
            className="form-input rounded-r-none"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />

          <button
            type="submit"
            className="py-2 px-6 rounded bg-blue-500 rounded-l-none text-white font-semibold hover:bg-blue-500"
          >
            Fine pausa
          </button>
        </form>
      </div>

      <div className="flex justify-center pt-12">
        <img src={imgLogo} alt="logo" className="w-32" />
      </div>
    </div>
  )
}

export default Login

export const pIvaCoerente = (pIva: string) => {
  const stringIva = String(pIva)
  const arrayIva = stringIva.split('')
  //controllo lunghezza
  if (arrayIva.length !== 11) {
    return {
      isValid: false,
      text: 'Il numero di Partita IVA deve contenere 11 numeri',
    }
  } else {
    // controllo che siano tutti numeri
    const anyChar = arrayIva.find((num: any) => isNaN(num))
    if (!!anyChar) {
      return {
        isValid: false,
        text: 'Il numero di Partita IVA deve contenere solo caratteri numerici',
      }
    }
    // controllo cifra di verifica
    const arrayNumIva = arrayIva.map((num) => Number(num))
    const x = arrayNumIva[0] + arrayNumIva[2] + arrayNumIva[4] + arrayNumIva[6] + arrayNumIva[8]
    const y =
      (arrayNumIva[1] + arrayNumIva[3] + arrayNumIva[5] + arrayNumIva[7] + arrayNumIva[9]) * 2
    const z = arrayNumIva.reduce((total, item, index) => {
      if (index % 2 !== 0 && item >= 5) {
        return total + 1
      } else {
        return total + 0
      }
    }, 0)
    const t = (x + y + z) % 10
    return {
      isValid: arrayNumIva[10] === (10 - t) % 10,
      text: 'Il numero di Partita IVA non è valido',
    }
  }
}

import { createSlice } from '@reduxjs/toolkit'

export const anagraficaRicerca = createSlice({
  name: 'anagraficaRicerca',
  initialState: {
    idSel: '',
    cognomeSel: '',
    nomeSel: '',
    codiceFiscaleSel: '',
    soloAnnoCorrente: true,
    cognomeIntestatarioSel: '',
    codiceSddSel: '',
    scuolaIdSel: '',
    dietaIdSel: '',
    emailIntestatariSel: '',
    iscrizioneDopoIlSel: '',
    iscrizionePrimaDelSel: '',
    pagamentoSddSel: '',
    bolletteSospeseSel: '',
    ritiratoSel: '',
    invioEmailSel: '',
    inizioServizioDopoIlSel: '',
    inizioServizioPrimaDelSel: '',
  },
  reducers: {
    resetAll: (state) => {
      state.idSel = ''
      state.cognomeSel = ''
      state.nomeSel = ''
      state.codiceFiscaleSel = ''
      state.soloAnnoCorrente = true
      state.cognomeIntestatarioSel = ''
      state.codiceSddSel = ''
      state.scuolaIdSel = ''
      state.dietaIdSel = ''
      state.emailIntestatariSel = ''
      state.iscrizioneDopoIlSel = ''
      state.iscrizionePrimaDelSel = ''
      state.pagamentoSddSel = ''
      state.bolletteSospeseSel = ''
      state.ritiratoSel = ''
      state.invioEmailSel = ''
      state.inizioServizioDopoIlSel = ''
      state.inizioServizioPrimaDelSel = ''
    },
    changeIdSel: (state, action) => {
      state.idSel = action.payload
    },
    resetIdSel: (state) => {
      state.idSel = ''
    },
    changeCognomeSel: (state, action) => {
      state.cognomeSel = action.payload
    },
    resetCognomeSel: (state) => {
      state.cognomeSel = ''
    },
    changeNomeSel: (state, action) => {
      state.nomeSel = action.payload
    },
    resetNomeSel: (state) => {
      state.nomeSel = ''
    },
    changeCodiceFiscaleSel: (state, action) => {
      state.codiceFiscaleSel = action.payload
    },
    resetCodiceFiscaleSel: (state) => {
      state.codiceFiscaleSel = ''
    },
    changeSoloAnnoCorrente: (state, action) => {
      state.soloAnnoCorrente = action.payload
    },
    changeCognomeIntestatarioSel: (state, action) => {
      state.cognomeIntestatarioSel = action.payload
    },
    resetCognomeIntestatarioSel: (state) => {
      state.cognomeIntestatarioSel = ''
    },
    changeCodiceSddSel: (state, action) => {
      state.codiceSddSel = action.payload
    },
    resetCodiceSddSel: (state) => {
      state.codiceSddSel = ''
    },
    changeScuolaIdSel: (state, action) => {
      state.scuolaIdSel = action.payload
    },
    resetScuolaIdSel: (state) => {
      state.scuolaIdSel = ''
    },
    changeDietaIdSel: (state, action) => {
      state.dietaIdSel = action.payload
    },
    resetDietaIdSel: (state) => {
      state.dietaIdSel = ''
    },
    changeEmailIntestatariSel: (state, action) => {
      state.emailIntestatariSel = action.payload
    },
    resetEmailIntestatariSel: (state) => {
      state.emailIntestatariSel = ''
    },
    changeIscrizioneDopoIlSel: (state, action) => {
      state.iscrizioneDopoIlSel = action.payload
    },
    resetIscrizioneDopoIlSel: (state) => {
      state.iscrizioneDopoIlSel = ''
    },
    changeIscrizionePrimaDelSel: (state, action) => {
      state.iscrizionePrimaDelSel = action.payload
    },
    resetIscrizionePrimaDelSel: (state) => {
      state.iscrizionePrimaDelSel = ''
    },
    changePagamentoSddSel: (state, action) => {
      state.pagamentoSddSel = action.payload
    },
    resetPagamentoSddSel: (state) => {
      state.pagamentoSddSel = ''
    },
    changeBolletteSospeseSel: (state, action) => {
      state.bolletteSospeseSel = action.payload
    },
    resetBolletteSospeseSel: (state) => {
      state.bolletteSospeseSel = ''
    },
    changeRitiratoSel: (state, action) => {
      state.ritiratoSel = action.payload
    },
    resetRitiratoSel: (state) => {
      state.ritiratoSel = ''
    },
    changeInvioEmailSel: (state, action) => {
      state.invioEmailSel = action.payload
    },
    resetInvioEmailSel: (state) => {
      state.invioEmailSel = ''
    },
    changeInizioServizioDopoIlSel: (state, action) => {
      state.inizioServizioDopoIlSel = action.payload
    },
    resetInizioServizioDopoIlSel: (state) => {
      state.inizioServizioDopoIlSel = ''
    },
    changeInizioServizioPrimaDelSel: (state, action) => {
      state.inizioServizioPrimaDelSel = action.payload
    },
    resetInizioServizioPrimaDelSel: (state) => {
      state.inizioServizioPrimaDelSel = ''
    },
  },
})

export const selectAnagraficaRicerca = (state: {
  anagraficaRicerca: {
    idSel: string
    cognomeSel: string
    nomeSel: string
    codiceFiscaleSel: string
    soloAnnoCorrente: boolean
    cognomeIntestatarioSel: string
    codiceSddSel: string
    scuolaIdSel: string
    dietaIdSel: string
    emailIntestatariSel: string
    iscrizioneDopoIlSel: string
    iscrizionePrimaDelSel: string
    pagamentoSddSel: string
    bolletteSospeseSel: string
    ritiratoSel: string
    invioEmailSel: string
    inizioServizioDopoIlSel: string
    inizioServizioPrimaDelSel: string
  }
}) => state

export const {
  resetAll,
  changeIdSel,
  resetIdSel,
  changeCognomeSel,
  resetCognomeSel,
  changeNomeSel,
  resetNomeSel,
  changeCodiceFiscaleSel,
  resetCodiceFiscaleSel,
  changeSoloAnnoCorrente,
  changeCognomeIntestatarioSel,
  resetCognomeIntestatarioSel,
  changeCodiceSddSel,
  resetCodiceSddSel,
  changeScuolaIdSel,
  resetScuolaIdSel,
  changeDietaIdSel,
  resetDietaIdSel,
  changeEmailIntestatariSel,
  resetEmailIntestatariSel,
  changeIscrizioneDopoIlSel,
  resetIscrizioneDopoIlSel,
  changeIscrizionePrimaDelSel,
  resetIscrizionePrimaDelSel,
  changePagamentoSddSel,
  resetPagamentoSddSel,
  changeBolletteSospeseSel,
  resetBolletteSospeseSel,
  changeRitiratoSel,
  resetRitiratoSel,
  changeInvioEmailSel,
  resetInvioEmailSel,
  changeInizioServizioDopoIlSel,
  resetInizioServizioDopoIlSel,
  changeInizioServizioPrimaDelSel,
  resetInizioServizioPrimaDelSel,
} = anagraficaRicerca.actions

export default anagraficaRicerca.reducer

import { useQuery } from 'react-query'
import axios from 'axios'

const getScuoleByServizioId = async (_: string, servizioId: number) => {
  const { data } = await axios.get(`/servizi/${servizioId}/scuole/`)
  return data
}

const useScuoleByServizioId = (servizioId: number) => {
  return useQuery(['scuoleByServizioId', servizioId], () => getScuoleByServizioId('scuoleByServizioId', servizioId), {
    enabled: !!servizioId,
    // 30 minuti
    staleTime: 30 * 60 * 1000,
  })
}

export default useScuoleByServizioId

interface Option {
  value: string
  label: string
}

export const QUOTA_COMPARTECIPAZIONE_OPTIONS: Option[] = [
  {
    value: 'NON_SOGGETTA',
    label: 'Non soggetta',
  },
  {
    value: 'SOGGETTA',
    label: 'Soggetta',
  },
  {
    value: 'SOGGETTA_ADDEBITATA',
    label: 'Soggetta e addebitata',
  },
  {
    value: 'SOGGETTA_RESTITUITA',
    label: 'Soggetta restituita',
  },
]

export const getLabelByValue = (value: string) => {
  const option = QUOTA_COMPARTECIPAZIONE_OPTIONS.find((f) => f.value === value)

  return option ? option.label : ''
}

import { Link, RouteComponentProps } from '@reach/router'
import React, { useContext } from 'react'
import useServizioById from '../../apiHooks/queries/useServizioById'
import ServizioContext from '../../context/ServizioContext'

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-900 text-sm font-medium rounded',
      }
    : {}
}

const ParametriCalcolo: React.FC<RouteComponentProps> = ({ children }) => {
  const { servizioId } = useContext(ServizioContext)

  const { data: servizio } = useServizioById(servizioId)

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex">
          {/* <Link
            to="./"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Parametri calcolo
          </Link> */}
          <Link
            to="./"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Giorni chiusura
          </Link>
          <Link
            to="esportazioni"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Esportazioni
          </Link>
          <Link
            to="diete"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Diete
          </Link>
          {servizio?.codiceCalcolo === 'base' && (
            <Link
              to="fasce"
              className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
              getProps={isActive}
            >
              Fasce
            </Link>
          )}
          <Link
            to="parametri-servizio"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Parametri servizio
          </Link>
        </div>
      </div>
      {children}
    </div>
  )
}

export default ParametriCalcolo

import React, { useState, useContext, useEffect, useRef, useMemo, useCallback } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import ReactModal from 'react-modal'
import { useForm } from 'react-hook-form'
import { VariableSizeList } from 'react-window'
import { useBlockLayout, useFilters, useSortBy, useTable } from 'react-table'
import moment from 'moment'
import { FaCaretDown, FaCheckCircle, FaEdit, FaPlus, FaTimes } from 'react-icons/fa'
import { FiFileText } from 'react-icons/fi'
import classNames from 'classnames'

import shortenText from '../../lib/shortenText'

import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import ServizioContext from '../../context/ServizioContext'

import useAvvisiPagamentoByMese from '../../apiHooks/queries/useAvvisiPagamentoByMese'
import useDateEmissioneByIdByAnno from '../../apiHooks/queries/useDateEmissioneByIdByAnno'
import useCreatePagamento from '../../apiHooks/mutations/useCreatePagamento'
import useUpdatePagamento from '../../apiHooks/mutations/useUpdatePagamento'
import useDeletePagamento from '../../apiHooks/mutations/useDeletePagamento'
import useGeneraPagamentiSdd from '../../apiHooks/mutations/useGeneraPagamentiSdd'

import Price from '../../components/Price'
import FormField, { InputType as FormInputType } from '../../components/FormField'
import Loading from '../../components/Loading'

const MESI = [
  { value: '9', label: 'Set', income: 105000 },
  { value: '10', label: 'Ott', income: 107234 },
  { value: '11', label: 'Nov', income: 106864 },
  { value: '12', label: 'Dic', income: 108000 },
  { value: '1', label: 'Gen', income: 107954 },
  { value: '2', label: 'Feb', income: 105998 },
  { value: '3', label: 'Mar', income: 106158 },
  { value: '4', label: 'Apr', income: 105764 },
  { value: '5', label: 'Mag', income: 105723 },
  { value: '6', label: 'Giu', income: 106781 },
  { value: '7', label: 'Lug', income: 57314 },
  { value: '8', label: 'Ago', income: 0 },
]

// enum TipoPagamento {
//   Contanti = 'cont',
//   SDD = 'sdd',
//   Bonifico = 'bon',
// }

interface Pagamento {
  id: string
  tipo: { value: string; label: string }
  data: string
  importo: number
  nota?: string
}

// interface Sollecito {
//   id: number
//   data: string
//   tipo: string
// }

// interface AvvisoPagamentoAPI {
//   anno: number
//   bambinoCodice: string
//   bambinoCodicefiscale: string
//   bambinoCognome: string
//   bambinoNome: string
//   codiceBarre: string
//   dataEmissione: string
//   dataPagamento: string
//   dataScadenza: string
//   descrizione: string
//   fruizioneId: number
//   id: number
//   importoPagato: string
//   importoRetta: string
//   importoTotale: string
//   intestatarioId: number
//   linkDownload: string
//   mese: number
//   nota: string
//   numeroAvvisoPagamento: string
//   pagamenti: []
//   pagatoreCap: string
//   pagatoreCodiceFiscale: string
//   pagatoreCognome: string
//   pagatoreComuneCodiceIstat: number
//   pagatoreIbanSdd: null
//   pagatoreIndirizzo: string
//   pagatoreNome: string
//   pdfAvviso: string
//   periodo: string
//   righeImporti: []
//   // righeImporti: "[{"importo": "60.00", "descrizione": "Quota fissa", "is_retta": true, "tipo_riga": null}, {"importo": "460.00", "descrizione": "Quota variabile in base a ISEE", "is_retta": true, "tipo_riga": null}, {"importo": "0.00", "descrizione": "4 gg assenza - franchigia 5 gg = 0", "is_retta": true, "tipo_riga": null}, {"importo": "-60.00", "descrizione": "Restituzione 50% quota iscrizione", "is_retta": false, "tipo_riga": null}]"
//   sbloccato: boolean
//   scuolaId: number
//   scuolaNome: string
//   servizioCodice: string
//   sezioneClasse: string
//   sezioneSezione: string
//   tipoPagamento: TipoPagamento
// }

// interface AvvisoPagamento {
//   id: number
//   codice: string
//   alunno: {
//     cognome: string
//     nome: string
//     scuola: string
//     classe: string
//   }
//   intestatario: {
//     cognome: string
//     nome: string
//   }
//   importo: number
//   importoGenitore: number
//   importoPagato: number
//   dataPagamento: string
//   tipoPagamento: TipoPagamento
//   note: string[]
//   solleciti: Sollecito[]
//   numeroSolleciti: number
// }

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const PagamentiSolleciti: React.FC<RouteComponentProps> = () => {
  //==================
  // CONTEXT
  //==================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  //==================
  // REF
  //==================
  const tableRef = useRef<VariableSizeList>(null)

  //==================
  // STATE
  //==================
  const [meseSel, setMeseSel] = useState('9')
  const [avvisoSel, setAvvisoSel] = useState<string | null>(null)
  const [avvisoSelIndex, setAvvisoSelIndex] = useState<number>(0)
  const [showPagamentiId, setShowPagamentiId] = useState<number | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showEsitiSddModal, setShowEsitiSddModal] = useState<boolean>(false)
  const nuovoPagamento = useMemo(() => {
    return {
      id: '',
      tipo: { value: 'bon', label: 'Bonifico' },
      data: moment().format('YYYY-MM-DD'),
      importo: 0,
      nota: '',
    }
  }, [])
  const [pagamentoSel, setPagamentoSel] = useState<Pagamento>(nuovoPagamento)

  //==================
  // QUERIES
  //==================
  const { data: avvisiPagamento, isFetching: isFetchingAvvisiPagamento } = useAvvisiPagamentoByMese({
    annoScolastico,
    servizioId,
    mese: meseSel,
  })
  const { data: dateEmissione = [] } = useDateEmissioneByIdByAnno({
    servizioId,
    annoScolastico,
  })

  //==================
  // MUTATIONS
  //==================
  const createPagamento = useCreatePagamento()
  const patchPagamento = useUpdatePagamento()
  const deletePagamento = useDeletePagamento()
  const generaPagamentiSdd = useGeneraPagamentiSdd()

  //==================
  // CALLBACKS
  //==================
  const getLabelPagamentoByValue = useCallback((value: string) => {
    switch (value) {
      case 'bon':
        return 'Bonifico'
      case 'sdd':
        return 'SDD'
      case 'cont':
        return 'Contanti'
      case 'pagopa':
        return 'PagoPA'
      default:
        return ''
    }
  }, [])

  const openModal = useCallback((id: string, index: number, pagamento: Pagamento, isEditing: boolean = false) => {
    document.getElementById('root')!.style.filter = 'blur(5px)'
    setAvvisoSel(id)
    setAvvisoSelIndex(index)
    isEditing && setPagamentoSel(pagamento)
  }, [])

  const closeModal = useCallback(() => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setAvvisoSel(null)
    setPagamentoSel(nuovoPagamento)
  }, [nuovoPagamento])

  const openDeleteModal = useCallback((id: string, index: number, pagamento: Pagamento, bambinoId: number) => {
    document.getElementById('root')!.style.filter = 'blur(5px)'
    setShowDeleteModal(true)
    setAvvisoSel(id)
    setAvvisoSelIndex(bambinoId)
    setPagamentoSel(pagamento)
  }, [])

  const closeDeleteModal = useCallback(() => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setShowDeleteModal(false)
    setAvvisoSel(null)
    setPagamentoSel(nuovoPagamento)
  }, [nuovoPagamento])

  const handleShowPagamenti = useCallback(
    (id: number | null) => {
      if (id === showPagamentiId) {
        setShowPagamentiId(null)
      } else {
        setShowPagamentiId(id)
      }
    },
    [showPagamentiId]
  )

  const handleAggiungiPagamento = useCallback(
    (index: number) => (values: any) => {
      const pagamentoToSave = {
        ...values,
        avvisoPagamentoId: avvisoSel,
        tipo: values.tipo.value,
      }
      createPagamento.mutateAsync({ pagamento: pagamentoToSave }).then((res) => {
        avvisiPagamento.find((el: any) => {
          if (el.id === res.avvisoPagamentoId) {
            setShowPagamentiId(null)
            return el.pagamenti.push(res)
          }
          return null
        })
        setTimeout(() => {
          // @ts-ignore
          tableRef.current.resetAfterIndex(index, true)
        }, 500)
      })
      closeModal()
    },
    [avvisoSel, createPagamento, closeModal, avvisiPagamento]
  )

  const handleModificaPagamento = useCallback(
    (values: any) => {
      const pagamentoToSave = {
        ...values,
        avvisoPagamentoId: avvisoSel,
        tipo: values.tipo.value,
      }
      patchPagamento.mutate({ pagamentoId: pagamentoSel.id, pagamento: pagamentoToSave })
      closeModal()
    },
    [avvisoSel, patchPagamento, closeModal, pagamentoSel.id]
  )

  const handleEliminaPagamento = useCallback(
    (pagamentoId: number, index: number) => {
      deletePagamento.mutateAsync(pagamentoId).then(() => {
        avvisiPagamento.find((rigaAvviso: any) => rigaAvviso.id === index).pagamenti = avvisiPagamento
          .find((rigaAvviso: any) => rigaAvviso.id === index)
          .pagamenti.filter((el: any) => {
            setShowPagamentiId(null)
            return el.id !== pagamentoId
          })

        setTimeout(() => {
          // @ts-ignore
          tableRef.current.resetAfterIndex(index > 0 ? index - 1 : 0, true)
        }, 500)
      })
      closeDeleteModal()
    },
    [deletePagamento, closeDeleteModal, avvisiPagamento]
  )

  const handleGeneraPagamentiSdd = useCallback(
    (values: any) => {
      const pagamentiSddToSave = {
        dataEmissione: values.dataEmissione.value,
        dataScadenza: values.dataScadenza,
        annoScolastico,
      }
      generaPagamentiSdd.mutate({ servizioId, pagamentiSdd: pagamentiSddToSave })
      setShowEsitiSddModal(false)
    },
    [generaPagamentiSdd, setShowEsitiSddModal, annoScolastico, servizioId]
  )

  //==================
  // USE FORM
  //==================
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: pagamentoSel,
  })

  //===================
  // TABLE
  //===================
  const data = useMemo(() => avvisiPagamento || [], [avvisiPagamento])

  const columnFilter = (props: any) => {
    const {
      filterValue,
      // preFilteredRows,
      setFilter,
    } = props.column
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
        placeholder="Cerca..."
        // placeholder={`Cerca tra ${preFilteredRows.length} avvisi...`}
        className="form-input p-1 rounded text-xs w-24"
      />
    )
  }

  const sddFilter = (props: any) => {
    const { filterValue, setFilter } = props.column
    return (
      <input
        type="checkbox"
        checked={filterValue || false}
        onChange={(e) => {
          setFilter(e.target.checked || undefined)
        }}
        className="form-checkbox p-1"
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: 'N°',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'numeroAvvisoPagamento',
        width: 45,
        disableFilters: true,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'Codice',
        Filter: columnFilter,
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'codiceBarre',
        width: 120,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'Scuola',
        Filter: columnFilter,
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'scuolaNome',
        width: 220,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 text-xs">{shortenText(props.value, 70)}</div>
          )
        },
      },
      {
        Header: 'Cognome e nome',
        Filter: columnFilter,
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'bambinoCognome',
        width: 237,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              <Link
                className="text-blue-500 hover:text-blue-600"
                to={`/app/anagrafica/alunno/${props.row.original.bambinoCodice}/servizio/${servizioId}`}
              >
                {props.value} {props.row.original.bambinoNome}
              </Link>
            </div>
          )
        },
      },
      {
        Header: 'SDD',
        Filter: sddFilter,
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'pagamentoSdd',
        width: 40,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              {!!props.value ? <FaCheckCircle className="text-green-400" /> : null}
            </div>
          )
        },
      },
      {
        Header: 'Importo',
        Footer: (props: any) => {
          const total = useMemo(
            () => props.rows.reduce((sum: any, row: any) => sum + parseFloat(row.values.importoTotale), 0),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-end items-center p-3 font-semibold bg-blue-100 text-blue-800 border-t border-gray-500">
              <Price amount={total || 0} />
            </div>
          )
        },
        accessor: 'importoTotale',
        width: 100,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div
              className={classNames('h-full flex justify-end items-center p-3 text-sm', {
                'text-gray-500': !props.value || props.value === '0.00',
              })}
            >
              <Price amount={props.value || 0} />
            </div>
          )
        },
      },
      {
        Header: 'Q. genit.',
        Footer: (props: any) => {
          const total = useMemo(
            () => props.rows.reduce((sum: any, row: any) => sum + parseFloat(row.values.quotaCaricoGenitore), 0),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-end items-center p-3 font-semibold bg-blue-100 text-blue-800 border-t border-gray-500">
              <Price amount={total || 0} />
            </div>
          )
        },
        accessor: 'quotaCaricoGenitore',
        width: 120,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div
              className={classNames(
                'h-full flex justify-end items-center p-3 text-sm',
                {
                  'text-gray-500': !props.value || props.value === '0.00',
                },
                {
                  'text-blue-500 font-semibold':
                    parseFloat(props.value) !== parseFloat(props.row.original.importoTotale),
                }
              )}
            >
              <Price amount={props.value || 0} />
            </div>
          )
        },
      },
      {
        Header: 'Pagato',
        Footer: (props: any) => {
          const total = useMemo(
            () => props.rows.reduce((sum: any, row: any) => sum + parseFloat(row.values.importoPagato), 0),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-end items-center p-3 font-semibold bg-blue-100 text-blue-800 border-t border-gray-500">
              <Price amount={total || 0} />
            </div>
          )
        },
        accessor: 'importoPagato',
        width: 120,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div
              className={classNames(
                'h-full flex justify-between items-center p-3 text-sm',
                {
                  'text-yellow-600 font-semibold':
                    parseFloat(props.value) > parseFloat(props.row.original.quotaCaricoGenitore),
                },
                {
                  'text-green-500 font-semibold':
                    parseFloat(props.value) === parseFloat(props.row.original.quotaCaricoGenitore),
                },
                {
                  'text-red-600': parseFloat(props.value) < parseFloat(props.row.original.quotaCaricoGenitore),
                }
              )}
            >
              <div>
                {props.row.original.pagamenti && props.row.original.pagamenti.length > 0 ? (
                  <button
                    type="button"
                    className="mr-1 p-1 text-gray-800"
                    onClick={() => handleShowPagamenti(props.row.original.id)}
                  >
                    <FaCaretDown
                      className={classNames('transform', {
                        'rotate-180': showPagamentiId === props.row.original.id,
                      })}
                    />
                  </button>
                ) : (
                  <div className="p-1 mr-1" style={{ width: 22, height: 22 }}>
                    &nbsp;
                  </div>
                )}
              </div>
              <Price amount={props.value || 0} />
            </div>
          )
        },
      },
      // {
      //   Header: 'N° solleciti',
      //   Footer: (props: any) => {
      //     return (
      //       <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
      //         &nbsp;
      //       </div>
      //     )
      //   },
      //   accessor: 'solleciti',
      //   width: 100,
      //   disableFilters: true,
      //   Cell: (props: any) => {
      //     return (
      //       <div className="h-full flex justify-center items-center p-3">{props.value.length || 0}</div>
      //     )
      //   },
      // },
      {
        Header: ' ',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'linkDownload',
        width: 84,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              <a
                className="bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                href={`${props.value}?t=${encodeURIComponent(localStorage.getItem('token') || '')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiFileText />
              </a>
              <button
                className="ml-3 bg-green-600 hover:bg-green-500 p-1 text-white rounded"
                onClick={() => {
                  openModal(props.row.original.id, props.row.index, nuovoPagamento)
                }}
              >
                <FaPlus />
              </button>
            </div>
          )
        },
      },
    ],
    [servizioId, nuovoPagamento, openModal, handleShowPagamenti, showPagamentiId]
  )

  // const defaultColumn = useMemo(
  //   () => ({
  //     Filter: columnFilter,
  //   }),
  //   []
  // )

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, totalColumnsWidth, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useBlockLayout,
      useFilters,
      useSortBy
    )

  //================
  // EFFECTS
  //================
  useEffect(() => {
    reset(pagamentoSel)
  }, [avvisoSel, pagamentoSel, reset])

  useEffect(() => {
    tableRef.current?.resetAfterIndex(0)
  }, [rows, showPagamentiId])

  //==================
  // TABLE ROWS
  //==================
  const rowHeights = useMemo(
    () =>
      rows.map((r: any) => {
        return (
          42 +
          (r.original.id === showPagamentiId
            ? r.original.pagamenti.length * 23 + (r.original.pagamenti.length > 0 ? 24 : 0)
            : 0)
        )
      }),
    [rows, showPagamentiId]
  )

  const getItemSize = useCallback(
    (index: number) => {
      return rowHeights[index]
    },
    [rowHeights]
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <>
          <div
            {...row.getRowProps({
              style,
            })}
            className="tr hover:bg-blue-100 flex flex-col h-full border-b border-gray-400"
          >
            <div className="flex items-center" style={{ height: 42 }}>
              {row.cells.map((cell) => {
                return (
                  <div {...cell.getCellProps()} className="td h-full overflow-y-hidden leading-none p-0">
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
            <div className="py-3 pl-20 pr-10">
              {
                // prettier-ignore
                //@ts-ignore
                row.original.pagamenti && row.original.pagamenti.length > 0 && showPagamentiId === row.original.id && row.original.pagamenti.map((p: any, index: number) => {
                  const pagamentoToShow = {
                    ...p,
                    tipo: {
                      value: p.tipo,
                      label: getLabelPagamentoByValue(p.tipo),
                    },
                  }
                  return (
                    <div key={p.id} className="flex items-center leading-none space-x-4 border bg-white">
                      <div className="flex space-x-1">
                        <button
                          className="p-1 text-blue-600 hover:text-blue-500 leading-tight text-md"
                          onClick={() => {
                            openModal(p.avvisoPagamentoId, index, pagamentoToShow, true)
                          }}
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="p-1 text-red-600 hover:text-red-500 leading-tight text-md"
                          onClick={() => {
                            //@ts-ignore
                            openDeleteModal(p.id, index, pagamentoToShow, row.original.id)
                          }}
                        >
                          <FaTimes />
                        </button>
                      </div>
                      <div className="flex w-full items-center text-xs">
                        <div className="w-20 p-1">
                          <Price amount={p.importo} />
                        </div>
                        <div className="w-20 p-1">{getLabelPagamentoByValue(p.tipo)}</div>
                        <div className="w-20 p-1">{moment(p.data, 'YYYY-MM-DD', true).format('DD/MM/YYYY')}</div>
                        <div className="flex-1 p-1">{!!p.nota && `${p.nota}`}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </>
      )
    },
    [prepareRow, rows, openModal, showPagamentiId, openDeleteModal, getLabelPagamentoByValue]
  )

  //==================
  // OPTIONS
  //==================
  const tipiPagamentoOptions = useMemo(
    () => [
      {
        value: 'bon',
        label: 'Bonifico',
      },
      {
        value: 'sdd',
        label: 'SDD',
      },
      {
        value: 'cont',
        label: 'Contanti',
      },
      {
        value: 'pagopa',
        label: 'PagoPA',
      },
    ],
    []
  )

  const dateEmissioneOptions = useMemo(() => {
    return dateEmissione.map((d: any) => ({
      value: d.dataEmissione,
      label: moment(d.dataEmissione, 'YYYY-MM-DD', true).format('DD/MM/YYYY'),
    }))
  }, [dateEmissione])

  //==================
  // RETURN
  //==================
  return (
    <div className="bg-white py-6">
      <style>
        {`
        .ag-theme-alpine * {
          border-style: none;
        }
        .ag-react-container {
          height: 100%;
        }
      `}
      </style>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row items-end justify-between mb-8">
          <h1 className="text-4xl leading-tight">
            Avvisi<small className="text-xl text-gray-500 ml-2"> {annoScolastico}</small>
          </h1>
          <div className="flex space-x-3">
            <button
              type="button"
              className="bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
              onClick={() => setShowEsitiSddModal(true)}
            >
              Esiti SDD
            </button>
            <Link
              to="esiti-bonifici"
              className="bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
            >
              Esiti bonifici
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between mb-6 space-y-3 lg:space-y-0">
          {MESI.map((mese) => {
            return (
              <div key={mese.value} className="text-center w-1/6 lg:w-1/12 px-2 border-r-2 border-blue-100">
                <button
                  className={`mb-1 py-1 px-3 focus:outline-none border border-transparent hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150 ${
                    mese.value === meseSel ? 'bg-blue-200 border-blue-200 text-blue-800 font-medium' : 'text-gray-600'
                  }`}
                  onClick={() => setMeseSel(mese.value)}
                >
                  {mese.label}
                </button>
                {/* <div className="text-xs text-gray-500">
                  <Price amount={mese.income} decimal={0} />
                </div> */}
              </div>
            )
          })}
        </div>

        {isFetchingAvvisiPagamento && <Loading size={40} className="py-6" />}

        <div className="table-wrapper block max-w-full overflow-x-scroll overflow-y-hidden">
          {!!avvisiPagamento && (
            <div
              {...getTableProps()}
              className="table w-full max-w-6xl text-sm border border-gray-400 rounded rounded-tr-none"
            >
              <div>
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map((column: any) => {
                      return (
                        <div
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={classNames(
                            'th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold',
                            {
                              'text-right':
                                column.Header === 'Importo' ||
                                column.Header === 'Pagato' ||
                                column.Header === 'Q. genit.',
                            },
                            {
                              'text-center': column.Header === 'SDD' || column.Header === 'N° solleciti',
                            }
                          )}
                        >
                          {column.render('Header')}
                          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                          <div className="pt-2 w-full" onClick={(e) => e.stopPropagation()}>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()}>
                <VariableSizeList
                  ref={tableRef}
                  height={400}
                  itemCount={rows.length}
                  itemSize={getItemSize}
                  width={totalColumnsWidth}
                >
                  {RenderRow}
                </VariableSizeList>
              </div>
              {footerGroups.map((group) => (
                <div className="tr" {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <div className="td" {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* AGGIUNGI O MODIFICA PAGAMENTO */}
      <ReactModal
        isOpen={!!avvisoSel && !showDeleteModal}
        onRequestClose={() => {
          setAvvisoSel(null)
          setPagamentoSel(nuovoPagamento)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-500 text-white font-semibold py-2 text-center">
            {!pagamentoSel.importo ? 'Aggiungi pagamento' : 'Modifica pagamento'}
          </div>

          <div className="px-10 py-6">
            <form
              onSubmit={
                !pagamentoSel.importo
                  ? handleSubmit(handleAggiungiPagamento(avvisoSelIndex))
                  : handleSubmit(handleModificaPagamento)
              }
            >
              <div className="mb-4">
                <FormField
                  fieldName="tipo"
                  label="Tipo pagamento"
                  type={FormInputType.Select}
                  options={tipiPagamentoOptions}
                  register={register}
                  errors={errors}
                  control={control}
                  validation={{ required: 'Inserisci il tipo di pagamento' }}
                />
              </div>
              <div className="mb-5">
                <FormField
                  fieldName="data"
                  label="Data"
                  type={FormInputType.Date}
                  validation={{ required: 'Inserisci la data di pagamento' }}
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="mb-5">
                <FormField
                  fieldName="importo"
                  label="Importo"
                  type={FormInputType.Number}
                  step="0.01"
                  register={register}
                  errors={errors}
                  validation={{
                    required: 'Inserisci un importo',
                    //min: { value: 0.0, message: 'Importo minimo: € 0.00' },
                  }}
                />
              </div>
              <div className="mb-5">
                <FormField
                  fieldName="nota"
                  label="Nota"
                  type={FormInputType.Textarea}
                  rows={2}
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="flex justify-between space-x-3">
                <button
                  type="button"
                  className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => closeModal()}
                >
                  Annulla
                </button>

                <button
                  type="submit"
                  className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  {!pagamentoSel.importo ? 'Aggiungi pagamento' : 'Modifica pagamento'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>

      {/* ELIMINA PAGAMENTO */}
      <ReactModal
        isOpen={!!showDeleteModal}
        onRequestClose={() => {
          setPagamentoSel(nuovoPagamento)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-500 text-white font-semibold py-2 text-center">Elimina pagamento</div>

          <div className="px-10 py-6">
            <p className="mb-4">Vuoi davvero eliminare questo pagamento?</p>
            <div className="mb-8 p-4 bg-blue-100 border rounded border-blue-200 text-sm">
              <Price amount={pagamentoSel.importo} /> effettuato tramite {pagamentoSel.tipo.label} il{' '}
              {moment(pagamentoSel.data, 'YYYY-MM-DD', true).format('DD/MM/YYYY')}
              {pagamentoSel.nota && <div className="mt-2">Note: {pagamentoSel.nota}</div>}
            </div>

            <div className="flex justify-between space-x-3">
              <button
                type="button"
                className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={() => closeDeleteModal()}
              >
                Annulla
              </button>

              <button
                type="button"
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={() => {
                  handleEliminaPagamento(parseInt(pagamentoSel.id), avvisoSelIndex)
                }}
              >
                Sì, elimina pagamento
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* GENERA PAGAMENTI SDD */}
      <ReactModal
        isOpen={!!showEsitiSddModal}
        onRequestClose={() => {
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-500 text-white font-semibold py-2 text-center">Esiti SDD</div>

          <div className="px-10 py-6">
            <form onSubmit={handleSubmit(handleGeneraPagamentiSdd)}>
              <div className="mb-4">
                <FormField
                  fieldName="dataEmissione"
                  label="Data emissione fattura"
                  type={FormInputType.Select}
                  options={dateEmissioneOptions}
                  register={register}
                  errors={errors}
                  control={control}
                  validation={{ required: 'Inserisci la data di emissione' }}
                />
              </div>
              <div className="mb-5">
                <FormField
                  fieldName="dataScadenza"
                  label="Data di pagamento presunta"
                  type={FormInputType.Date}
                  validation={{ required: 'Inserisci la data di pagamento' }}
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="flex justify-between space-x-3">
                <button
                  type="button"
                  className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => setShowEsitiSddModal(false)}
                >
                  Annulla
                </button>

                <button
                  type="submit"
                  className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  Segna pagati
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export default PagamentiSolleciti

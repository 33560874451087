import { navigate, Link } from '@reach/router'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactModal from 'react-modal'
import useUpdateIscrizione from '../apiHooks/mutations/useUpdateIscrizione'
import { API_URL } from '../config'
import FormField, { InputType as formFieldInputType } from './FormField'
import { FiCheckCircle, FiX } from 'react-icons/fi'
import classNames from 'classnames'
import { getStatoColor } from '../pages/iscrizioni/ListaIscrizioni'
import { convertToItalianDecimal } from './RiepilogoIscrizioneAsilo'

interface RiepilogoIscrizioneMensaProps {
  domandaIscrizione: any
}

export const RiepilogoIscrizioneMensa: React.FC<RiepilogoIscrizioneMensaProps> = ({
  domandaIscrizione,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const updateIscrizione = useUpdateIscrizione()
  const modalCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      padding: 0,
      border: 'none',
    },
    overlay: {
      backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
    },
  }

  const Badge = ({ text }: { text: string }) => {
    return (
      <span
        className={`text-sm font-semibold bg-green-500 p-1 text-white rounded inline-block leading-none text-center whitespace-nowrap align-baseline`}
      >
        {text}
      </span>
    )
  }

  const rifiutoIscrizione = (values: any) => {
    updateIscrizione
      .mutateAsync({
        iscrizioneId: domandaIscrizione.id,
        servizio: 'mensa',
        payload: { stato: 'RIFIUTATA', motivazioneRifiuto: values.motivazioneRifiuto },
      })
      .then((response) => {
        navigate('/app/iscrizioni')
      })
      .catch((error) => {
        console.log(error)
      })
    setOpenModal(false)
    document.getElementById('root')!.style.filter = 'blur(0)'
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<any>({
    defaultValues: { motivazioneRifiuto: domandaIscrizione?.motivazioneRifiuto },
  })

  const altriDati: [string, boolean][] | null = domandaIscrizione.altriDati
    ? Object.entries(JSON.parse(domandaIscrizione.altriDati))
    : null

  return (
    <div className="pt-6 pb-20 bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-8 text-gray-700">
          Riepilogo iscrizione ristorazione scolastica
        </h1>
        {domandaIscrizione.stato === 'RIFIUTATA' && (
          <p className="mb-6 text-red-600">
            La domanda è stata rifiutata con la seguente motivazione: "
            {domandaIscrizione.motivazioneRifiuto}"
          </p>
        )}
        <div className="mb-6 sm:text-lg flex justify-between">
          <p>
            Riepilogo della domanda di iscrizione al servizio di ristorazione scolastica per{' '}
            <span className="font-semibold uppercase">
              {domandaIscrizione.iscrittoPersonaCognome} {domandaIscrizione.iscrittoPersonaNome}
            </span>
          </p>
          <p className={`text-white ${getStatoColor(domandaIscrizione.stato)} p-1 text-sm`}>
            {domandaIscrizione.stato.includes('_')
              ? domandaIscrizione.stato.replace('_', ' ')
              : domandaIscrizione.stato}
          </p>
        </div>

        {domandaIscrizione.numeroProtocollo && domandaIscrizione.dataProtocollo && (
          <div className="mb-6">
            <p>
              Protocollo n° {domandaIscrizione.numeroProtocollo} del{' '}
              {moment(domandaIscrizione.dataProtocollo).format('DD/MM/YYYY HH:mm')}
            </p>
          </div>
        )}

        <div className="mt-4 overflow-x-auto mb-10">
          <table className="bg-gray-100 mb-4 w-full">
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  UTENTE CHE HA EFFETTUATO L'ACCESSO
                </th>
                <th scope="col" className="p-2"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-semibold text-gray-800">
                  Cognome e nome
                </td>
                <td className="p-2 border-inherit border-b border-solid border-gray-200 text-left">
                  {domandaIscrizione.primoRichiedenteCognome}{' '}
                  {domandaIscrizione.primoRichiedenteNome}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-semibold text-gray-800">
                  E-mail a cui verranno inviate le comunicazioni
                </td>
                <td className="p-2 text-left">{domandaIscrizione.primoRichiedenteEmail}</td>
              </tr>
            </tbody>

            {/* Iscritto */}
            <tbody className="align-bottom">
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  BAMBINO/A ISCRITTO/A
                </th>
                <th scope="col" className="p-2 text-left"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold align-middle text-gray-800">
                  Dati anagrafici
                </td>
                <td className="p-2 text-left">
                  <p className="uppercase font-bold">
                    {domandaIscrizione.iscrittoPersonaCognome}{' '}
                    {domandaIscrizione.iscrittoPersonaNome}
                  </p>
                  <p>{domandaIscrizione.iscrittoParentela}</p>
                  <p>{domandaIscrizione.iscrittoPersonaCodiceFiscale}</p>
                  <p>
                    Nato{' '}
                    {Number(domandaIscrizione.iscrittoPersonaNascitaCodiceComune) !== 0
                      ? 'a ' + domandaIscrizione.comuneNascitaIscritto
                      : 'in ' + domandaIscrizione.statoNascitaIscritto}{' '}
                    il {domandaIscrizione.iscrittoPersonaNascitaData}
                  </p>
                  <br />
                  <p>
                    {domandaIscrizione.iscrittoPersonaResidenzaIndirizzo},{' '}
                    {domandaIscrizione.iscrittoPersonaResidenzaNumeroCivico}
                  </p>
                  <p>
                    {domandaIscrizione.iscrittoPersonaResidenzaCap}{' '}
                    {domandaIscrizione.iscrittoNomeComuneResidenza}
                  </p>
                  <br />
                  <p>Cittadinanza: {domandaIscrizione.cittadinanzaIscritto}</p>
                  <p>Nazionalità: {domandaIscrizione.nazionalitaIscritto}</p>
                </td>
              </tr>
            </tbody>

            {/* Primo genitore */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  GENITORE / LEGALE RAPPRESENTANTE
                </th>
                <th scope="col" className="p-2 text-left"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Dati anagrafici
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left">
                  <p className="uppercase font-bold text-gray-800">
                    {domandaIscrizione.primoRichiedenteCognome}{' '}
                    {domandaIscrizione.primoRichiedenteNome}
                  </p>
                  <p>{domandaIscrizione.parentelaIntestatario}</p>
                  <p>{domandaIscrizione.primoRichiedenteCodiceFiscale}</p>
                  <p>
                    Nato{' '}
                    {Number(domandaIscrizione.primoRichiedenteNascitaCodiceComune) !== 0
                      ? 'a ' + domandaIscrizione.comuneNascitaPrimoRichiedente
                      : 'in ' + domandaIscrizione.statoNascitaPrimoRichiedente}{' '}
                    il {domandaIscrizione.primoRichiedenteNascitaData}
                  </p>

                  <br />

                  <p>
                    {domandaIscrizione.primoRichiedenteResidenzaIndirizzo},{' '}
                    {domandaIscrizione.primoRichiedenteResidenzaNumeroCivico}
                  </p>
                  <p>
                    {domandaIscrizione.primoRichiedenteResidenzaCap}{' '}
                    {domandaIscrizione.primoRichiedenteNomeComuneResidenza}
                  </p>
                  <br />
                  <p>Cittadinanza: {domandaIscrizione.cittadinanzaPrimoRichiedente}</p>
                  <p>Nazionalità: {domandaIscrizione.nazionalitaPrimoRichiedente}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Ruolo
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left">
                  <p>INTESTATARIO</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Telefono
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left">
                  <p>{domandaIscrizione.primoRichiedenteTelefono}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Cellulare
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left">
                  <p>{domandaIscrizione.primoRichiedenteCellulare}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid text-left font-bold text-gray-800">
                  E-mail
                </td>
                <td className="p-2 border-b border-solid text-left">
                  <p>{domandaIscrizione.primoRichiedenteEmail}</p>
                </td>
              </tr>
            </tbody>

            {/* Secondo genitore */}
            {!!domandaIscrizione.secondoRichiedenteCodiceFiscale && (
              <tbody>
                <tr className="bg-blue-400 text-white">
                  <th scope="col" className="p-2 pl-6 text-left">
                    2° GENITORE
                  </th>
                  <th scope="col" className="p-2 text-left"></th>
                </tr>

                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Dati anagrafici
                  </td>
                  <td className="p-2 border-b border-solid border-gray-200 text-left">
                    <p className="uppercase font-bold text-gray-800">
                      {domandaIscrizione.secondoRichiedenteCognome}{' '}
                      {domandaIscrizione.secondoRichiedenteNome}
                    </p>
                    <p>{domandaIscrizione.parentelaSecondoGenitore}</p>
                    <p>{domandaIscrizione.secondoRichiedenteCodiceFiscale}</p>
                    <p>
                      Nato{' '}
                      {Number(domandaIscrizione.secondoRichiedenteNascitaCodiceComune) !== 0
                        ? 'a ' + domandaIscrizione.comuneNascitaSecondoRichiedente
                        : 'in ' + domandaIscrizione.statoNascitaSecondoRichiedente}{' '}
                      il {domandaIscrizione.secondoRichiedenteNascitaData}
                    </p>

                    <br />

                    <p>
                      {domandaIscrizione.secondoRichiedenteResidenzaIndirizzo},{' '}
                      {domandaIscrizione.secondoRichiedenteResidenzaNumeroCivico}
                    </p>
                    <p>
                      {domandaIscrizione.secondoRichiedenteResidenzaCap}{' '}
                      {domandaIscrizione.secondoRichiedenteNomeComuneResidenza}
                    </p>
                    <br />
                    <p>Cittadinanza: {domandaIscrizione.cittadinanzaSecondoRichiedente}</p>
                    <p>Nazionalità: {domandaIscrizione.nazionalitaSecondoRichiedente}</p>
                  </td>
                </tr>

                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Ruolo
                  </td>
                  <td className="p-2 border-b border-solid border-gray-200 text-left">
                    <p className="uppercase">{domandaIscrizione.ruoloSecondoRichiedente}</p>
                  </td>
                </tr>

                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Telefono
                  </td>
                  <td className="p-2 border-b border-solid border-gray-200 text-left">
                    <p>{domandaIscrizione.secondoRichiedenteTelefono}</p>
                  </td>
                </tr>

                <tr>
                  <td className="p-2 pl-6 text-left font-bold text-gray-800">Cellulare</td>
                  <td className="p-2 text-left">
                    <p>{domandaIscrizione.secondoRichiedenteCellulare}</p>
                  </td>
                </tr>
              </tbody>
            )}

            {/* Scuola */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  SCUOLA E FREQUENZA
                </th>
                <th scope="col" className="p-2 text-left"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Tipo di scuola
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left">
                  <p>{domandaIscrizione.tipoScuola}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Scuola
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left">
                  <p>{domandaIscrizione.nomeScuola}</p>
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                  Frequenza settimanale
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left space-x-2">
                  {domandaIscrizione.frequenzaLunedi && <Badge text="Lunedì" />}
                  {domandaIscrizione.frequenzaMartedi && <Badge text="Martedì" />}
                  {domandaIscrizione.frequenzaMercoledi && <Badge text="Mercoledì" />}
                  {domandaIscrizione.frequenzaGiovedi && <Badge text="Giovedì" />}
                  {domandaIscrizione.frequenzaVenerdi && <Badge text="Venerdì" />}
                </td>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold text-gray-800">
                  Mese di inizio frequenza
                </td>
                <td className="p-2 text-left">
                  <p>{getFullMonth(domandaIscrizione.meseInizioFrequenza)}</p>
                </td>
              </tr>
            </tbody>

            {/* Diete */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  DIETA SPECIALE
                </th>
                <th scope="col" className="p-2 text-left"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold align-middle text-gray-800">
                  Richiesta dieta speciale?
                </td>
                <td className="p-2 border-b border-solid border-gray-200 text-left">
                  {!!domandaIscrizione.flagDietaPersonalizzata ? 'Sì' : 'No'}
                </td>
              </tr>

              {!!domandaIscrizione.flagDietaPersonalizzata && (
                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Dieta speciale richiesta per intolleranze o disturbi alimentari?
                  </td>
                  <td className="p-2 border-b border-solid border-gray-200 text-left">
                    {domandaIscrizione.dietaPersonalizzataFlagIntolleranze ? 'Sì' : 'No'}
                  </td>
                </tr>
              )}

              {!!domandaIscrizione.flagDietaPersonalizzata &&
                !!domandaIscrizione.dietaPersonalizzataFlagIntolleranze && (
                  <tr>
                    <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                      Allegato certificato medico?
                    </td>
                    <td className="p-2 border-inherit border-b border-solid border-gray-200 text-left">
                      {domandaIscrizione.dietaPersonalizzataAllegatoCertificatoMedico ? (
                        <>
                          Sì&nbsp;:&nbsp;&nbsp;
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${API_URL}/iscrizioni-mensa/${
                              domandaIscrizione.id
                            }/riepilogo-domanda/allegati?tipologiaAllegato=certificato_medico&t=${encodeURIComponent(
                              localStorage.getItem('token') || ''
                            )}`}
                            className="underline uppercase text-blue-400 hover:text-blue-500 leading-7"
                          >
                            Vedi allegato
                          </a>
                        </>
                      ) : (
                        <div>
                          No <br />
                          <small>
                            (È necessario presentare il documento cartaceo allo <br /> sportello
                            almeno un mese prima dell'inizio della scuola)
                          </small>
                        </div>
                      )}
                    </td>
                  </tr>
                )}

              {!!domandaIscrizione.flagDietaPersonalizzata && (
                <tr>
                  <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                    Dieta speciale richiesta per motivi etico-religiosi?
                  </td>
                  <td className="p-2 border-b border-solid border-gray-200 text-left">
                    {domandaIscrizione.dietaPersonalizzataFlagMotiviEtici ? 'Sì' : 'No'}
                  </td>
                </tr>
              )}

              {!!domandaIscrizione.flagDietaPersonalizzata &&
                !!domandaIscrizione.dietaPersonalizzataFlagMotiviEtici && (
                  <tr>
                    <td className="p-2 pl-6 border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                      Motivo etico-religioso
                    </td>
                    <td className="p-2 border-b border-solid border-gray-200 text-left">
                      {domandaIscrizione.dietaPersonalizzataMotivoEtico === 'no-maiale' &&
                        'Menu senza carne di maiale'}
                      {domandaIscrizione.dietaPersonalizzataMotivoEtico === 'no-bovino' &&
                        'Menu senza carne bovina'}
                      {domandaIscrizione.dietaPersonalizzataMotivoEtico === 'no-maiale-bovino' &&
                        'Menu senza carne di maiale e di bovino'}
                      {domandaIscrizione.dietaPersonalizzataMotivoEtico === 'no-carne' &&
                        'Menu senza tutti i tipi di carne'}
                      {domandaIscrizione.dietaPersonalizzataMotivoEtico === 'menu-vegetariano' &&
                        'Menu vegetariano'}
                      {domandaIscrizione.dietaPersonalizzataMotivoEtico === 'menu-vegano' &&
                        'Menu vegano'}
                    </td>
                  </tr>
                )}
            </tbody>

            {/* ISEE da INPS */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  ISEE
                </th>
                <th scope="col" className="p-2 text-left"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold text-gray-800">Importo</td>
                <td className="p-2 text-left">
                  {domandaIscrizione?.iseeImporto
                    ? convertToItalianDecimal(domandaIscrizione.iseeImporto)
                    : 'Attenzione: ISEE non trovato. Presentare dichiarazione entro il 31 Agosto.'}
                </td>
              </tr>
            </tbody>

            {/* Riduzioni */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  RIDUZIONI
                </th>
                <th scope="col" className="p-2 text-left"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold text-gray-800">
                  Riduzione per disabilità certificata?
                </td>
                <td className="p-2 text-left">
                  {!!domandaIscrizione.riduzioniFlagDisabilita ? 'Sì' : 'No'}
                </td>
              </tr>
            </tbody>

            {/* Pagamento */}
            <tbody>
              <tr className="bg-blue-400 text-white">
                <th scope="col" className="p-2 pl-6 text-left">
                  MODALITÀ DI PAGAMENTO
                </th>
                <th scope="col" className="p-2 text-left"></th>
              </tr>

              <tr>
                <td className="p-2 pl-6 text-left font-bold text-gray-800">Tipo di pagamento</td>
                <td className="p-2 text-left">
                  {domandaIscrizione.pagamentoTipo === 'sdd'
                    ? 'Con domiciliazione bancaria (SDD)'
                    : 'Senza domiciliazione bancaria'}
                </td>
              </tr>

              {domandaIscrizione.pagamentoTipo === 'sdd' && (
                <>
                  <tr>
                    <td className="p-2 pl-6 border-t border-b border-solid border-gray-200 text-left font-bold text-gray-800">
                      Intestatario o cointestatario
                    </td>
                    <td className="p-2 text-left border-t border-b border-solid border-gray-200">
                      {domandaIscrizione.primoRichiedenteCognome}{' '}
                      {domandaIscrizione.primoRichiedenteNome}
                    </td>
                  </tr>

                  <tr>
                    <td className="p-2 pl-6 text-left font-bold text-gray-800">IBAN</td>
                    <td className="p-2 text-left">{domandaIscrizione.pagamentoSddIban}</td>
                  </tr>
                </>
              )}
            </tbody>
            {/* Altri dati */}
            {domandaIscrizione.altriDati && altriDati && (
              <tbody>
                <tr className="bg-blue-400 text-white">
                  <th scope="col" className="p-2 pl-6 text-left">
                    ALTRI DATI
                  </th>
                  <th scope="col" className="p-2 text-left"></th>
                </tr>

                {altriDati.map(([key, val], index) => {
                  return (
                    <tr key={index}>
                      <td
                        className={classNames(
                          'p-2 pl-6 text-left font-bold text-gray-800',
                          index + 1 === altriDati.length
                            ? ''
                            : 'border-b border-solid border-gray-200 '
                        )}
                      >
                        {renderString(key)}
                      </td>
                      <td
                        className={classNames(
                          'p-2 text-left font-bold text-gray-800',
                          index + 1 === altriDati.length
                            ? ''
                            : 'border-b border-solid border-gray-200 '
                        )}
                      >
                        {val ? <FiCheckCircle /> : <FiX />}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            )}
          </table>
          {/* <h3 className="pt-20 pb-4 text-gray-700 text-2xl font-semibold">
            DICHIARAZIONE PER L'AMMISSIONE AL SERVIZIO
          </h3>

          <div className="text-sm py-8 px-6 border border-gray-400 bg-gray-100 mx-auto">
            <a
              onClick={() =>
                setDichiarazioniScaricate((curr) => ({ ...curr, ammissioneServizio: true }))
              }
              href={`${API_URL}/iscrizioni-mensa/pdf?fileName=dichiarazioni_finali.pdf&t=${encodeURIComponent(
                localStorage.getItem('token') || ''
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 flex items-center gap-x-2"
            >
              <FiDownload />
              Dichiarazione per l'ammissione al servizio
            </a>
          </div>
          {domandaIscrizione.pagamentoTipo === 'sdd' && (
            <>
              <h3 className="pt-10 pb-4 text-gray-700 text-2xl font-semibold">
                MANDATO SEPA DIRECT DEBIT
              </h3>

              <div className="text-sm py-8 px-6 border border-gray-400 bg-gray-100 mx-auto">
                <a
                  onClick={() => setDichiarazioniScaricate((curr) => ({ ...curr, sdd: true }))}
                  href={`${API_URL}/iscrizioni-mensa/pdf?fileName=dichiarazione_sdd.pdf&t=${encodeURIComponent(
                    localStorage.getItem('token') || ''
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:text-blue-800 flex items-center gap-x-2"
                >
                  <FiDownload />
                  Mandato SEPA Direct Debit
                </a>
              </div>
            </>
          )} */}

          <div className="check-approvazione mt-12">
            <div className="flex space-x-2 items-center tesxt-sm">
              <input
                id="accettazioneDichiarazioni"
                type="checkbox"
                className="cursor-pointer"
                checked={domandaIscrizione.accettazioneDichiarazioni}
                disabled
              />
              <label htmlFor="accettazioneDichiarazioni">
                Accettazione dichiarazione per l'ammissione al servizio
                {!domandaIscrizione.accettazioneDichiarazioni && (
                  <>
                    <br />{' '}
                    <span className="text-red-600 text-sm">
                      È necessario che il genitore / legale rappresentante visioni e accetti le
                      dichiarazioni per l'ammissione al servizio
                    </span>
                  </>
                )}
              </label>
            </div>
          </div>
          <div className="check-approvazione mt-4">
            <div className="flex space-x-2 items-center tesxt-sm">
              <input
                id="accettazionePrivacy"
                type="checkbox"
                className="cursor-pointer"
                checked={domandaIscrizione.accettazionePrivacy}
                disabled
              />
              <label htmlFor="accettazionePrivacy">
                Accettazione informativa sul trattamento dei dati personali
                {!domandaIscrizione.accettazionePrivacy && (
                  <>
                    <br />{' '}
                    <span className="text-red-600 text-sm">
                      È necessario che il genitore / legale rappresentante visioni e accetti l'
                      informativa sul trattamento dei dati personali
                    </span>
                  </>
                )}
              </label>
            </div>
          </div>

          {domandaIscrizione.pagamentoTipo === 'sdd' && (
            <div className="check-approvazione mt-4">
              <div className="flex space-x-2 items-center tesxt-sm">
                <input
                  id="dichiarazioniLette"
                  type="checkbox"
                  className="cursor-pointer"
                  checked={domandaIscrizione.accettazioneSdd}
                  disabled
                />
                <label htmlFor="dichiarazioniLette">
                  Accettazione autorizzazione ad addebito avvisi di pagamento per il servizio sul
                  conto corrente come da mandato SDD
                  {!domandaIscrizione.accettazionePrivacy && (
                    <>
                      <br />{' '}
                      <span className="text-red-600 text-sm">
                        È necessario che il genitore / legale rappresentante visioni e accetti il
                        mandato SEPA Direct Debit
                      </span>
                    </>
                  )}
                </label>
              </div>
            </div>
          )}

          {/* <h3 className="pt-10 pb-4 text-gray-700 text-2xl font-semibold">
            INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI
          </h3>
          <div className="text-sm py-8 px-6 border border-gray-400 bg-gray-100 mx-auto">
            <a
              onClick={() =>
                setDichiarazioniScaricate((curr) => ({ ...curr, trattamentoDatiPersonali: true }))
              }
              href={`${API_URL}/iscrizioni-mensa/pdf?fileName=informativa_privacy.pdf&t=${encodeURIComponent(
                localStorage.getItem('token') || ''
              )}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:text-blue-800 flex items-center gap-x-2"
            >
              {' '}
              <FiDownload />
              Informativa sul trattamento dei dati personali
            </a>
          </div> */}
        </div>

        {/* {domandaIscrizione.stato &&
          !(
            domandaIscrizione.stato.toLowerCase() === 'inviata' ||
            domandaIscrizione.stato.toLowerCase() === 'letta' ||
            domandaIscrizione.stato.toLowerCase() === 'approvata'
          ) && (
            <div className="check-approvazione">
              <div className="flex space-x-2 items-center text-sm">
                <input
                  id="dichiarazioniLette"
                  type="checkbox"
                  className="form-checkbox h-6 w-6"
                  checked={dichiarazioniLette}
                  onChange={(e) => {
                    if (
                      domandaIscrizione.pagamentoTipo === 'sdd' &&
                      !(
                        dichiarazioniScaricate.ammissioneServizio &&
                        dichiarazioniScaricate.trattamentoDatiPersonali &&
                        dichiarazioniScaricate.sdd
                      )
                    ) {
                      setCheckApprovazioneError(true)
                    } else if (
                      !(
                        dichiarazioniScaricate.ammissioneServizio &&
                        dichiarazioniScaricate.trattamentoDatiPersonali
                      )
                    ) {
                      setCheckApprovazioneError(true)
                    } else {
                      setCheckApprovazioneError(false)
                      setDichiarazioniLette(e.target.checked)
                    }
                  }}
                />
                <label htmlFor="dichiarazioniLette">
                  Dichiaro di aver preso visione della dichiarazione per l'ammissione <br />
                  al servizio e di acconsentire al trattamento dei dati
                  {checkApprovazioneError && (
                    <>
                      <br />{' '}
                      <span className="text-red-600">
                        È necessario scaricare e leggere tutte le dichiarazioni e informative
                        relative al servizio prima di dichiarare la presa visione.
                      </span>
                    </>
                  )}
                </label>
              </div>
            </div>
          )} */}
        <div className="flex justify-between mt-20">
          <Link
            to={`/app/iscrizioni`}
            className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded"
          >
            « Torna indietro
          </Link>

          {domandaIscrizione.stato !== 'GIA_ISCRITTO' && (
            <div className="flex gap-4">
              {domandaIscrizione.stato !== 'APPROVATA' && (
                <button
                  className={classNames(
                    'block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded'
                  )}
                  onClick={() => {
                    updateIscrizione
                      .mutateAsync({
                        iscrizioneId: domandaIscrizione.id,
                        servizio: 'mensa',
                        payload: {
                          stato: 'APPROVATA',
                          dataApprovazione: moment().format(),
                        },
                      })
                      .then((response) => {
                        navigate('/app/iscrizioni')
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                >
                  Approva
                </button>
              )}
              {domandaIscrizione.stato !== 'IN_COMPILAZIONE' && (
                <button
                  className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-blue-700 rounded"
                  onClick={() =>
                    updateIscrizione
                      .mutateAsync({
                        iscrizioneId: domandaIscrizione.id,
                        servizio: 'mensa',
                        payload: { stato: 'IN_COMPILAZIONE' },
                      })
                      .then((response) => {
                        navigate('/app/iscrizioni')
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  }
                >
                  Riporta in compilazione
                </button>
              )}

              {domandaIscrizione.stato !== 'RIFIUTATA' && (
                <button
                  className="block bg-red-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 p-2 transition-colors duration-150 hover:bg-red-600 rounded"
                  onClick={() => setOpenModal(true)}
                >
                  Rifiuta iscrizione
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={openModal}
        onAfterOpen={() => setValue('motivazioneRifiuto', domandaIscrizione?.motivazioneRifiuto)}
        onRequestClose={() => {
          setOpenModal(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="p-8" style={{ width: '32rem' }}>
          <form onSubmit={handleSubmit(rifiutoIscrizione)} autoComplete="off">
            <div className="flex flex-col w-full">
              <FormField
                fieldName="motivazioneRifiuto"
                label="Motivazione rifiuto iscrizione"
                type={formFieldInputType.Textarea}
                rows={3}
                validation={{
                  maxLength: { value: 500, message: 'Max 500 caratteri' },
                }}
                register={register}
                errors={errors}
                className="mb-3"
                watch={watch}
              />
              <button
                type="submit"
                className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
              >
                Rifiuta iscrizione
              </button>
            </div>
          </form>
        </div>
      </ReactModal>
    </div>
  )
}

const getFullMonth = (monthNumer: number) => {
  switch (monthNumer) {
    case 1:
      return 'Gennaio'
    case 2:
      return 'Febbraio'
    case 3:
      return 'Marzo'
    case 4:
      return 'Aprile'
    case 5:
      return 'Maggio'
    case 6:
      return 'Giugno'
    case 7:
      return 'Luglio'
    case 8:
      return 'Agosto'
    case 9:
      return 'Settembre'
    case 10:
      return 'Ottobre'
    case 11:
      return 'Novembre'
    case 12:
      return 'Dicembre'
  }
}
function renderString(input: string) {
  let words = input.split(/(?=[A-Z])/)
  words = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })
  return words.join(' ')
}

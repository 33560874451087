import React, { useContext, useState } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import { FaTimes, FaDownload, FaHistory } from 'react-icons/fa'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import ServizioContext from '../../../context/ServizioContext'

import useAlunni from '../../../apiHooks/queries/useAlunni'

import {
  selectAnagraficaRicerca,
  resetAll,
  changeIdSel,
  resetIdSel,
  changeCognomeSel,
  resetCognomeSel,
  changeNomeSel,
  resetNomeSel,
  changeCodiceFiscaleSel,
  resetCodiceFiscaleSel,
  changeSoloAnnoCorrente,
  changeCognomeIntestatarioSel,
  resetCognomeIntestatarioSel,
  changeCodiceSddSel,
  resetCodiceSddSel,
  changeScuolaIdSel,
  resetScuolaIdSel,
  changeDietaIdSel,
  resetDietaIdSel,
  changeEmailIntestatariSel,
  resetEmailIntestatariSel,
  changeIscrizioneDopoIlSel,
  resetIscrizioneDopoIlSel,
  changeIscrizionePrimaDelSel,
  resetIscrizionePrimaDelSel,
  changePagamentoSddSel,
  resetPagamentoSddSel,
  changeBolletteSospeseSel,
  resetBolletteSospeseSel,
  changeRitiratoSel,
  resetRitiratoSel,
  changeInvioEmailSel,
  resetInvioEmailSel,
  changeInizioServizioDopoIlSel,
  resetInizioServizioDopoIlSel,
  changeInizioServizioPrimaDelSel,
  resetInizioServizioPrimaDelSel,
} from '../../../redux/modules/anagraficaRicerca'

import { getSesso } from '../../../lib/codiceFiscale'
import useDebounce from '../../../lib/useDebounce'

import Loading from '../../../components/Loading'

import imgAlunno from '../../../images/alunno.svg'
import imgAlunna from '../../../images/alunna.svg'
import useScuole from '../../../apiHooks/queries/useScuole'
import useDieteByServizioId from '../../../apiHooks/queries/useDieteByServizioId'
import moment from 'moment'

interface Scuola {
  id: number
  nome: string
  codice: string
  codiceImport: string
  clienteId: number
  tipo: string
  servizi: number[]
}
interface Dieta {
  id: number
  tipoDieta: string
  nomeDieta: string
}

const Ricerca: React.FC<RouteComponentProps> = () => {
  const [ricercaAvanzataOpen, setRicercaAvanzataOpen] = useState(false)

  //===================
  // REDUX
  //===================
  const anagraficaRicerca = useSelector(selectAnagraficaRicerca)
  const {
    anagraficaRicerca: {
      idSel,
      cognomeSel,
      nomeSel,
      codiceFiscaleSel,
      soloAnnoCorrente,
      cognomeIntestatarioSel,
      codiceSddSel,
      scuolaIdSel,
      dietaIdSel,
      emailIntestatariSel,
      iscrizioneDopoIlSel,
      iscrizionePrimaDelSel,
      pagamentoSddSel,
      bolletteSospeseSel,
      ritiratoSel,
      invioEmailSel,
      inizioServizioDopoIlSel,
      inizioServizioPrimaDelSel,
    },
  } = anagraficaRicerca
  const dispatch = useDispatch()

  //===================
  // CONTEXT
  //===================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  const idDebounced: string = useDebounce(idSel, 500)
  const cognomeDebounced: string = useDebounce(cognomeSel, 500)
  const nomeDebounced: string = useDebounce(nomeSel, 500)
  const codiceFiscaleDebounced: string = useDebounce(codiceFiscaleSel, 500)
  const cognomeIntestatarioDebounced: string = useDebounce(cognomeIntestatarioSel, 500)
  const codiceSddDebounced: string = useDebounce(codiceSddSel, 500)
  const scuolaDebounced: string = useDebounce(scuolaIdSel, 500)
  const dietaDebounced: string = useDebounce(dietaIdSel, 500)
  const emailIntestatariDebounced: string = useDebounce(emailIntestatariSel, 500)
  const iscrizioneDopoIlDebounced: string = useDebounce(iscrizioneDopoIlSel, 500)
  const iscrizionePrimaDelDebounced: string = useDebounce(iscrizionePrimaDelSel, 500)
  const pagamentoSddDebounced: string = useDebounce(pagamentoSddSel, 500)
  const bolletteSospeseDebounced: string = useDebounce(bolletteSospeseSel, 500)
  const ritiratoDebounced: string = useDebounce(ritiratoSel, 500)
  const invioEmailDebounced: string = useDebounce(invioEmailSel, 500)
  const inizioServizioDopoIlDebounced: string = useDebounce(inizioServizioDopoIlSel, 500)
  const inizioServizioPrimaDelDebounced: string = useDebounce(inizioServizioPrimaDelSel, 500)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: scuole, error: errorScuole, isFetching: isFetchingScuole } = useScuole()
  const { data: diete = [] } = useDieteByServizioId(servizioId)

  const {
    data: alunni,
    error: errorAlunno,
    isFetching: isFetchingAlunno,
  } = useAlunni({
    servizioId,
    id: idDebounced,
    cognome: cognomeDebounced,
    nome: nomeDebounced,
    codiceFiscale: codiceFiscaleDebounced,
    annoScolastico: soloAnnoCorrente ? annoScolastico : '',
    cognomeIntestatario: cognomeIntestatarioDebounced,
    codiceSdd: codiceSddDebounced,
    scuolaId: scuolaDebounced,
    dietaId: dietaDebounced,
    emailIntestatari: emailIntestatariDebounced,
    iscrizioneDopoIl: iscrizioneDopoIlDebounced,
    iscrizionePrimaDel: iscrizionePrimaDelDebounced,
    pagamentoSdd: pagamentoSddDebounced,
    bolletteSospese: bolletteSospeseDebounced,
    ritirato: ritiratoDebounced,
    invioEmail: invioEmailDebounced,
    inizioServizioDopoIl: inizioServizioDopoIlDebounced,
    inizioServizioPrimaDel: inizioServizioPrimaDelDebounced,
  })

  //===================
  // EVENT HANDLERS
  //===================
  const handleChangeSoloAnnoCorrente = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSoloAnnoCorrente(event.target.checked))
  }

  if (isFetchingScuole) {
    return <Loading className="py-12" />
  }

  if (errorScuole || errorAlunno) {
    return <span>Si è verificato un errore</span>
  }

  return (
    <div className="pb-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* SOLO ANNO... */}
        <label className="flex items-center font-normal text-gray-600">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={soloAnnoCorrente}
            onChange={handleChangeSoloAnnoCorrente}
          />
          <span className="ml-2">Solo {annoScolastico}</span>
        </label>

        <div className="grid grid-cols-6 gap-4 py-4">
          {/* COGNOME ALUNNO */}
          <div className="relative flex items-center">
            <div>
              <input
                type="text"
                className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                placeholder="Cognome"
                value={cognomeSel}
                onChange={(e) => dispatch(changeCognomeSel(e.target.value))}
              />
            </div>
            {!!cognomeSel && (
              <div
                className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                onClick={() => {
                  dispatch(resetCognomeSel())
                }}
              >
                <FaTimes />
              </div>
            )}
          </div>
          {/* NOME ALUNNO */}
          <div className="relative flex items-center">
            <div>
              <input
                type="text"
                className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                placeholder="Nome"
                value={nomeSel}
                onChange={(e) => dispatch(changeNomeSel(e.target.value))}
              />
            </div>
            {!!nomeSel && (
              <div
                className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                onClick={() => {
                  dispatch(resetNomeSel())
                }}
              >
                <FaTimes />
              </div>
            )}
          </div>
          {/* CODICE FISCALE */}
          <div className="relative flex items-center">
            <div>
              <input
                type="text"
                className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                placeholder="Codice fiscale"
                value={codiceFiscaleSel}
                onChange={(e) => dispatch(changeCodiceFiscaleSel(e.target.value))}
              />
            </div>
            {!!codiceFiscaleSel && (
              <div
                className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                onClick={() => {
                  dispatch(resetCodiceFiscaleSel())
                }}
              >
                <FaTimes />
              </div>
            )}
          </div>
          {/* COGNOME INTESTATARI */}
          <div className="relative flex items-center">
            <div>
              <input
                type="text"
                className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                placeholder="Cognome intestatari"
                value={cognomeIntestatarioSel}
                onChange={(e) => dispatch(changeCognomeIntestatarioSel(e.target.value))}
              />
            </div>
            {!!cognomeIntestatarioSel && (
              <div
                className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                onClick={() => {
                  dispatch(resetCognomeIntestatarioSel())
                }}
              >
                <FaTimes />
              </div>
            )}
          </div>
          {/* SCUOLA */}
          <div className="relative flex items-center">
            <div>
              <select
                className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                placeholder="Scuola"
                value={scuolaIdSel}
                onChange={(e) => dispatch(changeScuolaIdSel(e.target.value))}
              >
                <option value="">Tutte le scuole</option>
                {scuole.map((scuola: Scuola) => {
                  return (
                    <option key={scuola.id} value={scuola.id}>
                      {scuola.nome}
                    </option>
                  )
                })}
              </select>
            </div>
            {!!scuolaIdSel && (
              <div
                className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                onClick={() => {
                  dispatch(resetScuolaIdSel())
                }}
              >
                <FaTimes />
              </div>
            )}
          </div>
          {/* ID ALUNNO */}
          <div className="relative flex items-center">
            <div>
              <input
                type="text"
                className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                placeholder="Id alunno"
                value={idSel}
                onChange={(e) => dispatch(changeIdSel(e.target.value))}
              />
            </div>
            {!!idSel && (
              <div
                className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                onClick={() => {
                  dispatch(resetIdSel())
                }}
              >
                <FaTimes />
              </div>
            )}
          </div>
        </div>

        <button
          type="button"
          className={classNames(
            'block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 transition-colors duration-150 hover:bg-blue-700 rounded hover:shadow-xl mb-3'
          )}
          onClick={() => setRicercaAvanzataOpen(!ricercaAvanzataOpen)}
        >
          {ricercaAvanzataOpen ? 'Nascondi' : 'Mostra'} ricerca avanzata
        </button>

        {!!ricercaAvanzataOpen && (
          <div className="p-4 bg-blue-100 border border-blue-200 rounded">
            {/* <h2 className="pb-4">Ricerca avanzata</h2> */}
            <div className="grid grid-cols-6 gap-2">
              {/* EMAIL INTESTATARI */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="emailIntestatari" className="text-sm text-gray-600">
                    Email intestatari
                  </label>
                  <input
                    id="emailIntestatari"
                    type="text"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Email intestatari"
                    value={emailIntestatariSel}
                    onChange={(e) => dispatch(changeEmailIntestatariSel(e.target.value))}
                  />
                </div>
                {!!emailIntestatariSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetEmailIntestatariSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* PAGAMENTO SDD */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="pagamentoSdd" className="text-sm text-gray-600">
                    Pagamento SDD
                  </label>
                  <select
                    id="pagamentoSdd"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Pagamento SDD"
                    value={pagamentoSddSel}
                    onChange={(e) => dispatch(changePagamentoSddSel(e.target.value))}
                  >
                    <option value="null">--Seleziona--</option>
                    <option value="true">Sì</option>
                    <option value="false">No</option>
                  </select>
                </div>
                {!!pagamentoSddSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetPagamentoSddSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* CODICE SDD */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="codiceSdd" className="text-sm text-gray-600">
                    Codice SDD
                  </label>
                  <input
                    id="codiceSdd"
                    type="text"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Codice SDD"
                    value={codiceSddSel}
                    onChange={(e) => dispatch(changeCodiceSddSel(e.target.value))}
                  />
                </div>
                {!!codiceSddSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetCodiceSddSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* BOLLETTE SOSPESE */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="bolletteSospese" className="text-sm text-gray-600">
                    Bollette sospese
                  </label>
                  <select
                    id="bolletteSospese"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Pagamento SDD"
                    value={bolletteSospeseSel}
                    onChange={(e) => dispatch(changeBolletteSospeseSel(e.target.value))}
                  >
                    <option value="null">--Seleziona--</option>
                    <option value="true">Sì</option>
                    <option value="false">No</option>
                  </select>
                </div>
                {!!bolletteSospeseSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetBolletteSospeseSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* INVIO EMAIL */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="invioEmail" className="text-sm text-gray-600">
                    Invio email
                  </label>
                  <select
                    id="invioEmail"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Pagamento SDD"
                    value={invioEmailSel}
                    onChange={(e) => dispatch(changeInvioEmailSel(e.target.value))}
                  >
                    <option value="null">--Seleziona--</option>
                    <option value="true">Sì</option>
                    <option value="false">No</option>
                  </select>
                </div>
                {!!invioEmailSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetInvioEmailSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* DIETA */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="dieta" className="text-sm text-gray-600">
                    Dieta
                  </label>
                  <select
                    id="dieta"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Dieta"
                    value={dietaIdSel}
                    onChange={(e) => dispatch(changeDietaIdSel(e.target.value))}
                  >
                    <option value="">--Seleziona--</option>
                    {diete.map((dieta: Dieta) => {
                      return (
                        <option key={dieta.id} value={dieta.id}>
                          {dieta.tipoDieta + ' - ' + dieta.nomeDieta}
                        </option>
                      )
                    })}
                  </select>
                </div>
                {!!dietaIdSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetDietaIdSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2 mt-2">
              {/* DATA ISCRIZIONE DOPO IL... (DATAMIN) */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="dataIscrizioneMin" className="text-sm text-gray-600">
                    Iscrizione dopo il...
                  </label>
                  <input
                    id="dataIscrizioneMin"
                    type="date"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Iscritto dopo il..."
                    value={iscrizioneDopoIlSel}
                    onChange={(e) => dispatch(changeIscrizioneDopoIlSel(e.target.value))}
                  />
                </div>
                {!!iscrizioneDopoIlSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetIscrizioneDopoIlSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* DATA ISCRIZIONE PRIMA DEL... (DATAMAX) */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="dataIscrizioneMax" className="text-sm text-gray-600">
                    Iscrizione prima del...
                  </label>
                  <input
                    id="dataIscrizioneMax"
                    type="date"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Iscritto prima del..."
                    value={iscrizionePrimaDelSel}
                    onChange={(e) => dispatch(changeIscrizionePrimaDelSel(e.target.value))}
                  />
                </div>
                {!!iscrizionePrimaDelSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetIscrizionePrimaDelSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* RITIRATO */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="ritirato" className="text-sm text-gray-600">
                    Ritirato
                  </label>
                  <select
                    id="ritirato"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Pagamento SDD"
                    value={ritiratoSel}
                    onChange={(e) => dispatch(changeRitiratoSel(e.target.value))}
                  >
                    <option value="null">--Seleziona--</option>
                    <option value="true">Sì</option>
                    <option value="false">No</option>
                  </select>
                </div>
                {!!ritiratoSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetRitiratoSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* DATA SERVIZIO DOPO IL... (DATAMIN) */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="dataInizioServizioMin" className="text-sm text-gray-600">
                    InizioServizio dopo il...
                  </label>
                  <input
                    id="dataInizioServizioMin"
                    type="date"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Inizio servizio dopo il..."
                    value={inizioServizioDopoIlSel}
                    onChange={(e) => dispatch(changeInizioServizioDopoIlSel(e.target.value))}
                  />
                </div>
                {!!inizioServizioDopoIlSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetInizioServizioDopoIlSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* DATA SERVIZIO PRIMA DEL... (DATAMAX) */}
              <div className="relative flex items-center">
                <div>
                  <label htmlFor="dataInizioServizioMax" className="text-sm text-gray-600">
                    InizioServizio prima del...
                  </label>
                  <input
                    id="dataInizioServizioMax"
                    type="date"
                    className="py-2 pl-4 pr-8 w-full text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
                    placeholder="Inizio servizio prima del..."
                    value={inizioServizioPrimaDelSel}
                    onChange={(e) => dispatch(changeInizioServizioPrimaDelSel(e.target.value))}
                  />
                </div>
                {!!inizioServizioPrimaDelSel && (
                  <div
                    className="absolute bottom-0 right-0 p-3 text-gray-500 cursor-pointer"
                    onClick={() => {
                      dispatch(resetInizioServizioPrimaDelSel())
                    }}
                  >
                    <FaTimes />
                  </div>
                )}
              </div>
              {/* RESET RICERCA */}
              <button
                type="reset"
                className={classNames(
                  'flex items-center justify-center space-x-2 self-end bg-orange-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 transition-colors duration-150 hover:bg-orange-700 rounded hover:shadow-xl mb-0.5'
                )}
                onClick={() => {
                  dispatch(resetAll())
                }}
              >
                <FaHistory /> <span>Reset ricerca</span>
              </button>
            </div>
          </div>
        )}

        {isFetchingAlunno && <Loading className="py-12" />}
        {alunni && (
          <div className="flex items-center justify-between mt-6">
            <h1 className="text-xl font-semibold">
              {alunni && alunni.length === 0
                ? 'Nessun risultato trovato. Prova con criteri di ricerca diversi'
                : alunni.length === 1
                ? '1 risultato trovato'
                : `${alunni.length} risultati trovati`}
            </h1>
            <CSVLink
              data={alunni.map((risultato: any) => ({
                id: risultato.alunno.id,
                cognome: risultato.alunno.cognome,
                nome: risultato.alunno.nome,
                codiceFiscale: risultato.alunno.codiceFiscale,
                scuola: risultato.sezione.scuolaNome,
                scuolaTipo: risultato.sezione.scuolaTipo,
                annoScolastico: risultato.sezione.annoScolastico,
                classe: risultato.sezione.classe,
                sezione: risultato.sezione.sezione,
              }))}
              filename="Esportazione alunni"
              className={classNames(
                'flex items-center space-x-2 bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 transition-colors duration-150 hover:bg-blue-700 rounded hover:shadow-xl mb-3'
              )}
            >
              <FaDownload /> <span>Esporta CSV</span>
            </CSVLink>
          </div>
        )}
        <div className="lg:flex lg:-mx-4 flex-wrap">
          {alunni &&
            alunni.map((alunnoRicerca: any, index: number) => {
              const { alunno, sezione, dataRitiro } = alunnoRicerca
              const sesso = alunno && getSesso(alunno.codiceFiscale || '')
              const ritirato = !!(dataRitiro && moment(dataRitiro).isBefore(moment()))

              return (
                <Link key={index} to={`../alunno/${alunno.id}`} className="lg:w-1/2 lg:px-4 mt-6">
                  <div className="bg-white shadow hover:shadow-lg border-gray-200 rounded-lg py-5 px-5 flex justify-between items-start">
                    <div
                      className={classNames('flex', {
                        'filter grayscale': ritirato,
                      })}
                    >
                      <img
                        src={sesso === 'F' ? imgAlunna : imgAlunno}
                        alt={`${alunno.cognome} ${alunno.nome}`}
                        className={classNames(
                          'w-16 h-16 p-1 rounded-full mr-6 border',
                          { 'bg-red-100 border-red-200': sesso === 'F' },
                          {
                            'bg-blue-100 border-blue-200': sesso !== 'F',
                          }
                        )}
                      />
                      <div className="flex flex-col items-start">
                        <h2
                          className={`text-lg mb-2 font-semibold ${
                            !alunno.nome ? 'text-red-600' : ritirato ? 'text-gray-400' : ''
                          }`}
                        >
                          {alunno.cognome} {alunno.nome}
                        </h2>

                        <div className="text-gray-600">{sezione.scuolaNome}</div>

                        <div className="text-purple-500 text-sm font-semibold">
                          {sezione.classe}
                          <sup>a</sup> {sezione.sezione}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className={classNames(
                          'py-1 px-2 rounded text-sm font-medium whitespace-nowrap mb-2',
                          {
                            'bg-blue-100 text-blue-600': sezione.annoScolastico === annoScolastico,
                          },
                          {
                            'bg-gray-100 text-gray-600': sezione.annoScolastico !== annoScolastico,
                          }
                        )}
                      >
                        {sezione.annoScolastico}
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Ricerca

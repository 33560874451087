import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const exportIscrizioniToFruizioni = async (
  tipoIscrizione: string,
  ids: string[],
  annoScolastico: string
) => {
  const { data } = await axios.post(`/iscrizioni-${tipoIscrizione}/export_to_fruizione/`, {
    ids,
    anno_scolastico: annoScolastico,
  })
  return data
}

const useExportIscrizioniToFruizioni = () => {
  return useMutation(
    ({
      tipoIscrizione,
      ids,
      annoScolastico,
    }: {
      tipoIscrizione: string
      ids: string[]
      annoScolastico: string
    }) => {
      return exportIscrizioniToFruizioni(tipoIscrizione, ids, annoScolastico)
    },
    {
      onSuccess: (data: any, variables: any) => {
        const { hide } = cogoToast.success(
          <div style={{ width: '800px', maxHeight: '400px' }} className="overflow-y-auto">
            <span dangerouslySetInnerHTML={{ __html: data.replace(/\n/gm, '<br />') }}></span>
            <br />
            <button
              type="button"
              onClick={() => {
                hide!()
              }}
              className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
            >
              CHIUDI
            </button>
          </div>,
          {
            hideAfter: 0,
            // onClick: () => {
            //   hide!()
            // },
          }
        )
      },
      onError: () => {},
    }
  )
}

export default useExportIscrizioniToFruizioni

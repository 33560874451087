import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeCucina {
  servizioId: number
  dataPresenze: string
  annoScolastico:string
}

const getPresenzeDataCucina = async (_: string, { servizioId, dataPresenze, annoScolastico }: IPresenzeCucina) => {
  const { data } = await axios.get(`/servizi/${servizioId}/presenze-totali-scuole/?data=${dataPresenze}&annoScolastico=${annoScolastico}`)
  return data
}

const usePresenzeDataCucina = ({ servizioId, dataPresenze, annoScolastico }: IPresenzeCucina) => {
  return useQuery(
    ['presenzeDataCucina', { servizioId, dataPresenze, annoScolastico }],
    () => getPresenzeDataCucina('presenzeDataCucina', { servizioId, dataPresenze, annoScolastico }),
    { enabled: !!servizioId && !!dataPresenze && !!annoScolastico}
  )
}

export default usePresenzeDataCucina

import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'

const deleteDieta = async (servizioId: number, dietaId: number) => {
  const { data } = await axios.delete(`/servizi/${servizioId}/diete/${dietaId}/`)
  return data
}

const useDeleteDieta = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ servizioId, dietaId }: { servizioId: number; dietaId: number }) => {
      return deleteDieta(servizioId, dietaId)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('dieteByServizioId')
        cogoToast.success('Dieta eliminata')
      },
      onError: () => {},
    }
  )
}

export default useDeleteDieta

import { useQuery } from 'react-query'
import axios from 'axios'

const getAvvisiPagamentoByFruizioneId = async (_: string, fruizioneId: number) => {
  const { data } = await axios.get(`/fruizioni/${fruizioneId}/avvisi-pagamento`)

  return data.results
}

const useAvvisiPagamentoByFruizioneId = (fruizioneId: number) => {
  return useQuery(
    ['avvisiPagamentoByFruizioneId', fruizioneId],
    () => getAvvisiPagamentoByFruizioneId('avvisiPagamentoByFruizioneId', fruizioneId),
    { enabled: !!fruizioneId }
  )
}

export default useAvvisiPagamentoByFruizioneId

import React from 'react'
import IBAN from 'iban'
import classNames from 'classnames'
import FormField, { InputType } from '../../../../components/FormField'
import { QUOTA_COMPARTECIPAZIONE_OPTIONS } from '../../../../lib/quotaCompartecipazioneOptions'

interface FormSingoloServizioProps {
  register: any
  errors: any
  control?: any
  watch?: any
  setValue?: any
  intestatari: any[]
  sezioneBidella: any
}

const ComuneBergamoMensa: React.FC<FormSingoloServizioProps> = ({
  register,
  errors,
  control,
  watch,
  setValue,
  intestatari,
  sezioneBidella,
}) => {
  return (
    <div>
      <section>
        {/* <h1 className="text-2xl mb-2 leading-tight">Mensa</h1>
        <hr className="border-blue-200 border-t-2 my-8"></hr> */}
        <div className="text-gray-900 mb-5 font-semibold leading-none">Intestatario bolletta</div>
        <FormField
          fieldName="intestatario"
          type={InputType.Select}
          options={intestatari.map((intestatario: any) => ({
            value: intestatario.id,
            label: `${intestatario.nome} ${intestatario.cognome}`,
          }))}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <div className="text-gray-900 mt-6 mb-5 font-semibold leading-none">Pagamento</div>
        <FormField
          fieldName="quotaCompartecipazione"
          label="Quota compartecipazione"
          type={InputType.Select}
          options={QUOTA_COMPARTECIPAZIONE_OPTIONS}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="pagamentoSospeso"
          label="Bollette sospese"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <div className="text-gray-900 mt-6 mb-5 font-semibold leading-none">Pagamento SDD</div>
        <FormField
          fieldName="pagamentoSdd"
          label="Pagamento SDD"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="iban"
          label="IBAN"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
          validation={{
            validate: (value: string) =>
              !!watch('pagamentoSdd')
                ? (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido'
                : !value || (!!value && IBAN.isValid(value)) || 'Inserire un IBAN valido',
          }}
        />
        <FormField
          fieldName="codiceSdd"
          label="Codice SDD"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRichiestaSdd"
          label="Data richiesta SDD"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Giorni di pasto previsti
        </div>
        <div className="flex items-end mb-4">
          <FormField
            fieldName="lun"
            label="L"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="mar"
            label="M"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="mer"
            label="M"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="gio"
            label="G"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="ven"
            label="V"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <div className="leading-none text-xs text-blue-600 uppercase">Ufficiale</div>
        </div>
        <div className="flex items-end">
          <FormField
            fieldName="lunBidella"
            label="L"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="marBidella"
            label="M"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="merBidella"
            label="M"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="gioBidella"
            label="G"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="venBidella"
            label="V"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <div className="leading-none text-xs text-pink-600 uppercase">Da operatore</div>
        </div>
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Agevolazioni</div>
        <FormField
          fieldName="flagIseeMinoreDi5000"
          label="ISEE < 5000"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="flagSecondoFiglio"
          label="Secondo fratello"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="flagDisabile"
          label="Portatore di handicap"
          type={InputType.Boolean}
          register={register}
          errors={errors}
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Date iscrizione, inizio ed eventuale ritiro
        </div>
        <FormField
          fieldName="dataIscrizione"
          label="Data iscrizione"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataInizio"
          label="Inizio servizio"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiro"
          label="Data ritiro"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiroBidella"
          label="Data ritiro operatore"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName="text-pink-600"
          inputClassName="text-pink-600"
        />
        <FormField
          fieldName="isRitiroConRestituzioneQuota"
          label="Motivo valido (restituzione quota iscrizione)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rimborsi e Maggiorazioni
        </div>
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaImporto"
          label="Importo una tantum Prossima bolletta"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaDescrizione"
          label="Descrizione causale una tantum"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaImporto"
          label="Importo mensile"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaDescrizione"
          label="Descrizione causale mensile"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riportoRiduzione"
          label="Riporto precedente"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Rimborsi Finanziari</div>
        <FormField
          fieldName="rimborsoFinanziarioImporto"
          label="Importo rimborso finanziario"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
          step="0.01"
        />
        <FormField
          fieldName="rimborsoFinanziarioDescrizione"
          label="Descrizione rimborso finanziario"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Note</div>
        <FormField
          fieldName="note"
          label="Note interne"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="noteBidella"
          label="Note operatore"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
        />

        <div className="hidden">
          <FormField
            fieldName="sezioneIdBidella"
            type={InputType.Text}
            errors={errors}
            register={register}
          />
        </div>

        {sezioneBidella && watch('sezioneIdBidella') && (
          <div className="mb-3 border-2 border-pink-500 rounded p-4 bg-pink-100">
            <div className="block text-sm leading-5 font-medium text-pink-500 mb-2">
              Sezione indicata da operatore
            </div>
            <div className="flex">
              <div className="mr-4">
                {sezioneBidella.scuolaNome} {sezioneBidella.classe} {sezioneBidella.sezione}
              </div>
              <button
                className="px-2 rounded bg-green-500 text-white hover:bg-green-600"
                onClick={() => {
                  setValue('sezioneIdBidella', '', { shouldDirty: true })
                }}
              >
                Annulla segnalazione
              </button>
            </div>
          </div>
        )}
      </section>

      <hr className="border-blue-200 border-t-2 my-8"></hr>

      <section>
        <FormField
          fieldName="modificatoBidella"
          label="Modificato da operatore (data ritiro, note o giorni di fruizione)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({
            'text-pink-500': watch('modificatoBidella'),
          })}
          inputClassName={classNames({
            'text-pink-500': watch('modificatoBidella'),
          })}
        />
      </section>
      <button
        type="submit"
        className="mt-8 block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
      >
        Salva
      </button>
    </div>
  )
}

export default ComuneBergamoMensa

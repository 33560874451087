import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface ICreateNotaFruizione {
  fruizioneId: number
  nota: string
}

const createNotaFruizione = async ({ fruizioneId, nota }: ICreateNotaFruizione) => {
  const { data } = await axios.post(`/note-fruizione/`, {
    fruizioneId,
    nota,
  })
  return data
}

const useCreateNotaFruizione = ({ fruizioneId, nota }: ICreateNotaFruizione) => {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return createNotaFruizione({ fruizioneId, nota })
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('alunnoByIdByAnno')
        cogoToast.success('Nota creata')
      },
      onError: () => {},
    }
  )
}

export default useCreateNotaFruizione

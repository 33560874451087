import React from 'react'
import logo from '../images/logo_h.svg'

const Footer: React.FC = () => {
  return (
    <footer style={{ backgroundColor: 'rgba(255,255,255,0.2)' }}>
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row sm:justify-between items-center">
        <img src={logo} className="w-32" alt="Rette Web" />
        <div className="text-sm text-gray-600">
          Copyright © {new Date().getFullYear()} F2 .net engineering s.r.l.
        </div>
      </div>
    </footer>
  )
}

export default Footer

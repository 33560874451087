import { useQuery } from 'react-query'
import axios from 'axios'

interface GetAlunniArgs {
  servizioId: number
  id: string
  cognome: string
  nome: string
  codiceFiscale: string
  annoScolastico?: string
  cognomeIntestatario: string
  codiceSdd: string
  scuolaId: string
  dietaId: string
  emailIntestatari: string
  iscrizioneDopoIl: string
  iscrizionePrimaDel: string
  pagamentoSdd: string
  bolletteSospese: string
  ritirato: string
  invioEmail: string
  inizioServizioDopoIl: string
  inizioServizioPrimaDel: string
}

const getAlunni = async (
  _: string,
  {
    servizioId,
    id,
    cognome,
    nome,
    codiceFiscale,
    annoScolastico,
    cognomeIntestatario,
    codiceSdd,
    scuolaId,
    dietaId,
    emailIntestatari,
    iscrizioneDopoIl,
    iscrizionePrimaDel,
    pagamentoSdd,
    bolletteSospese,
    ritirato,
    invioEmail,
    inizioServizioDopoIl,
    inizioServizioPrimaDel,
  }: GetAlunniArgs
) => {
  const queryId = id ? `&id=${id}` : ''
  const queryCognome = cognome ? `&cognome=${cognome}` : ''
  const queryNome = nome ? `&nome=${nome}` : ''
  const queryCodiceFiscale = codiceFiscale ? `&codiceFiscale=${codiceFiscale}` : ''
  const queryCognomeIntestatario = cognomeIntestatario
    ? `&cognomeIntestatario=${cognomeIntestatario}`
    : ''
  const queryCodiceSdd = codiceSdd ? `&codiceSdd=${codiceSdd}` : ''
  const queryScuolaId = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const queryDietaId = dietaId ? `&dietaId=${dietaId}` : ''
  const queryEmailIntestatari = emailIntestatari ? `&email=${emailIntestatari}` : ''
  const queryIscrizioneDopoIl = iscrizioneDopoIl ? `&dataIscrizioneMin=${iscrizioneDopoIl}` : ''
  const queryIscrizionePrimaDel = iscrizionePrimaDel
    ? `&dataIscrizioneMax=${iscrizionePrimaDel}`
    : ''
  const queryPagamentoSdd = pagamentoSdd ? `&pagamentoSdd=${pagamentoSdd}` : ''
  const queryBolletteSospese = bolletteSospese ? `&bolletteSospese=${bolletteSospese}` : ''
  const queryRitirato = ritirato ? `&ritirato=${ritirato}` : ''
  const queryInvioEmail = invioEmail ? `&invioEmail=${invioEmail}` : ''
  const queryInizioServizioDopoIl = inizioServizioDopoIl
    ? `&dataInizioServizioMin=${inizioServizioDopoIl}`
    : ''
  const queryInizioServizioPrimaDel = inizioServizioPrimaDel
    ? `&dataInizioServizioMax=${inizioServizioPrimaDel}`
    : ''

  const { data } = await axios.get(
    `/alunni/ricerca?limit=10000&servizioId=${servizioId}&annoScolastico=${annoScolastico}${queryId}${queryCognome}${queryNome}${queryCodiceFiscale}${queryCognomeIntestatario}${queryCodiceSdd}${queryScuolaId}${queryDietaId}${queryEmailIntestatari}${queryIscrizioneDopoIl}${queryIscrizionePrimaDel}${queryPagamentoSdd}${queryBolletteSospese}${queryRitirato}${queryInvioEmail}${queryInizioServizioDopoIl}${queryInizioServizioPrimaDel}`
  )

  return data.results
}

const useAlunni = ({
  servizioId,
  id,
  cognome,
  nome,
  codiceFiscale,
  annoScolastico,
  cognomeIntestatario,
  codiceSdd,
  scuolaId,
  dietaId,
  emailIntestatari,
  iscrizioneDopoIl,
  iscrizionePrimaDel,
  pagamentoSdd,
  bolletteSospese,
  ritirato,
  invioEmail,
  inizioServizioDopoIl,
  inizioServizioPrimaDel,
}: GetAlunniArgs) => {
  return useQuery(
    [
      'alunni',
      {
        servizioId,
        id,
        cognome,
        nome,
        codiceFiscale,
        annoScolastico,
        cognomeIntestatario,
        codiceSdd,
        scuolaId,
        dietaId,
        emailIntestatari,
        iscrizioneDopoIl,
        iscrizionePrimaDel,
        pagamentoSdd,
        bolletteSospese,
        ritirato,
        invioEmail,
        inizioServizioDopoIl,
        inizioServizioPrimaDel,
      },
    ],
    () =>
      getAlunni('alunni', {
        servizioId,
        id,
        cognome,
        nome,
        codiceFiscale,
        annoScolastico,
        cognomeIntestatario,
        codiceSdd,
        scuolaId,
        dietaId,
        emailIntestatari,
        iscrizioneDopoIl,
        iscrizionePrimaDel,
        pagamentoSdd,
        bolletteSospese,
        ritirato,
        invioEmail,
        inizioServizioDopoIl,
        inizioServizioPrimaDel,
      }),
    {
      enabled:
        !!servizioId &&
        (id.length >= 3 ||
          cognome.length >= 2 ||
          nome.length >= 2 ||
          codiceFiscale.length >= 3 ||
          cognomeIntestatario.length >= 2 ||
          codiceSdd.length === 16 ||
          !!scuolaId ||
          !!dietaId ||
          emailIntestatari.length >= 3 ||
          !!iscrizioneDopoIl ||
          !!iscrizionePrimaDel ||
          !!pagamentoSdd ||
          !!bolletteSospese ||
          !!ritirato ||
          !!invioEmail ||
          !!inizioServizioDopoIl ||
          !!inizioServizioPrimaDel),
      staleTime: 10 * 60 * 1000,
    }
  )
}

export default useAlunni

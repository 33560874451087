import React from 'react'
import { Router } from '@reach/router'
import { QueryClient, QueryClientProvider } from 'react-query'

import Login from './pages/login'
import Pausa from './pages/login/pausa'
import LoggedApp from './components/LoggedApp'

// const ReactQueryDevtools = require('react-query-devtools').ReactQueryDevtools

const App: React.FC = () => {
  const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    })

  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}

      <div className="app text-gray-900 antialiased">
        <div style={{ minHeight: 'calc(100vh - 120px)' }}>
          <Router primary={false}>
            <Login path="/" />
            <Pausa path="/pausa" />
            <LoggedApp path="/app/*" />
          </Router>
        </div>
      </div>
    </QueryClientProvider>
  )
}

export default App
 
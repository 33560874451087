import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import DayPicker from 'react-day-picker'
import ReactModal from 'react-modal'
import moment from 'moment'
import classNames from 'classnames'
import {
  FiFileText,
  FiMail,
  //  FiSmile,
  //   FiAlertCircle
} from 'react-icons/fi'
import { useForm } from 'react-hook-form'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css' // optional

import { API_URL } from '../../../config'
import ServizioContext from '../../../context/ServizioContext'
import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'

import useFasceByServizioId from '../../../apiHooks/queries/useFasceByServizioId'
import usePresenzeByFruizioneId from '../../../apiHooks/queries/usePresenzeByFruizioneId'
import useGiorniNonErogazioneByServizioId from '../../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import useBollettaFruizioneMese from '../../../apiHooks/queries/useBollettaFruizioneMese'
import useAvvisiPagamentoByFruizioneId from '../../../apiHooks/queries/useAvvisiPagamentoByFruizioneId'
// import useBollettaPdfFruizioneMese from '../../../apiHooks/queries/useBollettaPdfFruizioneMese'
import useCreateFruizione from '../../../apiHooks/mutations/useCreateFruizione'
import useCreateNotaFruizione from '../../../apiHooks/mutations/useCreateNotaFruizione'
import FormServizio from './formServizi/FormServizio'
import { isNonErogazione, isErrore, isWarning } from '../../../lib/validazionePresenze'
import 'react-day-picker/lib/style.css'
import { FaCaretDown } from 'react-icons/fa'
import Price from '../../../components/Price'
import FormField, { InputType as formFieldInputType } from '../../../components/FormField'
import useCreaNotaDiCredito from '../../../apiHooks/mutations/useCreaNotaDiCredito'
import useServizioById from '../../../apiHooks/queries/useServizioById'
// import useInviaEmailAvvisi from '../../../apiHooks/mutations/useInviaEmailAvvisi'
import useInviaEmailAvviso from '../../../apiHooks/mutations/useInviaEmailAvviso'

interface PropsServizio extends RouteComponentProps {
  id?: string
  alunnoId: number
  fruizioni?: any[]
  intestatari: any[]
  scuolaId: number
}

interface ModalNoteDiCredito {
  open: boolean
  nome: string
  cognome: string
  importoIvato: number
  aliquotaIva: number
  importoEsenteIva: number
  dataEmissione: string
  avvisoId: string
}

const modifiersStyles = {
  p: {
    color: '#48bb78',
  },
  a: {
    color: '#e53e3e',
  },
  b: {
    color: '#48bb78',
  },
  m: {
    color: '#48bb78',
  },
  nonErogazione: {
    backgroundColor: '#f0f0f0',
  },
  // errore: {
  //   //border: '2px solid #feb2b2',
  // },
  // warning: {
  //   border: '2px solid #fc0',
  //   // backgroundColor: '#fc0',
  // },
  outside: {
    border: 'none!important',
  },
  disabled: {
    backgroundColor: 'transparent!important',
  },
}

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]
const WEEKDAYS_LONG = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']

interface NotaFruizione {
  id: number
  autore: number
  autoreDati: {
    operatore: {
      nome: string
      cognome: string
    }
  }
  nota: string
  createdAt: string
  updatedAt: string
  fruizioneId: number
}

const getRangeDate = (date: Date) => {
  const momentDate = moment(date)
  return {
    from: momentDate.startOf('month').format('YYYY-MM-DD'),
    to: momentDate.endOf('month').format('YYYY-MM-DD'),
  }
}

const getDatePresenzeByTipo = (presenze: any[], tipo: string) => {
  return presenze
    .filter((p: any) => p.tipo === tipo)
    .map((p: any) => moment(p.data, 'YYYY-MM-DD', true).toDate())
}

const getDate = (giorni: any[]) => {
  return giorni.map((g) => moment(g.data, 'YYYY-MM-DD', true).toDate())
}

const Servizio: React.FC<PropsServizio> = ({ id, fruizioni, intestatari, alunnoId, scuolaId }) => {
  const { servizioId } = useContext(ServizioContext)
  const { data: servizio } = useServizioById(servizioId)
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const anni: string[] = annoScolastico.split('-')
  const dataMin = moment(`${anni[0]}-09-01`, 'YYYY-MM-DD', true)
  const dataMax = moment(`${anni[1]}-08-31`, 'YYYY-MM-DD', true)
  const todayMoment = moment()
  const initialMonth = todayMoment.isBefore(dataMin)
    ? dataMin.toDate()
    : todayMoment.isAfter(dataMax)
    ? dataMax.toDate()
    : todayMoment.toDate()

  const { data: fasceCalcolo } = useFasceByServizioId(servizioId)
  const fruizione = fruizioni && fruizioni.find((f: any) => f.servizioId === +id!)

  const today = new Date()
  const initialRange = getRangeDate(today)

  const [currentDate, setCurrentDate] = useState(today)
  const [from, setFrom] = useState(initialRange.from)
  const [to, setTo] = useState(initialRange.to)
  const [fruizioneIdCalcolo, setFruizioneIdCalcolo] = useState<number>(0)
  // const [fruizioneIdPdf, setFruizioneIdPdf] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  // const [bolletta, setBolletta] = useState<Bolletta | null>(null)
  const [nuovaNota, setNuovaNota] = useState('')
  const [showPresenzeMensili, setShowPresenzeMensili] = useState(false)
  const [showNoteFruizione, setShowNoteFruizione] = useState(false)
  const [showAvvisiPagamento, setShowAvvisiPagamento] = useState(true)
  const [importoDovuto, setImportoDovuto] = useState<number>(0)
  const DEFAULT: ModalNoteDiCredito = {
    open: false,
    nome: '',
    cognome: '',
    dataEmissione: '',
    importoIvato: 0,
    //TODO aliquota iva in questa pagina deve scendere da api
    aliquotaIva: 4,
    importoEsenteIva: 0,
    avvisoId: '',
  }
  const [modalNoteDiCredito, setModalNoteDiCredito] = useState<ModalNoteDiCredito>(DEFAULT)

  const { data: presenze = [] } = usePresenzeByFruizioneId({
    fruizioneId: fruizione ? fruizione.id : null,
    from,
    to,
  })

  const { data: giorniNonErogazione = [] } = useGiorniNonErogazioneByServizioId({
    servizioId: +id!,
    from,
    to,
    scuolaId,
  })

  const { data: bollettaMese } = useBollettaFruizioneMese({
    fruizioneId: fruizioneIdCalcolo,
    anno: moment(currentDate).format('YYYY'),
    mese: moment(currentDate).format('M'),
  })

  const { data: avvisiPagamento } = useAvvisiPagamentoByFruizioneId(fruizione.id)

  // const { data: bollettaPdf, isFetching: isFetchingBollettaPdf } = useBollettaPdfFruizioneMese({
  //   fruizioneId: fruizioneIdPdf,
  //   anno: moment(currentDate).format('YYYY'),
  //   mese: moment(currentDate).format('M'),
  // })

  const createFruizione = useCreateFruizione({
    servizioId: +id!,
    alunnoId,
    annoScolastico,
  })

  const creaNotaDiCredito = useCreaNotaDiCredito()

  const createNotaFruizione = useCreateNotaFruizione({
    fruizioneId: fruizione ? fruizione.id : null,
    nota: nuovaNota,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      dataEmissione: moment().format('YYYY-MM-DD'),
      importoIvato: 0,
      aliquotaIva: 0,
      importoEsenteIva: 0,
      note: '',
    },
  })

  // useEffect(() => {
  //   const bolletta: Bolletta = bollettaMese
  //   !!fruizioneIdCalcolo && setBolletta(bolletta)
  // }, [fruizioneIdCalcolo, isFetchingBollettaMese])
  const handleModalNotaDiCredito = (avviso: any) => (event: any) => {
    document.getElementById('root')!.style.filter = 'blur(5px)'
    setModalNoteDiCredito({
      open: true,
      nome: avviso.bambinoNome,
      cognome: avviso.bambinoCognome,
      dataEmissione: moment().format('YYYY-MM-DD'),
      importoIvato: +avviso.importoTotale,
      avvisoId: avviso.id,
      aliquotaIva: 4,
      importoEsenteIva: 0,
    })
  }

  const handleAnteprimaBolletta = (event: any) => {
    document.getElementById('root')!.style.filter = 'blur(5px)'
    // const bolletta: Bolletta = bollettaMese

    setFruizioneIdCalcolo(fruizione ? fruizione.id : 0)
    //setBolletta(bolletta)
    setModalOpen(true)
  }

  // const handleBollettaPdf = (event: any) => {
  //   setFruizioneIdPdf(fruizione ? fruizione.id : 0,)
  // }

  useEffect(() => {
    const range = getRangeDate(currentDate)
    setFrom(range.from)
    setTo(range.to)
  }, [currentDate])

  useEffect(() => {
    const importoDovutoCalcolato: number =
      avvisiPagamento &&
      avvisiPagamento.reduce(
        (acc: number, avviso: any) => acc + (avviso.quotaCaricoGenitore - avviso.importoPagato),
        0
      )
    setImportoDovuto(importoDovutoCalcolato)
  }, [avvisiPagamento])

  useEffect(() => {
    reset({
      dataEmissione: modalNoteDiCredito.dataEmissione,
      importoIvato: modalNoteDiCredito.importoIvato,
      aliquotaIva: modalNoteDiCredito.aliquotaIva,
      importoEsenteIva: modalNoteDiCredito.importoEsenteIva,
      note: '',
    })
  }, [reset, modalNoteDiCredito])

  const handleChangeMonth = (date: Date) => {
    setCurrentDate(date)
  }
  const onSubmit = (values: any) => {
    const avviso = {
      importoIvato: -values.importoIvato,
      aliquotaIva: values.aliquotaIva,
      importoEsenteIva: -values.importoEsenteIva,
      dataEmissione: values.dataEmissione,
      note: values.note,
    }
    creaNotaDiCredito.mutate({
      avvisoId: modalNoteDiCredito.avvisoId + '',
      avviso,
      fruizioneId: fruizione.id,
    })
    setModalNoteDiCredito(DEFAULT)
    document.getElementById('root')!.style.filter = 'blur(0)'
  }

  const markError = (day: Date) => {
    const data = moment(day).format('YYYY-MM-DD')
    const presenzaGiornaliera = presenze.find((p: any) => p.data === data)
    const tipoPresenza = presenzaGiornaliera && presenzaGiornaliera.tipo
    return isErrore(data, tipoPresenza, giorniNonErogazione, fruizione, servizio)
  }

  const markWarning = (day: Date) => {
    const data = moment(day).format('YYYY-MM-DD')
    const presenzaGiornaliera = presenze.find((p: any) => p.data === data)
    const tipoPresenza = presenzaGiornaliera && presenzaGiornaliera.tipo
    return isWarning(data, tipoPresenza, servizio)
  }

  const modifiers = {
    p: getDatePresenzeByTipo(presenze, 'P'),
    a: getDatePresenzeByTipo(presenze, 'A'),
    b: getDatePresenzeByTipo(presenze, 'B'),
    m: getDatePresenzeByTipo(presenze, 'M'),
    nonErogazione: getDate(giorniNonErogazione),
    errore: markError,
    warning: markWarning,
    disabled: [
      { daysOfWeek: [0, 6] },
      (d: Date) => isNonErogazione(giorniNonErogazione, moment(d).format('YYYY-MM-DD'), servizio),
    ],
  }

  const renderDay = (day: Date) => {
    const data = moment(day).format('YYYY-MM-DD')
    const nonErogazione = isNonErogazione(giorniNonErogazione, data, servizio)
    let descrizioneNonErogazione = ''
    if (nonErogazione) {
      descrizioneNonErogazione = nonErogazione.descrizione
    }
    return (
      <>
        <span title={descrizioneNonErogazione} className="leading-none">
          {moment(day).format('D')}
        </span>
        {/* <div
          className="triangle absolute top-0 right-0"
          style={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 8px 8px 0',
            borderColor: 'transparent'
          }}
        ></div> */}
        <div
          className="point absolute w-1 h-1 rounded-full"
          style={{
            top: 4,
            left: 15,
          }}
        ></div>
      </>
    )
  }

  const numeroPresenze =
    presenze &&
    presenze.filter((p: any) => p.tipo === 'P' || p.tipo === 'B' || p.tipo === 'M').length
  const numeroAssenze = presenze && presenze.filter((p: any) => p.tipo === 'A').length

  const inviaEmailAvviso = useInviaEmailAvviso()

  const handleInviaEmail = (idAvviso: string, servizioId: number) => {
    inviaEmailAvviso.mutate({ idAvviso, servizioId })
  }

  return (
    <div className="flex-1 lg:flex lg:-mx-10 pt-6 pb-20">
      <div className="md:w-1/2 md:px-10">
        {/* <h2 className="font-semibold mb-4">Parametri servizio</h2> */}
        {fruizione && fasceCalcolo ? (
          <FormServizio
            fruizione={fruizione}
            intestatari={intestatari}
            fasceCalcolo={fasceCalcolo}
          />
        ) : (
          <div>
            <p className="mb-6">Questo servizio non è attivo per questo alunno.</p>
            <button
              className="bg-blue-500 hover:bg-blue-500 py-3 w-full text-xl text-white rounded"
              onClick={() => createFruizione.mutate()}
            >
              Attiva il servizio
            </button>
          </div>
        )}
      </div>
      <div className="md:w-1/2 md:px-10 flex justify-end">
        {fruizione && (
          <div className="w-full">
            <button
              type="button"
              className="flex items-center justify-between w-full mb-4 focus:outline-none"
              onClick={() => setShowPresenzeMensili(!showPresenzeMensili)}
            >
              <h2 className="font-semibold">
                Presenze mensili
                <span className="p-1 bg-green-300 rounded ml-2 text-xs">{numeroPresenze}</span>
                <span className="p-1 bg-red-300 rounded ml-2 text-xs">{numeroAssenze}</span>
              </h2>
              <FaCaretDown
                className={classNames(
                  'transform transition-transform ease-in-out duration-300',
                  { 'rotate-180': showPresenzeMensili },
                  { 'rotate-0': !showPresenzeMensili }
                )}
              />
            </button>
            {showPresenzeMensili && (
              <>
                <div style={{ lineHeight: 1.15 }}>
                  <style>{`
                .DayPicker-Day {
                  position: relative;
                }
                .DayPicker-Day--errore .point {
                 background-color: #ff5722;
                }
                .DayPicker-Day--warning .point {
                  background-color: #ffc905;
                }
            `}</style>
                  <DayPicker
                    className="bg-white rounded py-2 px-2 mb-8 shadow-lg border-gray-200 "
                    locale="it"
                    months={MONTHS}
                    initialMonth={initialMonth}
                    fromMonth={dataMin.toDate()}
                    toMonth={dataMax.toDate()}
                    weekdaysLong={WEEKDAYS_LONG}
                    weekdaysShort={WEEKDAYS_SHORT}
                    firstDayOfWeek={1}
                    //disabledDays={{ daysOfWeek: [0, 6] }}
                    modifiers={modifiers}
                    modifiersStyles={modifiersStyles}
                    onMonthChange={handleChangeMonth}
                    renderDay={renderDay}
                  />
                </div>

                <div className="flex space-x-4">
                  <button
                    className="block border border-blue-400 hover:bg-white hover:border-blue-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-blue-600 hover:text-blue-700 text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 mb-12"
                    onClick={handleAnteprimaBolletta}
                  >
                    Anteprima Bolletta
                  </button>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${API_URL}/fruizioni/${fruizione.id}/pdf/?anno=${moment(
                      currentDate
                    ).format('YYYY')}&mese=${moment(currentDate).format(
                      'M'
                    )}&t=${encodeURIComponent(localStorage.getItem('token') || '')}`}
                    className="block border border-blue-400 hover:bg-white hover:border-blue-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-blue-600 hover:text-blue-700 text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 mb-12"
                  >
                    Bolletta PDF
                  </a>
                </div>
              </>
            )}

            <div>
              <button
                type="button"
                className="flex items-center justify-between w-full mb-4 focus:outline-none"
                onClick={() => setShowNoteFruizione(!showNoteFruizione)}
              >
                <h2 className="font-semibold">
                  Note
                  <span className="p-1 bg-yellow-300 rounded ml-2 text-xs">
                    {fruizione.noteFruizione ? fruizione.noteFruizione.length : 0}
                  </span>
                </h2>
                <FaCaretDown
                  className={classNames(
                    'transform transition-transform ease-in-out duration-300',
                    { 'rotate-180': showNoteFruizione },
                    { 'rotate-0': !showNoteFruizione }
                  )}
                />
              </button>
              {showNoteFruizione && (
                <>
                  {fruizione.noteFruizione
                    ? fruizione.noteFruizione.map((nota: NotaFruizione) => {
                        return (
                          <div key={nota.id} className="mb-3 p-4 bg-yellow-200 rounded shadow-md">
                            <div className="text-xs text-yellow-600 leading-tight">
                              {moment(nota.createdAt).format('DD/MM/YYYY')}
                            </div>
                            <div className="text-sm text-yellow-800 font-semibold mb-1">
                              {nota.autoreDati.operatore.cognome} {nota.autoreDati.operatore.nome}
                            </div>
                            <div className="text-sm">{nota.nota}</div>
                          </div>
                        )
                      })
                    : null}
                  <div className="mb-3 p-4 bg-yellow-200 rounded shadow-md">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        // console.log(nuovaNota)
                        createNotaFruizione.mutate()
                      }}
                    >
                      <textarea
                        className="mb-1 form-input w-full text-sm"
                        id="nuovaNota"
                        name="nuovaNota"
                        rows={2}
                        placeholder="Nuova nota"
                        value={nuovaNota}
                        onChange={(e) => setNuovaNota(e.target.value)}
                      />

                      <button
                        type="submit"
                        className="block w-full justify-end bg-yellow-600 hover:bg-yellow-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-3 rounded transition-colors duration-150"
                      >
                        Aggiungi nota
                      </button>
                    </form>
                  </div>
                </>
              )}
            </div>

            <button
              type="button"
              className="flex items-center justify-between w-full mb-4 focus:outline-none"
              onClick={() => setShowAvvisiPagamento(!showAvvisiPagamento)}
            >
              <h2 className="font-semibold">Avvisi di pagamento</h2>
              <FaCaretDown
                className={classNames(
                  'transform transition-transform ease-in-out duration-300',
                  { 'rotate-180': showAvvisiPagamento },
                  { 'rotate-0': !showAvvisiPagamento }
                )}
              />
            </button>
            {showAvvisiPagamento && (
              <table className="min-w-full bg-white overflow-x-auto block">
                <thead>
                  <tr>
                    <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      T
                    </th>
                    <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Mese
                    </th>
                    <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Data
                    </th>
                    <th className="px-4 py-3 border-b border-gray-200 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
                      Importo
                    </th>
                    <th className="px-4 py-3 border-b border-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Pagato
                    </th>
                    <th className="px-1 py-3 border-b border-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Int
                    </th>
                    <th className="px-4 py-3 border-b border-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Doc
                    </th>
                    <th className="px-4 py-3 border-b border-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Mail
                    </th>

                    <th
                      className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      colSpan={2}
                    ></th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {avvisiPagamento && avvisiPagamento.length > 0
                    ? avvisiPagamento.map((avviso: any, index: number) => {
                        const ruoloIntestatario = intestatari.find(
                          (intestatario: { id: number; nome: string; cognome: string }) => {
                            return intestatario.id === avviso.intestatarioId
                          }
                        ).ruolo
                        const letteraIntestatario = ruoloIntestatario.slice(0, 1).toUpperCase()

                        const disabledCreaNC = !!avvisiPagamento.find(
                          (av: any) =>
                            !!av.annullaAvvisoPagamentoId &&
                            av.annullaAvvisoPagamentoId === avviso.id
                        )

                        return (
                          <tr key={avviso.id}>
                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200',
                                !!avviso.annullaAvvisoPagamentoId && 'text-red-500'
                              )}
                            >
                              {!!avviso.annullaAvvisoPagamentoId ? 'NC' : 'B'}
                            </td>
                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                            >
                              {moment(avviso.mese, 'M', true).format('MMM').toUpperCase()}
                            </td>
                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                            >
                              {moment(avviso.dataEmissione).format('DD MMM').toUpperCase()}
                            </td>
                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b text-right',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                            >
                              <Price amount={avviso.quotaCaricoGenitore} />
                            </td>
                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                              // title={`Pagato ${avviso.importoPagato}`}
                            >
                              {avviso.importoPagato >= avviso.quotaCaricoGenitore ? (
                                <div className="flex items-center justify-between">
                                  {/* <FiSmile
                                    className={classNames(
                                      'block text-xl',
                                      avviso.importoPagato > avviso.quotaCaricoGenitore
                                        ? 'font-semibold text-blue-400'
                                        : 'text-green-400'
                                    )}
                                  /> */}
                                  <Tippy
                                    arrow={false}
                                    disabled={avviso.pagamenti.length <= 0}
                                    content={
                                      <>
                                        <div className="grid grid-cols-2 gap-5">
                                          <div className="text-center">Data</div>
                                          <div className="text-center">Importo</div>
                                        </div>
                                        {avviso.pagamenti.map((pagamento: any) => (
                                          <div
                                            className="grid grid-cols-2 gap-5"
                                            key={pagamento.id}
                                          >
                                            <div className="text-center">
                                              {moment(pagamento.data)
                                                .format('DD MMM YYYY')
                                                .toUpperCase()}
                                            </div>
                                            <div className="text-center">€ {pagamento.importo}</div>
                                          </div>
                                        ))}
                                      </>
                                    }
                                  >
                                    <div
                                      className={classNames(
                                        'text-xs',
                                        avviso.importoPagato > avviso.quotaCaricoGenitore
                                          ? 'font-semibold text-blue-600'
                                          : 'text-green-600'
                                      )}
                                    >
                                      <Price amount={avviso.importoPagato} />
                                    </div>
                                  </Tippy>
                                </div>
                              ) : avviso.importoPagato > 0 ? (
                                <div className="flex items-center justify-between">
                                  {/* <FiAlertCircle className="block text-orange-400 text-xl" /> */}
                                  <div className="text-orange-600 text-xs">
                                    <Price amount={avviso.importoPagato} />
                                  </div>
                                </div>
                              ) : (
                                <div className="flex items-center justify-between">
                                  {/* <FiAlertCircle className="block text-red-500 text-xl" /> */}
                                  <div className="text-red-700 text-xs">
                                    <Price amount={avviso.importoPagato} />
                                  </div>
                                </div>
                              )}
                            </td>
                            <td
                              className={classNames(
                                'py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                              // title={ruoloIntestatario}
                            >
                              <Tippy arrow={false} content={ruoloIntestatario}>
                                <div className="text-xs">{letteraIntestatario}</div>
                              </Tippy>
                            </td>
                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                            >
                              <div className="flex space-x-1">
                                <a
                                  href={`${avviso.linkDownload}?t=${encodeURIComponent(
                                    localStorage.getItem('token') || ''
                                  )}`}
                                  className="text-blue-500"
                                >
                                  <FiFileText />
                                </a>

                                {avviso.linkDownload && avviso.pdfRicevuta && (
                                  <a
                                    href={`${
                                      avviso.linkDownload.slice(0, -1) + '-ricevuta/'
                                    }?t=${encodeURIComponent(localStorage.getItem('token') || '')}`}
                                    className="text-purple-500"
                                  >
                                    <FiFileText />
                                  </a>
                                )}
                              </div>
                            </td>

                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                            >
                              <button
                                type="button"
                                className="flex items-center space-x-2 bg-white hover:bg-gray-200 border border-gray-400 hover:border-gray-500 text-gray-600 font-medium hover:shadow-xl focus:shadow-outline focus:outline-none text-sm leading-5 py-1 px-2 rounded transition-colors duration-150"
                                // className={`block ${
                                //   !!avviso.dataInvioMail
                                //     ? 'text-purple-500'
                                //     : 'bg-red-200 hover:bg-gray-200'
                                // }  border border-gray-400 hover:border-gray-500 text-gray-600 font-medium hover:shadow-xl focus:shadow-outline focus:outline-none text-sm leading-5 py-1 px-2 rounded transition-colors duration-150`}
                                onClick={() => handleInviaEmail(avviso.id, servizioId)}
                                disabled={inviaEmailAvviso.isLoading}
                              >
                                <FiMail
                                  className={`${
                                    !!avviso.dataInvioMail ? 'text-green-500' : 'text-red-500'
                                  }`}
                                />
                                <div>Invia avviso</div>
                              </button>
                            </td>

                            <td
                              className={classNames(
                                'px-4 py-2 whitespace-nowrap border-b',
                                index === avvisiPagamento.length - 1
                                  ? 'border-gray-600'
                                  : 'border-gray-200'
                              )}
                            >
                              {!!avviso.annullaAvvisoPagamentoId ? null : (
                                <button
                                  type="button"
                                  // className="block bg-white hover:bg-gray-200 border border-gray-400 hover:border-gray-500 text-gray-600 font-medium hover:shadow-xl focus:shadow-outline focus:outline-none text-sm leading-5 py-1 px-2 rounded  transition-colors duration-150"
                                  className={`block ${
                                    !disabledCreaNC
                                      ? 'hover:bg-gray-200 hover:border-gray-500 hover:shadow-xl text-gray-600'
                                      : 'cursor-not-allowed text-gray-400'
                                  }  border border-gray-400 font-medium focus:shadow-outline focus:outline-none text-sm leading-5 py-1 px-2 rounded transition-colors duration-150`}
                                  onClick={handleModalNotaDiCredito(avviso)}
                                  disabled={disabledCreaNC}
                                >
                                  + NC
                                </button>
                              )}
                            </td>
                          </tr>
                        )
                      })
                    : null}
                  {avvisiPagamento?.length > 0 ? (
                    <tr>
                      <td
                        className={classNames(
                          'px-4 py-2 whitespace-nowrap text-right font-semibold',
                          importoDovuto < 0 ? 'bg-yellow-200' : 'bg-white'
                        )}
                        colSpan={2}
                      >
                        {importoDovuto >= 0 ? 'Importo dovuto:' : 'Credito:'}
                      </td>
                      <td
                        className={classNames(
                          'px-4 py-2 whitespace-nowrap text-right font-semibold',
                          importoDovuto < 0 ? 'bg-yellow-200' : 'bg-white',
                          importoDovuto > 0 ? 'text-red-600' : 'text-green-600'
                        )}
                      >
                        <Price amount={importoDovuto} />
                      </td>
                      <td
                        className={classNames(
                          'px-4 py-2 whitespace-nowrap text-right font-semibold',
                          importoDovuto < 0 ? 'bg-yellow-200' : 'bg-white',
                          importoDovuto > 0 ? 'text-red-600' : 'text-green-600'
                        )}
                        colSpan={5}
                      ></td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => {
          setFruizioneIdCalcolo(0)
          //setBolletta(null)
          setModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        {!!bollettaMese && bollettaMese.righe && (
          <div className="max-w-lg">
            <div className="bg-blue-500 text-white font-semibold px-4 py-2 text-center">
              Bolletta di {moment(bollettaMese?.mese, 'M', true).format('MMMM')}{' '}
              {bollettaMese?.anno}
            </div>

            <div className="px-8 py-6">
              <table className="mx-auto">
                <tbody>
                  {bollettaMese?.righe.map((riga) => (
                    <tr key={riga.descrizione}>
                      <td className="pb-1 px-4">{riga.descrizione}</td>
                      <td className="pb-1 px-4 text-right">{riga.importo}</td>
                    </tr>
                  ))}
                  <tr className="border-t border-blue-300">
                    <td className="pt-2 px-4 uppercase font-bold">Totale</td>
                    <td className="pt-2 px-4 text-right font-bold">{bollettaMese?.totale}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ReactModal>
      <ReactModal
        isOpen={modalNoteDiCredito.open}
        onRequestClose={() => {
          setModalNoteDiCredito(DEFAULT)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="p-8" style={{ width: '32rem' }}>
          <div className="mb-6">
            <h1 className="text-gray-800 text-md">
              Bolletta per <span className="font-bold">{modalNoteDiCredito.nome}</span>{' '}
              <span className="font-bold">{modalNoteDiCredito.cognome}</span>{' '}
            </h1>
            <h2 className="text-gray-600">Crea nota di credito</h2>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="" autoComplete="off">
            <div className="flex flex-col w-full">
              <FormField
                fieldName="dataEmissione"
                label="Data di emissione"
                type={formFieldInputType.Date}
                validation={{ required: 'Inserisci la data di emissione' }}
                register={register}
                errors={errors}
              />

              <FormField
                fieldName="importoIvato"
                label="Importo IVA inclusa"
                type={formFieldInputType.Number}
                step={'0.01'}
                max={modalNoteDiCredito.importoIvato + ''}
                min="0"
                validation={{ required: 'Inserisci il periodo' }}
                register={register}
                errors={errors}
                className="mb-3"
              />
              <FormField
                fieldName="aliquotaIva"
                label="Aliquota IVA %"
                type={formFieldInputType.Number}
                step={'0.01'}
                validation={{ required: 'Inserisci il periodo' }}
                register={register}
                errors={errors}
                className="mb-3"
              />
              <FormField
                fieldName="importoEsenteIva"
                label="Importo IVA esente"
                type={formFieldInputType.Number}
                step={'0.01'}
                max={modalNoteDiCredito.importoIvato + ''}
                min="0"
                validation={{ required: 'Inserisci il periodo' }}
                register={register}
                errors={errors}
                className="mb-3"
              />
              <FormField
                fieldName="note"
                label="Note"
                type={formFieldInputType.Textarea}
                rows={3}
                validation={{
                  maxLength: { value: 500, message: 'Max 500 caratteri' },
                }}
                register={register}
                errors={errors}
                className="mb-3"
                watch={watch}
              />
              <button
                type="submit"
                className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
              >
                Genera nota di credito
              </button>
            </div>
          </form>
        </div>
      </ReactModal>
    </div>
  )
}

export default Servizio

import { createSlice } from '@reduxjs/toolkit'

export const fatturazioneGenerazioneAvvisi = createSlice({
  name: 'fatturazioneGenerazioneAvvisi',
  initialState: {
    dataEmissione: '',
    dataScadenza: '',
    periodo: '',
    nota: '',
  },
  reducers: {
    changeDatiGenerazioneAvvisi: (state, action) => {
      state.dataEmissione = action.payload.dataEmissione
      state.dataScadenza = action.payload.dataScadenza
      state.periodo = action.payload.periodo
      state.nota = action.payload.nota
    },
  },
})

export const selectFatturazioneGenerazioneAvvisi = (state: {
  fatturazioneGenerazioneAvvisi: {
    dataEmissione: string
    dataScadenza: string
    periodo: string
    nota: string
  }
}) => state

export const { changeDatiGenerazioneAvvisi } = fatturazioneGenerazioneAvvisi.actions

export default fatturazioneGenerazioneAvvisi.reducer

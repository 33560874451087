import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'

interface CreateDieta {
  tipoDieta: string
  nomeDieta: string
  servizioId: number
}

const createDieta = async (servizioId: number, dieta: CreateDieta) => {
  const { data } = await axios.post(`/servizi/${servizioId}/diete/`, dieta)
  return data
}

const useCreateDieta = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ servizioId, dieta }: { servizioId: number; dieta: CreateDieta }) => {
      return createDieta(servizioId, dieta)
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('dieteByServizioId')
        cogoToast.success('Dieta creata correttamente.')
      },
      onError: () => {},
    }
  )
}

export default useCreateDieta

import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'

const deleteGiornoNonErogazione = async (giornoNonErogazioneId: number) => {
  const { data } = await axios.delete(`/giorni-non-erogazione/${giornoNonErogazioneId}/`)
  return data
}

const useDeleteGiornoNonErogazione = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (giornoNonErogazioneId: number) => {
      return deleteGiornoNonErogazione(giornoNonErogazioneId)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('giorniNonErogazioneByServizioId')
        cogoToast.success('Giorno di chiusura eliminato correttamente')
      },
      onError: () => {},
    }
  )
}

export default useDeleteGiornoNonErogazione

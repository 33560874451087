import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'

export interface CreateFascia {
  id?: number
  codice: string
  descrizione?: string
  aliquotaIva: number

  quotaFissaDescrizione?: string
  quotaFissaImporto: number
  quotaFissaApplicazioneIva?: boolean

  costoPastoDescrizione?: string
  costoPastoImporto: number
  costoPastoApplicazioneIva?: boolean

  commissioniSddImporto: number
  commissioniSddDescrizione?: string
  commissioniSddApplicazioneIva?: boolean
}

const createFascia = async (servizioId: number, fascia: CreateFascia) => {
  return await axios.post(`/fasce-calcolo/`, { ...fascia, servizioId })
}

const useCreateFascia = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ servizioId, fascia }: { servizioId: number; fascia: CreateFascia }) => {
      return createFascia(servizioId, fascia)
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(['fasceByServizioId', data.servizioId])
        cogoToast.success('Fascia creata correttamente.')
      },
      onError: () => {},
    }
  )
}

export default useCreateFascia

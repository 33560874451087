const reactSelectCustomStyles = {
  control: (provided: any, status: any) => {
    if (status.isFocused) {
      return {
        ...provided,
        outline: 'none',
        boxShadow: '0 0 0 3px rgba(199, 210, 254, 0.5)',
        borderColor: '#c7d2fe',
        '&:hover': {
          borderColor: '#c7d2fe',
        },
      }
    }
    return {
      ...provided,
      boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      borderColor: '#d1d5db',
      '&:hover': {
        borderColor: '#d1d5db',
      },
    }
  },
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '5px 10px',
  }),
  singleValue: (provided: any) => provided,
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: '#e2e8f0',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#cbd5e0',
    '&:hover': {
      color: '#a0aec0',
    },
  }),
  option: (provided: any, status: any) => {
    if (status.isSelected) {
      return {
        ...provided,
        backgroundColor: '#4299e1',
      }
    }
    return {
      ...provided,
    }
  },
}

export default reactSelectCustomStyles

import React, { useState, useContext } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import classNames from 'classnames'
import ReactModal from 'react-modal'
// import InlineEdit, { InputType } from 'riec'
import { useDispatch, useSelector } from 'react-redux'
import { FiEdit } from 'react-icons/fi'

import useScuoleByServizioId from '../../../apiHooks/queries/useScuoleByServizioId'
import useSezioniByScuolaId from '../../../apiHooks/queries/useSezioniByScuolaId'
import useAlunniBySezioneId from '../../../apiHooks/queries/useAlunniBySezioneId'
import useUpdateSezioneById from '../../../apiHooks/mutations/useUpdateSezioneById'
import useCreateSezione from '../../../apiHooks/mutations/useCreateSezione'
import useCreateAlunno from '../../../apiHooks/mutations/useCreateAlunno'

import {
  selectAnagraficaElenco,
  changeScuolaSel,
  changeSezioneSel,
} from '../../../redux/modules/anagraficaElenco'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import ServizioContext from '../../../context/ServizioContext'

import Loading from '../../../components/Loading'
import moment from 'moment'

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const Elenco: React.FC<RouteComponentProps> = () => {
  //===================
  // REDUX
  //===================
  const anagraficaElenco = useSelector(selectAnagraficaElenco)
  const {
    anagraficaElenco: { scuolaSel, sezioneSel },
  } = anagraficaElenco
  const dispatch = useDispatch()

  //===================
  // CONTEXT
  //===================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  //===================
  // LOCAL STATE
  //===================
  const [keywordScuola, setKeywordScuola] = useState('')
  const [keywordClasse, setKeywordClasse] = useState('')
  const [keywordAlunno, setKeywordAlunno] = useState('')
  const [nuovaSezioneClasse, setNuovaSezioneClasse] = useState('')
  const [nuovaSezioneSezione, setNuovaSezioneSezione] = useState('')
  const [nuovaSezioneAdulti, setNuovaSezioneAdulti] = useState(false)
  const [editClasseModalOpen, setEditClasseModalOpen] = useState(false)
  const [editedSezioneSel, setEditedSezioneSel] = useState({
    classe: '',
    sezione: '',
    adulti: false,
  })
  const [creaNuovaSezione, setCreaNuovaSezione] = useState(false)
  const [nuovoAlunnoCognome, setNuovoAlunnoCognome] = useState('')
  const [nuovoAlunnoNome, setNuovoAlunnoNome] = useState('')
  const [creaNuovoAlunno, setCreaNuovoAlunno] = useState(false)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: scuole, isFetching: isFetchingScuole } = useScuoleByServizioId(servizioId)
  const { data: sezioni, isFetching: isFetchingSezioni } = useSezioniByScuolaId({
    scuolaId: scuolaSel + '',
    annoScolastico,
  })
  const { data: alunni, isFetching: isFetchingAlunni } = useAlunniBySezioneId(sezioneSel)

  const sezioneSelCompleta = sezioni?.find((s: any) => s.id === sezioneSel)

  //===================
  // HOOKS MUTATIONS
  //===================
  const updateSezione = useUpdateSezioneById(sezioneSel)
  const createSezione = useCreateSezione()
  const createAlunno = useCreateAlunno()

  const search = (keywords: string, fields: string[]) => (item: { [key: string]: string }) =>
    keywords
      .split(' ')
      .every((term) =>
        fields.some(
          (attribute: string) =>
            item[attribute] && item[attribute].toLowerCase().indexOf(term) !== -1
        )
      )

  const handleCreateSezione = () => {
    createSezione.mutate({
      classe: nuovaSezioneClasse,
      sezione: nuovaSezioneSezione,
      adulti: nuovaSezioneAdulti,
      annoScolastico,
      scuolaId: scuolaSel,
    })
    setCreaNuovaSezione(false)
  }

  const handleEditSezione = () => {
    setEditClasseModalOpen(true)
    setEditedSezioneSel({
      classe: sezioneSelCompleta.classe,
      sezione: sezioneSelCompleta.sezione,
      adulti: sezioneSelCompleta.adulti,
    })
  }

  const handleCreateAlunno = () => {
    createAlunno.mutate({
      servizioId,
      alunno: {
        cognome: nuovoAlunnoCognome,
        nome: nuovoAlunnoNome,
        sezioneId: sezioneSel,
        annoScolastico,
      },
    })
    setCreaNuovoAlunno(false)
  }

  return (
    <div className="pb-20 min-h-screen">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-4 flex flex-col sm:flex-row">
          <div className="scuola w-1/3 pr-6">
            <input
              type="text"
              className="py-2 px-4 w-full text-gray-700 rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500 text-sm mb-4"
              placeholder="Scuola..."
              value={keywordScuola}
              onChange={(e) => setKeywordScuola(e.target.value)}
            />
            <div>
              {isFetchingScuole && <Loading />}
              {scuole &&
                scuole.filter(search(keywordScuola, ['nome', 'tipo'])).map((s: any) => (
                  <div
                    key={s.id}
                    className={classNames(
                      'text-md text-blue-900 px-4 py-1 rounded-lg cursor-pointer mb-1',
                      {
                        'bg-blue-200 font-medium': scuolaSel === s.id,
                      }
                    )}
                    onClick={(e) => {
                      dispatch(changeScuolaSel(s.id))
                      dispatch(changeSezioneSel(0))
                    }}
                  >
                    <span className="capitalize">{s.nome}</span>
                  </div>
                ))}
            </div>
          </div>
          {!!scuolaSel && (
            <>
              <div className="classe w-1/3 px-6 border-l-2 border-blue-200">
                <input
                  type="text"
                  className="py-2 px-4 w-full text-gray-700 rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500 text-sm mb-4"
                  placeholder="Classe..."
                  value={keywordClasse}
                  onChange={(e) => setKeywordClasse(e.target.value)}
                />
                <div>
                  {!creaNuovaSezione && (
                    <button
                      type="button"
                      className="block w-full mb-4 bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                      onClick={() => setCreaNuovaSezione(true)}
                    >
                      Crea nuova
                    </button>
                  )}
                  {creaNuovaSezione && (
                    <div className="flex space-x-4 mb-4 p-4 items-center bg-gray-200">
                      <div className="w-2/3">
                        <div className="flex space-x-2 mb-2">
                          <input
                            type="text"
                            placeholder="Classe"
                            className="mt-0 form-input w-0 flex-1 text-sm"
                            value={nuovaSezioneClasse}
                            onChange={(e) => setNuovaSezioneClasse(e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Sezione"
                            className="mt-0 form-input w-0 flex-1 text-sm"
                            value={nuovaSezioneSezione}
                            onChange={(e) => setNuovaSezioneSezione(e.target.value)}
                          />
                        </div>
                        <div className="flex space-x-2 items-center text-sm">
                          <input
                            id="sezioneAdulti"
                            type="checkbox"
                            className="form-checkbox h-6 w-6"
                            checked={nuovaSezioneAdulti}
                            onChange={(e) => setNuovaSezioneAdulti(e.target.checked)}
                          />
                          <label htmlFor="sezioneAdulti">Classe adulti?</label>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="w-1/3 block bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-3 rounded transition-colors duration-150"
                        onClick={handleCreateSezione}
                      >
                        Crea
                      </button>
                    </div>
                  )}
                  {isFetchingSezioni && <Loading />}
                  {sezioni &&
                    sezioni.filter(search(keywordClasse, ['classe', 'sezione'])).map((c: any) => {
                      return (
                        <div
                          key={c.id}
                          className={classNames(
                            'text-md px-4 py-1 rounded-lg cursor-pointer mb-1',
                            {
                              'bg-blue-200 font-medium text-blue-900':
                                sezioneSel === c.id && !c.adulti,
                            },
                            {
                              'bg-blue-200 font-semibold text-pink-700':
                                sezioneSel === c.id && c.adulti,
                            }
                          )}
                          onClick={(e) => dispatch(changeSezioneSel(c.id))}
                        >
                          {sezioneSel === c.id ? (
                            <div className="flex justify-between items-center">
                              {/* <div className="flex items-center">
                                <InlineEdit
                                  type={InputType.Text}
                                  value={c.classe}
                                  onChange={(v) => {
                                    updateSezione.mutate({ classe: v })
                                  }}
                                  viewClass="mr-1"
                                  render={(v) => (
                                    <span>
                                      {v}
                                      <sup>a</sup>
                                    </span>
                                  )}
                                />
                                <InlineEdit
                                  type={InputType.Text}
                                  value={c.sezione}
                                  onChange={(v) => {
                                    updateSezione.mutate({ sezione: v })
                                  }}
                                />
                              </div> */}
                              <span>
                                {c.classe}
                                <sup>a</sup> {c.sezione}
                              </span>
                              <button
                                type="button"
                                onClick={handleEditSezione}
                                className="text-blue-400"
                              >
                                <FiEdit />
                              </button>
                            </div>
                          ) : (
                            <span
                              className={classNames({ 'text-pink-600 font-semibold': c.adulti })}
                            >
                              {c.classe}
                              <sup>a</sup> {c.sezione}
                            </span>
                          )}
                        </div>
                      )
                    })}
                </div>
              </div>

              {!!sezioneSel && (
                <div className="alunno w-1/3 pl-6 border-l-2 border-blue-200">
                  <input
                    type="text"
                    className="py-2 px-4 w-full text-gray-700 rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500 text-sm mb-4"
                    placeholder="Nome cognome..."
                    value={keywordAlunno}
                    onChange={(e) => setKeywordAlunno(e.target.value)}
                  />
                  <div>
                    {!creaNuovoAlunno && (
                      <button
                        type="button"
                        className="block w-full mb-4 bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                        onClick={() => setCreaNuovoAlunno(true)}
                      >
                        Crea nuovo
                      </button>
                    )}
                    {creaNuovoAlunno && (
                      <div className="flex space-x-2 mb-4 p-4 items-center bg-gray-200">
                        <div className="w-2/3">
                          <div className="flex items-center space-x-2">
                            <input
                              type="text"
                              className="mt-0 form-input w-0 flex-1 text-sm"
                              value={nuovoAlunnoCognome}
                              placeholder="Cognome"
                              onChange={(e) => setNuovoAlunnoCognome(e.target.value)}
                            />
                            <input
                              type="text"
                              className="mt-0 form-input w-0 flex-1 text-sm"
                              value={nuovoAlunnoNome}
                              placeholder="Nome"
                              onChange={(e) => setNuovoAlunnoNome(e.target.value)}
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="w-1/3 block bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-3 rounded transition-colors duration-150"
                          onClick={handleCreateAlunno}
                        >
                          Crea
                        </button>
                      </div>
                    )}
                    {isFetchingAlunni && <Loading />}
                    {alunni &&
                      alunni
                        .filter(search(keywordAlunno, ['cognome', 'nome']))
                        .map((alunno: any) => {
                          return (
                            <Link to={`alunno/${alunno.id}`} key={alunno.id}>
                              <div
                                className={`text-md capitalize px-4 py-1 rounded-lg mb-1 ${
                                  !alunno.nome
                                    ? 'text-red-600'
                                    : alunno.dataRitiro &&
                                      moment(alunno.dataRitiro).isBefore(moment())
                                    ? 'text-gray-400'
                                    : 'text-gray-800'
                                }`}
                              >
                                {alunno.cognome.toLowerCase()}{' '}
                                {alunno.nome && alunno.nome.toLowerCase()}
                              </div>
                            </Link>
                          )
                        })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* EDIT CLASSE MODAL */}
      <ReactModal
        isOpen={editClasseModalOpen}
        onRequestClose={() => {
          setEditClasseModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Modifica {sezioneSelCompleta?.classe}
            <sup>a</sup> {sezioneSelCompleta?.sezione}
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div>
              <div className="flex space-x-2 mb-2">
                <input
                  type="text"
                  placeholder="Classe"
                  className="mt-0 form-input w-0 flex-1 text-sm"
                  value={editedSezioneSel.classe}
                  onChange={(e) =>
                    setEditedSezioneSel({ ...editedSezioneSel, classe: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Sezione"
                  className="mt-0 form-input w-0 flex-1 text-sm"
                  value={editedSezioneSel.sezione}
                  onChange={(e) =>
                    setEditedSezioneSel({ ...editedSezioneSel, sezione: e.target.value })
                  }
                />
              </div>
              <div className="flex space-x-2 items-center text-sm">
                <input
                  id="sezioneAdulti"
                  type="checkbox"
                  className="form-checkbox h-6 w-6"
                  checked={editedSezioneSel.adulti}
                  onChange={(e) =>
                    setEditedSezioneSel({ ...editedSezioneSel, adulti: e.target.checked })
                  }
                />
                <label htmlFor="sezioneAdulti">Classe adulti?</label>
              </div>
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setEditClasseModalOpen(false)
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="submit"
                onClick={() => {
                  setEditClasseModalOpen(false)
                  updateSezione.mutate({
                    classe: editedSezioneSel.classe,
                    sezione: editedSezioneSel.sezione,
                    adulti: editedSezioneSel.adulti,
                  })
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Modifica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export default Elenco

import { useQuery } from 'react-query'
import axios from 'axios'

const getAlunniBySezioneId = async (_: string, sezioneId: number) => {
  const { data } = await axios.get(`/sezioni/${sezioneId}/alunni?limit=500`)

  return data.results
}

const useAlunniBySezioneId = (sezioneId: number) => {
  return useQuery(['alunniBySezioneId', sezioneId], () => getAlunniBySezioneId('alunniBySezioneId', sezioneId), {
    enabled: !!sezioneId,
  })
}

export default useAlunniBySezioneId

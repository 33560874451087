interface Option {
  value: number
  label: string
}

export const ASILO_GRATIS_OPTIONS: Option[] = [
  { value: 0, label: 'No' },
  { value: 1, label: 'Provvisorio' },
  { value: 2, label: 'Accettato' },
]

export const getLabelByValue = (value: number) => {
  const option = ASILO_GRATIS_OPTIONS.find(f => f.value === value)

  return option ? option.label : ''
}
import React, { useState, useContext } from 'react'
import { Link, navigate } from '@reach/router'
import { useQueryClient } from 'react-query'
import InlineEdit, { InputType } from 'riec'
import classNames from 'classnames'
import { FiUser, FiPower, FiCoffee } from 'react-icons/fi'

import AnnoScolasticoContext from '../context/AnnoScolasticoContext'
import ServizioContext from '../context/ServizioContext'

import useProfile from '../apiHooks/queries/useProfile'
import useServizi from '../apiHooks/queries/useServizi'
import useServiziGenitore from '../apiHooks/queries/useServiziGenitore'

import logo from '../images/logo.svg'
import './Header.css'
// import { LAST_YEAR } from '../config'

const isPartiallyActive = ({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) => {
  return isPartiallyCurrent ? { className: 'pt-1 pb-3 mr-8 border-b-2 border-blue-500' } : {}
}

const Header: React.FC = () => {
  const queryClient = useQueryClient()

  const { annoScolastico, handleChangeAnnoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId, handleChangeServizioId } = useContext(ServizioContext)

  const clienteId = localStorage.getItem('clienteId')

  const { data: user } = useProfile(' ', clienteId ? parseInt(clienteId, 10) : undefined)

  const { data: serviziGenitore } = useServiziGenitore(
    user?.ruolo,
    clienteId ? parseInt(clienteId, 10) : undefined
  )
  const { data: servizi } = useServizi(user?.ruolo)

  const serviziToShow = user?.ruolo === 'genitore' ? serviziGenitore : servizi

  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('nome')
    localStorage.removeItem('cognome')
    localStorage.removeItem('servizioId')
    localStorage.removeItem('clienteId')
    localStorage.removeItem('intestatarioId')
    queryClient.clear()
    navigate('/')
  }

  const handlePausa = () => {
    localStorage.removeItem('token')
    queryClient.clear()
    navigate('/pausa')
  }

  if (!user || !serviziToShow) {
    return null
  }

  return (
    <header className="bg-white border-b border-gray-300">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="bg-white">
          <div className="flex items-center justify-between h-16">
            <div className="left flex items-center">
              <img src={logo} alt="Rette Web" className="w-12 mr-4" />
              {/* {user?.ruolo === 'genitore' ? (
                <div className="text-gray-700 font-semibold">Area genitori</div>
              ) : ( */}
              <div className="text-sm text-gray-700">
                <div className="flex items-center">
                  <div>{user.cliente}</div>
                  <div className="mx-2 text-gray-400">•</div>
                  <div>
                    <InlineEdit
                      type={InputType.Select}
                      value={servizioId + ''}
                      onChange={(v) => handleChangeServizioId(+v)}
                      options={user?.servizi.map((s: any) => ({
                        value: s + '',
                        label: serviziToShow.find((servizio: any) => servizio.id === s).nome,
                      }))}
                      viewClass="cursor-pointer font-semibold hover:text-blue-600"
                      editClass="form-select text-sm"
                      loadingClass="text-red-600"
                    />
                  </div>
                  <div className="mx-2 text-gray-400">•</div>
                  <div>
                    <InlineEdit
                      type={InputType.Select}
                      value={annoScolastico}
                      onChange={handleChangeAnnoScolastico}
                      options={[
                        { value: '2020-2021', label: '2020-2021' },
                        { value: '2021-2022', label: '2021-2022' },
                        { value: '2022-2023', label: '2022-2023' },
                        { value: '2023-2024', label: '2023-2024' },
                        { value: '2024-2025', label: '2024-2025' },
                      ]}
                      viewClass="cursor-pointer font-semibold hover:text-blue-600"
                      editClass="form-select text-sm"
                      loadingClass="text-red-600"
                    />
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
            <div className="right flex items-center relative">
              <div
                className="w-8 h-8 border rounded-full flex justify-center items-center text-blue-500 bg-blue-50 border-blue-100 cursor-pointer"
                onClick={() => setUserMenuOpen((userMenuOpen) => !userMenuOpen)}
              >
                <FiUser />
              </div>
              <div
                className="text-sm text-gray-600 ml-2 leading-none cursor-pointer"
                onClick={() => setUserMenuOpen((userMenuOpen) => !userMenuOpen)}
              >
                {user?.nome} {user?.cognome}
              </div>
              <div
                className={classNames('absolute top-0 right-0 mt-10 rounded-md shadow-lg', {
                  hidden: !userMenuOpen,
                })}
                style={{ width: 150 }}
              >
                <div className="py-1 rounded-md bg-white shadow-xs">
                  <button
                    className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center user-menu-item"
                    onClick={handlePausa}
                  >
                    <FiCoffee className="text-gray-500 mr-2 pausa" />
                    Pausa Caffè
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center user-menu-item"
                    onClick={handleLogout}
                  >
                    <FiPower className="text-gray-500 mr-2 esci" />
                    Esci
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav className="bg-white">
          <div className="flex items-center justify-start text-sm">
            {user.ruolo === 'amministrazione' && (
              <Link
                to="anagrafica"
                className="pt-1 pb-3 mr-8 text-gray-600"
                getProps={isPartiallyActive}
              >
                Anagrafica
              </Link>
            )}
            {(user.ruolo === 'amministrazione' || user.ruolo === 'presenze') && (
              <Link
                to="presenze"
                className="pt-1 pb-3 mr-8 text-gray-600"
                getProps={isPartiallyActive}
              >
                Presenze
              </Link>
            )}
            {user.ruolo === 'cucina' && (
              <Link
                to="presenze/cucina"
                className="pt-1 pb-3 mr-8 text-gray-600"
                getProps={isPartiallyActive}
              >
                Presenze cucina
              </Link>
            )}
            {user.ruolo === 'amministrazione' && (
              <Link
                to="avvisi-pagamento"
                className="pt-1 pb-3 mr-8 text-gray-600"
                getProps={isPartiallyActive}
              >
                Avvisi pagamento
              </Link>
            )}
            {user.ruolo === 'amministrazione' &&
              (user.servizi.includes(2) ||
                user.servizi.includes(3) ||
                user.servizi.includes(4)) && ( // Mostrare le iscrizioni solo a Bergamo Mensa e Nidi
                <Link
                  to="iscrizioni"
                  className="pt-1 pb-3 mr-8 text-gray-600"
                  getProps={isPartiallyActive}
                >
                  Iscrizioni
                </Link>
              )}

            {user.ruolo === 'amministrazione' &&
              (user.servizi.includes(2) || user.servizi.includes(4)) && ( // Mostrare le graduatorie solo a Bergamo Nidi
                <Link
                  to="graduatoria"
                  className="pt-1 pb-3 mr-8 text-gray-600"
                  getProps={isPartiallyActive}
                >
                  Graduatoria
                </Link>
              )}

            {user.ruolo === 'amministrazione' && (
              <Link
                to="impostazioni"
                className="pt-1 pb-3 text-gray-600"
                getProps={isPartiallyActive}
              >
                Impostazioni
              </Link>
            )}
            {user.ruolo === 'genitore' && (
              <>
                <Link
                  to="genitori/bollette"
                  className="pt-1 pb-3 mr-8 text-gray-600"
                  getProps={isPartiallyActive}
                >
                  Bollette
                </Link>
                <Link
                  to="genitori/presenze"
                  className="pt-1 pb-3 mr-8 text-gray-600"
                  getProps={isPartiallyActive}
                >
                  Presenze
                </Link>
              </>
            )}
            {user.ruolo === 'genitore' &&
              (servizioId === 5 || servizioId === 6 || servizioId === 7) && (
                <Link
                  to="genitori/certificazione-pagamenti"
                  className="pt-1 pb-3 text-gray-600"
                  getProps={isPartiallyActive}
                >
                  Certificazione pagamenti
                </Link>
              )}
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header

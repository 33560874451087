import React from 'react'
import classNames from 'classnames'
import { FixedSizeList } from 'react-window'

interface PresenzeMensiliTableProps {
  getTableProps: any
  getTableBodyProps: any
  headerGroups: any
  footerGroups: any
  totalColumnsWidth: any
  rows: any
  prepareRow: any
}

const PresenzeMensiliTable: React.FC<PresenzeMensiliTableProps> = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  totalColumnsWidth,
  rows,
  prepareRow,
}) => {
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr hover:bg-blue-100 flex h-full items-center border-b border-gray-400"
        >
          {row.cells.map((cell: any) => {
            return (
              <div
                {...cell.getCellProps()}
                className="td h-full overflow-y-hidden leading-tight p-0"
              >
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  return (
    <div>
      <>
        <div className="table-wrapper block max-w-full overflow-x-scroll overflow-y-hidden">
          <div
            {...getTableProps()}
            className="table w-full max-w-6xl text-sm border border-gray-400 rounded rounded-tr-none"
          >
            <div>
              {headerGroups.map((headerGroup: any) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column: any) => (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={classNames(
                        'th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold',
                        {
                          'text-right': column.Header === 'Totale',
                        },
                        {
                          'text-center': column.Header === 'P',
                        }
                      )}
                    >
                      {column.render('Header')}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                      <div className="pt-2 w-full" onClick={(e) => e.stopPropagation()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()}>
              <FixedSizeList
                height={400}
                itemCount={rows.length}
                itemSize={42}
                width={totalColumnsWidth}
              >
                {RenderRow}
              </FixedSizeList>
            </div>
            {/* {footerGroups[0].headers.length < 1 && */}
            {footerGroups.map((group: any) => (
              <div className="tr footer-tr" {...group.getFooterGroupProps()}>
                {group.headers.map((column: any) => (
                  <div className="td" {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  )
}

export default PresenzeMensiliTable

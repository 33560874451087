import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, Link } from '@reach/router'

import useProfile from '../../apiHooks/queries/useProfile'
import useAlunniGenitore2Anni from '../../apiHooks/queries/useAlunniGenitore2Anni'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import Loading from '../../components/Loading'

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-900 text-sm font-medium rounded',
      }
    : {}
}

const Genitori: React.FC<RouteComponentProps> = ({ children, location }) => {
  const rotta =
    location && location.pathname.indexOf('bollette') > 0
      ? 'bollette'
      : location && location.pathname.indexOf('presenze') > 0
      ? 'presenze'
      : 'certificazione-pagamenti'

  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const annoScolasticoPrecedente = annoScolastico
    ?.split('-')
    .map((anno) => parseInt(anno) - 1)
    .join('-')

  const servizioId = localStorage.getItem('servizioId')
  const clienteId = localStorage.getItem('clienteId')

  const [alunni, setAlunni] = useState<any[]>([])
  const { data: user } = useProfile(' ', clienteId ? parseInt(clienteId, 10) : undefined)

  const { data, isFetching: isFetchingAlunni } = useAlunniGenitore2Anni(
    annoScolastico,
    annoScolasticoPrecedente,
    parseInt(servizioId ? servizioId : '')
  )
  const { alunniAnnoCorrente, alunniAnnoPrecedente } = data || {}

  useEffect(() => {
    if (alunniAnnoCorrente) {
      if (rotta === 'certificazione-pagamenti' && alunniAnnoPrecedente) {
        const alunni = [...alunniAnnoCorrente]
        for (const alunno of alunniAnnoPrecedente) {
          if (!alunni.find((a: any) => a.codiceFiscale === alunno.codiceFiscale)) {
            alunni.push(alunno)
          }
        }
        setAlunni(alunni)
      } else {
        setAlunni(alunniAnnoCorrente)
      }
    }
  }, [alunniAnnoCorrente, alunniAnnoPrecedente, annoScolastico, rotta])

  if (!user) {
    return null
  }

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex items-center space-x-4">
          <div className="text-sm ">Seleziona un alunno:</div>
          {!isFetchingAlunni ? (
            alunni.map((alunno: any) => {
              return (
                <Link
                  key={alunno.id}
                  to={`./${rotta}/${alunno.id}`}
                  className="py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
                  getProps={isActive}
                >
                  {alunno.nome} {alunno.cognome}
                </Link>
              )
            })
          ) : (
            <Loading size={20} />
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export default Genitori

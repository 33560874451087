import { useQuery } from 'react-query'
import axios from 'axios'

interface GetAlunnoArgs {
  alunnoId?: number
  annoScolastico?: string
}

const getAlunnoByIdByAnno = async (_: string, { alunnoId, annoScolastico }: GetAlunnoArgs) => {
  const { data } = await axios.get(`/alunni/${alunnoId}/anno/${annoScolastico}`)
  return data
}

const useAlunnoByIdByAnno = ({ alunnoId, annoScolastico }: GetAlunnoArgs) => {
  return useQuery(
    ['alunnoByIdByAnno', { alunnoId, annoScolastico }],
    () => getAlunnoByIdByAnno('alunnoByIdByAnno', { alunnoId, annoScolastico }),
    { enabled: !!alunnoId && !!annoScolastico }
  )
}

export default useAlunnoByIdByAnno

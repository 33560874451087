import { useQuery } from 'react-query'
import axios from 'axios'

const getDieteByServizioId = async (_: string, servizioId: number) => {
  const { data } = await axios.get(`/servizi/${servizioId}/diete`)

  return data.results
}

const useDieteByServizioId = (servizioId: number) => {
  return useQuery(['dieteByServizioId', servizioId], () => getDieteByServizioId('dieteByServizioId', servizioId), {
    enabled: !!servizioId,
    // 20 minuti
    staleTime: 20 * 60 * 1000,
  })
}

export default useDieteByServizioId

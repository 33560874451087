import { useQuery } from 'react-query'
import axios from 'axios'

interface IFruizioniDaControllare {
  annoScolastico: string
  servizioId: number
  scuolaId?: number
}

const getCountFruizioniDaControllare = async (
  _: string,
  { annoScolastico, servizioId, scuolaId }: IFruizioniDaControllare
) => {
  const scuolaIdQuery = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const { data } = await axios.get(
    `/fruizioni/da-controllare?limit=1&annoScolastico=${annoScolastico}&servizioId=${servizioId}${scuolaIdQuery}`
  )

  return data.count
}

const useCountFruizioniDaControllare = ({ annoScolastico, servizioId, scuolaId }: IFruizioniDaControllare) => {
  return useQuery(
    ['countFruizioniDaControllare', { annoScolastico, servizioId, scuolaId }],
    () => getCountFruizioniDaControllare('countFruizioniDaControllare', { annoScolastico, servizioId, scuolaId }),
    {
      enabled: !!annoScolastico && !!servizioId,
      // 5 minuti
      //staleTime: 5 * 60 * 1000,
    }
  )
}

export default useCountFruizioniDaControllare

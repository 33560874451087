import { createSlice } from '@reduxjs/toolkit'

export const anagraficaElenco = createSlice({
  name: 'anagraficaElenco',
  initialState: {
    scuolaSel: 0,
    sezioneSel: 0,
  },
  reducers: {
    changeScuolaSel: (state, action) => {
      state.scuolaSel = action.payload
    },
    changeSezioneSel: (state, action) => {
      state.sezioneSel = action.payload
    },
  },
})

export const selectAnagraficaElenco = (state: {
  anagraficaElenco: { scuolaSel: number; sezioneSel: number }
}) => state

export const { changeScuolaSel, changeSezioneSel } = anagraficaElenco.actions

export default anagraficaElenco.reducer

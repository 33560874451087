import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface UpdateDieta {
  id: number
  tipoDieta: string
  nomeDieta: string
  servizioId: number
}

const updateDieta = async (servizioId: number, dieta: UpdateDieta) => {
  const { data } = await axios.patch(`/servizi/${servizioId}/diete/${dieta.id}`, dieta)
  return data
}

const useUpdateDieta = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ servizioId, dieta }: { servizioId: number; dieta: UpdateDieta }) => {
      return updateDieta(servizioId, dieta)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('dieteByServizioId')
        cogoToast.success('Dieta aggiornata')
      },
      onError: () => {},
    }
  )
}

export default useUpdateDieta

import axios from 'axios'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from 'react-query'
import { Fascia } from '../queries/useFasceByServizioId'

const updateFascia = async (servizioId: number, fascia: Fascia) => {
  return await axios.patch(`/fasce-calcolo/${fascia.id}/`, { ...fascia, servizioId })
}

const useUpdateFascia = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ servizioId, fascia }: { servizioId: number; fascia: any }) => {
      return updateFascia(servizioId, fascia)
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(['fasceByServizioId', data.servizioId])
        cogoToast.success('Fascia modificata correttamente')
      },
    }
  )
}

export default useUpdateFascia

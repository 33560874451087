import { useQuery } from 'react-query'
import axios from 'axios'

const getServiziGenitore = async (clienteId?: number | null) => {
  const { data } = await axios.get(`/servizi/genitore/?clienteId=${clienteId}`)

  return data.results
}

const useServiziGenitore = (ruolo: string, clienteId?: number | null) => {
  return useQuery('serviziGenitore', () => getServiziGenitore(clienteId), {
    enabled: ruolo === 'genitore',
    // 30 minuti
    staleTime: 30 * 60 * 1000,
  })
}

export default useServiziGenitore

import React, { useState, useEffect } from 'react'
import { RouteComponentProps, Router, navigate } from '@reach/router'
import moment from 'moment'

import AnnoScolasticoContext from '../context/AnnoScolasticoContext'
import ServizioContext from '../context/ServizioContext'
import useProfile from '../apiHooks/queries/useProfile'

import Header from './Header'
import Footer from './Footer'

import Home from '../pages/home'
import Anagrafica from '../pages/anagrafica'
import Ricerche from '../pages/anagrafica/liste'
import Lista from '../pages/anagrafica/liste/Elenco'
import Ricerca from '../pages/anagrafica/liste/Ricerca'
import AlunniDaControllare from '../pages/anagrafica/liste/AlunniDaControllare'
import FruizioniDaControllare from '../pages/anagrafica/liste/FruizioniDaControllare'
import Alunno from '../pages/anagrafica/alunno'
import Presenze from '../pages/presenze'
import PresenzeGiornaliere from '../pages/presenze/giornaliere'
import PresenzeMensili from '../pages/presenze/mensili'
import Fatturazione from '../pages/presenze/fatturazione'
import PresenzeCucina from '../pages/presenze/cucina'
import AvvisiPagamento from '../pages/avvisiPagamento'
import Documenti from '../pages/avvisiPagamento/documenti'
import Avvisi from '../pages/avvisiPagamento/avvisi'
import EsitiBonifici from '../pages/avvisiPagamento/esitiBonifici'
import Ricevute from '../pages/avvisiPagamento/ricevute'
import Insoluti from '../pages/avvisiPagamento/insoluti'
import Solleciti from '../pages/avvisiPagamento/solleciti'
import Impostazioni from '../pages/impostazioni'
// import ParametriCalcolo from '../pages/impostazioni/parametri-calcolo'
import GiorniChiusura from '../pages/impostazioni/giorni-chiusura'
import Esportazioni from '../pages/impostazioni/esportazioni'
import Diete from '../pages/impostazioni/diete'
import Genitori from '../pages/genitori'
import GenitoriBollette from '../pages/genitori/bollette'
import GenitoriPresenze from '../pages/genitori/presenze'
import GenitoriCertificazionePagamenti from '../pages/genitori/certificazionePagamenti'
import Fasce from '../pages/impostazioni/fasce/fasce'
import ParametriServizio from '../pages/impostazioni/parametri-servizio'
import Iscrizioni from '../pages/iscrizioni'
import ListaIscrizioni from '../pages/iscrizioni/ListaIscrizioni'
import RiepilogoDomanda from '../pages/iscrizioni/DomandaIscrizione'
import Graduatoria from '../pages/graduatoria'

const LoggedApp: React.FC<RouteComponentProps> = ({ children }) => {
  //==============================
  // RECUPERIAMO UTENTE
  // =============================
  const token = localStorage.getItem('token')
  const clienteId = localStorage.getItem('clienteId')

  const { data: user } = useProfile(token, clienteId ? parseInt(clienteId, 10) : undefined)

  //==============================
  // ANNO SCOLASTICO PER CONTEXT
  // =============================
  const meseCorrente = moment().month()
  const annoCorrente = moment().year()
  let annoScolasticoCorrente = ''

  if (meseCorrente > 7) {
    annoScolasticoCorrente = `${annoCorrente}-${annoCorrente + 1}`
  } else {
    annoScolasticoCorrente = `${annoCorrente - 1}-${annoCorrente}`
  }

  const ultimoAnnoUsato = localStorage.getItem('annoScolastico')

  const [annoScolastico, setAnnoScolastico] = useState<string>(
    ultimoAnnoUsato || annoScolasticoCorrente
  )

  const handleChangeAnnoScolastico = (anno: string) => {
    navigate('/app').then(() => {
      setAnnoScolastico(anno)
      localStorage.setItem('annoScolastico', anno)
    })
  }

  //==============================
  // SERVIZIO PER CONTEXT
  // =============================
  const ultimoServizioUsato = localStorage.getItem('servizioId')
  const [servizioId, setServizioId] = useState<number>(
    ultimoServizioUsato ? +ultimoServizioUsato : 0
  )

  const handleChangeServizioId = (servizioId: number) => {
    navigate('/app').then(() => {
      setServizioId(servizioId)
      localStorage.setItem('servizioId', servizioId + '')
    })
  }

  useEffect(() => {
    if (user && user.servizi && user.servizi.length > 0) {
      setServizioId(ultimoServizioUsato ? +ultimoServizioUsato : user.servizi[0])
      localStorage.setItem(
        'servizioId',
        ultimoServizioUsato ? +ultimoServizioUsato : user.servizi[0]
      )
    }
  }, [user, ultimoServizioUsato])

  if (!user) {
    // Non dovresti essere qui...
    return null
  }

  return (
    <div className="logged-app">
      <AnnoScolasticoContext.Provider value={{ annoScolastico, handleChangeAnnoScolastico }}>
        <ServizioContext.Provider value={{ servizioId, handleChangeServizioId }}>
          <Header />
          <div
            className="logged"
            style={{
              backgroundColor: 'hsla(208, 85%, 98%, 1)',
              minHeight: 'calc(100vh - 223px)',
            }}
          >
            <Router primary={false}>
              <Home path="/" />
              <Anagrafica path="anagrafica">
                <Ricerche path="/">
                  <Lista path="/" />
                  <Ricerca path="ricerca" />
                  <AlunniDaControllare path="anomalie-alunni" />
                  <FruizioniDaControllare path="anomalie-fruizioni" />
                </Ricerche>
                <Alunno path="alunno/:id/*"></Alunno>
              </Anagrafica>
              <Presenze path="presenze">
                <PresenzeGiornaliere path="/" />
                <PresenzeMensili path="mensili" />
                <Fatturazione path="fatturazione" />
                <PresenzeCucina path="cucina" />
              </Presenze>
              <AvvisiPagamento path="avvisi-pagamento">
                <Documenti path="/" />
                <Avvisi path="avvisi" />
                <EsitiBonifici path="avvisi/esiti-bonifici" />
                <Ricevute path="ricevute" />
                <Insoluti path="insoluti" />
                <Solleciti path="solleciti" />
              </AvvisiPagamento>
              {(user.servizi.includes(2) ||
                user.servizi.includes(3) ||
                user.servizi.includes(4)) && (
                <Iscrizioni path="iscrizioni">
                  <ListaIscrizioni path="/" />
                  <RiepilogoDomanda path=":id/riepilogo-domanda" />
                </Iscrizioni>
              )}
              {(servizioId === 2 || servizioId === 4) && <Graduatoria path="graduatoria" />}
              <Impostazioni path="impostazioni">
                {/* <ParametriCalcolo path="parametri-calcolo" /> */}
                <GiorniChiusura path="/" />
                <Esportazioni path="esportazioni" />
                <Diete path="diete" />
                <Fasce path="fasce" />
                <ParametriServizio path="parametri-servizio" />
              </Impostazioni>
              <Genitori path="genitori">
                <GenitoriBollette path="/bollette/:id" />
                <GenitoriPresenze path="/presenze/:id" />
                {(servizioId === 5 || servizioId === 6 || servizioId === 7) && (
                  <GenitoriCertificazionePagamenti path="/certificazione-pagamenti/:id" />
                )}
              </Genitori>
            </Router>
          </div>
          <Footer />
        </ServizioContext.Provider>
      </AnnoScolasticoContext.Provider>
    </div>
  )
}

export default LoggedApp

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const deletePagamento = async (pagamentoId: number) => {
  const { data } = await axios.delete(`/pagamenti/${pagamentoId}/`)
  return data
}

const useDeletePagamento = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (pagamentoId: number) => {
      return deletePagamento(pagamentoId)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('avvisiPagamentoByMese', {}, { cancelRefetch: true })
        cogoToast.success('Pagamento eliminato')
      },
      onError: () => {},
    }
  )
}

export default useDeletePagamento

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface GetTokenArgs {
  username: string
  password: string
}

const getToken = async ({ username, password }: GetTokenArgs) => {
  const { data } = await axios.post(`/auth-token/`, { username, password })
  return data
}

const useLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ username, password }: { username: string; password: string }) => {
      return getToken({ username, password })
    },
    {
      onSuccess: (data: any) => {
        // update cache
        const {
          user: {
            username,
            operatore: { nome, cognome, ruolo, servizi },
          },
        } = data
        queryClient.setQueryData(['profile'], {
          username,
          nome,
          cognome,
          ruolo,
          servizi,
        })
      },
      onError: () => {},
    }
  )
}

export default useLogin

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface IUpdateFruizione {
  fruizioneId?: number
  fruizione?: any
}

const updateFruizione = async ({ fruizioneId, fruizione }: IUpdateFruizione) => {
  const { data } = await axios.patch(`/fruizioni/${fruizioneId}/`, fruizione)
  return data
}

const useUpdateFruizione = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ fruizioneId, fruizione }: { fruizioneId: number; fruizione: any }) => {
      return updateFruizione({ fruizioneId, fruizione })
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('alunnoByIdByAnno')
        queryClient.invalidateQueries('presenzeAlunniData')
        queryClient.invalidateQueries('countFruizioniDaControllare')
        cogoToast.success('Servizio aggiornato')
      },
      onError: () => {},
    }
  )
}

export default useUpdateFruizione

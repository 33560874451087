import { useQuery } from 'react-query'
import axios from 'axios'

const getGraduatoria = async (_: string, annoScolastico: string) => {
  const { data } = await axios.get(`/iscrizioni-asilo/graduatoria?annoScolastico=${annoScolastico}`)

  return data
}

const useGraduatoria = (annoScolastico: string) => {
  return useQuery(
    ['graduatoria', annoScolastico],
    () => getGraduatoria('graduatoria', annoScolastico),
    {
      enabled: !!annoScolastico,
    }
  )
}

export default useGraduatoria

import React, { useState, useContext, useEffect } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import InlineEdit, { InputType } from 'riec'
import classNames from 'classnames'
import moment from 'moment'
import ReactModal from 'react-modal'
import { FiEdit } from 'react-icons/fi'
import CreatableSelect from 'react-select/creatable'
import { useForm } from 'react-hook-form'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { useDispatch, useSelector } from 'react-redux'

import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'

import useProfile from '../../apiHooks/queries/useProfile'
import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import usePresenzeSezioniData from '../../apiHooks/queries/usePresenzeSezioniData'
import usePresenzeAlunniData from '../../apiHooks/queries/usePresenzeAlunniData'
import useAlunniAltreSezioni from '../../apiHooks/queries/useAlunniAltreSezioni'

import useUpdatePresenzeGiorno from '../../apiHooks/mutations/useUpdatePresenzeGiorno'
import useUpdateFruizione from '../../apiHooks/mutations/useUpdateFruizione'
import useCreateAlunno from '../../apiHooks/mutations/useCreateAlunno'
// import useUpdateAlunno from '../../apiHooks/mutations/useUpdateAlunno'
import useInviaEmailCucina from '../../apiHooks/mutations/useInviaEmailCucina'

import {
  selectPresenze,
  loadScuole,
  loadSezioneSel,
  changeScuolaSel,
  changeSezioneSel,
  changeDataSel,
} from '../../redux/modules/presenze'

import Loading from '../../components/Loading'
import FormField, { InputType as FormInputType } from '../../components/FormField'

import reactSelectCustomStyles from '../../components/reactSelectCustomStyles'
import 'react-day-picker/lib/style.css'

import MomentLocaleUtils from 'react-day-picker/moment'

import 'moment/locale/it'
import useGiorniNonErogazioneByServizioId from '../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import { isNonErogazione, isRitirato } from '../../lib/validazionePresenze'
import useDebounce from '../../lib/useDebounce'
import useServizioById from '../../apiHooks/queries/useServizioById'

ReactModal.setAppElement('#root')

const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]
const WEEKDAYS_LONG = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']

const modifiersStyles = {
  p: {
    color: '#48bb78',
  },
  a: {
    color: '#e53e3e',
  },
  b: {
    color: '#48bb78',
  },
  m: {
    color: '#48bb78',
  },
  nonErogazione: {
    backgroundColor: '#f0f0f0',
  },
  // errore: {
  //   //border: '2px solid #feb2b2',
  // },
  // warning: {
  //   border: '2px solid #fc0',
  //   // backgroundColor: '#fc0',
  // },
  outside: {
    border: 'none!important',
  },
  disabled: {
    backgroundColor: 'transparent!important',
  },
}

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

interface Scuola {
  id: number
  nome: string
}

interface Sezione {
  id: number
  presenze: number | null
  presenze_A: number
  presenze_P: number
  presenze_B: number
  presenze_M: number
  classe: string
  sezione: string
  scuolaId: number
  fittizia: boolean
}

type Giorno = 'lunBidella' | 'marBidella' | 'merBidella' | 'gioBidella' | 'venBidella'

interface Fruizione {
  id: number
  lun: boolean
  mar: boolean
  mer: boolean
  gio: boolean
  ven: boolean
  sab: boolean
  dom: boolean
  lunBidella: boolean
  marBidella: boolean
  merBidella: boolean
  gioBidella: boolean
  venBidella: boolean
  sabBidella: boolean
  domBidella: boolean
  noteBidella: string
  dataRitiroBidella: string
  dataInizio: string
  dataRitiro: string
  dataIscrizione: string
  centroEstivo: string
  pagamentoSospeso: boolean
}

type Presenza = 'P' | 'A' | 'B' | 'M'

interface Alunno {
  id: number
  nome: string
  cognome: string
  codiceFiscale: string
  fruizione: Fruizione
  presenza: string
}

interface Totali {
  P: number
  A: number
  B: number
  M: number
}

const totaliInitial = { P: 0, A: 0, B: 0, M: 0 }

const calcolaTotali = (alunni: Alunno[]): Totali => {
  const totali = alunni
    ? alunni.reduce((acc: Totali, alunno: Alunno) => {
        const presenza = alunno.presenza as Presenza
        const presenze = { ...acc }
        presenze[presenza] = presenze[presenza] + 1
        return presenze
      }, totaliInitial)
    : totaliInitial
  return totali
}

const PresenzeGiornaliere: React.FC<RouteComponentProps> = () => {
  //===================
  // REDUX
  //===================
  const presenze = useSelector(selectPresenze)
  const {
    presenze: { scuolaSel, sezioneSel, dataSel },
  } = presenze
  const dispatch = useDispatch()

  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { data: servizio } = useServizioById(servizioId)
  // DATE PER CALENDARIO
  const todayMoment = moment()
  let dataInitial = todayMoment.toDate()
  let dataMin = moment(`${todayMoment.format('YYYY')}-09-01`, 'YYYY-MM-DD', true)
  let dataMax = moment(`${todayMoment.format('YYYY')}-08-31`, 'YYYY-MM-DD', true)

  if (annoScolastico) {
    const anni: string[] = annoScolastico.split('-')
    dataMin = moment(`${anni[0]}-09-01`, 'YYYY-MM-DD', true)
    dataMax = moment(`${anni[1]}-08-31`, 'YYYY-MM-DD', true)

    dataInitial = todayMoment.isBefore(dataMin)
      ? dataMin.toDate()
      : todayMoment.isAfter(dataMax)
      ? dataMax.toDate()
      : todayMoment.toDate()
  }

  //===================
  // LOCAL STATE
  //===================
  const [cercaAlunno, setCercaAlunno] = useState('')
  const [mostraRitirati, setMostraRitirati] = useState<boolean>(false)
  const [alunnoSel, setAlunnoSel] = useState<Alunno | null>(null)
  const [alunni, setAlunni] = useState<Alunno[]>([])
  const [totali, setTotali] = useState<Totali>(totaliInitial)
  const [cercaAlunnoSel, setCercaAlunnoSel] = useState<{
    value: any
    label: string
    __isNew__?: boolean
  } | null>(null)

  //===================
  // HOOKS QUERIES
  //===================

  // Ruolo
  const clienteId = localStorage.getItem('clienteId')

  const { data: user } = useProfile(' ', clienteId ? parseInt(clienteId, 10) : undefined)

  // Fetch giorni non erogazione
  const { data: giorniNonErogazione = [] } = useGiorniNonErogazioneByServizioId({
    servizioId: servizioId,
    from: moment(dataSel).format('YYYY-MM-DD'),
    to: moment(dataSel).format('YYYY-MM-DD'),
    scuolaId: scuolaSel?.id,
  })

  // Fetch scuole
  const { data: scuole = [], isFetching: isFetchingScuole } = useScuoleByServizioId(servizioId)

  // Fetch sezioni
  const { data: sezioni = [], isFetching: isFetchingSezioni } = usePresenzeSezioniData({
    servizioId,
    scuolaId: scuolaSel?.id,
    dataPresenze: dataSel,
    annoScolastico,
  })

  // Fetch alunni
  const { data: presenzeAlunni, isFetching: isFetchingPresenzeAlunni } = usePresenzeAlunniData({
    servizioId,
    sezioneId: sezioneSel?.id,
    dataPresenze: dataSel,
    annoScolastico,
    // mostraRitirati,
  })

  const debouncedCognome = useDebounce(cercaAlunno, 500)
  // Fetch alunni altre sezioni
  const alunniAltreSezioni = useAlunniAltreSezioni({
    servizioId,
    sezioneId: sezioneSel?.id,
    dataPresenze: dataSel,
    cognome: debouncedCognome,
  })
  // console.log('alunni', alunniAltreSezioni.data)

  //===================
  // HOOKS MUTATIONS
  //===================
  const updatePresenze = useUpdatePresenzeGiorno(servizioId)
  const updateFruizione = useUpdateFruizione()
  const createAlunno = useCreateAlunno()
  // const [updateAlunno] = useUpdateAlunno()
  const inviaEmailCucina = useInviaEmailCucina()

  //===================
  // HOOK FORM
  //===================
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: alunnoSel ? alunnoSel.fruizione : {},
  })

  //===================
  // EFFECTS
  //===================

  // Set prima scuola
  useEffect(() => {
    if (scuole.length > 0) {
      dispatch(loadScuole(scuole[0]))

      sezioneSel?.scuolaId === scuolaSel?.id
        ? dispatch(loadSezioneSel(sezioni[0]))
        : dispatch(changeSezioneSel(sezioni[0]))
    }
  }, [isFetchingScuole, scuole, scuolaSel, sezioni, sezioneSel, dispatch])

  // Set alunni
  useEffect(() => {
    const alunni = presenzeAlunni
      ? presenzeAlunni.map((alunno: Alunno) => {
          const alunnoRitirato = isRitirato(
            moment(dataSel, 'YYYY-MM-DD', true).format('YYYY-MM-DD'),
            alunno.fruizione.dataRitiro,
            alunno.fruizione.dataRitiroBidella
          )

          let presenza = alunno.presenza

          if (!presenza && !alunnoRitirato) {
            const giorno = `${moment(dataSel, 'YYYY-MM-DD', true)
              .format('ddd')
              .toLowerCase()}Bidella` as Giorno

            presenza = alunno.fruizione[giorno] ? 'P' : 'A'
          }
          return {
            ...alunno,
            presenza,
          }
        })
      : []

    setAlunni(alunni)
  }, [dataSel, presenzeAlunni])

  // Aggiorna totali
  useEffect(() => {
    setTotali(calcolaTotali(alunni))
  }, [alunni])

  //===================
  // EVENT HANDLERS
  //===================

  const handleChangePresenza = (alunnoId: number, presenza: string) => {
    const nuoviAlunni = alunni.map((alunno: Alunno) => {
      if (alunno.id === alunnoId) {
        return {
          ...alunno,
          presenza,
        }
      }
      return alunno
    })

    setAlunni(nuoviAlunni)
  }

  const handleSettaTutti = (tipoPresenza: Presenza) => () => {
    const nuoviAlunni = alunni.map((alunno: Alunno) => {
      if (
        isRitirato(
          moment(dataSel, 'YYYY-MM-DD', true).format('YYYY-MM-DD'),
          alunno.fruizione.dataRitiro,
          alunno.fruizione.dataRitiroBidella
        )
      ) {
        return alunno
      }
      return {
        ...alunno,
        presenza: tipoPresenza,
      }
    })

    setAlunni(nuoviAlunni)
  }

  const openModal = (alunno: Alunno) => {
    document.getElementById('root')!.style.filter = 'blur(5px)'
    setAlunnoSel(alunno)
  }

  const closeModal = () => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setAlunnoSel(null)
  }

  const handleModificaFruizione = (datiFruizione: any) => {
    const dataRitiroBidella = datiFruizione.dataRitiroBidella || null
    const noteBidella = datiFruizione.noteBidella

    const lunBidella = datiFruizione.lunBidella
    const marBidella = datiFruizione.marBidella
    const merBidella = datiFruizione.merBidella
    const gioBidella = datiFruizione.gioBidella
    const venBidella = datiFruizione.venBidella

    // Se dati modificati lancio mutation,
    // in modo da non alzare inutilmente
    // il flag modificatoBidella
    if (
      alunnoSel &&
      (dataRitiroBidella !== alunnoSel.fruizione.dataRitiroBidella ||
        noteBidella !== alunnoSel.fruizione.noteBidella ||
        lunBidella !== alunnoSel.fruizione.lunBidella ||
        marBidella !== alunnoSel.fruizione.marBidella ||
        merBidella !== alunnoSel.fruizione.merBidella ||
        gioBidella !== alunnoSel.fruizione.gioBidella ||
        venBidella !== alunnoSel.fruizione.venBidella)
    ) {
      updateFruizione.mutate({
        fruizioneId: alunnoSel.fruizione.id,
        fruizione: {
          ...datiFruizione,
          dataRitiroBidella,
          modificatoBidella: true,
        },
      })
    }
    closeModal()
  }

  const handleSalva = (event: React.FormEvent) => {
    event.preventDefault()
    if (sezioneSel) {
      updatePresenze.mutate({
        sezioneId: sezioneSel.id,
        dataPresenze: dataSel,
        presenze: alunni
          .filter((alunno) => alunno.presenza)
          .map((alunno) => ({
            alunnoId: alunno.id,
            tipo: alunno.presenza,
          })),
      })
    }
  }

  // const handleCreaAlunno = (cognome: any) => {
  //   console.log('CREA', cognome)
  //   createAlunno({ annoScolastico, sezioneId: sezioneSel!.id, cognome, nome:''  })
  // }

  const handleSetNuovoAlunno = (option: any) => {
    setCercaAlunnoSel(option)
  }

  const handleChangeNuovoAlunno = () => {
    if (!cercaAlunnoSel) {
      return
    }
    if (cercaAlunnoSel.__isNew__) {
      createAlunno.mutate({
        servizioId,
        alunno: {
          annoScolastico,
          sezioneId: sezioneSel!.id,
          cognome: cercaAlunnoSel.value,
          dataInizio: dataSel,
        },
      })
    } else {
      // Spostamento verso questa sezione
      // updateAlunno({
      //   alunnoId: cercaAlunnoSel.value,
      //   alunno: {
      //     sezioneId: sezioneSel?.id,
      //   },
      // })
      // Spostamento verso questa sezione
      updateFruizione.mutate({
        fruizioneId: cercaAlunnoSel.value,
        fruizione: {
          sezioneIdBidella: sezioneSel?.id,
        },
      })
    }
    setCercaAlunnoSel(null)
  }

  const handleDayChange = (day: Date) => {
    dispatch(changeDataSel(moment(day).format('YYYY-MM-DD')))
  }

  const isDisabled =
    isNonErogazione(giorniNonErogazione, dataSel, servizio) ||
    moment(dataSel, 'YYYY-MM-DD', true).isBefore(dataMin) ||
    moment(dataSel, 'YYYY-MM-DD', true).isAfter(dataMax)

  const tutteSezioniSalvate = sezioni.every((sezione: any) => {
    if (sezione.presenze === sezione.alunniAttivi) {
      return true
    } else if (sezione.alunniAttivi === null) {
      return true
    } else return false
  })

  const numeroPresenzeScuola = sezioni.reduce((acc: any, sezione: Sezione) => {
    return acc + sezione.presenze_P + sezione.presenze_B + sezione.presenze_M
  }, 0)

  const handleInvioCucina = () => {
    tutteSezioniSalvate &&
      scuolaSel &&
      inviaEmailCucina.mutate({
        servizioId,
        emailCucina: { data: dataSel, scuolaId: scuolaSel.id, annoScolastico: annoScolastico },
      })
  }

  // RENDER
  if (!user) {
    return null
  }

  return (
    <div className="bg-white py-6">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {isFetchingScuole && <Loading size={40} className="py-6" />}
        <h2 className="text-lg leading-tight mt-3 mb-4 text-gray-600 flex items-center">
          {scuolaSel && (
            <InlineEdit
              type={InputType.Select}
              value={scuolaSel.id + ''}
              onChange={(v) => dispatch(changeScuolaSel(scuole.find((s: Scuola) => s.id === +v)))}
              options={scuole}
              valueKey="id"
              labelKey="nome"
              editClass="form-select text-lg"
              viewClass="cursor-pointer text-blue-500"
            />
          )}
          <span className="mx-3 text-gray-400">&raquo;</span>
          <DayPickerInput
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            format="LL"
            placeholder={`${MomentLocaleUtils.formatDate(
              moment(dataSel, 'YYYY-MM-DD', true).toDate(),
              'LL',
              'it'
            )}`}
            inputProps={{
              className: 'form-input p-2 text-blue-500 cursor-pointer border-0',
            }}
            dayPickerProps={{
              locale: 'it',
              localeUtils: MomentLocaleUtils,
              className: 'bg-white rounded py-2 px-2 shadow-lg border-gray-200',
              months: MONTHS,
              initialMonth: dataInitial,
              fromMonth: dataMin.toDate(),
              toMonth: dataMax.toDate(),
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
              modifiersStyles: modifiersStyles,
              modifiers: {
                disabled: [
                  (d: Date) =>
                    isNonErogazione(giorniNonErogazione, moment(d).format('YYYY-MM-DD'), servizio),
                  { before: dataMin.toDate() },
                  { after: dataMax.toDate() },
                ],
              },
              disabledDays: [
                (d: Date) =>
                  isNonErogazione(giorniNonErogazione, moment(d).format('YYYY-MM-DD'), servizio),
                { before: dataMin.toDate() },
                { after: dataMax.toDate() },
              ],
            }}
            value={moment(dataSel).format('DD MMMM YYYY')}
            onDayChange={handleDayChange}
          />
        </h2>

        <div className="flex flex-wrap items-center justify-between py-3 px-4 sm:px-6 bg-gray-100 mb-4">
          <div>
            {isFetchingSezioni && <Loading size={40} className="py-6" />}
            <div className="flex flex-wrap items-center">
              {sezioni
                .filter((sezione: Sezione) => !sezione.fittizia)
                .map((sezione: any) => (
                  <div
                    key={sezione.id}
                    className={classNames(
                      'py-px px-2 rounded mr-2 my-1 text-sm font-semibold cursor-pointer',
                      {
                        'bg-gray-300 hover:bg-gray-400 text-gray-700 hover:text-gray-900':
                          !sezione.presenze,
                      },
                      {
                        'bg-green-300 hover:bg-green-400 text-green-800 hover:text-green-900':
                          sezione.presenze,
                      },
                      {
                        'border-4 border-blue-400 shadow-lg': sezione.id === sezioneSel?.id,
                      }
                    )}
                    onClick={() => dispatch(changeSezioneSel(sezione))}
                  >
                    {sezione.classe} {sezione.sezione}
                  </div>
                ))}
            </div>
          </div>
          <div>
            {!isFetchingSezioni && (
              <div className="flex items-center space-x-6">
                <div
                  className={classNames(
                    { 'text-gray-600': !tutteSezioniSalvate },
                    { hidden: !numeroPresenzeScuola }
                  )}
                >
                  {numeroPresenzeScuola} pasti salvati
                </div>
                <button
                  className={classNames(
                    'block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150',
                    {
                      'opacity-50 cursor-not-allowed': !tutteSezioniSalvate,
                    },
                    {
                      'hover:bg-blue-700 hover:shadow-xl': tutteSezioniSalvate,
                    }
                  )}
                  onClick={handleInvioCucina}
                >
                  Invia a cucina
                </button>
              </div>
            )}
          </div>
        </div>

        {isFetchingPresenzeAlunni && <Loading className="py-12" />}

        {!!isDisabled && (
          <div className="my-12 py-2 px-4 bg-orange-100 rounded border border-orange-200 text-lg">
            Non è possibile indicare presenze in un giorno di chiusura.
          </div>
        )}

        {sezioneSel && !isDisabled && (
          <table>
            <thead>
              <tr>
                <th className="pl-4 sm:pl-6 align-bottom py-4">
                  <label className="flex items-center font-normal text-gray-600">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      checked={mostraRitirati}
                      onChange={(event) => {
                        setMostraRitirati(event.target.checked)
                      }}
                    />
                    <span className="ml-2">Mostra ritirati</span>
                  </label>
                </th>
                <th className="text-center align-bottom py-4">
                  <button
                    className="px-2 border border-gray-400 text-sm text-gray-600 rounded hover:bg-green-600 hover:text-white hover:border-green-700"
                    onClick={handleSettaTutti('P')}
                  >
                    Tutti presenti
                  </button>
                </th>
                <th className="text-center align-bottom py-4">
                  <button
                    className="px-2 border border-gray-400 text-sm text-gray-600 rounded hover:bg-red-500 hover:text-white hover:border-red-500"
                    onClick={handleSettaTutti('A')}
                  >
                    Tutti assenti
                  </button>
                </th>
                <th colSpan={2} className="py-4 text-right align-bottom"></th>
                <th className="py-4 text-right align-bottom">
                  <button
                    type="submit"
                    className="hover:shadow-xl focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 bg-blue-500 hover:bg-blue-700"
                    onClick={handleSalva}
                  >
                    Salva
                  </button>
                </th>
              </tr>
              <tr>
                <th
                  rowSpan={2}
                  className="px-4 sm:px-6 pt-2 pb-1 bg-gray-100 text-left text-2xl leading-4 font-semibold text-blue-500 uppercase"
                >
                  {sezioneSel.classe}
                  <sup className="lowercase">a</sup> {sezioneSel.sezione}
                </th>
                <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-sm leading-4 font-medium text-green-600 text-center">
                  P<span className="hidden sm:inline">resenti</span>
                </th>
                <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-sm leading-4 font-medium text-red-600 text-center">
                  A<span className="hidden sm:inline">ssenti</span>
                </th>
                {servizioId === 3 && (
                  <>
                    <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-sm leading-4 font-medium text-yellow-600 text-center">
                      B<span className="hidden sm:inline">ianco</span>
                    </th>
                    <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-sm leading-4 font-medium text-purple-600 text-center">
                      M<span className="hidden sm:inline">inipasto</span>
                    </th>
                  </>
                )}
                <th
                  rowSpan={2}
                  className="px-4 sm:px-6 pt-2 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-600 align-top"
                >
                  Modifica gg
                  <br />
                  segna ritiro
                </th>
              </tr>
              <tr>
                <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-sm leading-4 font-black text-green-500 uppercase text-center align-middle">
                  {totali.P}
                </th>
                <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-sm leading-4 font-black text-red-500 uppercase text-center">
                  {totali.A}
                </th>
                {servizioId === 3 && (
                  <>
                    <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-sm leading-4 font-black text-yellow-600 uppercase text-center">
                      {totali.B}
                    </th>
                    <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-sm leading-4 font-black text-purple-500 uppercase text-center">
                      {totali.M}
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {alunni &&
                alunni
                  .filter((alunno) => {
                    return mostraRitirati
                      ? alunno
                      : !isRitirato(
                          dataSel,
                          alunno.fruizione.dataRitiro,
                          alunno.fruizione.dataRitiroBidella
                        )
                  })
                  .map((alunno) => {
                    const nuovoAlunno =
                      moment().diff(
                        moment(alunno.fruizione.dataInizio, 'YYYY-MM-DD', true),
                        'days'
                      ) <= 5
                    const isUcraino = alunno.codiceFiscale?.toLocaleLowerCase().startsWith('ucrain')

                    return (
                      <tr
                        key={alunno.id}
                        className={classNames({
                          'bg-gray-100':
                            moment(alunno.fruizione.dataRitiro, 'YYYY-MM-DD', true).isAfter(
                              moment(dataSel)
                            ) ||
                            moment(alunno.fruizione.dataRitiroBidella, 'YYYY-MM-DD', true).isBefore(
                              moment(dataSel)
                            ),
                        })}
                      >
                        <td
                          className="px-4 sm:px-6 py-4 border-b border-gray-200"
                          style={{
                            backgroundColor: nuovoAlunno ? '#dae670' : 'transparent',
                            textDecoration: isUcraino ? 'underline' : 'none',
                          }}
                        >
                          {user.ruolo === 'presenze' ? (
                            <span className={`${!alunno.nome ? 'text-red-600' : 'text-gray-900'}`}>
                              <b>{alunno.cognome}</b> {alunno.nome}
                            </span>
                          ) : (
                            <Link
                              to={`/app/anagrafica/alunno/${alunno.id}/servizio/${servizioId}`}
                              className={`${
                                !alunno.nome
                                  ? 'text-red-600 hover:text-red-700'
                                  : isRitirato(
                                      dataSel,
                                      alunno.fruizione.dataRitiro,
                                      alunno.fruizione.dataRitiroBidella
                                    )
                                  ? 'text-gray-500 hover:text-blue-500'
                                  : 'text-gray-900 hover:text-blue-500'
                                // : 'text-gray-900 hover:text-blue-500'
                              }`}
                            >
                              <b>{alunno.cognome}</b> {alunno.nome}
                            </Link>
                          )}
                        </td>

                        <td className="px-4 sm:px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
                          <input
                            type="radio"
                            name={`${alunno.id}`}
                            className="form-radio text-green-500"
                            checked={alunno.presenza === 'P'}
                            onChange={() => handleChangePresenza(alunno.id, 'P')}
                          />
                        </td>
                        <td className="px-4 sm:px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
                          <input
                            type="radio"
                            name={`${alunno.id}`}
                            className="form-radio text-red-500"
                            checked={alunno.presenza === 'A'}
                            onChange={() => handleChangePresenza(alunno.id, 'A')}
                          />
                        </td>
                        {servizioId === 3 && (
                          <>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
                              <input
                                type="radio"
                                name={`${alunno.id}`}
                                className="form-radio text-yellow-500"
                                checked={alunno.presenza === 'B'}
                                onChange={() => handleChangePresenza(alunno.id, 'B')}
                              />
                            </td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
                              <input
                                type="radio"
                                name={`${alunno.id}`}
                                className="form-radio text-purple-500"
                                checked={alunno.presenza === 'M'}
                                onChange={() => handleChangePresenza(alunno.id, 'M')}
                              />
                            </td>
                          </>
                        )}
                        <td className="px-4 sm:px-6 py-4 whitespace-nowrap border-b border-gray-200 text-center">
                          <button
                            className="text-blue-500 hover:text-blue-700"
                            onClick={() => {
                              openModal(alunno)
                              reset(alunno.fruizione)
                            }}
                          >
                            <FiEdit />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              <tr>
                <td colSpan={6} className="bg-blue-100 py-4 px-4">
                  <p className="leading-none mb-2 text-blue-700 font-semibold">Nuovo alunno:</p>
                  <CreatableSelect
                    isClearable
                    options={alunniAltreSezioni.data}
                    onInputChange={setCercaAlunno}
                    isLoading={alunniAltreSezioni.isLoading}
                    formatCreateLabel={(inputValue) => `Crea "${inputValue}"`}
                    noOptionsMessage={() => 'Nessun alunno'}
                    placeholder="Seleziona da altra classe o crea nuovo..."
                    //onCreateOption={(v: any) => console.log('V',v)}
                    onChange={handleSetNuovoAlunno}
                    styles={reactSelectCustomStyles}
                    value={null}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <ReactModal
        isOpen={!!alunnoSel}
        onRequestClose={() => {
          setAlunnoSel(null)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-500 text-white font-semibold py-2 text-center">
            {alunnoSel?.cognome} {alunnoSel?.nome}
          </div>

          <div className="px-10 py-6">
            <form onSubmit={handleSubmit(handleModificaFruizione)}>
              {servizioId === 3 && (
                <div>
                  <div className="leading-5 font-medium text-gray-800 mb-3">
                    Giorni di fruizione
                  </div>
                  <div className="flex">
                    {servizio?.servizioLun && (
                      <FormField
                        fieldName="lunBidella"
                        label="L"
                        type={FormInputType.WeekDay}
                        register={register}
                        errors={errors}
                        defaultValue={alunnoSel?.fruizione.lunBidella}
                      />
                    )}
                    {servizio?.servizioMar && (
                      <FormField
                        fieldName="marBidella"
                        label="M"
                        type={FormInputType.WeekDay}
                        register={register}
                        errors={errors}
                        defaultValue={alunnoSel?.fruizione.marBidella}
                      />
                    )}
                    {servizio?.servizioMer && (
                      <FormField
                        fieldName="merBidella"
                        label="M"
                        type={FormInputType.WeekDay}
                        register={register}
                        errors={errors}
                        defaultValue={alunnoSel?.fruizione.merBidella}
                      />
                    )}
                    {servizio?.servizioGio && (
                      <FormField
                        fieldName="gioBidella"
                        label="G"
                        type={FormInputType.WeekDay}
                        register={register}
                        errors={errors}
                        defaultValue={alunnoSel?.fruizione.gioBidella}
                      />
                    )}
                    {servizio?.servizioVen && (
                      <FormField
                        fieldName="venBidella"
                        label="V"
                        type={FormInputType.WeekDay}
                        register={register}
                        errors={errors}
                        defaultValue={alunnoSel?.fruizione.venBidella}
                      />
                    )}
                    {servizio?.servizioSab && (
                      <FormField
                        fieldName="sabBidella"
                        label="S"
                        type={FormInputType.WeekDay}
                        register={register}
                        errors={errors}
                        defaultValue={alunnoSel?.fruizione.sabBidella}
                      />
                    )}
                    {servizio?.servizioDom && (
                      <FormField
                        fieldName="domBidella"
                        label="D"
                        type={FormInputType.WeekDay}
                        register={register}
                        errors={errors}
                        defaultValue={alunnoSel?.fruizione.domBidella}
                      />
                    )}
                  </div>
                  <hr className="my-6"></hr>
                </div>
              )}
              <div className="mb-5">
                <FormField
                  fieldName="dataRitiroBidella"
                  label="Data ritiro"
                  type={FormInputType.Date}
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="noteBidella"
                  label="Note"
                  type={FormInputType.Textarea}
                  rows={2}
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => closeModal()}
                >
                  Annulla
                </button>

                <button
                  type="submit"
                  className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  Salva
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={!!cercaAlunnoSel}
        onRequestClose={() => {
          setCercaAlunnoSel(null)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-500 text-white font-semibold py-2 text-center">
            {!cercaAlunnoSel?.__isNew__ && 'Sposta '}
            {cercaAlunnoSel?.label}
          </div>

          <div className="px-10 py-6">
            <p className="mb-6">
              Sei sicuro di voler {cercaAlunnoSel?.__isNew__ ? 'creare' : 'spostare'} l'alunno{' '}
              <b>{cercaAlunnoSel?.__isNew__ ? cercaAlunnoSel?.value : cercaAlunnoSel?.label}</b> in
              questa sezione ({sezioneSel?.classe}
              {sezioneSel?.sezione})?
            </p>
            <div className="flex justify-between">
              <button
                className="block bg-gray-400 hover:bg-gray-500 focus:shadow-outline  focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={() => {
                  setCercaAlunnoSel(null)
                }}
              >
                Annulla
              </button>
              <button
                className="block bg-blue-500 hover:bg-blue-700 focus:shadow-outline  focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={handleChangeNuovoAlunno}
              >
                {cercaAlunnoSel?.__isNew__ ? 'Crea' : 'Sposta'} alunno
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

//PresenzeGiornaliere.whyDidYouRender = true

export default PresenzeGiornaliere

import React from 'react'
import { RouteComponentProps, Link } from '@reach/router'

import useProfile from '../../apiHooks/queries/useProfile'

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-900 text-sm font-medium rounded',
      }
    : {}
}

const Presenze: React.FC<RouteComponentProps> = ({ children }) => {
  const clienteId = localStorage.getItem('clienteId')

  const { data: user } = useProfile(' ', clienteId ? parseInt(clienteId, 10) : undefined)

  if (!user) {
    return null
  }

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex">
          {(user.ruolo === 'amministrazione' || user.ruolo === 'presenze') && (
            <Link
              to="./"
              className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
              getProps={isActive}
            >
              Giornaliere
            </Link>
          )}
          {(user.ruolo === 'amministrazione' || user.ruolo === 'presenze') && (
            <Link
              to="mensili"
              className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
              getProps={isActive}
            >
              Mensili
            </Link>
          )}
          {user.ruolo === 'amministrazione' && (
            <Link
              to="fatturazione"
              className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
              getProps={isActive}
            >
              Fatturazione
            </Link>
          )}
          {(user.ruolo === 'amministrazione' || user.ruolo === 'cucina') && (
            <Link
              to="cucina"
              className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
              getProps={isActive}
            >
              Cucina
            </Link>
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export default Presenze

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const deleteAlunno = async (alunnoId: number) => {
  const { data } = await axios.delete(`/alunni/${alunnoId}/`)
  return data
}

const useDeleteAlunno = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (alunnoId: number) => {
      return deleteAlunno(alunnoId)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('countAlunniDaControllare')
        cogoToast.success('Alunno eliminato')
      },
      onError: () => {},
    }
  )
}

export default useDeleteAlunno

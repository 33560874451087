import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

const getRiepilogoDomandaIscrizione = async (
  _: string,
  servizio: string | undefined,
  id: number
) => {
  if (!servizio) {
    return null
  }
  const { data } = await axios.get(`/iscrizioni-${servizio}/${id}/riepilogo-domanda`)

  return data
}

const useRiepilogoDomandaIscrizione = (id: number, servizio: string | undefined) => {
  const queryClient = useQueryClient()
  return useQuery(
    ['domandaIscrizione', id, servizio],
    () => getRiepilogoDomandaIscrizione('domandaIscrizione', servizio, id),
    {
      enabled: !!id && !!servizio,
      staleTime: 1000 * 60 * 5, 
      cacheTime: 1000 * 60 * 10, 
      onSuccess: (data: any) => {
        if(data.stato==='LETTA'){
          queryClient.invalidateQueries('iscrizioni')
        }
      },
    }
  )
}

export default useRiepilogoDomandaIscrizione

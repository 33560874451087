import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import { LAST_YEAR } from '../../config'

const duplicaFruizione = async (fruizioneId: number) => {
  const { data } = await axios.get(
    `/fruizioni/${fruizioneId}/iscrivi-nuovo-anno/?annoScolastico=${LAST_YEAR}`
  )
  return data
}

const useDuplicaFruizione = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (fruizioneId: number) => {
      return duplicaFruizione(fruizioneId)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('alunnoByIdByAnno')
        cogoToast.success('Fruizione duplicata')
      },
      onError: () => {},
    }
  )
}

export default useDuplicaFruizione

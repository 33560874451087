import { useQuery } from 'react-query'
import axios from 'axios'

const getAlunniGenitore2Anni = async (annoScolastico: string, servizioId: number) => {
  const { data } = await axios.get(
    `/alunni/genitore?annoScolastico=${annoScolastico}&servizioId=${servizioId}`
  )

  return data.results
}

const useAlunniGenitore2Anni = (
  annoScolastico: string,
  annoScolasticoPrecedente: string,
  servizioId: number
) => {
  return useQuery(
    ['alunniGenitore2Anni', { annoScolastico, annoScolasticoPrecedente, servizioId }],
    async () => {
      const alunniAnnoCorrente = await getAlunniGenitore2Anni(annoScolastico, servizioId)
      const alunniAnnoPrecedente = await getAlunniGenitore2Anni(
        annoScolasticoPrecedente,
        servizioId
      )
      return { alunniAnnoCorrente, alunniAnnoPrecedente }
    },
    {
      // staleTime: 10 * 60 * 1000,
    }
  )
}

export default useAlunniGenitore2Anni

import React, { useState, useContext, useMemo } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import { useForm } from 'react-hook-form'
import Select from 'react-select'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import ServizioContext from '../../../context/ServizioContext'
import { codiceFiscaleValido, nomeCoerente, cognomeCoerente } from '../../../lib/codiceFiscale'

import FormField, { InputType } from '../../../components/FormField'
import reactSelectCustomStyles from '../../../components/reactSelectCustomStyles'

import useComuni from '../../../apiHooks/queries/useComuni'
import useCittadinanze from '../../../apiHooks/queries/useCittadinanze'
import useSezioniByScuolaId from '../../../apiHooks/queries/useSezioniByScuolaId'
import useDieteByServizioId from '../../../apiHooks/queries/useDieteByServizioId'
import useUpdateAlunno from '../../../apiHooks/mutations/useUpdateAlunno'
import { FaMinusCircle } from 'react-icons/fa'

interface AlunnoDatiProps extends RouteComponentProps {
  alunno?: any
  scuole?: any
  intestatari?: any
}

interface Option {
  label: string
  value: number
}

interface Dieta {
  id: number
  tipoDieta: string
  nomeDieta: string
}

interface DatiAlunnoForm {
  cognome: string
  nome: string
  noCodiceFiscale: boolean
  codiceFiscale: string
  dataNascita: string
  sezione: any
  statoNascitaDati: { codiceImport: string; codiceIstat: number; denominazione: string }
}

const Dati: React.FC<AlunnoDatiProps> = ({ alunno, scuole, intestatari }) => {
  // ========================
  // CONTEXT
  // ========================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  // ========================
  // LOCAL STATE
  // ========================
  const [comuniKeyword, setComuniKeyword] = useState('')
  const [statiKeyword, setStatiKeyword] = useState('')
  const [dietaId, setDietaId] = useState<number>(alunno.dietaId)
  const [scuolaId, setScuolaId] = useState<number>(alunno.sezione.scuolaId)

  // ========================
  // QUERIES
  // ========================
  // Sezioni by Scuola
  const { data: sezioni } = useSezioniByScuolaId({
    scuolaId: scuolaId + '',
    annoScolastico,
  })

  // Comuni
  const comuni = useComuni(comuniKeyword)

  // Stati
  const stati = useCittadinanze(statiKeyword)

  // Diete
  const { data: diete = [] } = useDieteByServizioId(servizioId)

  // ========================
  // MUTATIONS
  // ========================
  // UpdateAlunno
  const updateAlunno = useUpdateAlunno()

  // ========================
  // FORM HOOK
  // ========================
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
  } = useForm<DatiAlunnoForm>({
    defaultValues: alunno,
  })

  const DIETEOPTIONS: Option[] = diete.map((dieta: Dieta) => {
    return {
      value: dieta.id,
      label: dieta.tipoDieta + ' - ' + dieta.nomeDieta,
    }
  })

  const dietaCorrente: Dieta = useMemo(
    () => diete.find((d: Dieta) => d.id === dietaId),
    [diete, dietaId]
  )

  const dietaCorrenteValue: Option = {
    value: dietaCorrente?.id || 0,
    label: dietaCorrente?.tipoDieta + ' - ' + dietaCorrente?.nomeDieta,
  }

  const scuolaCorrente = useMemo(
    () => scuole.find((s: any) => s.id === scuolaId),
    [scuole, scuolaId]
  )

  const scuolaCorrenteValue: Option = {
    value: scuolaCorrente.id,
    label: scuolaCorrente.nome,
  }

  const onSubmit = (data: any) => {
    const alunnoToSave = {
      ...data,
      comuneCodiceIstat: data.comuneDati?.codiceIstat,
      comuneCodiceIstatNascita: data.comuneNascitaDati?.codiceIstat,
      // statoCodiceIstat: data.statoDati.codiceIstat,
      statoCodiceIstatNascita: data.statoNascitaDati?.codiceIstat,
      cittadinanzaCodiceIstat: data.cittadinanzaDati?.codiceIstat || '',
      nazionalitaCodiceIstat: data.nazionalitaDati?.codiceIstat,
      dietaId,
      sezioneId: data.sezione.id,
      dataNascita: data.dataNascita || null,
      codiceFiscale: data.codiceFiscale || null,
    }

    updateAlunno.mutate({
      alunnoId: alunno.id,
      alunno: alunnoToSave,
    })
  }

  return (
    <div className="lg:pl-32 w-full flex flex-col lg:flex-row pt-6 pb-20">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <section>
          <h3 className="text-gray-900 mb-5 font-semibold">Dati anagrafici</h3>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="cognome"
                label="Cognome"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il cognome',
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') ||
                    cognomeCoerente(getValues('codiceFiscale'), value) ||
                    'Cognome e codice fiscale non coerenti',
                }}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="nome"
                label="Nome"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il nome',
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') ||
                    nomeCoerente(getValues('codiceFiscale'), value) ||
                    'Nome e codice fiscale non coerenti',
                }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
          <FormField
            fieldName="noCodiceFiscale"
            label="No codice fiscale"
            type={InputType.Boolean}
            register={register}
            errors={errors}
            className="mb-3"
          />
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="codiceFiscale"
                label="Codice Fiscale"
                type={InputType.Text}
                validation={{
                  // required: 'Inserisci il codice fiscale',
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') ||
                    (!!value && codiceFiscaleValido(value)) ||
                    'Inserire un codice fiscale valido',
                }}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="dataNascita"
                label="Data di nascita"
                type={InputType.Date}
                // validation={{ required: 'Inserisci la data di nascita' }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="comuneNascitaDati"
                label="Comune di nascita"
                type={InputType.Select}
                onInputChange={setComuniKeyword}
                options={comuni.data}
                isLoading={comuni.isLoading}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                // validation={{ required: 'Inserisci il comune' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="statoNascitaDati"
                label="Stato di nascita"
                type={InputType.Select}
                onInputChange={setStatiKeyword}
                options={stati.data}
                isLoading={stati.isLoading}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                // validation={{ required: 'Inserisci lo stato di nascita' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="cittadinanzaDati"
                label="Cittadinanza"
                type={InputType.Select}
                onInputChange={setStatiKeyword}
                options={stati.data}
                isLoading={stati.isLoading}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                // validation={{ required: 'Inserisci la cittadinanza' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="nazionalitaDati"
                label="Nazionalità"
                type={InputType.Select}
                onInputChange={setStatiKeyword}
                options={stati.data}
                isLoading={stati.isLoading}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                // validation={{ required: 'Inserisci la nazionalità' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>
        </section>

        <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>

        <section>
          <h3 className="text-gray-900 mb-5 font-semibold">Residenza</h3>

          <div className="mb-5">
            <FormField
              fieldName="indirizzo"
              label="Indirizzo"
              type={InputType.Text}
              validation={{ required: "Inserisci l'indirizzo" }}
              register={register}
              errors={errors}
              control={control}
            />
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="cap"
                label="CAP"
                type={InputType.Text}
                validation={{ required: 'Inserisci il CAP' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="comuneDati"
                label="Comune di residenza"
                type={InputType.Select}
                onInputChange={setComuniKeyword}
                options={comuni.data}
                isLoading={comuni.isLoading}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                validation={{
                  // required: 'Inserisci il comune'
                  validate: (value: string) =>
                    !!watch('noCodiceFiscale') || !!value || 'Inserisci il comune',
                }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>
        </section>

        <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>

        <section className="mb-6">
          <h3 className="text-gray-900 mb-2 font-semibold">Dieta</h3>
          <Select
            name="dieta"
            className="mb-6"
            type={InputType.Select}
            options={DIETEOPTIONS}
            value={dietaCorrenteValue}
            onChange={(option: any) => {
              setDietaId(option.value)
            }}
            styles={reactSelectCustomStyles}
            loadingMessage={() => 'Caricamento...'}
            noOptionsMessage={() => 'Nessuna opzione disponibile'}
            placeholder="Seleziona..."
          />
        </section>

        <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>

        <section className="mb-6">
          <h3 className="text-gray-900 mb-2 font-semibold">Cambio sezione</h3>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6 mr-6">
              <label htmlFor="scuola" className="block text-sm leading-5 font-medium text-gray-600">
                Scuola
              </label>
              <Select
                name="scuola"
                className="mt-1"
                options={scuole.map((s: any) => ({
                  value: s.id,
                  label: s.nome,
                }))}
                value={scuolaCorrenteValue}
                onChange={(option: any) => {
                  setScuolaId(option.value)
                  setValue('sezione', '')
                }}
                styles={reactSelectCustomStyles}
                loadingMessage={() => 'Caricamento...'}
                noOptionsMessage={() => 'Nessuna opzione disponibile'}
                placeholder="Seleziona..."
              />
            </div>
            <div className="flex-1 mb-6">
              <FormField
                fieldName="sezione"
                label="Sezione"
                type={InputType.Select}
                options={[{ classe: '-- Seleziona', sezione: '--', id: '' }, ...(sezioni || [])]}
                getOptionValue={(v: any) => v.id}
                getOptionLabel={(v: any) => v.classe + ' ' + v.sezione}
                errors={errors}
                control={control}
              />
            </div>
          </div>
        </section>

        <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>

        <section className="mb-6">
          <h3 className="text-gray-900 mb-2 font-semibold">Note interne</h3>

          <div>
            <FormField
              fieldName="note"
              type={InputType.Textarea}
              rows={3}
              register={register}
              errors={errors}
              control={control}
            />
          </div>
        </section>

        <button
          type="submit"
          className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
        >
          Salva
        </button>
      </form>
      <div className="lg:pl-24">
        <h2 className="font-bold mt-10 lg:mt-0 mb-3">Intestatari</h2>
        {intestatari &&
          intestatari.map((intestatario: any) => {
            return (
              <div key={intestatario.id} className="flex items-center space-x-2 mb-2">
                {!!intestatario.inibitoAccessoDati && <FaMinusCircle className="text-red-600" />}
                <Link
                  to={`intestatari/${intestatario.id}`}
                  className="block text-blue-500 hover:text-blue-700 "
                >
                  {intestatario.cognome} {intestatario.nome}
                </Link>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Dati

import React from 'react'

interface IAnnoScolasticoContext {
  annoScolastico: string
  handleChangeAnnoScolastico: (anno: string) => void
}

const AnnoScolasticoContext = React.createContext<IAnnoScolasticoContext>({
  annoScolastico: '',
  handleChangeAnnoScolastico: () => {},
})

export default AnnoScolasticoContext

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface GenerazionePagamentiSdd {
  dataEmissione: string
  dataScadenza: string
  annoScolastico: string
}

const generaPagamentiSdd = async (servizioId: number, pagamentiSdd: GenerazionePagamentiSdd) => {
  const { data } = await axios.post(`/servizi/${servizioId}/genera-pagamenti-sdd/`, pagamentiSdd)
  return data
}

const useGeneraPagamentiSdd = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ servizioId, pagamentiSdd }: { servizioId: number; pagamentiSdd: GenerazionePagamentiSdd }) => {
      return generaPagamentiSdd(servizioId, pagamentiSdd)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        queryClient.invalidateQueries('avvisiPagamentoByMese')
      },
      onError: () => {},
    }
  )
}

export default useGeneraPagamentiSdd

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import { navigate } from '@reach/router'

interface GetIntestatarioArgs {
  intestatarioId?: number
  intestatario?: any
}

const updateIntestatarioById = async ({ intestatarioId, intestatario }: GetIntestatarioArgs) => {
  const { data } = await axios.put(`/intestatari/${intestatarioId}/`, intestatario)
  return data
}

const useUpdateIntestatarioById = (intestatarioId: number, alunnoId: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ intestatario }: { intestatario: any }) => {
      const intestatarioToSave = {
        ...intestatario,
      }
      // console.log('intestatarioToSave: ', intestatarioToSave)
      return updateIntestatarioById({
        intestatarioId,
        intestatario: intestatarioToSave,
      })
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('alunnoByIdByAnno')
        cogoToast.success('Intestatario salvato correttamente')
        navigate(`/app/anagrafica/alunno/${alunnoId}/intestatari`)
      },
      onError: () => {},
    }
  )
}

export default useUpdateIntestatarioById

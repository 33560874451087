import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface ICreateIntestatario {
  intestatario?: any
  alunnoId?: number
}

const createIntestatario = async ({ intestatario, alunnoId }: ICreateIntestatario) => {
  const { data } = await axios.post(`/alunni/${alunnoId}/intestatario/`, intestatario)
  return data
}

const useCreateIntestatario = (alunnoId?: number) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ intestatario }: { intestatario: any }) => {
      const intestatarioToCreate = {
        ...intestatario,
      }
      return createIntestatario({
        intestatario: intestatarioToCreate,
        alunnoId,
      })
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('alunnoByIdByAnno')
        cogoToast.success('Intestatario creato')
      },
      onError: () => {},
    }
  )
}

export default useCreateIntestatario

import { configureStore } from '@reduxjs/toolkit'
import anagraficaElenco from '../modules/anagraficaElenco'
import anagraficaRicerca from '../modules/anagraficaRicerca'
import presenze from '../modules/presenze'
import fatturazioneGenerazioneAvvisi from '../modules/fatturazioneGenerazioneAvvisi'

const store = configureStore({
  reducer: {
    anagraficaElenco,
    anagraficaRicerca,
    presenze,
    fatturazioneGenerazioneAvvisi
  },
})

export type RootState = ReturnType<typeof store.getState>

export default store

import Axios from 'axios'
import cogoToast from 'cogo-toast'
import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import ServizioContext from '../../context/ServizioContext'

const deleteFascia = async (fasciaId: number) => {
  return await Axios.delete(`/fasce-calcolo/${fasciaId}/`)
}

const useDeleteFascia = () => {
  const { servizioId } = useContext(ServizioContext)
  const queryClient = useQueryClient()
  return useMutation(
    (fasciaId: number) => {
      return deleteFascia(fasciaId)
    },
    {
      onSuccess: () => {
        cogoToast.success('Fascia eliminata correttamente')
        queryClient.invalidateQueries(['fasceByServizioId', servizioId])
      },
    }
  )
}
export default useDeleteFascia

import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useUpdateFruizione from '../../../../apiHooks/mutations/useUpdateFruizione'

import MensaAFasce from './MensaAFasce'
import ComuneBergamoMensa from './ComuneBergamoMensa'
import ComuneBergamoAsilo from './ComuneBergamoAsilo'

import { Fascia } from '../../../../apiHooks/queries/useFasceByServizioId'

import { getLabelByValue as getLabelByValueFrequenza } from '../../../../lib/tipoFrequenzaOptions'
import { getLabelByValue as getLabelByValueCentroEstivo } from '../../../../lib/centroEstivoOptions'
import { getLabelByValue as getLabelByValueAsilo } from '../../../../lib/isAsiloGratisOptions'
import { getLabelByValue as getQuotaCompartecipazioneByValue } from '../../../../lib/quotaCompartecipazioneOptions'
import useServizioById from '../../../../apiHooks/queries/useServizioById'
import ServizioContext from '../../../../context/ServizioContext'
import CddBergamo from './CddBergamo'
// import useAvvisiPagamentoByFruizioneId from '../../../../apiHooks/queries/useAvvisiPagamentoByFruizioneId'

interface FormServizioProps {
  fruizione: any
  intestatari: any[]
  fasceCalcolo: Fascia[]
}

const getFruizioneValues = (fruizione: any, fasceCalcolo: Fascia[]) => {
  return {
    ...fruizione,
    intestatario: fruizione.intestatario
      ? {
          value: fruizione.intestatario.id,
          label: `${fruizione.intestatario.cognome} ${fruizione.intestatario.nome}`,
        }
      : null,
    quotaCompartecipazione: fruizione.quotaCompartecipazione
      ? {
          value: fruizione.quotaCompartecipazione,
          label: getQuotaCompartecipazioneByValue(fruizione.quotaCompartecipazione),
        }
      : null,
    tipoFrequenza: fruizione.tipoFrequenza
      ? {
          value: fruizione.tipoFrequenza,
          label: getLabelByValueFrequenza(fruizione.tipoFrequenza),
        }
      : null,
    turno: fruizione.turno
      ? {
          value: fruizione.turno,
          label: `${fruizione.turno}° turno`,
        }
      : null,
    centroEstivo: fruizione.centroEstivo
      ? {
          value: fruizione.centroEstivo,
          label: getLabelByValueCentroEstivo(fruizione.centroEstivo),
        }
      : null,
    fasciaCalcolo: fruizione.fasciaCalcolo
      ? {
          value: fruizione.fasciaCalcolo,
          label:
            fasceCalcolo &&
            fasceCalcolo.find((fascia) => fascia.id === fruizione.fasciaCalcolo)?.descrizione,
        }
      : null,
    isAsiloGratis: {
      value: fruizione.isAsiloGratis,
      label: getLabelByValueAsilo(fruizione.isAsiloGratis),
    },
  }
}

const FormServizio: React.FC<FormServizioProps> = ({ fruizione, intestatari, fasceCalcolo }) => {
  const { servizioId } = useContext(ServizioContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: getFruizioneValues(fruizione, fasceCalcolo),
  })

  const { data: servizio } = useServizioById(servizioId)
  const updateFruizione = useUpdateFruizione()

  useEffect(() => {
    reset(getFruizioneValues(fruizione, fasceCalcolo))
  }, [fruizione, fasceCalcolo, reset])

  const onSubmit = (data: any) => {
    const fruizioneToSave = {
      ...data,
      intestatarioId: data.intestatario ? data.intestatario.value : null,
      tipoFrequenza: data.tipoFrequenza ? data.tipoFrequenza.value : null,
      centroEstivo: data.centroEstivo ? data.centroEstivo.value : null,
      turno: data.turno ? data.turno.value : null,
      dataInizio: data.dataInizio || null,
      dataRitiro: data.dataRitiro || null,
      dataRitiroBidella: data.dataRitiroBidella || null,
      dataFineAmbientamento: data.dataFineAmbientamento || null,
      // RENDERE GENERICO
      quotaCompartecipazione: data.quotaCompartecipazione
        ? data.quotaCompartecipazione.value
        : null,
      isee: data.isee ? parseFloat(data.isee) : null,
      dataRichiestaSdd: data.dataRichiestaSdd || null,
      dataScadenzaIsee: data.dataScadenzaIsee || null,
      dataInizioRitiroTemporaneo: data.dataInizioRitiroTemporaneo || null,
      dataFineRitiroTemporaneo: data.dataFineRitiroTemporaneo || null,
      isAsiloGratis: data.isAsiloGratis ? parseInt(data.isAsiloGratis.value, 10) : null,
      asiloGratisQuotaGenitore: data.asiloGratisQuotaGenitore
        ? parseFloat(data.asiloGratisQuotaGenitore)
        : null,
      riduzioneMaggiorazioneFissaImporto: data.riduzioneMaggiorazioneFissaImporto
        ? parseFloat(data.riduzioneMaggiorazioneFissaImporto)
        : null,
      riduzioneMaggiorazioneTemporaneaImporto: data.riduzioneMaggiorazioneTemporaneaImporto
        ? parseFloat(data.riduzioneMaggiorazioneTemporaneaImporto)
        : null,
      riportoRiduzione: data.riportoRiduzione ? parseFloat(data.riportoRiduzione) : null,
      fasciaCalcolo: data.fasciaCalcolo ? data.fasciaCalcolo.value : null,
      sezioneIdBidella: data.sezioneIdBidella ? data.sezioneIdBidella.id : null,
      nascondiCertificazionePagamentiFlag: data.nascondiCertificazionePagamentiFlag,
    }
    console.log(fruizioneToSave)
    updateFruizione.mutate({
      fruizioneId: fruizione.id,
      fruizione: fruizioneToSave,
    })
  }

  const giorniMensaPrevisti = {
    ufficiale: {
      lun: { fruizione: fruizione.lun, servizio: servizio?.servizioLun },
      mar: { fruizione: fruizione.mar, servizio: servizio?.servizioMar },
      mer: { fruizione: fruizione.mer, servizio: servizio?.servizioMer },
      gio: { fruizione: fruizione.gio, servizio: servizio?.servizioGio },
      ven: { fruizione: fruizione.ven, servizio: servizio?.servizioVen },
      sab: { fruizione: fruizione.sab, servizio: servizio?.servizioSab },
      dom: { fruizione: fruizione.dom, servizio: servizio?.servizioDom },
    },
    operatore: {
      lun: { fruizione: fruizione.lunBidella, servizio: servizio?.servizioLun },
      mar: { fruizione: fruizione.marBidella, servizio: servizio?.servizioMar },
      mer: { fruizione: fruizione.merBidella, servizio: servizio?.servizioMer },
      gio: { fruizione: fruizione.gioBidella, servizio: servizio?.servizioGio },
      ven: { fruizione: fruizione.venBidella, servizio: servizio?.servizioVen },
      sab: { fruizione: fruizione.sabBidella, servizio: servizio?.servizioSab },
      dom: { fruizione: fruizione.domBidella, servizio: servizio?.servizioDom },
    },
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {fruizione.servizioId === 3 && parseInt(fruizione.annoScolastico.split('-')[0]) < 2023 && (
        <ComuneBergamoMensa
          register={register}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          intestatari={intestatari}
          sezioneBidella={fruizione.sezioneIdBidella}
        />
      )}
      {((fruizione.servizioId >= 5 && fruizione.servizioId < 8) ||
        (fruizione.servizioId === 3 &&
          parseInt(fruizione.annoScolastico.split('-')[0]) >= 2023)) && (
        <MensaAFasce
          register={register}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          intestatari={intestatari}
          sezioneBidella={fruizione.sezioneIdBidella}
          fasceCalcolo={fasceCalcolo}
          numeroTurni={servizio?.numeroTurni}
          giorniMensaPrevisti={giorniMensaPrevisti}
          pagamentoSospeso={fruizione?.pagamentoSospeso}
          nascondiCertificazionePagamentiFlag={fruizione?.nascondiCertificazionePagamentiFlag}
          servizioId={fruizione.servizioId}
        />
      )}
      {(fruizione.servizioId === 2 || fruizione.servizioId === 4) && (
        <ComuneBergamoAsilo
          register={register}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          intestatari={intestatari}
          sezioneBidella={fruizione.sezioneIdBidella}
        />
      )}
      {fruizione.servizioId === 8 && (
        <CddBergamo
          register={register}
          errors={errors}
          control={control}
          watch={watch}
          intestatari={intestatari}
          fasceCalcolo={fasceCalcolo}
          pagamentoSospeso={fruizione?.pagamentoSospeso}
          servizioId={fruizione.servizioId}
        />
      )}
    </form>
  )
}

export default FormServizio

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const deleteIntestatarioById = async (intestatarioId: number) => {
  const { data } = await axios.delete(`/intestatari/${intestatarioId}/`)
  return data
}

const useDeleteIntestatarioById = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (intestatarioId: number) => {
      return deleteIntestatarioById(intestatarioId)
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('alunnoByIdByAnno')
        cogoToast.success('Intestatario eliminato')
      },
      onError: () => {},
    }
  )
}

export default useDeleteIntestatarioById

interface CentroEstivo {
  value: string
  label: string
}

export const CENTRO_ESTIVO: CentroEstivo[] = [
  { value: 'NO', label: 'No' },
  { value: '2_SETTIMANE', label: 'Servizio 2 settimane' },
  { value: '4_SETTIMANE', label: 'Servizio 4 settimane' },
]

export const getLabelByValue = (value: string) => {
  const centroEstivo = CENTRO_ESTIVO.find((c) => c.value === value)

  return centroEstivo ? centroEstivo.label : ''
}

import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import ReactModal from 'react-modal'
import moment from 'moment'

import { API_URL } from '../../config'

import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import InlineEdit, { InputType } from 'riec'
import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import useServizioById from '../../apiHooks/queries/useServizioById'

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

interface Scuola {
  id: number
  nome: string
  codice: string
  codiceImport: string
  clienteId: number
  tipo: string
  servizi: number[]
}

const Esportazioni: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  const { data: servizio } = useServizioById(servizioId)

  //===================
  // LOCAL STATE
  //===================
  const [meseSel, setMeseSel] = useState<string>(moment().subtract(1, 'month').format('MM'))
  const [annoSel, setAnnoSel] = useState<string>(moment().format('YYYY'))
  const [annoSelPagamenti, setAnnoSelPagamenti] = useState<string>(moment().format('YYYY'))
  const [scuolaSel, setScuolaSel] = useState<number>(0)
  const [dataRitiro, setDataRitiro] = useState<string>(moment().format('YYYY-MM-DD'))
  const [exportPresenzeModalOpen, setExportPresenzeModalOpen] = useState<boolean>(false)
  const [creaGrigliaModalOpen, setCreaGrigliaModalOpen] = useState<boolean>(false)
  const [totaliMeseModalOpen, setTotaliMeseModalOpen] = useState<boolean>(false)
  const [secondoFratelloModalOpen, setSecondoFratelloModalOpen] = useState<boolean>(false)
  const [reportFasceModalOpen, setReportFasceModalOpen] = useState<boolean>(false)
  const [pagamentiPerMeseModalOpen, setPagamentiPerMeseModalOpen] = useState<boolean>(false)
  const [reportPagamentiPerMeseAnnoModal, setReportPagamentiPerMeseAnnoOpen] =
    useState<boolean>(false)
  const [estrazionePerIstat, setEstrazionePerIstat] = useState<boolean>(false)

  //===================
  // EFFECTS
  //===================
  useEffect(() => {
    const anni: string[] = annoScolastico.split('-')
    const anno = parseInt(meseSel, 10) <= 8 ? anni[1] : anni[0]
    setAnnoSel(anno)
  }, [annoScolastico, meseSel])

  // Fetch scuole
  const { data: scuole = [], isFetching: isFetchingScuole } = useScuoleByServizioId(servizioId)

  // Set prima scuola
  useEffect(() => {
    if (scuole.length > 0) {
      setScuolaSel(scuole[0].id)
    }
  }, [isFetchingScuole, scuole])

  const scuoleOptions =
    scuole &&
    scuole.map((scuola: Scuola) => {
      return {
        value: scuola.id,
        label: scuola.nome,
      }
    })

  const mesiOptions = [
    { value: '09', label: 'Settembre' },
    { value: '10', label: 'Ottobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Dicembre' },
    { value: '01', label: 'Gennaio' },
    { value: '02', label: 'Febbraio' },
    { value: '03', label: 'Marzo' },
    { value: '04', label: 'Aprile' },
    { value: '05', label: 'Maggio' },
    { value: '06', label: 'Giugno' },
    { value: '07', label: 'Luglio' },
    { value: '08', label: 'Agosto' },
  ]
  const anniOptions = [
    { value: '2020', label: '2020' },
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
  ]

  // console.log('DATA RITIRO: ', dataRitiro)

  function calcolaAnno(mese: string) {
    if (parseInt(mese) >= 1 && parseInt(mese) < 9) {
      return annoScolastico.split('-')[1]
    } else return annoScolastico.split('-')[0]
  }

  return (
    <div className="bg-white pt-6 pb-12">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-8">Esportazioni</h1>

        <div className="flex space-x-6">
          <a
            className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-600 hover:shadow-xl"
            href={`${API_URL}/servizi/${servizioId}/export-anagrafiche/?annoScolastico=${annoScolastico}&t=${encodeURIComponent(
              localStorage.getItem('token') || ''
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Esporta alunni
          </a>
          <a
            className="block bg-purple-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-purple-600 hover:shadow-xl"
            href={`${API_URL}/servizi/${servizioId}/export-avvisi/?annoScolastico=${annoScolastico}&t=${encodeURIComponent(
              localStorage.getItem('token') || ''
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Esporta avvisi
          </a>
          <button
            className="block bg-pink-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-pink-600 hover:shadow-xl"
            onClick={() => {
              setExportPresenzeModalOpen(true)
            }}
          >
            Esporta presenze...
          </button>
          <button
            className="block bg-yellow-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-yellow-600 hover:shadow-xl"
            onClick={() => {
              setCreaGrigliaModalOpen(true)
            }}
          >
            Crea griglia...
          </button>
          <button
            className="block bg-teal-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-teal-600 hover:shadow-xl"
            onClick={() => {
              setTotaliMeseModalOpen(true)
            }}
          >
            Totali per mese...
          </button>
        </div>
        <div className="pt-6 flex space-x-6">
          <button
            className="block bg-green-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-green-600 hover:shadow-xl"
            onClick={() => setSecondoFratelloModalOpen(true)}
          >
            Controllo secondi fratelli...
          </button>
          {servizio?.codiceCalcolo === 'base' && (
            <button
              className="block bg-orange-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-orange-600 hover:shadow-xl"
              onClick={() => {
                setReportFasceModalOpen(true)
              }}
            >
              Report fasce...
            </button>
          )}
          <a
            className="block bg-indigo-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-indigo-600 hover:shadow-xl"
            href={`${API_URL}/avvisi-pagamento/report-pagamenti/?annoScolastico=${annoScolastico}&t=${encodeURIComponent(
              localStorage.getItem('token') || ''
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Report pagamenti
          </a>
          {servizioId === 8 && (
            <button
              className="block bg-green-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-green-600 hover:shadow-xl"
              onClick={() => {
                setPagamentiPerMeseModalOpen(true)
              }}
            >
              Report per smaf
            </button>
          )}
          <button
            className="block bg-orange-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-orange-600 hover:shadow-xl"
            onClick={() => {
              setReportPagamentiPerMeseAnnoOpen(true)
            }}
          >
            Pagamenti per mese...
          </button>
          {servizio?.id === 2 && (
            <button
              className="block bg-purple-500 focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-purple-600 hover:shadow-xl"
              onClick={() => {
                setEstrazionePerIstat(true)
              }}
            >
              Estrazione per istat...
            </button>
          )}
        </div>
      </div>

      {/* MODAL PRESENZE MESE */}
      <ReactModal
        isOpen={exportPresenzeModalOpen}
        onRequestClose={() => {
          setExportPresenzeModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Esporta tutte le presenze
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div>
              <InlineEdit
                type={InputType.Select}
                value={meseSel}
                onChange={(v) => {
                  setMeseSel(v)
                }}
                options={mesiOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setExportPresenzeModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setExportPresenzeModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                  window.open(
                    `${API_URL}/servizi/${servizioId}/export-presenze/?annoScolastico=${annoScolastico}&mese=${meseSel}&anno=${annoSel}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* MODAL CREA GRIGLIA */}
      <ReactModal
        isOpen={creaGrigliaModalOpen}
        onRequestClose={() => {
          setCreaGrigliaModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Griglia mensile per inserimento manuale
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div>
              <label className="text-sm leading-5 font-medium text-gray-600">Mese</label>
              <InlineEdit
                type={InputType.Select}
                value={meseSel}
                onChange={(v) => {
                  setMeseSel(v)
                }}
                options={mesiOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div>
              <label className="text-sm leading-5 font-medium text-gray-600">Scuola</label>
              <InlineEdit
                type={InputType.Select}
                value={scuolaSel + ''}
                onChange={(v) => setScuolaSel(scuole.find((s: Scuola) => s.id === +v).id)}
                options={scuoleOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setCreaGrigliaModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setCreaGrigliaModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                  window.open(
                    `${API_URL}/servizi/${servizioId}/griglia-pdf/?annoScolastico=${annoScolastico}&mese=${meseSel}&anno=${annoSel}&scuolaId=${scuolaSel}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* MODAL TOTALI PER MESE */}
      <ReactModal
        isOpen={totaliMeseModalOpen}
        onRequestClose={() => {
          setTotaliMeseModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Totali per mese
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div>
              <InlineEdit
                type={InputType.Select}
                value={meseSel}
                onChange={(v) => {
                  setMeseSel(v)
                }}
                options={mesiOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setTotaliMeseModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setTotaliMeseModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                  window.open(
                    `${API_URL}/servizi/${servizioId}/report-pdf/?annoScolastico=${annoScolastico}&mese=${meseSel}&anno=${annoSel}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* MODAL SECONDO FRATELLO */}
      <ReactModal
        isOpen={secondoFratelloModalOpen}
        onRequestClose={() => {
          setSecondoFratelloModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-sm">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Secondo fratello
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <p>Esporta il report secondi fratelli, escludendo chi si è ritirato prima del...</p>
            <div>
              <InlineEdit
                type={InputType.Date}
                value={dataRitiro}
                onChange={(v) => {
                  setDataRitiro(v)
                }}
                format={(value) => moment(value).format('DD/MM/YYYY')}
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setSecondoFratelloModalOpen(false)
                  setDataRitiro(moment().format('YYYY-MM-DD'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setSecondoFratelloModalOpen(false)
                  setDataRitiro(moment().format('YYYY-MM-DD'))
                  window.open(
                    `${API_URL}/servizi/${servizioId}/secondi-fratelli/?annoScolastico=${annoScolastico}&dataRitiro=${dataRitiro}&servizioAggiuntivo=2&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* MODAL REPORT FASCE */}
      <ReactModal
        isOpen={reportFasceModalOpen}
        onRequestClose={() => {
          setReportFasceModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Esporta report fasce
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div>
              <InlineEdit
                type={InputType.Select}
                value={meseSel}
                onChange={(v) => {
                  setMeseSel(v)
                }}
                options={mesiOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>

            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setReportFasceModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setReportFasceModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                  window.open(
                    `${API_URL}/avvisi-pagamento/report-fasce/?annoScolastico=${annoScolastico}&mese=${meseSel}&anno=${annoSel}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={pagamentiPerMeseModalOpen}
        onRequestClose={() => {
          setPagamentiPerMeseModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Pagamenti per mese
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div>
              <InlineEdit
                type={InputType.Select}
                value={meseSel}
                onChange={(v) => {
                  setMeseSel(v)
                }}
                options={mesiOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setPagamentiPerMeseModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setPagamentiPerMeseModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                  window.open(
                    `${API_URL}/avvisi-pagamento/report-smaf/?annoScolastico=${annoScolastico}&mese=${meseSel}&anno=${annoSel}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* MODAL REPORT PAGAMENTI PER MESE E ANNO */}
      <ReactModal
        isOpen={reportPagamentiPerMeseAnnoModal}
        onRequestClose={() => {
          setReportPagamentiPerMeseAnnoOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Esporta pagamenti
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div className="flex flex-col gap-y-4">
              <InlineEdit
                type={InputType.Select}
                value={meseSel}
                onChange={(v) => {
                  setMeseSel(v)
                }}
                options={mesiOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
              <InlineEdit
                type={InputType.Select}
                value={annoSelPagamenti}
                onChange={(v) => {
                  setAnnoSelPagamenti(v)
                }}
                options={anniOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setReportPagamentiPerMeseAnnoOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setReportPagamentiPerMeseAnnoOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                  window.open(
                    `${API_URL}/avvisi-pagamento/report-pagamenti-anno-mese/?mese=${meseSel}&anno=${annoSelPagamenti}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      
      {/* MODAL ESTRAZIONE PER ISTAT */}
      <ReactModal
        isOpen={estrazionePerIstat}
        onRequestClose={() => {
          setEstrazionePerIstat(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-6xl">
          <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
            Estrazione per istat
          </div>
          <div className="flex flex-col space-y-6 px-8 py-6">
            <div>
              <InlineEdit
                type={InputType.Select}
                value={meseSel}
                onChange={(v) => {
                  setMeseSel(v)
                }}
                options={mesiOptions}
                // viewClass="cursor-pointer text-blue-500"
                viewClass="block w-full form-select text-lg"
                editClass="block w-full form-select text-lg"
              />
            </div>
            <div className="flex space-x-6 items-center justify-between">
              <button
                type="button"
                onClick={() => {
                  setReportFasceModalOpen(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                }}
                className="block bg-gray-400 hover:bg-gray-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Annulla
              </button>
              <button
                type="button"
                onClick={() => {
                  setEstrazionePerIstat(false)
                  setMeseSel(moment().subtract(1, 'month').format('MM'))
                  window.open(
                    `${API_URL}/avvisi-pagamento/estrazione-istat/?idServizio=${servizioId}&mese=${meseSel}&anno=${calcolaAnno(
                      meseSel
                    )}&t=${encodeURIComponent(localStorage.getItem('token') || '')}`
                  )
                }}
                className="block bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export default Esportazioni

import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import ReactModal from 'react-modal'
import InlineEdit, { InputType } from 'riec'
import moment from 'moment'
import { useTable, useSortBy, useFilters, useBlockLayout } from 'react-table'
import { FixedSizeList } from 'react-window'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { FiFileText, FiEye } from 'react-icons/fi'
import { FcUnlock } from 'react-icons/fc'
import { FaCircle } from 'react-icons/fa'
import { useForm } from 'react-hook-form'

import { API_URL } from '../../config'

import {
  TipoPresenza,
  isPresente,
  isAssente,
  isRitirato,
  isInRitiroTemporaneo,
  isFestivita,
  isChiusuraNoFestivita,
  isWeekend,
  isErroreSegnato,
  isErroreDimenticato,
  isWarning,
} from '../../lib/validazionePresenze'
import { hasAnomalieFruizioni } from '../../lib/controlloAnomalie'

import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import ServizioContext from '../../context/ServizioContext'

import usePresenzeMese from '../../apiHooks/queries/usePresenzeMese'
import useGiorniNonErogazioneByServizioId from '../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import useBollettaFruizioneMese from '../../apiHooks/queries/useBollettaFruizioneMese'
import useAlunniDaControllare from '../../apiHooks/queries/useAlunniDaControllare'
import useFruizioniDaControllare from '../../apiHooks/queries/useFruizioniDaControllare'
import useStatoAvvisiPagamento from '../../apiHooks/queries/useStatoAvvisiPagamento'
import useAvvisoPagamentoById from '../../apiHooks/queries/useAvvisoPagamentoById'
import useGeneraAvvisiPagamento from '../../apiHooks/mutations/useGeneraAvvisiPagamento'

import {
  selectPresenze,
  loadScuole,
  changeScuolaSel,
  changeMeseSel,
  changeShowPresenzeMensili,
} from '../../redux/modules/presenze'

import {
  selectFatturazioneGenerazioneAvvisi,
  changeDatiGenerazioneAvvisi,
} from '../../redux/modules/fatturazioneGenerazioneAvvisi'

import Loading from '../../components/Loading'
import Price from '../../components/Price'
import FormField, { InputType as formFieldInputType } from '../../components/FormField'
import useServizioById from '../../apiHooks/queries/useServizioById'

interface Scuola {
  id: number
  nome: string
  codice: string
  codiceImport: string
  clienteId: number
  tipo: string
  servizi: number[]
}

interface Fruizione {
  [giorno: string]: boolean
}

interface AlunnoBolletta {
  nome: string
  cognome: string
  fruizioneId: number
}

interface RigaBolletta {
  descrizione: string
  importo: number
  isRetta?: boolean
}

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const Fatturazione: React.FC<RouteComponentProps> = () => {
  //===================
  // REDUX
  //===================
  const presenze = useSelector(selectPresenze)
  const {
    presenze: { scuolaSel, meseSel, showPresenzeMensili },
  } = presenze
  const avvisiPagamento = useSelector(selectFatturazioneGenerazioneAvvisi)
  const {
    fatturazioneGenerazioneAvvisi: { dataEmissione, dataScadenza, periodo, nota },
  } = avvisiPagamento
  const dispatch = useDispatch()

  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { data: servizio } = useServizioById(servizioId)
  //===================
  // LOCAL STATE
  //===================
  const [annoSel, setAnnoSel] = useState<string>(moment().format('YYYY'))
  const [giorniDelMese, setGiorniDelMese] = useState<number[]>([])
  const [alunni, setAlunni] = useState<any[]>([])
  const [numeroErrori, setNumeroErrori] = useState({
    numeroErrori: 0,
    numeroWarning: 0,
    numeroAnomalie: 0,
    numeroAnomalieFruizione: 0,
  })
  const [fruizioneIdSel, setFruizioneId] = useState<number>(0)
  const [avvisoId, setAvvisoId] = useState<number>(0)
  const [righeAvvisoModale, setRigheAvvisoModale] = useState<any[]>([])
  const [isPdf, setIsPdf] = useState(false)
  const [modalBollettaOpen, setModalBollettaOpen] = useState<boolean>(false)
  const [modalAvvisoOpen, setModalAvvisoOpen] = useState<boolean>(false)
  const [formModalOpen, setFormModalOpen] = useState<boolean>(false)
  const [alunnoBolletta, setAlunnoBolletta] = useState<AlunnoBolletta | null>(null)

  //===================
  // HOOKS QUERIES
  //===================

  // Fetch giorni non erogazione
  const { data: giorniNonErogazione } = useGiorniNonErogazioneByServizioId({
    servizioId,
    from: moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true)
      .startOf('month')
      .format('YYYY-MM-DD'),
    to: moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true).endOf('month').format('YYYY-MM-DD'),
    scuolaId: scuolaSel ? scuolaSel.id : null,
  })

  // Fetch scuole
  const { data: scuole = [], isFetching: isFetchingScuole } = useScuoleByServizioId(servizioId)

  const scuoleOptions =
    scuole &&
    scuole.map((scuola: Scuola) => {
      return {
        value: scuola.id,
        label: scuola.nome,
      }
    })

  const mesiOptions = [
    { value: '09', label: 'Settembre' },
    { value: '10', label: 'Ottobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Dicembre' },
    { value: '01', label: 'Gennaio' },
    { value: '02', label: 'Febbraio' },
    { value: '03', label: 'Marzo' },
    { value: '04', label: 'Aprile' },
    { value: '05', label: 'Maggio' },
    { value: '06', label: 'Giugno' },
    { value: '07', label: 'Luglio' },
    { value: '08', label: 'Agosto' },
  ]

  // Fetch presenze mensili
  const { data: presenzeMensili, isFetching: isFetchingPresenze } = usePresenzeMese({
    servizioId,
    scuolaId: scuolaSel ? scuolaSel.id + '' : '',
    mese: parseInt(meseSel),
    anno: parseInt(annoSel),
    annoScolastico,
  })

  // Fetch avvisi pagamento
  const { data: statoAvvisiPagamento } = useStatoAvvisiPagamento({
    servizioId,
    scuolaId: scuolaSel ? scuolaSel.id + '' : '',
    mese: parseInt(meseSel),
    anno: parseInt(annoSel),
  })

  // Fetch bolletta mese
  const { data: bollettaMese, isFetching: isFetchingBollettaMese } = useBollettaFruizioneMese({
    fruizioneId: fruizioneIdSel,
    anno: moment(annoSel, 'YYYY', true).format('YYYY'),
    mese: moment(meseSel, 'MM', true).format('M'),
  })

  // Fetch alunni da controllare
  const { data: alunniDaControllare } = useAlunniDaControllare({
    servizioId,
    annoScolastico,
  })

  // Fetch alunni con anomalie fruizioni
  const { data: alunniConAnomalieFruizioni } = useFruizioniDaControllare({
    annoScolastico,
    servizioId,
    scuolaId: scuolaSel?.id,
  })

  // Fetch avviso pagamento
  const { data: avvisoPagamento } = useAvvisoPagamentoById(avvisoId)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      dataEmissione,
      dataScadenza,
      periodo,
      nota,
    },
  })

  // ========================
  // MUTATIONS
  // ========================

  // UpdateAlunno
  const generaAvvisiPagamento = useGeneraAvvisiPagamento()

  //===================
  // EFFECTS
  //===================
  // Set alunno bolletta
  useEffect(() => {
    if (fruizioneIdSel) {
      const alunnoBolletta = presenzeMensili.find((p: any) => p.fruizione.id === fruizioneIdSel)
      setAlunnoBolletta(alunnoBolletta)
    }
  }, [isFetchingPresenze, fruizioneIdSel, isFetchingBollettaMese, presenzeMensili])

  // Set prima scuola
  useEffect(() => {
    if (scuole.length > 0) {
      dispatch(loadScuole(scuole[0]))
    }
  }, [isFetchingScuole, scuole, dispatch])

  // Set avviso pagamento / pdf
  useEffect(() => {
    // ANTEPRIMA
    if (avvisoPagamento && avvisoPagamento.righeImporti) {
      const righeAvvisoPagamento = JSON.parse(avvisoPagamento.righeImporti)

      if (righeAvvisoPagamento && righeAvvisoPagamento.length) {
        setRigheAvvisoModale(righeAvvisoPagamento)
      }
    }
    // PDF
    if (avvisoPagamento && isPdf) {
      window.open(
        `${avvisoPagamento.linkDownload}?t=${encodeURIComponent(
          localStorage.getItem('token') || ''
        )}`
      )
    }
  }, [avvisoPagamento, isPdf])

  // Set anno sel
  useEffect(() => {
    const anni: string[] = annoScolastico.split('-')
    const anno = parseInt(meseSel, 10) <= 8 ? anni[1] : anni[0]
    setAnnoSel(anno)
  }, [annoScolastico, meseSel])

  // Set giorni del mese
  useEffect(() => {
    let giorniNelMese: number
    let giorniMese: number[] = []
    giorniNelMese = moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true).daysInMonth()

    for (let i: number = 1; i <= giorniNelMese; i++) {
      giorniMese.push(i)
    }

    setGiorniDelMese(giorniMese)
  }, [annoSel, meseSel])

  // Set dati generazione avvisi pagamento
  useEffect(() => {
    reset({
      dataEmissione,
      dataScadenza,
      periodo,
      nota,
    })
  }, [reset, dataEmissione, dataScadenza, periodo, nota])

  //===================
  // EVENT HANDLERS
  //===================
  const handleAnteprimaBolletta =
    (id: number, avvisoCreato: boolean) => (event: React.MouseEvent) => {
      setIsPdf(false)
      document.getElementById('root')!.style.filter = 'blur(5px)'

      if (!!avvisoCreato) {
        setAvvisoId(id)
        setModalAvvisoOpen(true)
      } else {
        setFruizioneId(id)
        setModalBollettaOpen(true)
      }
    }

  const handlePdfAvviso = (id: number) => (event: React.MouseEvent) => {
    setIsPdf(true)
    setAvvisoId(id)
  }

  //===================
  // TABLE
  //===================
  // console.log(alunni)
  //   const data = React.useMemo(
  //     () =>
  //       meseSel === '07'
  //         ? alunni.filter((alunno) => {
  //             return alunno.fruizione.centroEstivo === 'NO'
  //           })
  //         : alunni,
  //     [meseSel, alunni]
  //   )
  const data = React.useMemo(() => alunni, [alunni])
  // IS PRESENTE OK
  const isPresenteOkClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isPresente(value) &&
      !isWarning(
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        value,
        servizio
      ) &&
      !isErroreSegnato(
        value,
        giorniNonErogazione,
        fruizione,
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS ASSENTE OK
  const isAssenteOkClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isAssente(value) &&
      //!isPresente(value) &&
      !isErroreSegnato(
        value,
        giorniNonErogazione,
        fruizione,
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS CHIUSURA (NO FESTIVITÀ)
  const isChiusuraNoFestivitaClass = useCallback(
    (day: string) =>
      isChiusuraNoFestivita(
        giorniNonErogazione,
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format('YYYY-MM-DD')
      ),
    [annoSel, giorniNonErogazione, meseSel]
  )

  // IS WEEKEND O FESTIVITÀ
  const isWeekendOFestivitaClass = useCallback(
    (day: string) =>
      isWeekend(
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format('YYYY-MM-DD')
      ) ||
      isFestivita(
        giorniNonErogazione,
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format('YYYY-MM-DD')
      ),
    [annoSel, giorniNonErogazione, meseSel]
  )

  // IS RITIRATO
  const isRitiratoClass = useCallback(
    (day: string, dataRitiro: string, dataRitiroBidella: string) =>
      isRitirato(
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        dataRitiro,
        dataRitiroBidella
      ),
    [annoSel, meseSel]
  )

  // IS RITIRATO TEMPORANEO
  const isRitiratoTemporaneoClass = useCallback(
    (day: string, dataInizioRitiroTemporaneo: string, dataFineRitiroTemporaneo: string) =>
      isInRitiroTemporaneo(
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        dataInizioRitiroTemporaneo,
        dataFineRitiroTemporaneo
      ),
    [annoSel, meseSel]
  )

  // IS ERRORE DIMENTICATO
  const isErroreDimenticatoClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isErroreDimenticato(
        value,
        giorniNonErogazione,
        fruizione,
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS ERRORE SEGNATO
  const isErroreSegnatoClass = useCallback(
    (value: TipoPresenza, day: string, fruizione: Fruizione) =>
      isErroreSegnato(
        value,
        giorniNonErogazione,
        fruizione,
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        servizio
      ),
    [annoSel, giorniNonErogazione, meseSel, servizio]
  )

  // IS WARNING
  const isWarningClass = useCallback(
    (value: TipoPresenza, day: string) =>
      isWarning(
        moment(`${annoSel}-${meseSel}-${parseInt(day, 10)}`, 'YYYY-MM-D', true).format(
          'YYYY-MM-DD'
        ),
        value,
        servizio
      ),
    [annoSel, meseSel, servizio]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Classe',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'sezione',
        width: 160,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'Cognome e nome',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'cognome',
        width: 210,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              <Link
                className={`${
                  !props.row.original.nome
                    ? 'text-red-600 hover:text-red-700'
                    : 'text-blue-500 hover:text-blue-600'
                }`}
                to={`/app/anagrafica/alunno/${props.row.original.id}/servizio/${servizioId}`}
              >
                {props.value} {props.row.original.nome}
              </Link>
            </div>
          )
        },
      },
      {
        Header: 'Avviso',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'avviso',
        width: 140,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              <button
                className={classNames(
                  'bg-green-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-green-700 hover:shadow-xl',
                  { invisible: props.row.original.fruizione.pagamentoSospeso }
                )}
                onClick={handleAnteprimaBolletta(
                  !!props.row.original.avviso
                    ? props.row.original.avviso
                    : props.row.original.fruizione.id,
                  !!props.row.original.avviso
                )}
                disabled={!(props.row.original.avviso || props.row.original.fruizione.id)}
              >
                <FiEye />
              </button>
              {!!props.row.original.avviso ? (
                <button
                  className={classNames(
                    'ml-3 bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl',
                    { invisible: props.row.original.fruizione.pagamentoSospeso }
                  )}
                  onClick={handlePdfAvviso(props.row.original.avviso)}
                >
                  <FiFileText />
                </button>
              ) : (
                <a
                  className={classNames(
                    'ml-3 bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl',
                    { invisible: props.row.original.fruizione.pagamentoSospeso }
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${API_URL}/fruizioni/${
                    props.row.original.fruizione.id
                  }/pdf/?anno=${annoSel}&mese=${moment(meseSel, 'MM', true).format(
                    'M'
                  )}&t=${encodeURIComponent(localStorage.getItem('token') || '')}`}
                >
                  <FiFileText />
                </a>
              )}
              {!!props.row.original.avviso ? (
                props.row.original.errorePagopa ? (
                  <div className="ml-3 text-yellow-500" title={props.row.original.errorePagopa}>
                    <FaCircle />
                  </div>
                ) : (
                  <div
                    className="ml-3 text-green-500"
                    title={`Avviso creato ${props.row.original.avviso}`}
                  >
                    <FaCircle />
                  </div>
                )
              ) : (
                <div className="ml-3 text-gray-400" title="Avviso non creato">
                  <FaCircle />
                </div>
              )}
              {!!props.row.original.calcoloSbloccato && (
                <div className="text-lg ml-2" title="Calcolo sbloccato">
                  <FcUnlock />
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Totale',
        Footer: (props: any) => {
          const total = useMemo(
            () => props.rows.reduce((sum: any, row: any) => sum + row.values.totaleRetta, 0),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-end items-center p-3 font-semibold bg-blue-100 text-blue-800 border-t border-gray-500">
              <Price amount={total || 0} />
            </div>
          )
        },
        accessor: 'totaleRetta',
        width: 100,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div
              className={classNames('h-full flex justify-end items-center p-3 text-sm', {
                'bg-yellow-200': !props.value,
                'line-through text-gray-500': props.row.original.fruizione.pagamentoSospeso,
              })}
            >
              <Price amount={props.row.original.totaleRetta || 0} />
            </div>
          )
        },
      },
      {
        Header: 'Presenze',
        Footer: (props: any) => {
          const total = useMemo(
            () => props.rows.reduce((sum: any, row: any) => sum + row.values.numeroPresenze, 0),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-center items-center p-3 font-semibold bg-blue-100 text-green-700 border-t border-gray-500 text-xs">
              {total || null}
            </div>
          )
        },
        // Footer: (props: any) => {
        //   return (
        //     <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
        //       &nbsp;
        //     </div>
        //   )
        // },
        accessor: 'numeroPresenze',
        width: 80,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-center items-center p-3 text-green-500">
              {props.value}
            </div>
          )
        },
      },
      {
        Header: 'Assenze',
        Footer: (props: any) => {
          const total = useMemo(
            () => props.rows.reduce((sum: any, row: any) => sum + row.values.numeroAssenze, 0),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-center items-center p-3 font-semibold bg-blue-100 text-red-700 border-t border-gray-500 text-xs">
              {total || null}
            </div>
          )
        },
        // Footer: (props: any) => {
        //   return (
        //     <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
        //       &nbsp;
        //     </div>
        //   )
        // },
        accessor: 'numeroAssenze',
        width: 80,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-center items-center p-3 text-red-500">
              {props.value}
            </div>
          )
        },
      },
      {
        Header: 'Errori',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'errori',
        width: 140,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              {!!props.row.original.numeroErrori ? (
                <span className="w-5 h-5 flex justify-center items-center bg-red-700 text-xs text-white rounded-full">
                  {props.row.original.numeroErrori}
                </span>
              ) : (
                <span className="w-5 h-5"></span>
              )}
              {!!props.row.original.numeroWarning && (
                <span className="ml-2 w-5 h-5 flex justify-center items-center bg-yellow-500 text-xs text-white rounded-full">
                  {props.row.original.numeroWarning}
                </span>
              )}
              {!!props.row.original.hasAnomalie && (
                <span className="ml-2 w-5 h-5 flex justify-center items-center bg-teal-400 text-xs text-white rounded-full">
                  !
                </span>
              )}
              {!!props.row.original.hasAnomalieFruizione && (
                <span className="ml-2 w-5 h-5 flex justify-center items-center bg-purple-400 text-xs text-white rounded-full">
                  !
                </span>
              )}
            </div>
          )
        },
      },
      ...(showPresenzeMensili
        ? giorniDelMese.map((g) => {
            return {
              Header: g + '',
              Footer: (props: any) => {
                return (
                  <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
                    &nbsp;
                  </div>
                )
              },
              accessor: g + '',
              width: 55,
              disableSortBy: true,
              disableFilters: true,
              Cell: (props: any) => {
                return (
                  <div
                    className={classNames(
                      'h-full p-3',
                      {
                        'text-green-600':
                          props.value === 'P' &&
                          isPresenteOkClass(
                            props.value,
                            props.column.Header,
                            props.row.original.fruizione
                          ),
                      },
                      {
                        'text-yellow-600':
                          props.value === 'B' &&
                          isPresenteOkClass(
                            props.value,
                            props.column.Header,
                            props.row.original.fruizione
                          ),
                      },
                      {
                        'text-purple-600':
                          props.value === 'M' &&
                          isPresenteOkClass(
                            props.value,
                            props.column.Header,
                            props.row.original.fruizione
                          ),
                      },
                      {
                        // text-gray-500
                        'text-red-600': isAssenteOkClass(
                          props.value,
                          props.column.Header,
                          props.row.original.fruizione
                        ),
                      },
                      {
                        'text-red-500 font-bold': isErroreSegnatoClass(
                          props.value,
                          props.column.Header,
                          props.row.original.fruizione
                        ),
                      },
                      {
                        // text-yellow-500
                        'text-red-500 font-bold': isWarningClass(props.value, props.column.Header),
                      },
                      {
                        'bg-gray-200': isWeekendOFestivitaClass(props.column.Header),
                      },
                      {
                        // bg-purple-200
                        'bg-red-300': isChiusuraNoFestivitaClass(props.column.Header),
                      },
                      {
                        'bg-red-300': isErroreDimenticatoClass(
                          props.value,
                          props.column.Header,
                          props.row.original.fruizione
                        ),
                      },
                      {
                        'bg-gray-400 font-bold': isRitiratoClass(
                          props.column.Header,
                          props.row.original.fruizione.dataRitiro,
                          props.row.original.fruizione.dataRitiroBidella
                        ),
                      },
                      {
                        'bg-gray-300 font-bold': isRitiratoTemporaneoClass(
                          props.column.Header,
                          props.row.original.fruizione.dataInizioRitiroTemporaneo,
                          props.row.original.fruizione.dataFineRitiroTemporaneo
                        ),
                      }
                    )}
                  >
                    {props.value || ''}
                  </div>
                )
              },
            }
          })
        : []),
    ],
    [
      giorniDelMese,
      servizioId,
      annoSel,
      meseSel,
      showPresenzeMensili,
      isPresenteOkClass,
      isAssenteOkClass,
      isRitiratoClass,
      isRitiratoTemporaneoClass,
      isChiusuraNoFestivitaClass,
      isWeekendOFestivitaClass,
      isErroreDimenticatoClass,
      isErroreSegnatoClass,
      isWarningClass,
      // servizio,
    ]
  )

  const columnFilter = (props: any) => {
    const {
      filterValue,
      // preFilteredRows,
      setFilter,
    } = props.column
    // const count = preFilteredRows.length

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
        placeholder="Cerca..."
        // placeholder={`Cerca tra ${count} alunni...`}
        className="form-input p-1 rounded text-xs w-24"
      />
    )
  }

  const defaultColumn = React.useMemo(
    () => ({
      Filter: columnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    totalColumnsWidth,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
    },
    useBlockLayout,
    useFilters,
    useSortBy
  )

  //===================
  // HELPERS
  //===================
  const getNumeroErrori = (alunniOk: any) => {
    return alunniOk.reduce(
      (acc: any, alunno: any) => {
        return {
          numeroErrori: acc.numeroErrori + alunno.numeroErrori,
          numeroWarning: acc.numeroWarning + alunno.numeroWarning,
          numeroAnomalie: acc.numeroAnomalie + (alunno.hasAnomalie ? 1 : 0),
          numeroAnomalieFruizione:
            acc.numeroAnomalieFruizione + (alunno.hasAnomalieFruizione ? 1 : 0),
        }
      },
      {
        numeroErrori: 0,
        numeroWarning: 0,
        numeroAnomalie: 0,
        numeroAnomalieFruizione: 0,
      }
    )
  }

  //===================
  // EFFECTS 2
  //===================
  // CALCOLO ALUNNI
  useEffect(() => {
    const getAlunniOk = () => {
      const alunniOk =
        presenzeMensili && presenzeMensili.length
          ? presenzeMensili
              .filter((presenza: any) => presenza.sezione)
              .map((presenzeMensili: any) => {
                const numeroPresenze = presenzeMensili.presenze.reduce((acc: any, giorno: any) => {
                  if (isPresente(giorno.tipo)) {
                    return acc + 1
                  }
                  return acc
                }, 0)

                const numeroAssenze = presenzeMensili.presenze.reduce((acc: any, giorno: any) => {
                  if (isAssente(giorno.tipo)) {
                    return acc + 1
                  }
                  return acc
                }, 0)

                const presenze: {
                  [key: string]: TipoPresenza
                } = presenzeMensili.presenze.reduce((acc: any, g: any) => {
                  const numeroGiorno = moment(g.data).date()
                  return {
                    ...acc,
                    [numeroGiorno + '']: g.tipo,
                  }
                }, {})

                // const presenzeLuglio = (meseSel: string) => {
                //   if (meseSel === '07') {
                //     return presenze
                //   } else {
                //     return 'MESE SBAGLIATO'
                //   }
                // }
                // console.log(meseSel, presenzeLuglio(meseSel))

                const numeroDimenticanze = giorniDelMese.reduce((acc, g) => {
                  const data = moment(`${annoSel}-${meseSel}-${g}`, 'YYYY-MM-D', true).format(
                    'YYYY-MM-DD'
                  )
                  const isDimenticato = isErroreDimenticato(
                    presenze[g + ''],
                    giorniNonErogazione,
                    presenzeMensili.fruizione,
                    data,
                    servizio
                  )
                  return acc + (isDimenticato ? 1 : 0)
                }, 0)

                const numeroSalvataggiErrati = presenzeMensili.presenze.reduce(
                  (acc: any, g: any) => {
                    const isSegnatoSbagliato = isErroreSegnato(
                      g.tipo,
                      giorniNonErogazione,
                      presenzeMensili.fruizione,
                      moment(g.data, 'YYYY-MM-D', true).format('YYYY-MM-DD'),
                      servizio
                    )
                    return acc + (isSegnatoSbagliato ? 1 : 0)
                  },
                  0
                )

                const numeroErrori = numeroDimenticanze + numeroSalvataggiErrati

                const numeroWarning = presenzeMensili.presenze.reduce((acc: any, g: any) => {
                  const data = moment(g.data, 'YYYY-MM-DD', true).format('YYYY-MM-DD')
                  const warning = isWarning(data, g.tipo, servizio)
                  return acc + (warning ? 1 : 0)
                }, 0)

                const hasAnomalie = alunniDaControllare.find(
                  (alunno: any) => alunno.id === presenzeMensili.id
                )

                const alunnoConAnomalieFruizione = alunniConAnomalieFruizioni.find(
                  (alunno: any) => alunno.alunnoId === presenzeMensili.id
                )

                const hasAnomalieFruizione =
                  alunnoConAnomalieFruizione &&
                  hasAnomalieFruizioni(alunnoConAnomalieFruizione, servizioId)

                // const totaleDaFormattare = presenzeMensili.totaleRetta || 0
                // const totale = `€ ${totaleDaFormattare.toFixed(2)}`.replace('.', ',')

                return {
                  ...presenzeMensili,
                  sezione: `${presenzeMensili.sezione?.classe} ${presenzeMensili.sezione?.sezione}`,
                  // totale,
                  numeroPresenze,
                  numeroAssenze,
                  numeroErrori,
                  numeroWarning,
                  hasAnomalie: !!hasAnomalie,
                  hasAnomalieFruizione: !!hasAnomalieFruizione,
                  ...presenze,
                }
              })
          : []

      return alunniOk
    }

    if (
      presenzeMensili &&
      alunniDaControllare &&
      alunniConAnomalieFruizioni &&
      giorniNonErogazione
    ) {
      setAlunni(getAlunniOk())
    }
  }, [
    alunniDaControllare,
    alunniConAnomalieFruizioni,
    giorniNonErogazione,
    presenzeMensili,
    annoSel,
    giorniDelMese,
    meseSel,
    servizioId,
    servizio,
  ])

  useEffect(() => {
    if (alunni && alunni.length > 0) {
      setNumeroErrori(getNumeroErrori(alunni))
    }
  }, [alunni])

  const onSubmit = (data: any) => {
    const dataToPass = {
      ...data,
      scuolaId: scuolaSel?.id,
      mese: parseInt(meseSel),
      anno: parseInt(annoSel),
      dataScadenzaPagopa: data.dataScadenza,
    }

    dispatch(changeDatiGenerazioneAvvisi(data))

    generaAvvisiPagamento.mutate({
      servizioId,
      avviso: dataToPass,
    })
    setFormModalOpen(false)
  }

  // Generazione disabled
  const ciSonoErrori =
    numeroErrori.numeroErrori + numeroErrori.numeroAnomalie + numeroErrori.numeroAnomalieFruizione >
    0
  const generazioneInCorso = statoAvvisiPagamento && statoAvvisiPagamento.status === 'progress'
  const almenoUnoDaGenerare = alunni.some((e: any) => {
    return e.calcoloSbloccato || !(e.avviso || e.fruizione.pagamentoSospeso)
  })
  const generazioneDisabled = ciSonoErrori || generazioneInCorso || !almenoUnoDaGenerare

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr hover:bg-blue-100 flex h-full items-center border-b border-gray-400"
        >
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps()}
                className="td h-full overflow-y-hidden leading-tight p-0"
              >
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  return (
    <div className="bg-white py-6">
      <style>{`
        .ag-theme-alpine * {
          border-style: none;
        }
        .ag-react-container {
          height: 100%;
        }
      `}</style>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {isFetchingScuole && <Loading size={40} className="py-6" />}
        <h2 className="text-lg leading-tight mt-3 mb-4 text-gray-600 flex items-center">
          {scuolaSel && (
            <InlineEdit
              type={InputType.Select}
              value={scuolaSel.id + ''}
              onChange={(v) => dispatch(changeScuolaSel(scuole.find((s: Scuola) => s.id === +v)))}
              options={scuoleOptions}
              editClass="form-select text-lg"
              viewClass="cursor-pointer text-blue-500"
            />
          )}
        </h2>
        <h1 className="text-4xl leading-tight mb-8">
          <span className="text-xl">Presenze di</span>{' '}
          <InlineEdit
            type={InputType.Select}
            value={meseSel}
            onChange={(v) => {
              dispatch(changeMeseSel(v))
            }}
            options={mesiOptions}
            viewClass="cursor-pointer text-blue-500"
            editClass="form-select text-lg"
          />
        </h1>

        {numeroErrori.numeroErrori +
          numeroErrori.numeroWarning +
          numeroErrori.numeroAnomalie +
          numeroErrori.numeroAnomalieFruizione >
          0 && (
          <div className="bg-orange-100 py-3 px-6 rounded mb-6">
            <div className="flex flex-col">
              {!!numeroErrori.numeroErrori && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-red-700 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroErrori}
                  </div>
                  <div>
                    Presenze non salvate o indicate in giorni di chiusura o con alunno ritirato
                  </div>
                </div>
              )}
              {!!numeroErrori.numeroWarning && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-yellow-500 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroWarning}
                  </div>
                  <div>Presenze in giorni non previsti</div>
                </div>
              )}
              {!!numeroErrori.numeroAnomalie && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-teal-400 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroAnomalie}
                  </div>
                  <div>Alunni con anomalie nei dati</div>
                </div>
              )}
              {!!numeroErrori.numeroAnomalieFruizione && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-purple-400 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroAnomalieFruizione}
                  </div>
                  <div>Alunni con anomalie nelle fruizioni</div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex bg-blue-100 py-3 px-6 rounded mb-6">
          <button
            disabled={generazioneDisabled}
            type="button"
            onClick={() => setFormModalOpen(true)}
            className={classNames(
              'block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150',
              {
                'opacity-50 cursor-not-allowed': generazioneDisabled,
              },
              {
                'hover:bg-blue-700 hover:shadow-xl': !generazioneDisabled,
              }
            )}
          >
            Generazione avvisi di pagamento
          </button>
          {statoAvvisiPagamento && statoAvvisiPagamento.status === 'done' && (
            <div className="ml-6 text-sm text-blue-600 leading-5 py-2">Avvisi già generati</div>
          )}
          {statoAvvisiPagamento && statoAvvisiPagamento.status === 'progress' && (
            <div className="ml-6 flex">
              <Loading size={30} type="Watch" />
              <div className="ml-3 text-sm text-blue-600 leading-5 py-2">
                Generazione in corso...
              </div>
            </div>
          )}
          {statoAvvisiPagamento && statoAvvisiPagamento.status === 'error' && (
            <div className="ml-6 text-sm text-red-600 leading-5 py-2">
              Errore durante l'ultima generazione
            </div>
          )}
        </div>

        {!!alunni && alunni.length > 0 ? (
          <>
            <div className="flex items-center justify-end">
              <button
                type="button"
                className="block p-3 bg-gray-100 border border-b-0 border-gray-400 text-sm font-medium rounded-t"
                onClick={() => dispatch(changeShowPresenzeMensili(!showPresenzeMensili))}
              >
                {!showPresenzeMensili ? 'Mostra' : 'Nascondi'} dettaglio giornaliero
              </button>
            </div>
            <div className="table-wrapper block max-w-full overflow-x-scroll overflow-y-hidden">
              <div
                {...getTableProps()}
                className="table w-full max-w-6xl text-sm border border-gray-400 rounded rounded-tr-none"
              >
                <div>
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column: any) => (
                        <div
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={classNames(
                            'th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold',
                            {
                              'text-right': column.Header === 'Totale',
                            },
                            {
                              'text-center': column.Header === 'Assenze',
                            }
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                          <div className="pt-2 w-full" onClick={(e) => e.stopPropagation()}>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()}>
                  <FixedSizeList
                    height={400}
                    itemCount={rows.length}
                    itemSize={42}
                    width={totalColumnsWidth}
                  >
                    {RenderRow}
                  </FixedSizeList>
                </div>
                {footerGroups[0].headers.length < 30 &&
                  footerGroups.map((group) => (
                    <div className="tr footer-tr" {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <div className="td" {...column.getFooterProps()}>
                          {column.render('Footer')}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </>
        ) : (
          <p>Non ci sono dati da mostrare</p>
        )}
      </div>

      <ReactModal
        isOpen={formModalOpen}
        onRequestClose={() => {
          setFormModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg p-8">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex justify-between items-center"
            autoComplete="off"
          >
            <div className="flex flex-col">
              <div className="flex mb-3">
                <FormField
                  fieldName="dataEmissione"
                  label="Data di emissione"
                  type={formFieldInputType.Date}
                  validation={{ required: 'Inserisci la data di emissione' }}
                  register={register}
                  errors={errors}
                  className="mr-6"
                />
                <FormField
                  fieldName="dataScadenza"
                  label="Data di scadenza"
                  type={formFieldInputType.Date}
                  validation={{ required: 'Inserisci la data di scadenza' }}
                  register={register}
                  errors={errors}
                />
              </div>
              <FormField
                fieldName="periodo"
                label="Periodo"
                type={formFieldInputType.Text}
                validation={{ required: 'Inserisci il periodo' }}
                register={register}
                errors={errors}
                className="mb-3"
              />
              <FormField
                fieldName="nota"
                label="Note"
                type={formFieldInputType.Textarea}
                rows={3}
                validation={{
                  maxLength: { value: 500, message: 'Max 500 caratteri' },
                }}
                register={register}
                errors={errors}
                className="mb-3"
              />
              <button
                type="submit"
                className="block bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
              >
                Genera avvisi di pagamento
              </button>
            </div>
          </form>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={modalBollettaOpen}
        onRequestClose={() => {
          setFruizioneId(0)
          // setBolletta(null)
          setModalBollettaOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        {!!bollettaMese && bollettaMese.righe && (
          <div className="max-w-lg">
            <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
              Bolletta provvisoria di {moment(bollettaMese?.mese, 'M', true).format('MMMM')}{' '}
              {bollettaMese?.anno}
              <br />
              {alunnoBolletta?.cognome} {alunnoBolletta?.nome}
            </div>

            <div className="px-8 py-6">
              <table className="mx-auto">
                <tbody>
                  {bollettaMese?.righe.map((riga) => (
                    <tr key={riga.descrizione}>
                      <td className="pb-1 px-4">{riga.descrizione}</td>
                      <td className="pb-1 px-4 text-right">{riga.importo}</td>
                    </tr>
                  ))}
                  <tr className="border-t border-blue-300">
                    <td className="pt-2 px-4 uppercase font-bold">Totale</td>
                    <td className="pt-2 px-4 text-right font-bold">{bollettaMese?.totale}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ReactModal>

      <ReactModal
        isOpen={modalAvvisoOpen}
        onRequestClose={() => {
          setAvvisoId(0)
          // setBolletta(null)
          setModalAvvisoOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        {righeAvvisoModale.length > 0 && (
          <div className="max-w-lg">
            <div className="bg-blue-500 text-white font-semibold px-6 py-2 text-center">
              Bolletta di {moment(avvisoPagamento?.mese, 'M', true).format('MMMM')}{' '}
              {avvisoPagamento?.anno}
              <br />
              {avvisoPagamento?.bambinoCognome} {avvisoPagamento?.bambinoNome}
            </div>

            <div className="px-8 py-6">
              <table className="mx-auto">
                <tbody>
                  {righeAvvisoModale.map((riga: RigaBolletta) => (
                    <tr key={riga.descrizione}>
                      <td className="pb-1 px-4">{riga.descrizione}</td>
                      <td className="pb-1 px-4 text-right">{riga.importo}</td>
                    </tr>
                  ))}
                  <tr className="border-t border-blue-300">
                    <td className="pt-2 px-4 uppercase font-bold">Totale</td>
                    <td className="pt-2 px-4 text-right font-bold">
                      {avvisoPagamento?.importoTotale}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ReactModal>
    </div>
  )
}

export default Fatturazione

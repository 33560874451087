import { useQuery } from 'react-query'
import axios from 'axios'

interface IAvvisiPagamento {
  annoScolastico: string
  servizioId: number
  mese?: string
  scadutiEntro?: string
}

const getAvvisiPagamentoByMese = async (
  _: string,
  { annoScolastico, servizioId, mese, scadutiEntro }: IAvvisiPagamento
) => {
  const meseString = mese ? `&mese=${mese}` : ''
  const scadutiEntroString = scadutiEntro ? `&scadutiEntro=${scadutiEntro}` : ''
  const { data } = await axios.get(
    `/avvisi-pagamento?limit=10000&annoScolastico=${annoScolastico}&servizio=${servizioId}${meseString}${scadutiEntroString}`
  )

  return data.results
}

const useAvvisiPagamentoByMese = ({ annoScolastico, servizioId, mese, scadutiEntro }: IAvvisiPagamento) => {
  return useQuery(
    ['avvisiPagamentoByMese', { annoScolastico, servizioId, mese, scadutiEntro }],
    () => getAvvisiPagamentoByMese('avvisiPagamentoByMese', { annoScolastico, servizioId, mese, scadutiEntro }),
    {
      enabled: !!annoScolastico && !!servizioId,
      // 1 minuto
      // staleTime:   60 * 1000,
    }
  )
}

export default useAvvisiPagamentoByMese

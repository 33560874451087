import { Link, navigate, RouteComponentProps, Router } from '@reach/router'
import classNames from 'classnames'
import moment from 'moment'
import React, { useCallback, useContext, useState } from 'react'
import ReactModal from 'react-modal'

import Loading from '../../../components/Loading'
import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import { getSesso } from '../../../lib/codiceFiscale'
import Dati from './Dati'
import Intestatari from './intestatari'
import ElencoIntestatari from './intestatari/Elenco'
import Intestatario from './intestatari/Intestatario'
import Servizio from './Servizio'

import useDeleteAlunno from '../../../apiHooks/mutations/useDeleteAlunno'
import useAlunnoByIdByAnno from '../../../apiHooks/queries/useAlunnoByIdByAnno'
import useProfile from '../../../apiHooks/queries/useProfile'
import useScuole from '../../../apiHooks/queries/useScuole'
import useServizi from '../../../apiHooks/queries/useServizi'

import useDuplicaFruizione from '../../../apiHooks/mutations/useDuplicaFruizione'
import { LAST_YEAR } from '../../../config'
import imgAlunna from '../../../images/alunna.svg'
import imgAlunno from '../../../images/alunno.svg'

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-900 text-sm font-medium rounded',
      }
    : {}
}

const isPartiallyActive = ({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) => {
  return isPartiallyCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-900 text-sm font-medium rounded',
      }
    : {}
}

interface AlunnoProps extends RouteComponentProps {
  id?: number
}

const Alunno: React.FC<AlunnoProps> = ({ children, id }) => {
  const token = localStorage.getItem('token')
  const clienteId = localStorage.getItem('clienteId')

  const { data: user } = useProfile(token, clienteId ? parseInt(clienteId, 10) : undefined)

  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showDuplicaFruizioneModal, setShowDuplicaFruizioneModal] = useState<boolean>(false)

  const {
    data: alunno,
    error: errorAlunno,
    isFetching: isFetchingAlunno,
  } = useAlunnoByIdByAnno({
    alunnoId: id,
    annoScolastico,
  })

  const { data: scuole, error: errorScuole, isFetching: isFetchingScuole } = useScuole()

  const sesso = alunno && getSesso(alunno.codiceFiscale || '')

  const getEtaAlunno = (dataNascita: string) => {
    if (!dataNascita) {
      return '-'
    }
    const dataNascitaMoment = moment(dataNascita, 'YYYY-MM-DD', true)
    if (!dataNascitaMoment.isValid()) {
      return '-'
    }

    const etaInAnni = alunno && moment().diff(dataNascitaMoment, 'years')

    if (etaInAnni < 1) {
      return `${moment().diff(dataNascitaMoment, 'months')} mesi`
    }

    return `${etaInAnni} ann${etaInAnni === 1 ? 'o' : 'i'}`
  }

  const deleteAlunno = useDeleteAlunno()

  const duplicaFruizione = useDuplicaFruizione()

  const closeDeleteModal = useCallback(() => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setShowDeleteModal(false)
  }, [])
  const closeDuplicaFruizioneModal = useCallback(() => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setShowDuplicaFruizioneModal(false)
  }, [])

  const handleEliminaAlunno = useCallback(
    (alunnoId: number) => {
      deleteAlunno.mutate(alunnoId)
      closeDeleteModal()
      navigate(`/app/anagrafica`)
    },
    [deleteAlunno, closeDeleteModal]
  )

  const handleDuplicaFruizione = useCallback(
    (fruizioneId: number) => {
      duplicaFruizione.mutate(fruizioneId)
      closeDeleteModal()
      navigate(`/app/anagrafica`)
    },
    [closeDeleteModal, duplicaFruizione]
  )

  const { data: servizi } = useServizi()

  const renderNomiServizi =
    user &&
    user.servizi.map((sId: any) => {
      const nomeServizio = servizi && servizi.find((s: any) => s.id === sId).nome
      const isAttivo = alunno && alunno.fruizioni.find((f: any) => f.servizioId === sId)

      return (
        <Link
          key={sId}
          to={`servizio/${sId}`}
          className={`mr-4 py-1 px-3 border border-gray-100 hover:bg-white hover:border-blue-200 text-sm rounded transition-colors duration-150 ${
            !!isAttivo ? 'text-gray-700' : 'text-gray-500'
          }`}
          getProps={isActive}
        >
          <span className="capitalize">{nomeServizio}</span>
        </Link>
      )
    })

  if (isFetchingScuole || isFetchingAlunno) {
    return <Loading className="py-12" />
  }

  if (errorScuole || errorAlunno) {
    return <span>Si è verificato un errore</span>
  }

  if (!alunno.sezione) {
    return (
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl mb-4 leading-tight">
          {alunno.cognome} {alunno.nome}
        </h1>
        Questo alunno non ha sezione assegnata o fruizioni per l'anno corrente: vedere{' '}
        {alunno.sezioni.map((s: any) => (
          <span key={s.annoScolastico} className="py-1 px-2 bg-blue-200 rounded mr-2">
            {s.annoScolastico}
          </span>
        ))}
      </div>
    )
  }

  const scuola =
    scuole && alunno.sezione ? scuole.find((s: any) => s.id === alunno.sezione.scuolaId) : {}

  const alunnoIscrittoAdAnnoCorrente = alunno.sezioni.some(
    (sezione: { annoScolastico: string }) => sezione.annoScolastico === LAST_YEAR
  )

  return (
    <div>
      <div className="bg-white py-8 border-b border-gray-300">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-start justify-between">
            <div className="flex items-center">
              <img
                src={sesso === 'F' ? imgAlunna : imgAlunno}
                alt={alunno.cognome + ' ' + alunno.nome}
                className={classNames(
                  'w-24 h-24 p-1 rounded-full mr-8 border',
                  { 'bg-red-100 border-red-200': sesso === 'F' },
                  { 'bg-blue-100 border-blue-200': sesso !== 'F' }
                )}
              />
              <div>
                <h1 className="text-4xl mb-2 leading-tight flex items-center space-x-4">
                  <div>
                    {alunno.cognome} {alunno.nome}
                  </div>
                  <div className="py-1 px-3 rounded bg-gray-200 text-base text-gray-600 tracking-wide">
                    {alunno.id}
                  </div>
                </h1>

                <div className="flex items-center">
                  <h2 className="py-1 pr-5 border-r border-gray-300 mr-5 text-gray-600">
                    {getEtaAlunno(alunno.dataNascita)}
                  </h2>
                  <h2 className="py-1 pr-5 border-r border-gray-300 mr-5 capitalize text-gray-600">
                    {scuola.nome}
                  </h2>
                  <h2 className="py-1 text-gray-600">
                    {alunno.sezione && (
                      <span>
                        {alunno.sezione.classe}
                        <sup>a</sup> {alunno.sezione.sezione}
                      </span>
                    )}
                  </h2>
                </div>
              </div>
            </div>
            <div className="space-y-2 flex flex-col justify-center">
              {alunno.cancellabile && (
                <button
                  type="button"
                  className="block bg-red-600 hover:bg-red-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Elimina
                </button>
              )}
              {!alunnoIscrittoAdAnnoCorrente && (
                <button
                  type="button"
                  className="block bg-blue-200 hover:bg-blue-300 hover:shadow-xl focus:shadow-outline focus:outline-none text-blue-800 text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => setShowDuplicaFruizioneModal(true)}
                >
                  Duplica fruizione
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex">
          <Link
            to="./"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Dati
          </Link>
          <Link
            to="intestatari"
            className="mr-4 py-1 px-3 border border-gray-50 hover:border-blue-300 hover:bg-white text-gray-600 text-sm rounded transition-colors duration-150"
            getProps={isPartiallyActive}
          >
            Intestatari
          </Link>
          {renderNomiServizi}
        </div>

        {alunno && (
          <Router primary={false} className="pb-10">
            <Dati path="/" alunno={alunno} scuole={scuole} intestatari={alunno.intestatari} />
            <Intestatari path="intestatari">
              <ElencoIntestatari
                path="/"
                intestatari={alunno.intestatari}
                fruizioni={alunno.fruizioni}
                servizi={servizi}
                alunnoId={alunno.id}
              />
              <Intestatario path="/:intestatarioId" alunnoId={alunno.id} />
              <Intestatario path="/nuovo" alunnoId={alunno.id} />
            </Intestatari>
            <Servizio
              path="servizio/:id"
              fruizioni={alunno.fruizioni}
              intestatari={alunno.intestatari}
              alunnoId={alunno.id}
              scuolaId={alunno.sezione.scuolaId}
            />
          </Router>
        )}
      </div>

      {/* ELIMINA ALUNNO */}
      <ReactModal
        isOpen={!!showDeleteModal}
        onRequestClose={() => {
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-500 text-white font-semibold py-2 text-center">
            Elimina alunno
          </div>

          <div className="px-10 py-6">
            <p className="mb-4">
              Vuoi davvero eliminare{' '}
              <b>
                {alunno.cognome} {alunno.nome}
              </b>
              ?
            </p>

            <div className="flex justify-between space-x-3">
              <button
                type="button"
                className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={() => closeDeleteModal()}
              >
                Annulla
              </button>

              <button
                type="button"
                className="block bg-red-600 hover:bg-red-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={() => {
                  handleEliminaAlunno(alunno.id)
                }}
              >
                Sì, elimina alunno
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={!!showDuplicaFruizioneModal}
        // onRequestClose={() => {
        //   document.getElementById('root')!.style.filter = 'blur(0)'
        // }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-500 text-white font-semibold py-2 text-center">
            Duplica fruizione nell'anno {LAST_YEAR}
          </div>

          <div className="px-10 py-6">
            <p className="mb-4">Vuoi davvero duplicare la fruizione?</p>

            <div className="flex justify-between space-x-3">
              <button
                type="button"
                className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={() => closeDuplicaFruizioneModal()}
              >
                Annulla
              </button>

              <button
                type="button"
                className="block bg-green-600 hover:bg-green-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                onClick={() => {
                  handleDuplicaFruizione(alunno.fruizioni[0].id)
                }}
              >
                Sì, duplica
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export default Alunno

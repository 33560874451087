import React, { useContext, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useForm } from 'react-hook-form'
import FormField, { InputType } from '../../components/FormField'
import useServizioById from '../../apiHooks/queries/useServizioById'
import ServizioContext from '../../context/ServizioContext'
import useUpdateServiziById from '../../apiHooks/mutations/useUpdateServiziById'
import useUpdateClienteById from '../../apiHooks/mutations/useUpdateClienteById'

interface Option {
  label: string
  value: string
}

const NUMERO_TURNI: Option[] = [
  {
    label: '-- Seleziona --',
    value: '',
  },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
]

const ParametriServizio: React.FC<RouteComponentProps> = () => {
  //===================
  // REACT HOOK FORM
  //===================
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({})

  //===================
  // QUERY
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { data: servizio } = useServizioById(servizioId)

  useEffect(() => {
    const numeroTurni = NUMERO_TURNI.find((t) => t.value === servizio?.numeroTurni.toString())
    reset({ ...servizio, numeroTurni: numeroTurni })
  }, [servizio, reset])

  //===================
  // MUTATION
  //===================
  const updateServizi = useUpdateServiziById()
  const updateCliente = useUpdateClienteById()

  //===================
  // EVENT HANDLERS
  //===================
  const saveParametri = (data: any) => {
    const servizioToSave = { ...data, numeroTurni: Number(data.numeroTurni.value) }
    updateServizi.mutate({ servizioId, servizio: servizioToSave })
    updateCliente.mutate({
      clienteId: data.clienteId,
      cliente: {
        prossimoNumeroAvvisoPagamento: data.clienteProssimoNumeroAvvisoPagamento,
        indirizziNotifica: data.clienteIndirizziNotifica,
        telefonoContatto: data.clienteTelefonoContatto,
        indirizzoContatto: data.clienteIndirizzoContatto,
      },
    })
  }

  if (!servizio) {
    return null
  }

  console.log(servizio)

  return (
    <div>
      <div className="bg-white py-6">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl leading-tight mb-8">Parametri servizio</h1>
          <div className="max-w-4xl bg-gray-100 p-8 rounded-lg">
            <form onSubmit={handleSubmit(saveParametri)}>
              {/* Gestione servizio */}
              <div className="flex space-x-20">
                <div className="w-full">
                  <h2 className="mb-3 text-lg font-semibold">Gestione servizio</h2>
                  <p className="block text-sm leading-5 font-medium text-gray-600">
                    Giorni di servizio
                  </p>
                  <div className="flex gap-6 flex-wrap mt-1">
                    <FormField
                      fieldName="servizioLun"
                      label="Lun"
                      type={InputType.Boolean}
                      register={register}
                      errors={errors}
                      className="mb-3"
                    />
                    <FormField
                      fieldName="servizioMar"
                      label="Mar"
                      type={InputType.Boolean}
                      register={register}
                      errors={errors}
                      className="mb-3"
                    />
                    <FormField
                      fieldName="servizioMer"
                      label="Mer"
                      type={InputType.Boolean}
                      register={register}
                      errors={errors}
                      className="mb-3"
                    />
                    <FormField
                      fieldName="servizioGio"
                      label="Gio"
                      type={InputType.Boolean}
                      register={register}
                      errors={errors}
                      className="mb-3"
                    />
                    <FormField
                      fieldName="servizioVen"
                      label="Ven"
                      type={InputType.Boolean}
                      register={register}
                      errors={errors}
                      className="mb-3"
                    />
                    <FormField
                      fieldName="servizioSab"
                      label="Sab"
                      type={InputType.Boolean}
                      register={register}
                      errors={errors}
                      className="mb-3"
                    />
                    <FormField
                      fieldName="servizioDom"
                      label="Dom"
                      type={InputType.Boolean}
                      register={register}
                      errors={errors}
                      className="mb-3"
                    />
                  </div>
                  <FormField
                    fieldName="numeroTurni"
                    label="Numero turni"
                    type={InputType.Select}
                    isClearable
                    register={register}
                    errors={errors}
                    className="mb-3"
                    options={NUMERO_TURNI}
                    control={control}
                  />
                  <div className="flex space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="emailCucina"
                        label="Email cucina"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                    <div className="w-1/2">
                      <FormField
                        fieldName="clienteIndirizziNotifica"
                        label="Indirizzi di notifica"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>
                  <div className="flex space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="clienteIndirizzoContatto"
                        label="Indirizzo di contatto"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                    <div className="w-1/2">
                      <FormField
                        fieldName="clienteTelefonoContatto"
                        label="Telefono di contatto"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Gestione avvisi pagamento */}{' '}
              <div className="flex space-x-20">
                <div className="w-full">
                  <h2 className="mt-6 mb-3 text-lg font-semibold">Gestione avvisi pagamento</h2>
                  <div className="flex space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="calcoloSemplificatoMinimoFatturabile"
                        label="Minimo fatturabile"
                        type={InputType.Number}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        step="0.01"
                      />
                    </div>
                    <div className="w-1/2">
                      <FormField
                        fieldName="clienteProssimoNumeroAvvisoPagamento"
                        label="Prossimo numero fattura"
                        type={InputType.Number}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        step="1"
                      />
                    </div>
                  </div>

                  <div className="flex space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="calcoloSemplificatoDescrizioneRigaEsonero"
                        label="Descrizione riga esonero"
                        type={InputType.Textarea}
                        rows={2}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                    <div className="w-1/2">
                      <p className="block text-sm leading-5 font-medium text-gray-600">
                        Aggregazione avvisi pagamento
                      </p>
                      <div className="flex gap-6 flex-wrap mt-1">
                        <FormField
                          fieldName="calcoloSemplificatoAggregaMaggioGiugno"
                          label="Mag-Giu"
                          type={InputType.Boolean}
                          register={register}
                          errors={errors}
                          className="mb-3"
                        />
                        <FormField
                          fieldName="calcoloSemplificatoAggregaGiugnoLuglio"
                          label="Giu-Lug"
                          type={InputType.Boolean}
                          register={register}
                          errors={errors}
                          className="mb-3"
                        />
                        <FormField
                          fieldName="calcoloSemplificatoAggregaSettembreOttobre"
                          label="Set-Ott"
                          type={InputType.Boolean}
                          register={register}
                          errors={errors}
                          className="mb-3"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="calcoloSemplificatoPercScontoSecondoFratello"
                        label="% sconto secondo fratello"
                        type={InputType.Number}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        step="0.01"
                        validation={{
                          min: { value: 0, message: 'Percentuale non valida' },
                          max: { value: 100, message: 'Percentuale non valida' },
                        }}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormField
                        fieldName="calcoloSemplificatoPercScontoHandicap"
                        label="% sconto disabile"
                        type={InputType.Number}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        step="0.01"
                        validation={{
                          min: { value: 0, message: 'Percentuale non valida' },
                          max: { value: 100, message: 'Percentuale non valida' },
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="testoFinaleEmailAvviso"
                        label="Testo finale email avvisi"
                        type={InputType.Textarea}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        rows={4}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormField
                        fieldName="emailAvvisiBcc"
                        label="Invia tutti gli avvisi di pagamento in copia a:"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>

                  <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>

                  <div className="flex space-x-20 mb-3">
                    <div className="w-full">
                      <FormField
                        fieldName="calcoloSemplificatoIngressoDimissioni"
                        label="Abilita detrazioni in base a ISEE e rimborsi primo ingresso/dimissioni"
                        type={InputType.Boolean}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        rows={4}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-20">
                    <div className="w-full">
                      <FormField
                        fieldName="calcoloSemplificatoIngressoDimissioniDescrizione"
                        label="Descrizione riga rimborso primo ingresso/dimissioni"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>
                  <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>
                  <div className="flex space-x-20 mb-3">
                    <div className="w-full">
                      <FormField
                        fieldName="flagOrdinamentoAlfabetico"
                        label="Ordinamento alfabetico bollettini"
                        type={InputType.Boolean}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>
                  <div className="flex space-x-20">
                    <div className="w-full">
                      <FormField
                        fieldName="codiceServizioAvvisiPagamento"
                        label="Codice servizio avvisi pagamento"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {[2, 3].includes(servizio?.id) && (
                <>
                  <h2 className="mt-6 mb-3 text-lg font-semibold">Protocollo</h2>
                  <div className="flex mb-3 space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="annoPraticaProtocollo"
                        label="Anno pratica protocollo"
                        type={InputType.Number}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        // validation={{
                        //   min: { value: 2010, message: 'Anno non valido' },
                        //   max: { value: 3000, message: 'Anno non valido' },
                        // }}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormField
                        fieldName="numeroPraticaProtocolo"
                        label="Numero pratica protocollo"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>
                  <div className="flex mb-3 space-x-20">
                    <div className="w-1/2">
                      <FormField
                        fieldName="annoPraticaProtocolloIscrizioni"
                        label="Anno pratica protocollo iscrizioni"
                        type={InputType.Number}
                        register={register}
                        errors={errors}
                        className="mb-3"
                        // validation={{
                        //   min: { value: 2010, message: 'Anno non valido' },
                        //   max: { value: 3000, message: 'Anno non valido' },
                        // }}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormField
                        fieldName="numeroPraticaProtocoloIscrizioni"
                        label="Numero pratica protocollo iscrizioni"
                        type={InputType.Text}
                        register={register}
                        errors={errors}
                        className="mb-3"
                      />
                    </div>
                  </div>
                </>
              )}
              <button
                type="submit"
                className="block mt-6 bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              >
                Salva
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParametriServizio

import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import useGraduatoria from '../../apiHooks/queries/useGraduatoria'
import moment from 'moment'
import { API_URL } from '../../config'
import { VariableSizeList } from 'react-window'
import { useBlockLayout, useFilters, useSortBy, useTable } from 'react-table'
import { FiDownload } from 'react-icons/fi'
import classNames from 'classnames'
import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import ServizioContext from '../../context/ServizioContext'

const Graduatoria: React.FC<RouteComponentProps> = ({ children }) => {
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { data: graduatoria } = useGraduatoria(annoScolastico)
  const { data: scuole } = useScuoleByServizioId(servizioId)
  const tableRef = useRef<VariableSizeList>(null)
  const data = useMemo(() => graduatoria || [], [graduatoria])

  const scuoleOptions = scuole?.flatMap((scuola: any) => {
    if (scuola.orari.length > 0) {
      return scuola.orari.map((orario: any) => {
        return scuola.nome + '_' + orario
      })
    } else return scuola.nome + '_'
  })

  const columns = useMemo(
    () => [
      {
        Header: '',
        Footer: (props: any) => {
          return <div></div>
        },
        accessor: 'nomeScuola',
        width: 0,
        disableFilters: true,
        Cell: (props: any) => {
          return <div></div>
        },
      },
      {
        Header: '',
        Footer: (props: any) => {
          return <div></div>
        },
        accessor: 'orarioScuola',
        width: 0,
        disableFilters: true,
        Cell: (props: any) => {
          return <div></div>
        },
      },
      {
        Header: () => {
          return <div className="mt-5">Cognome</div>
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'iscrittoPersonaCognome',
        width: 184,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 font-bold">
              {props.value}
            </div>
          )
        },
      },
      {
        Header: () => {
          return <div className="mt-5">Nome</div>
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'iscrittoPersonaNome',
        width: 184,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: () => {
          return <div className="mt-5">Mesi</div>
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'iscrittoPersonaNascitaData',
        width: 100,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              {calcolaMesiAl30Settembre('2024', props.value)}
            </div>
          )
        },
      },
      {
        Header: () => {
          return <div className="mt-5">PR</div>
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        width: 100,
        disableFilters: true,
        accessor: 'flagDipendenteComune',
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              {props.row.original.flagDipendenteComune ||
              props.row.original.d10 ||
              props.row.original.d11
                ? 'X'
                : ''}
            </div>
          )
        },
      },
      {
        Header: () => {
          return <div className="mt-5">Isee</div>
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        width: 150,
        disableFilters: true,
        accessor: 'importoIsee',
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              {props.row.original.iseeFlag
                ? formatIseeImporto(props.row.original.iseeImporto)
                : 'Attrib. Max'}
            </div>
          )
        },
      },
      {
        Header: (props: any) => {
          return (
            <div>
              <p>Punteggio</p>
              <hr className="h-px mt-3 bg-gray-400 border-0" />
              <p className="mt-2">Totale</p>
            </div>
          )
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        width: 130,
        disableFilters: true,
        accessor: 'punteggioTotale',
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 mr-12 bg-yellow-400 font-bold">
              {props.value}
            </div>
          )
        },
      },
      {
        Header: (props: any) => {
          return (
            <div>
              <p className="mb-5"></p>
              <hr className="h-px mt-8 bg-gray-400 border-0" />
              <p className="mt-2">A</p>
            </div>
          )
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        width: 60,
        disableFilters: true,
        accessor: 'punteggioA',
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 pl-0">{props.value}</div>
          )
        },
      },
      {
        Header: (props: any) => {
          return (
            <div>
              <p className="mb-5"></p>
              <hr className="h-px mt-8 bg-gray-400 border-0" />
              <p className="mt-2">B</p>
            </div>
          )
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        width: 60,
        disableFilters: true,
        accessor: 'punteggioB',
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 pl-0">{props.value}</div>
          )
        },
      },
      {
        Header: (props: any) => {
          return (
            <div>
              <p className="mb-5"></p>
              <hr className="h-px mt-8 bg-gray-400 border-0" />
              <p className="mt-2">C</p>
            </div>
          )
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        width: 60,
        disableFilters: true,
        accessor: 'punteggioC',
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 pl-0">{props.value}</div>
          )
        },
      },
      {
        Header: (props: any) => {
          return (
            <div>
              <p className="mb-5"></p>
              <hr className="h-px mt-8 bg-gray-400 border-0" />
              <p className="mt-2">D</p>
            </div>
          )
        },
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        width: 60,
        disableFilters: true,
        accessor: 'punteggioD',
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 pl-0">{props.value}</div>
          )
        },
      },
    ],
    []
  )

  const columnFilter = (props: any) => {
    const {
      filterValue,
      // preFilteredRows,
      setFilter,
    } = props.column

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
        placeholder="Cerca..."
        // placeholder={`Cerca tra ${preFilteredRows.length} avvisi...`}
        className="form-input p-1 rounded text-xs w-24"
      />
    )
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: columnFilter,
    }),
    []
  )

  const initialState = {
    filters: [],
    sortBy: [{ desc: true, id: 'punteggioTotale' }],
  }

  const {
    // @ts-ignore
    setFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    totalColumnsWidth,
    rows,
    prepareRow,
    // @ts-ignore
    state,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
      // @ts-ignore
      initialState,
    },
    useBlockLayout,
    useFilters,
    useSortBy
  )

  const rowHeights = useMemo(
    () =>
      rows.map((r: any) => {
        return 42
      }),
    [rows]
  )

  const getItemSize = useCallback(
    (index: number) => {
      return rowHeights[index]
    },
    [rowHeights]
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)

      return (
        <>
          <div
            {...row.getRowProps({
              style,
            })}
            className="tr hover:bg-blue-100 border-b border-gray-400"
          >
            <div className="flex items-center">
              {row.cells.map((cell) => {
                return (
                  <div
                    {...cell.getCellProps()}
                    className="td h-full overflow-y-hidden leading-none p-0"
                  >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    },
    [prepareRow, rows]
  )

  return (
    <div>
      <div className="bg-white pt-6 pb-20">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl leading-tight mb-8">Graduatoria</h1>
        </div>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between gap-x-4">
            <select
              className="mb-8 flex items-center gap-x-2 bg-gray-100 border border-gray-400 p-3 rounded font-semibold text-sm hover:bg-gray-200 focus:border-blue-500 focus:outline-blue-500"
              onChange={(e) => {
                if (e.target.value.split('_')[0] !== '') {
                  setFilter('nomeScuola', e.target.value.split('_')[0])
                } else setFilter('nomeScuola', '')
                setFilter('orarioScuola', e.target.value.split('_')[1])
              }}
            >
              <option value="">Seleziona una scuola</option>
              {scuoleOptions?.map((option: any, index: number) => {
                return (
                  <option key={index} value={option}>
                    {option.split('_')[0] + ' - ' + option.split('_')[1]}
                  </option>
                )
              })}
            </select>
            <a
              className="mb-8 flex items-center gap-x-2 bg-blue-500 text-white p-3 rounded font-semibold text-sm hover:bg-blue-600"
              href={`${API_URL}/iscrizioni-asilo/graduatoria/download?annoScolastico=${annoScolastico}&t=${encodeURIComponent(
                localStorage.getItem('token') || ''
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiDownload />
              Scarica csv
            </a>
          </div>
        </div>

        {
          // @ts-ignore
          !!graduatoria && stateFilterCondition(state?.filters, rows) && (
            <div
              className={classNames(
                'table-wrapper mx-auto px-4 sm:px-6 lg:px-8 mb-12 overflow-y-hidden'
              )}
            >
              <div
                {...getTableProps()}
                className="table w-full max-w-5xl text-sm border border-gray-400 rounded rounded-tr-none mx-auto"
              >
                <div>
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column: any) => {
                        return (
                          <div
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className={classNames(
                              column.width === 0
                                ? ''
                                : 'th border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold',
                              column.id.includes('punteggio')
                                ? 'py-2'
                                : column.width !== 0
                                ? 'py-2 px-3'
                                : '',
                              column.id === 'punteggioTotale' && 'pl-3'
                            )}
                          >
                            {column.render('Header')}
                            <span>
                              {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                            </span>
                            <div className="pt-2 w-full" onClick={(e) => e.stopPropagation()}>
                              {column.canFilter ? column.render('Filter') : null}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()}>
                  <VariableSizeList
                    ref={tableRef}
                    height={500}
                    itemCount={rows.length}
                    itemSize={getItemSize}
                    width={totalColumnsWidth}
                  >
                    {RenderRow}
                  </VariableSizeList>
                </div>
                {footerGroups.map((group) => (
                  <div className="tr" {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <div className="td" {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

const calcolaMesiAl30Settembre = (anno: string, dataDiNascita: string) => {
  return Math.floor(
    moment.duration(moment(`${anno}-09-30`, 'YYYY-MM-DD').diff(moment(dataDiNascita))).as('months')
  )
}

function formatIseeImporto(input: string) {
  const number = parseFloat(input.replace(',', '.'))

  if (isNaN(number)) {
    throw new Error('Input non valido')
  }

  const hasDecimals = number % 1 !== 0

  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  })

  return formatter.format(number)
}

const stateFilterCondition = (filters: any[], rows: any[]) => {
  if (filters.length === 0) return false
  if (filters.length === 1 && rows.length === 0) return false

  const filterIds = filters.map((filter) => filter.id)
  if (filters.length === 2 && rows.length === 0) {
    return !filterIds.includes('nomeScuola') || !filterIds.includes('orarioScuola')
  }

  return true
}

export default Graduatoria

import { Link, RouteComponentProps } from '@reach/router'
import classNames from 'classnames'
import moment from 'moment'
import 'moment/locale/it'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { DayModifiers } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils from 'react-day-picker/moment'
import { FaCoins, FaForward } from 'react-icons/fa'
import ReactModal from 'react-modal'
import { useBlockLayout, useFilters, useSortBy, useTable } from 'react-table'
import { VariableSizeList } from 'react-window'

import useAvvisiPagamentoByMese from '../../apiHooks/queries/useAvvisiPagamentoByMese'
import useServizi from '../../apiHooks/queries/useServizi'
import Price from '../../components/Price'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import ServizioContext from '../../context/ServizioContext'
import shortenText from '../../lib/shortenText'

const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]
const WEEKDAYS_LONG = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const PagamentiSolleciti: React.FC<RouteComponentProps> = () => {
  //==================
  // USE STATE
  //==================
  const [dataSel, setDataSel] = useState<string>(
    moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
  )
  const [dataSelDal, setDataSelDal] = useState<string>('')
  // const [rowsIdsSel, setRowsIdsSel] = useState<any>([])
  const [dataSollecito, setDataSollecito] = useState(moment().format('YYYY-MM-DD'))
  const [avvisoSel, setAvvisoSel] = useState<any>(null)

  //==================
  // REF
  //==================
  const tableRef = useRef<VariableSizeList>(null)

  //==================
  // CONTEXT
  //==================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  //==================
  // QUERIES
  //==================
  const { data: avvisiPagamento } = useAvvisiPagamentoByMese({
    annoScolastico,
    servizioId,
    scadutiEntro: dataSel,
  })

  const { data: servizi } = useServizi()

  const currentServizio = servizi?.find((ser: any) => ser.id === servizioId)

  //==================
  // MUTATIONS
  //==================
  // const generaSolleciti = useGeneraSolleciti()

  //==================
  // CALLBACKS
  //==================
  const handleDayChange = useCallback(
    (day: Date, modifiers: DayModifiers, dayPickerInput: DayPickerInput) => {
      setDataSel(moment(day).format('YYYY-MM-DD'))
    },
    [setDataSel]
  )

  const handleDayChangeDal = useCallback(
    (day: Date, modifiers: DayModifiers, dayPickerInput: DayPickerInput) => {
      setDataSelDal(moment(day).format('YYYY-MM-DD'))
    },
    [setDataSelDal]
  )

  // const handleRowsChecking = useCallback(
  //   (rowId) => {
  //     let rowsIdsToSave = rowsIdsSel
  //     if (rowsIdsSel.indexOf(rowId) !== -1) {
  //       // RIMUOVI ROW SE GIÀ PRESENTE NELL'ARRAY
  //       rowsIdsToSave = rowsIdsSel.filter((r: any) => r !== rowId)
  //     } else {
  //       // AGGIUNGI ROW SE NON È PRESENTE NELL'ARRAY
  //       rowsIdsToSave = [...rowsIdsSel, rowId]
  //     }
  //     setRowsIdsSel(rowsIdsToSave)
  //   },
  //   [rowsIdsSel]
  // )

  // const handleGeneraSolleciti = useCallback(
  //   (numeroSollecito: number) => {
  //     // console.log(rowsIdsSel, numeroSollecito, dataSollecito)
  //     const sollecitiToSave = {
  //       avvisiPagamento: rowsIdsSel,
  //       livello: numeroSollecito,
  //       dataSollecito,
  //     }
  //     generaSolleciti.mutate({ servizioId, solleciti: sollecitiToSave })
  //   },
  //   [generaSolleciti, servizioId, rowsIdsSel, dataSollecito]
  // )

  const getLabelPagamentoByValue = useCallback((value: string) => {
    switch (value) {
      case 'bon':
        return 'Bonifico'
      case 'sdd':
        return 'SDD'
      case 'cont':
        return 'Contanti'
      default:
        return ''
    }
  }, [])

  //==================
  // REACT TABLE
  //==================
  const data = useMemo(() => avvisiPagamento || [], [avvisiPagamento])

  const columns = useMemo(
    () => [
      {
        Header: 'N°',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'numeroAvvisoPagamento',
        width: 45,
        disableFilters: true,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'Codice',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'codiceBarre',
        width: 120,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'Scuola',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'scuolaNome',
        width: 160,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 text-xs">
              {shortenText(props.value, 70)}
            </div>
          )
        },
      },
      {
        Header: 'Cognome e nome',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'bambinoCognome',
        width: 210,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3">
              <Link
                className="text-blue-500 hover:text-blue-600"
                to={`/app/anagrafica/alunno/${props.row.original.bambinoCodice}/servizio/${servizioId}`}
              >
                {props.value} {props.row.original.bambinoNome}
              </Link>
            </div>
          )
        },
      },
      {
        Header: 'Q. genitore',
        Footer: (props: any) => {
          const total = useMemo(
            () =>
              props.rows.reduce(
                (sum: any, row: any) => sum + parseFloat(row.values.quotaCaricoGenitore),
                0
              ),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-end items-center p-3 font-semibold bg-blue-100 text-blue-800 border-t border-gray-500">
              <Price amount={total || 0} />
            </div>
          )
        },
        accessor: 'quotaCaricoGenitore',
        width: 120,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div
              className={classNames('h-full flex justify-end items-center p-3 text-sm', {
                'text-gray-500': !props.value || props.value === '0.00',
              })}
            >
              <Price amount={props.value || 0} />
            </div>
          )
        },
      },
      {
        Header: 'Pagato',
        Footer: (props: any) => {
          const total = useMemo(
            () =>
              props.rows.reduce(
                (sum: any, row: any) => sum + parseFloat(row.values.importoPagato),
                0
              ),
            [props.rows]
          )

          return (
            <div className="h-full flex justify-end items-center p-3 font-semibold bg-blue-100 text-blue-800 border-t border-gray-500">
              <Price amount={total || 0} />
            </div>
          )
        },
        accessor: 'importoPagato',
        width: 120,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div
              className={classNames(
                'h-full flex justify-end items-center p-3 text-sm',
                {
                  'text-green-500 font-semibold':
                    parseFloat(props.value) >= parseFloat(props.row.original.quotaCaricoGenitore),
                },
                {
                  'text-red-600':
                    parseFloat(props.value) < parseFloat(props.row.original.quotaCaricoGenitore),
                }
              )}
            >
              <Price amount={props.value || 0} />
            </div>
          )
        },
      },
      {
        Header: 'Scadenza',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'dataScadenza',
        width: 150,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <div className="h-full flex items-center p-3">
              {moment(props.value, 'YYYY-MM-DD', true).format('DD/MM/YYYY')}
            </div>
          )
        },
      },
      {
        Header: 'Note',
        Footer: (props: any) => {
          return (
            <div className="h-full flex justify-start items-center p-3 bg-blue-100 text-blue-800 border-t border-gray-500">
              &nbsp;
            </div>
          )
        },
        accessor: 'nota',
        width: 150,
        disableFilters: true,
        Cell: (props: any) => {
          return <div className="h-full flex items-center p-3 text-xs">{props.value}</div>
        },
      },
    ],
    // [servizioId, handleRowsChecking, rowsIdsSel]
    [servizioId]
  )

  const columnFilter = (props: any) => {
    const {
      filterValue,
      // preFilteredRows,
      setFilter,
    } = props.column
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
        placeholder="Cerca..."
        // placeholder={`Cerca tra ${preFilteredRows.length} avvisi...`}
        className="form-input p-1 rounded text-xs w-24"
      />
    )
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: columnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    totalColumnsWidth,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
    },
    useBlockLayout,
    useFilters,
    useSortBy
  )

  const rowHeights = useMemo(
    () =>
      rows.map((r: any) => {
        return 42
      }),
    [rows]
  )

  const getItemSize = useCallback(
    (index: number) => {
      return rowHeights[index]
    },
    [rowHeights]
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <>
          <div
            {...row.getRowProps({
              style,
            })}
            className="tr hover:bg-blue-100 border-b border-gray-400"
          >
            <div className="flex items-center">
              {row.cells.map((cell) => {
                return (
                  <div
                    {...cell.getCellProps()}
                    className="td h-full overflow-y-hidden leading-none p-0"
                  >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )
    },
    [prepareRow, rows]
  )

  const handleGeneraSolleciti = (dataSollecito: string, dataDal: string, dataAl: string) => {
    console.log('date: ', dataSollecito, dataDal, dataAl)
  }

  //==================
  // RETURN
  //==================
  return (
    <div className="bg-white py-6">
      <style>
        {`
        .ag-theme-alpine * {
          border-style: none;
        }
        .ag-react-container {
          height: 100%;
        }
      `}
      </style>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-6">
          Elenco insoluti dal{' '}
          <DayPickerInput
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            format="LL"
            placeholder={`${MomentLocaleUtils.formatDate(
              moment(currentServizio?.dataPrimaEmissione, 'YYYY-MM-DD', true).toDate(),
              'LL',
              'it'
            )}`}
            inputProps={{
              className: 'form-input p-2 text-4xl text-blue-500 cursor-pointer border-0',
            }}
            dayPickerProps={{
              locale: 'it',
              localeUtils: MomentLocaleUtils,
              className: 'bg-white rounded py-2 px-2 shadow-lg border-gray-200',
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
              disabledDays: [
                {
                  before: moment(currentServizio?.dataPrimaEmissione).toDate(),
                },
              ],
            }}
            value={moment(currentServizio?.dataPrimaEmissione).format('DD MMMM YYYY')}
            onDayChange={handleDayChangeDal}
          />{' '}
          al{' '}
          <DayPickerInput
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            format="LL"
            placeholder={`${MomentLocaleUtils.formatDate(
              moment(dataSel, 'YYYY-MM-DD', true).toDate(),
              'LL',
              'it'
            )}`}
            inputProps={{
              className: 'form-input p-2 text-4xl text-blue-500 cursor-pointer border-0',
            }}
            dayPickerProps={{
              locale: 'it',
              localeUtils: MomentLocaleUtils,
              className: 'bg-white rounded py-2 px-2 shadow-lg border-gray-200',
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
            }}
            value={moment(dataSel).format('DD MMMM YYYY')}
            onDayChange={handleDayChange}
          />
        </h1>

        <div className="table-wrapper block max-w-full mb-12 overflow-x-scroll overflow-y-hidden">
          {!!avvisiPagamento && (
            <div
              {...getTableProps()}
              className="table w-full max-w-6xl text-sm border border-gray-400 rounded rounded-tr-none"
            >
              <div>
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map((column: any) => {
                      return (
                        <div
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={classNames(
                            'th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold',
                            {
                              'text-right':
                                column.Header === 'Importo' || column.Header === 'Pagato',
                            },
                            {
                              'text-center': column.Header === 'Soll.' || column.Header === 'N°',
                            }
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                          <div className="pt-2 w-full" onClick={(e) => e.stopPropagation()}>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ))}
              </div>
              <div {...getTableBodyProps()}>
                <VariableSizeList
                  ref={tableRef}
                  height={400}
                  itemCount={rows.length}
                  itemSize={getItemSize}
                  width={totalColumnsWidth}
                >
                  {RenderRow}
                </VariableSizeList>
              </div>
              {footerGroups.map((group) => (
                <div className="tr" {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <div className="td" {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* <div className="mb-6">Righe selezionate: {rowsIdsSel.length || 0}</div> */}

        <div className="flex space-x-6 items-end justify-between">
          <div className="w-64">
            <label
              htmlFor="dataSollecito"
              className="block text-sm leading-5 font-medium text-gray-600"
            >
              Data sollecito
            </label>

            <input
              className="mt-1 form-input w-full h-10"
              id="dataSollecito"
              name="dataSollecito"
              type="date"
              value={dataSollecito}
              onChange={(e) => setDataSollecito(e.target.value)}
            />
          </div>
          <div className="flex space-x-3">
            <button
              type="button"
              className={classNames(
                'focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:shadow-xl bg-blue-500  hover:bg-blue-700 cursor-pointer'
                // { 'bg-blue-400 hover:bg-blue-500 cursor-not-allowed': !rowsIdsSel.length },
                // { 'bg-blue-500  hover:bg-blue-700 cursor-pointer': rowsIdsSel.length }
              )}
              onClick={() =>
                handleGeneraSolleciti(
                  dataSollecito,
                  !!dataSelDal ? dataSelDal : currentServizio?.dataPrimaEmissione,
                  dataSel
                )
              }
              // disabled={rowsIdsSel.length < 1}
            >
              Genera solleciti
            </button>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={!!avvisoSel}
        onRequestClose={() => {
          setAvvisoSel(null)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          {!!avvisoSel && (
            <>
              <div className="bg-blue-500 text-white font-semibold py-2 text-center">
                Cronologia solleciti e pagamenti per
                <br />
                {avvisoSel.bambinoCognome} {avvisoSel.bambinoNome}
              </div>

              <div className="px-10 py-6">
                <p className="mb-4">
                  Importo dovuto:{' '}
                  <b>
                    <Price amount={avvisoSel.importoTotale} />
                  </b>{' '}
                  entro il{' '}
                  <b>{moment(avvisoSel.dataScadenza, 'YYYY-MM-DD', true).format('DD/MM/YYYY')}</b>
                </p>
                <div className="flex flex-col mb-8 bg-blue-100 border rounded border-blue-200 text-sm">
                  {avvisoSel.pagamenti.map((p: any) => {
                    return (
                      <div
                        key={p.id}
                        className="flex w-full items-center text-xs px-2 border-b  border-blue-200"
                      >
                        <div className="w-6 p-1">
                          {p.importo ? <FaCoins color="#fc0" /> : <FaForward />}
                        </div>
                        <div className="w-20 p-1">
                          {moment(p.data, 'YYYY-MM-DD', true).format('DD/MM/YYYY')}
                        </div>
                        <div className="w-20 p-1 text-right">
                          <Price amount={p.importo} />
                        </div>
                        <div className="w-20 p-1">{getLabelPagamentoByValue(p.tipo)}</div>
                        <div className="flex-1 p-1 leading-tight">{!!p.nota && `${p.nota}`}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  )
}

export default PagamentiSolleciti

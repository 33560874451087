import { useQuery } from 'react-query'
import axios from 'axios'

interface IGetBollettaFruizioneMese {
  fruizioneId: number
  anno: string
  mese: string
}

interface RigaBolletta {
  descrizione: string
  importo: number
}

interface Bolletta {
  id: number
  fruizioneId: number
  mese: number
  anno: number
  righe: RigaBolletta[]
  totale: number
}

const getBollettaFruizioneMese = async (
  _: string,
  { fruizioneId, anno, mese }: IGetBollettaFruizioneMese
): Promise<Bolletta> => {
  const { data } = await axios.get(`/fruizioni/${fruizioneId}/calcola-pagamento/?anno=${anno}&mese=${mese}`)
  return data as Bolletta
}

const useBollettaFruizioneMese = ({ fruizioneId, anno, mese }: IGetBollettaFruizioneMese) => {
  return useQuery(
    ['bollettaFruizioneMese', { fruizioneId, anno, mese }],
    () => getBollettaFruizioneMese('bollettaFruizioneMese', { fruizioneId, anno, mese }),
    { enabled: !!fruizioneId && !!anno && !!mese }
  )
}

export default useBollettaFruizioneMese

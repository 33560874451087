import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeSezioni {
  scuolaId?: number
  servizioId: number
  dataPresenze: string
  annoScolastico: string
}

const getPresenzeSezioniData = async (
  _: string,
  { scuolaId, servizioId, dataPresenze, annoScolastico }: IPresenzeSezioni
) => {
  const { data } = await axios.get(
    `/scuole/${scuolaId}/presenze-sezioni/${servizioId}/?data=${dataPresenze}&annoScolastico=${annoScolastico}`
  )

  return data.results
}

const usePresenzeSezioniData = ({ scuolaId, servizioId, dataPresenze, annoScolastico }: IPresenzeSezioni) => {
  return useQuery(
    ['presenzeSezioniData', { scuolaId, servizioId, dataPresenze, annoScolastico }],
    () => getPresenzeSezioniData('presenzeSezioniData', { scuolaId, servizioId, dataPresenze, annoScolastico }),
    {
      enabled: !!scuolaId && !!servizioId && !!dataPresenze && !!annoScolastico,
    }
  )
}

export default usePresenzeSezioniData

import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

const inviaEmailRicevute = async (
  servizioId: number,
  annoScolastico: string,
  tipoPagamento: string
) => {
  const { data } = await axios.post(`/servizi/${servizioId}/invia-email-ricevute/`, {
    annoScolastico,
    tipoPagamento,
  })
  return data
}

const useInviaEmailRicevute = () => {
  return useMutation(
    ({ servizioId, annoScolastico, tipoPagamento }) => {
      return inviaEmailRicevute(servizioId, annoScolastico, tipoPagamento)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        cogoToast.success("Ricevute accodate per l'invio")
      },
      onError: () => {
        // console.log('KO')
      },
    }
  )
}

export default useInviaEmailRicevute

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useTable, useBlockLayout, Column } from 'react-table'
import moment from 'moment'
import classNames from 'classnames'
import { FiFileText } from 'react-icons/fi'

import Price from '../../components/Price'
import useAlunniGenitore from '../../apiHooks/queries/useAlunniGenitore'
import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import useAvvisiPagamentoGenitore from '../../apiHooks/queries/useAvvisiPagamentoGenitore'
import Loading from '../../components/Loading'

interface RigaImporto {
  importo: number
  descrizione: string
  is_retta: boolean
  tipo_riga: string
}

interface Pagamento {
  avvisoPagamentoId: number
  data: string
  id: number
  importo: string
  nota: string
  tipo: string
}

interface Bolletta {
  anno: number
  asiloGratisQuotaGenitore: string
  bambinoCodice: string
  bambinoCodicefiscale: string
  bambinoCognome: string
  bambinoNome: string
  codiceBarre: string
  dataEmissione: string
  dataInvioMail: string
  dataPagamento: string
  dataScadenza: string
  descrizione: string
  detrazioneAsiloGratis: string
  fruizioneId: number
  id: number
  importoPagato: string
  importoRetta: string
  importoTotale: string
  intestatarioId: number
  isAsiloGratis: number
  linkDownload: string
  mese: number
  nota: string
  numeroAvvisoPagamento: number
  pagamenti: Pagamento[]
  pagamentoSdd: boolean
  pagatoreCap: string
  pagatoreCodiceFiscale: string
  pagatoreCognome: string
  pagatoreComuneCodiceIstat: number
  pagatoreIban: string
  pagatoreIndirizzo: string
  pagatoreNome: string
  pagatoreSdd: string
  pdfAvviso: string
  pdfRicevuta: string | null
  periodo: string
  quotaCaricoGenitore: string
  righeImporti: RigaImporto[]
  sbloccato: boolean
  scuolaId: number
  scuolaNome: string
  servizioCodice: string
  sezioneClasse: string
  sezioneSezione: string
  solleciti: []
  tipoPagamento: string
}

interface Alunno {
  cap: string
  cittadinanzaCodiceIstat: number
  codiceFiscale: string
  cognome: string
  comuneCodiceIstat: number
  comuneCodiceIstatNascita: number
  dataNascita: string
  fruizioni: []
  id: number
  indirizzo: string
  intestatari: []
  isResidente: boolean
  nazionalitaCodiceIstat: number
  nome: string
  note: string
  statoCodiceIstat: number
  statoCodiceIstatNascita: number
}

interface FiglioProps extends RouteComponentProps {
  id?: number
}

const Bollette: React.FC<FiglioProps> = ({ id }) => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  //===================
  // LOCAL STATE
  //===================
  const [alunno, setAlunno] = useState<Alunno | null>(null)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: alunni, isFetching: isFetchingAlunni } = useAlunniGenitore(
    annoScolastico,
    servizioId
  )
  const { data: bollette, isFetching: isFetchingBollette } = useAvvisiPagamentoGenitore({
    annoScolastico,
    servizioId,
    alunnoId: id,
  })

  //===================
  // EFFECTS
  //===================
  useEffect(() => {
    const alunnoSel = alunni && alunni.find((a: Alunno) => a.id + '' === id + '')
    setAlunno(alunnoSel)
  }, [id, alunni])

  //==================
  // REACT TABLE
  //==================
  const data: Bolletta[] = useMemo(() => bollette || [], [bollette])

  const columns = useMemo<Column<Bolletta>[]>(
    () => [
      {
        Header: 'Periodo',
        accessor: 'periodo',
        width: 280,
        Cell: (props: any) => {
          return <div className="h-full flex justify-start items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'N°',
        accessor: 'numeroAvvisoPagamento',
        width: 50,
        Cell: (props: any) => {
          return <div className="h-full flex justify-center items-center p-3">{props.value}</div>
        },
      },
      {
        Header: 'Importo',
        accessor: 'quotaCaricoGenitore',
        width: 120,
        Cell: (props: any) => {
          return (
            <div
              className={classNames('h-full flex justify-end items-center p-3 font-semibold', {
                'text-gray-500': !props.value || props.value === '0.00',
              })}
            >
              {props.value ? <Price amount={props.value} /> : null}
            </div>
          )
        },
      },
      {
        Header: 'Scadenza',
        accessor: 'dataScadenza',
        width: 170,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-end items-center p-3">
              {moment(props.value, 'YYYY-MM-DD', true).format('DD/MM/YYYY')}
            </div>
          )
        },
      },
      {
        Header: 'Pagato',
        accessor: 'importoPagato',
        width: 186,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-end items-center p-3">
              <div
                className={classNames(
                  {
                    'text-red-600 font-semibold':
                      props.value < props.row.original.quotaCaricoGenitore &&
                      moment(props.row.original.dataScadenza, 'YYYY-MM-DD', true).isBefore(
                        moment().format('YYYY-MM-DD')
                      ),
                  },
                  {
                    'text-green-500 font-semibold':
                      props.value === props.row.original.quotaCaricoGenitore,
                  }
                  // {
                  //   'text-yellow-600 font-semibold':
                  //     props.value > props.row.original.quotaCaricoGenitore ||
                  //     moment(props.row.original.dataScadenza, 'YYYY-MM-DD', true).isSame(
                  //       moment().format('YYYY-MM-DD')
                  //     ),
                  // }
                )}
              >
                <Price amount={props.value} />
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Bolletta',
        accessor: 'linkDownload',
        width: 140,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-center items-center p-3">
              {props.value && (
                <a
                  className="bg-blue-500 focus:shadow-outline focus:outline-none text-white leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                  href={`${props.value}?t=${encodeURIComponent(
                    localStorage.getItem('token') || ''
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiFileText />
                </a>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Ricevuta',
        accessor: (row) => {
          if (row.pdfRicevuta && row.linkDownload) {
            return row.linkDownload.slice(0, -1) + '-ricevuta/'
          }
        },
        width: 140,
        Cell: (props: any) => {
          return (
            <div className="h-full flex justify-center items-center p-3">
              {props.value && (
                <a
                  className="bg-blue-500 focus:shadow-outline focus:outline-none text-white leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                  href={`${props.value}?t=${encodeURIComponent(
                    localStorage.getItem('token') || ''
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiFileText />
                </a>
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout
  )

  return (
    <div>
      <div className="bg-white h-full pt-8 pb-32">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {!isFetchingAlunni && !isFetchingBollette ? (
            <>
              {' '}
              <h1 className="text-4xl leading-tight mb-6">Bollette di {alunno?.nome}</h1>
              <div className="table-wrapper block max-w-full overflow-x-scroll overflow-y-hidden">
                <div
                  {...getTableProps()}
                  className="table w-full max-w-6xl border border-gray-400 rounded rounded-tr-none"
                >
                  <div>
                    {headerGroups.map((headerGroup) => (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className="tr flex items-center w-full"
                      >
                        {headerGroup.headers.map((column: any) => (
                          <div
                            {...column.getHeaderProps()}
                            className={classNames(
                              'th py-2 px-3 border-b border-gray-400 bg-gray-100 text-left text-gray-800 font-semibold',
                              {
                                'text-right':
                                  column.Header === 'Importo' ||
                                  column.Header === 'Pagato' ||
                                  column.Header === 'Scadenza',
                              },
                              {
                                'text-center':
                                  column.Header === 'N°' ||
                                  column.Header === 'Bolletta' ||
                                  column.Header === 'Ricevuta',
                              }
                            )}
                          >
                            {column.render('Header')}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row)
                      return (
                        <div
                          {...row.getRowProps()}
                          className="tr hover:bg-blue-100 border-b border-gray-400"
                        >
                          <div className="flex items-center">
                            {row.cells.map((cell) => {
                              return (
                                <div
                                  {...cell.getCellProps()}
                                  className="td h-full overflow-y-hidden leading-none p-0"
                                >
                                  {cell.render('Cell')}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  )
}

export default Bollette

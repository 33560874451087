import { useQuery } from 'react-query'
import axios from 'axios'

interface IAvvisiPagamento {
  annoScolastico: string
  servizioId: number
  alunnoId?: number
}

const getAvvisiPagamentoGenitore = async (_: string, { annoScolastico, servizioId, alunnoId }: IAvvisiPagamento) => {
  const { data } = await axios.get(
    `/avvisi-pagamento/genitore/?limit=1000&annoScolastico=${annoScolastico}&idServizio=${servizioId}&idAlunno=${alunnoId}`
  )

  return data.results
}

const useAvvisiPagamentoGenitore = ({ annoScolastico, servizioId, alunnoId }: IAvvisiPagamento) => {
  return useQuery(
    ['avvisiPagamentoGenitore', { annoScolastico, servizioId, alunnoId }],
    () => getAvvisiPagamentoGenitore('avvisiPagamentoGenitore', { annoScolastico, servizioId, alunnoId }),
    {
      enabled: !!annoScolastico && !!servizioId && !!alunnoId,
      // 5 minuti
      staleTime: 5 * 60 * 1000,
    }
  )
}

export default useAvvisiPagamentoGenitore

import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'

// import { API_URL } from '../../config'
import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'

import useDateSollecitiByIdByAnno from '../../apiHooks/queries/useDateSollecitiByIdByAnno'

const Solleciti: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: dateSolleciti = [] } = useDateSollecitiByIdByAnno({
    servizioId,
    annoScolastico,
  })

  return (
    <div className="bg-white pt-6 pb-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-8">Solleciti</h1>

        <table>
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Data
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                N° solleciti
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody>
            {dateSolleciti.map((d: any) => (
              <tr key={d.dataSolleciti}>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  {moment(d.dataSolleciti).format('DD/MM/YYYY')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">{d.numeroSolleciti}</td>
                {/* <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  <div className="flex space-x-3">
                    <a
                      className="block bg-teal-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-teal-600 hover:shadow-xl"
                      href={`${API_URL}/servizi/${servizioId}/download-pdf-bollette/?dataSolleciti=${
                        d.dataSolleciti
                      }&intestatarioConEmail=${true}&t=${encodeURIComponent(
                        localStorage.getItem('token') || ''
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PDF
                    </a>
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Solleciti

import React from 'react'
import accounting from 'accounting'

interface PriceProps {
  amount: number
  decimalSup?: boolean
  decimal?: number
}

const Price: React.FC<PriceProps> = ({ amount, decimalSup = false, decimal = 2 }) => {
  if (!amount) {
    //return null;
  }
  if (decimalSup) {
    const splitPrice = accounting.formatMoney(amount, '€ ', decimal, '.', ',').split(',')
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        {splitPrice[0]},<sup>{splitPrice[1]}</sup>
      </span>
    )
  }
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {accounting.formatMoney(amount, '€ ', decimal, '.', ',')}
    </span>
  )
}

export default Price

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface CreateGiornoNonErogazione {
  data: string
  descrizione: string
  isFestivita: boolean
  servizioId: number
}

const createGiornoNonErogazione = async (giornoNonErogazione: CreateGiornoNonErogazione) => {
  const data = await axios.post(`/giorni-non-erogazione/`, giornoNonErogazione)
  return data
}

const useCreateGiornoNonErogazione = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (giornoNonErogazione: CreateGiornoNonErogazione) => {
      return createGiornoNonErogazione(giornoNonErogazione)
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('giorniNonErogazioneByServizioId')
        cogoToast.success('Giorno di chiusura aggiunto correttamente')
      },
      onError: () => {},
    }
  )
}

export default useCreateGiornoNonErogazione

import { useMutation } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface ImportazioneEsiti {
  avvisi: string
  importo: number
  data: string
  riga: number
}

const importaEsiti = async (servizioId: number, esiti: ImportazioneEsiti) => {
  const { data } = await axios.post(`/servizi/${servizioId}/importa-esiti/`, esiti)
  return data
}

const useImportaEsiti = () => {
  return useMutation(
    ({ servizioId, esiti }: { servizioId: number; esiti: ImportazioneEsiti }) => {
      return importaEsiti(servizioId, esiti)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        cogoToast.success('Esiti importati correttamente')
      },
      onError: () => {},
    }
  )
}

export default useImportaEsiti

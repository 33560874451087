import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface GetTokenArgs {
  token: string
  c: string
}

const getToken = async ({ token, c }: GetTokenArgs) => {
  const { data } = await axios.post(`/auth-token-external/`, { token, c })
  return data
}

const useLoginExternal = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ token, c }: { token: string; c: string }) => {
      return getToken({ token, c })
    },
    {
      onSuccess: (data: any) => {
        //console.log(data)
        // update cache
        const {
          email,
          user: {
            operatore: { nome, cognome, servizi },
          },
        } = data
        queryClient.setQueryData(['profile'], {
          nome,
          cognome,
          email,
          ruolo: 'genitore',
          servizi,
        })
      },
      onError: () => {},
    }
  )
}

export default useLoginExternal

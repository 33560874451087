import { useQuery } from 'react-query'
import axios from 'axios'

const getAlunniGenitore = async (annoScolastico: string, servizioId: number) => {
  const { data } = await axios.get(
    `/alunni/genitore?annoScolastico=${annoScolastico}&servizioId=${servizioId}`
  )

  return data.results
}

const useAlunniGenitore = (annoScolastico: string, servizioId: number) => {
  return useQuery(
    ['alunniGenitore', { annoScolastico, servizioId }],
    () => getAlunniGenitore(annoScolastico, servizioId),
    {
      // staleTime: 10 * 60 * 1000,
    }
  )
}

export default useAlunniGenitore

import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface Cliente {
  prossimoNumeroAvvisoPagamento: number
  indirizziNotifica: string
  telefonoContatto: string
  indirizzoContatto: string
}

const updateClienteById = async (clienteId: number, cliente: Cliente) => {
  const { data } = await axios.patch(`/clienti/${clienteId}/`, cliente)
  return data
}

const useUpdateClienteById = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ clienteId, cliente }: { clienteId: number; cliente: Cliente }) => {
      return updateClienteById(clienteId, cliente)
    },
    {
      onSuccess: (data: any) => {
        console.log(data)
        queryClient.invalidateQueries('servizioById')
      },
      onError: () => {},
    }
  )
}

export default useUpdateClienteById

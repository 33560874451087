import { RouteComponentProps } from '@reach/router'
import cogoToast from 'cogo-toast'
import React, { useContext, useState } from 'react'
import { useCallback } from 'react'
import { FiEdit } from 'react-icons/fi'
import ReactModal from 'react-modal'
import useCreateFascia, { CreateFascia } from '../../../apiHooks/mutations/useCreateFascia'

import useDeleteFascia from '../../../apiHooks/mutations/useDeleteFascia'
import useUpdateFascia from '../../../apiHooks/mutations/useUpdateFascia'
import useFasceByServizioId, { Fascia } from '../../../apiHooks/queries/useFasceByServizioId'
import ServizioContext from '../../../context/ServizioContext'

const renderNumbersAsCurrency = (value: string) => {
  return value.replace(/\./g, ',') + ' €'
}
const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
    overflow: 'initial',
    borderRadius: '0.375rem',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const DEFAULT: Fascia<number | undefined> = {
  id: undefined,

  codice: '',

  descrizione: '',
  aliquotaIva: 0,

  quotaFissaDescrizione: '',
  quotaFissaImporto: 0,
  quotaFissaApplicazioneIva: false,

  costoPastoDescrizione: '',
  costoPastoImporto: 0,
  costoPastoApplicazioneIva: false,

  commissioniSddImporto: 0,
  commissioniSddDescrizione: '',
  commissioniSddApplicazioneIva: false,
}

const Fasce: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)

  // =====
  // STATE
  // =====
  const [fasciaEditing, setFasciaEditing] = useState<Fascia<number | undefined>>(DEFAULT)

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  //===================
  // QUERIES AND MUTATIONS
  //===================
  const { data: fasce = [] } = useFasceByServizioId(servizioId)
  const updateFascia = useUpdateFascia()
  const deleteDieta = useDeleteFascia()
  const createFascia = useCreateFascia()

  // ==============
  // EVENT HANDLERS
  // ==============
  const closeModal = useCallback((setter) => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setter(false)
    setFasciaEditing(DEFAULT)
  }, [])

  const handleChangeFasciaEditing = (
    field: Partial<Fascia>,
    fasciaEditing: Fascia<number | undefined>
  ) => {
    setFasciaEditing({ ...fasciaEditing, ...field })
  }

  const handleSaveUpdateFascia = (fasciaEditing: Fascia) => {
    updateFascia.mutateAsync({ servizioId, fascia: fasciaEditing }).then(() => {
      setShowEditModal(false)
      setFasciaEditing(DEFAULT)
    })
  }

  const handleDeleteFascia = (id: number) => {
    deleteDieta.mutateAsync(id).then(() => {
      setFasciaEditing(DEFAULT)
      setShowDeleteModal(false)
    })
  }

  const handleClickDelete = (fascia: Fascia) => {
    setFasciaEditing(fascia)
    setShowDeleteModal(true)
  }

  const handleCreateFascia = (fascia: CreateFascia) => {
    if (!fascia.codice) {
      cogoToast.error('Campo codice obbligatorio')
      setError('codice')
    }

    if (fascia.codice) {
      createFascia.mutateAsync({ servizioId, fascia }).then(() => {
        setFasciaEditing(DEFAULT)
        setShowEditModal(false)
        setError('')
      })
    }
  }

  return (
    <div>
      <div className="bg-white py-6">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl leading-tight mb-8">Fasce</h1>
          <table className="">
            <thead>
              <tr>
                <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Codice
                </th>
                <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Descrizione
                </th>

                <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Costo pasto importo
                </th>
              </tr>
            </thead>
            <tbody>
              {fasce.map((fascia) => (
                <tr key={fascia.id} className="text-sm">
                  <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                    {fascia.codice}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                    {fascia.descrizione}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200">
                    {renderNumbersAsCurrency(fascia.costoPastoImporto + '')}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap border-b border-gray-200 text-center">
                    <div className="flex items-center justify-between space-x-3">
                      <button
                        type="button"
                        className="border border-transparent hover:border-teal-200 hover:bg-teal-100 hover:text-teal-500 focus:shadow-outline focus:outline-none text-blue-500 leading-5 py-1 px-5 rounded transition-colors duration-150"
                        onClick={() => {
                          setShowEditModal(true)
                          setFasciaEditing(fascia)
                        }}
                      >
                        <FiEdit />
                      </button>
                      <button
                        type="button"
                        className="border border-transparent hover:border-red-200 hover:bg-red-100 hover:text-red-500 focus:shadow-outline focus:outline-none text-blue-500 leading-5 py-1 px-5 rounded transition-colors duration-150"
                        onClick={() => handleClickDelete(fascia)}
                      >
                        Elimina
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-6">
            <button
              className="bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              onClick={() => setShowEditModal(true)}
            >
              Nuova fascia
            </button>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={!!showEditModal}
        onRequestClose={() => {
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        {/* 
            ============
            HEADER MODAL
            ============
        */}
        <div className="py-3 mb-3 px-8 bg-blue-500 text-white rounded-t-md">
          <h1 className="text-lg font-bold">{fasciaEditing.id ? 'Modifica' : 'Crea nuova'} fascia</h1>
        </div>
        {/* 
            ==========================
            BODY MODAL
            WRAPPER 3 PARAMETRI FASCIA
            ==========================
          */}
        <div className="px-8">
          {/* INFO PRINCIPALI */}
          <div id="info-principali">
            <div className="-mx-8 w-full flex mb-6">
              <label className="block px-10">
                <span className="block text-sm text-gray-600">Codice</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing({ codice: event.target.value }, fasciaEditing)
                  }
                  className={`form-input ${error === 'codice' && 'ring-1 ring-red-400'}`}
                  type="text"
                  value={fasciaEditing.codice}
                />
              </label>
              <label className="block px-10">
                <span className="block text-sm text-gray-600">Descrizione</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing({ descrizione: event.target.value }, fasciaEditing)
                  }
                  className="form-input"
                  type="text"
                  value={fasciaEditing.descrizione}
                />
              </label>
              <label className="block px-10">
                <span className="block text-sm text-gray-600">Aliquota IVA</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeFasciaEditing(
                      {
                        aliquotaIva: +event.target.value,
                      },
                      fasciaEditing
                    )
                  }}
                  className="form-input"
                  type="number"
                  value={fasciaEditing.aliquotaIva}
                />
              </label>
            </div>
          </div>

          {/* TRE PARAMETRI */}
          <div className="-mx-8 flex flex-wrap">
            {/* 
            ===========
            COSTO PASTO
            ===========
            */}
            <div id="costo-pasto" className="pb-6 px-10 w-1/3 border-t pt-3 border-gray-300">
              <h2 className="text-gray-800 text-lg font-bold mb-3">Costo Pasto</h2>

              <label className="block mb-3">
                <span className="block text-sm text-gray-600">Descrizione costo pasto</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      { costoPastoDescrizione: event.target.value },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="text"
                  value={fasciaEditing.costoPastoDescrizione}
                />
              </label>
              <label className="block mb-3">
                <span className="block text-sm text-gray-600">Importo</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      {
                        costoPastoImporto: +event.target.value,
                      },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="number"
                  value={fasciaEditing.costoPastoImporto}
                />
              </label>

              <label className="flex items-center">
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      {
                        costoPastoApplicazioneIva: event.target.checked,
                      },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="checkbox"
                  checked={fasciaEditing.costoPastoApplicazioneIva}
                />
                <span className="text-sm text-gray-600 ml-2 mt-1">Applicazione IVA</span>
              </label>
            </div>

            {/* 
            ===============
            COMMISSIONI SDD
            ===============
            */}
            <div
              id="commissioni-sdd"
              className="pb-6 px-10 border-l border-r border-gray-300 w-1/3 border-t pt-3"
            >
              <h2 className="text-gray-800 text-lg font-bold mb-3">Commissioni SDD</h2>

              <label className="block mb-3">
                <span className="block text-sm text-gray-600">Descrizione SDD</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      { commissioniSddDescrizione: event.target.value },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="text"
                  value={fasciaEditing.commissioniSddDescrizione}
                />
              </label>

              <label className="block mb-3">
                <span className="block text-sm text-gray-600">Importo SDD</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      {
                        commissioniSddImporto: +event.target.value,
                      },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="number"
                  value={fasciaEditing.commissioniSddImporto}
                />
              </label>

              <label className="flex items-center">
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      {
                        commissioniSddApplicazioneIva: event.target.checked,
                      },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="checkbox"
                  checked={fasciaEditing.commissioniSddApplicazioneIva}
                />
                <span className="text-sm text-gray-600 mt-1 ml-2">IVA SDD</span>
              </label>
            </div>
            {/* 
            ===========
            QUOTA FISSA
            ===========
            */}
            <div id="quota-fissa" className="pb-6 px-10 w-1/3 border-t pt-3 border-gray-300">
              <h2 className="text-gray-800 text-lg font-bold mb-3">Quota Fissa</h2>
              <label className="block mb-3">
                <span className="block text-sm text-gray-600">Quota fissa descrizione</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      { quotaFissaDescrizione: event.target.value },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="text"
                  value={fasciaEditing.quotaFissaDescrizione}
                />
              </label>

              <label className="block mb-3">
                <span className="block text-sm text-gray-600">Quota fissa importo</span>
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeFasciaEditing(
                      { quotaFissaImporto: +event.target.value },
                      fasciaEditing
                    )
                  }
                  className="form-input"
                  type="number"
                  value={+fasciaEditing.quotaFissaImporto}
                />
              </label>

              <label className="flex items-center">
                <input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeFasciaEditing(
                      {
                        quotaFissaApplicazioneIva: event.target.checked,
                      },
                      fasciaEditing
                    )
                  }}
                  className="form-input"
                  type="checkbox"
                  checked={fasciaEditing.quotaFissaApplicazioneIva}
                />
                <span className="text-sm text-gray-600 ml-2 mt-1">IVA quota fissa</span>
              </label>
            </div>
          </div>
        </div>
        {/* 
        ============
        FOOTER
        ============
        */}
        <div className="py-2 px-8 bg-gray-200 rounded-b-md">
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              onClick={() => closeModal(setShowEditModal)}
            >
              Annulla
            </button>

            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
              onClick={() => {
                !!fasciaEditing.id
                  ? handleSaveUpdateFascia(fasciaEditing as Fascia)
                  : handleCreateFascia(fasciaEditing)
              }}
            >
              {!!fasciaEditing.id ? 'Salva' : 'Crea'}
            </button>
          </div>
        </div>
      </ReactModal>

      {!!fasciaEditing.id && (
        <ReactModal
          isOpen={!!showDeleteModal}
          onRequestClose={() => {
            document.getElementById('root')!.style.filter = 'blur(0)'
          }}
          style={modalCustomStyles}
        >
          <div className="max-w-lg">
            <div className="bg-blue-500 text-white font-semibold py-2 text-center">
              Eliminazione fascia {fasciaEditing.codice}
            </div>

            <div className="px-8 py-4">
              <p className="mb-4">Attenzione, sei davvero sicuro di cancellare questa fascia?</p>

              <div className="flex justify-between space-x-3">
                <button
                  type="button"
                  className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => closeModal(setShowDeleteModal)}
                >
                  Annulla
                </button>

                <button
                  type="button"
                  className="block bg-red-600 hover:bg-red-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => {
                    handleDeleteFascia(fasciaEditing.id!)
                  }}
                >
                  Sì, elimina fascia
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
    </div>
  )
}

export default Fasce

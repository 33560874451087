import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface CreatePagamento {
  avvisoPagamentoId: number
  tipo: string
  data: string
  importo: string
  nota?: string
}

const createPagamento = async (pagamento: CreatePagamento) => {
  const { data } = await axios.post(`/pagamenti/`, pagamento)
  return data
}

const useCreatePagamento = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ pagamento }: { pagamento: CreatePagamento }) => {
      return createPagamento(pagamento)
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries('avvisiPagamentoByMese', {}, { cancelRefetch: true })
        cogoToast.success('Pagamento creato correttamente.')
      },
      onError: () => {},
    }
  )
}

export default useCreatePagamento

import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'

import { API_URL } from '../../config'
import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'

import useDateEmissioneByIdByAnno from '../../apiHooks/queries/useDateEmissioneByIdByAnno'
import useInviaEmailAvvisi from '../../apiHooks/mutations/useInviaEmailAvvisi'

const Documenti: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: dateEmissione = [] } = useDateEmissioneByIdByAnno({
    servizioId,
    annoScolastico,
  })

  //===================
  // HOOKS MUTATIONS
  //===================
  const inviaEmailAvvisi = useInviaEmailAvvisi()

  const handleInviaEmail = React.useCallback(
    (dataEmissione) => {
      inviaEmailAvvisi.mutate({ servizioId, dataEmissione })
    },
    [servizioId, inviaEmailAvvisi]
  )

  return (
    <div className="bg-white pt-6 pb-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-8">Documenti</h1>

        <table>
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Data
              </th>
              <th colSpan={5} className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Scuole
              </th>
            </tr>
          </thead>
          <tbody>
            {dateEmissione.map((d: any) => (
              <tr key={d.dataEmissione}>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  {moment(d.dataEmissione).format('DD/MM/YYYY')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-b border-gray-200">
                  <span className="text-gray-900 font-semibold">{d.numScuole}</span>
                  <span className="text-gray-500 text-sm px-2">/</span>
                  <span className="text-gray-500 text-sm">{d.totScuole}</span>
                </td>
                <td className="px-3 py-4 whitespace-nowrap border-b border-gray-200">
                  {/* <div className="flex space-x-3"> */}
                  <a
                    className="flex items-center justify-between space-x-2 bg-teal-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-teal-600 hover:shadow-xl"
                    href={`${API_URL}/servizi/${servizioId}/download-pdf-bollette/?dataEmissione=${
                      d.dataEmissione
                    }&intestatarioConEmail=${true}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>PDF email</div>
                    <div className="px-2 py-px bg-gray-700 bg-opacity-40 shadow-inner text-xs font-semibold text-white rounded-full">
                      {d.avvisiPagamentoMail}
                    </div>
                  </a>
                </td>
                <td className="px-3 py-4 whitespace-nowrap border-b border-gray-200">
                  <a
                    className="flex items-center justify-between space-x-2 bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                    href={`${API_URL}/servizi/${servizioId}/download-pdf-bollette/?dataEmissione=${
                      d.dataEmissione
                    }&intestatarioConEmail=${false}&t=${encodeURIComponent(
                      localStorage.getItem('token') || ''
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>PDF no email</div>
                    <div className="px-2 py-px bg-gray-700 bg-opacity-40 shadow-inner text-xs font-semibold text-white rounded-full">
                      {d.avvisiPagamentoNoMail}
                    </div>
                  </a>
                </td>
                <td className="px-3 py-4 whitespace-nowrap border-b border-gray-200">
                  <a
                    className="block bg-purple-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-purple-700 hover:shadow-xl"
                    href={`${API_URL}/servizi/${servizioId}/download-sdd-bollette/?dataEmissione=${
                      d.dataEmissione
                    }&t=${encodeURIComponent(localStorage.getItem('token') || '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    File SDD
                  </a>
                </td>
                <td className="px-3 py-4 whitespace-nowrap border-b border-gray-200">
                  <button
                    type="button"
                    className="flex items-center justify-between space-x-2 bg-pink-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-pink-700 hover:shadow-xl"
                    onClick={() => handleInviaEmail(d.dataEmissione)}
                    disabled={inviaEmailAvvisi.isLoading}
                  >
                    <div>Invia <b>{d.avvisiPagamentoDaInviare}</b> email</div>
                    <div className="px-2 py-px bg-gray-700 bg-opacity-40 shadow-inner text-xs font-semibold text-white rounded-full">
                      {d.avvisiPagamentoMail -
                        d.avvisiPagamentoDaInviare +
                        ' / ' +
                        d.avvisiPagamentoMail}
                    </div>
                  </button>
                  {/* </div> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Documenti
